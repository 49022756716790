import NavBar from "../components/common/Navbar";
import Footer from "../components/common/Footer";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { Province } from "../interfaces/Provice";
import { SexualOrientation } from "../interfaces/SexualOrientation";
import { getProvinces, getSexualOrientations } from "../api/formSelectsData";
import AmigosTab from "../components/friend/AmigosTab";

export default function Amigos() {
  const { slug } = useParams();

  const [info, setInfo] = useState({
    orientations: [] as SexualOrientation[],
    provinces: [] as Province[],
  });

  useEffect(() => {
    const getData = async () => {
      const orientations = await getSexualOrientations();
      const provinces = await getProvinces();
      setInfo({
        orientations: orientations?.info?.data[0] ?? [],
        provinces: provinces?.info?.data[0] ?? [],
      });
    };
    getData();
  }, []);

  return (
    <div className=" h-screen ">
      <NavBar />
      <main className="flex justify-center">
        {slug != undefined && <AmigosTab slug={slug} {...info} />}
      </main>
      <Footer />
    </div>
  );
}
