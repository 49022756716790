import { create } from "zustand";

interface StepperState {
  step: number;
  setStep: (by: number) => void;
}

export const useStepperStore = create<StepperState>()((set) => ({
  step: 0,
  setStep: (by) => set(() => ({ step: by })),
}));
