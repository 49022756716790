import { SVGProps } from "react";

export function FireIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="20"
      height="24"
      viewBox="0 0 20 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.30859 0.750028C9.11272 0.751942 8.92537 0.830403 8.78659 0.968637C8.64781 1.10687 8.56861 1.29391 8.56592 1.48977C8.55292 2.38617 8.14931 3.11471 7.43066 3.91263C6.71202 4.71054 5.69063 5.52115 4.63428 6.45999C2.52158 8.33767 0.25 10.8461 0.25 15C0.25 17.055 0.997704 19.1396 2.61719 20.6968C4.23667 22.254 6.70119 23.25 10 23.25C13.2988 23.25 15.7633 22.254 17.3828 20.6968C19.0023 19.1396 19.75 17.055 19.75 15C19.75 11.1543 17.1783 7.66639 14.6963 5.10354C12.2143 2.54069 9.7334 0.87747 9.7334 0.87747C9.60788 0.79314 9.4598 0.748717 9.30859 0.750028ZM10.6636 10.2364C10.8293 10.249 10.9916 10.3159 11.1206 10.4356C13.9136 13.0508 14.5 15.7014 14.5 17.4624C14.5 19.8669 12.5131 21.7493 9.9751 21.7486C8.08735 21.7486 6.49294 20.7259 5.81494 19.0796C5.13919 17.4386 5.57259 15.5732 6.94434 14.2134C7.22709 13.9314 7.68009 13.923 7.97559 14.19C8.45109 14.6205 8.96961 14.6802 9.43311 14.3555C10.0826 13.9025 10.5098 12.7414 9.91357 11.2647C9.78157 10.9384 9.89286 10.564 10.1831 10.3653C10.3279 10.2655 10.4978 10.2237 10.6636 10.2364Z"
        fill="currentColor"
      />
    </svg>
  );
}
