import { Avatar, Card, CardFooter, Image, Tooltip } from "@nextui-org/react";
import Moment from "react-moment";
import { HeartIcon } from "../../assets/icons/HeartIcon";
import { Post } from "../../interfaces/Post";
import { FormEvent, useMemo, useState } from "react";
import { addPostReaction } from "../../api/publication";
// import CommentPostModal from "./CommentPostModal";
// import LightBoxImage from "../common/LightBoxImage";
import { useHomeStore } from "../../stores/homeData";
import { useAuthStore } from "../../stores/authStore";
import CommentPostModal from "./CommentPostModal";

export default function PostImage({ post }: { post: Post }) {
  const [ckeck, setCheck] = useState(post.info.myReacted);
  // const [isOpenModal, setIsOpenModal] = useState(false);
  const reactionType = useHomeStore((state) => state.reactionType);
  const authData = useAuthStore((state) => state.user);

  const [isOpenModal, setIsOpenModal] = useState(false);

  const handleReact = async (value: boolean) => {
    setCheck(value);

    if (value)
      await addPostReaction({
        publicacion_id: post.id,
        reaccion_id: reactionType, // TODO: integrate all type of reactions
      });
    else {
      await addPostReaction({
        publicacion_id: post.id,
        reaccion_id: 0,
      });
      // console.log(res);
    }
  };
  const onCheck = async (e: FormEvent<HTMLInputElement>) => {
    const input = e.target as HTMLInputElement;
    const value = input.checked;
    //
    await handleReact(value);
  };
  const url = useMemo(() => {
    let res = "";
    if (!post.content.startsWith("http")) res += import.meta.env.VITE_BASE_URL;

    res += post.content;
    if (post.media == "video") {
      res = res.replace("content", "frame.jpg");
      // console.log(res);
    }

    return res;
  }, [post.media]);

  return (
    <Card
      key={post.id}
      className="col-span-12 sm:col-span-6 md:col-span-4 h-[300px] pt-0 relative"
    >
      <Image
        removeWrapper
        isZoomed
        alt="Card background"
        className="z-0 w-full h-full object-cover cursor-pointer"
        src={url}
        onClick={() => {
          setIsOpenModal(true);
          // if (!post.blur || (post.blur && authData?.id == post.user.id))
          //   setIsOpenLightbox({
          //     open: true,
          //     type: "image",
          //     poster: "",
          //     src: url,
          //   });
        }}
      />

      {post.media == "video" && (
        <div className="absolute w-full h-full flex items-center justify-center text-white hover:text-gray-100 bg-black/10">
          <div
            className="hover:opacity-55 cursor-pointer"
            onClick={
              () => setIsOpenModal(true)
              // setIsOpenLightbox({
              //   open: true,
              //   type: "video",
              //   poster: url,
              //   src: urlVideo,
              // })
            }
          >
            <svg
              width="70"
              height="90"
              viewBox="0 0 130 130"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M65.0001 128.333C99.9781 128.333 128.333 99.9774 128.333 64.9993C128.333 30.0213 99.9781 1.66602 65.0001 1.66602C30.022 1.66602 1.66675 30.0213 1.66675 64.9993C1.66675 99.9774 30.022 128.333 65.0001 128.333Z"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M49.1667 45.7798C49.1667 42.757 49.1667 41.2456 49.7984 40.4018C50.3489 39.6665 51.1916 39.2064 52.1078 39.141C53.1592 39.0659 54.4305 39.8832 56.9732 41.5178L86.8703 60.7374C89.0768 62.1558 90.18 62.865 90.5611 63.7668C90.894 64.5548 90.894 65.4439 90.5611 66.2319C90.18 67.1337 89.0768 67.8429 86.8703 69.2613L56.9733 88.4809C54.4305 90.1155 53.1592 90.9328 52.1078 90.8577C51.1916 90.7923 50.3489 90.3322 49.7984 89.5969C49.1667 88.7531 49.1667 87.2417 49.1667 84.2189V45.7798Z"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        </div>
      )}
      {post.blur && authData?.id != post.user.id && (
        <div className="backdrop-blur-lg w-full h-full text-black absolute">
          <Tooltip
            className="translate-y-48"
            content={
              <div className="max-w-60 ">
                <p>Contenido solo para amigos.</p>
                <p>Envíale una solicitud de amistad para desbloquearlo.</p>
              </div>
            }
            placement="top"
          >
            <div className="w-full h-full"></div>
          </Tooltip>
        </div>
      )}
      <CardFooter className="absolute bg-black/20 bottom-0 z-10 border-t-1  border-black/10 backdrop-blur-md">
        <div className="flex flex-grow gap-2 items-center">
          <Avatar
            showFallback
            classNames={{
              base: "bg-zinc-100",
              icon: "text-black/50",
            }}
            src={
              post.user?.profile_path
                ? post.user?.profile_path.startsWith("http")
                  ? post.user?.profile_path
                  : import.meta.env.VITE_BASE_URL + post.user?.profile_path
                : undefined
            }
            className={`w-[50px] h-[50px] border-2 border-white/40`}
          />
          <div className="flex flex-col">
            <p className="text-tiny text-white">Publicado</p>
            <p className="text-tiny text-white">
              <Moment utc fromNow className="">
                {post.created_at}
              </Moment>
            </p>
          </div>
        </div>
        <div className="bg-white/10 p-[5px] pb-1 rounded-lg">
          <label className="like ">
            <input onChange={onCheck} checked={ckeck} type="checkbox"></input>
            <div className="checkmark flex">
              <HeartIcon stroke="#ffffffde" height={24} width={24} />
            </div>
          </label>
        </div>
      </CardFooter>
      <CommentPostModal
        showComments={true}
        isOpen={isOpenModal}
        closeModal={() => setIsOpenModal(false)}
        post={post}
      />
    </Card>
  );
}
