import NavBar from "../components/common/Navbar";
import Footer from "../components/common/Footer";
import { useEffect, useState } from "react";
import { useAuthStore } from "../stores/authStore";
import { getUser } from "../api/user";
import { Spinner } from "@nextui-org/react";

export default function SuccessPaid() {
  const authData = useAuthStore((state) => state.user);
  const setUserData = useAuthStore((state) => state.saveUser);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    if (authData) {
      const getData = async () => {
        setIsLoading(true);
        const userRes = await getUser(authData!.id);
        const userData = userRes.info.data[0];
        setUserData({
          ...authData,
          ...userData,
        });
        setIsLoading(false);
      };
      getData();
    }
  }, []);

  return (
    <div className=" h-screen ">
      <NavBar />
      <main className="flex justify-center">
        <div className="flex max-w-8xl w-full overflow-hidden pt-6 md:pt-18 min-h-[100vh] flex-col">
          <div className="px-4 md:px-8 w-full max-w-8xl mb-4">
            <section className="w-full mb-4">
              {isLoading ? (
                <div className="w-full flex justify-center pt-20 h-[50vh]">
                  <Spinner color="primary" />
                </div>
              ) : authData?.is_payed ? (
                <div className="mt-2 flex flex-col items-center">
                  <h1 className="text-xl font-semibold">
                    El pago se ha realizado correctamente!
                  </h1>
                  <p className="mt-10">
                    Ahora puedes disfrutar de todas las ventajas de ser premium
                    🎉
                  </p>
                </div>
              ) : (
                <div className="mt-2 flex flex-col items-center">
                  <h1 className="text-xl font-semibold">
                    Parece que no se ha procesado correctamente el pago
                  </h1>
                  <p className="mt-10">
                    Inténtalo otra vez o contacta con los administradores
                  </p>
                </div>
              )}
            </section>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
}
