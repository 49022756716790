import { AxiosError } from "axios";
import { Api } from ".";
import axiosClient from "../utils/axios";

export async function getFantasies(query?: string) {
	try {
		const q = query ? `?query=${query}` : "";
		return (await axiosClient.get(`${Api.fantasy.fantasyList}${q}`, {})).data;
	} catch (error) {
		return (error as AxiosError).response?.data;
	}
}
export async function getFantasiesPersonal(query = "", page = 1, take = 16) {
	try {
		// Construimos la query string
		let q = `?page=${page}&take=${take}`;
		if (query) {
			// Añade & si ya tienes ?page=...
			q += `&query=${query}`;
		}
		const { data } = await axiosClient.get(`${Api.fantasy.fantasyList}${q}`);
		return data;
	} catch (error) {
		return (error as AxiosError).response?.data;
	}
}
export async function getFantasy(id: number) {
	try {
		return (await axiosClient.get(`${Api.fantasy.fantasyList}/${id}`, {})).data;
	} catch (error) {
		return (error as AxiosError).response?.data;
	}
}
export async function getFantasySlug(slug: string) {
	try {
		return (await axiosClient.get(`${Api.fantasy.fantasyList}/${slug}`, {})).data;
	} catch (error) {
		return (error as AxiosError).response?.data;
	}
}

export async function getHoroscopes() {
	try {
		return (await axiosClient.get(Api.horoscope.horoscopeList, {})).data;
	} catch (error) {
		return (error as AxiosError).response?.data;
	}
}

export async function getLangueages() {
	try {
		return (await axiosClient.get(Api.langueage.langueageList, {})).data;
	} catch (error) {
		return (error as AxiosError).response?.data;
	}
}

export async function getSexualOrientations() {
	try {
		return (await axiosClient.get(Api.sexualOrientation.orientationList, {})).data;
	} catch (error) {
		return (error as AxiosError).response?.data;
	}
}

export async function getProvinces() {
	try {
		return (await axiosClient.get(Api.province.provinceList, {})).data;
	} catch (error) {
		return (error as AxiosError).response?.data;
	}
}

export async function getProfesions() {
	try {
		return (await axiosClient.get(Api.profesion.profesionList, {})).data;
	} catch (error) {
		return (error as AxiosError).response?.data;
	}
}

export async function getLookingFor() {
	try {
		return (await axiosClient.get(Api.looking.lookingForList, {})).data;
	} catch (error) {
		return (error as AxiosError).response?.data;
	}
}
export async function getCities() {
	try {
		return (await axiosClient.get(Api.city.list)).data;
	} catch (error) {
		return (error as AxiosError).response?.data;
	}
}
