import {
  Image,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Tooltip,
} from "@nextui-org/react";
import { Fantasy } from "../../interfaces/Fantasy";
import { Province } from "../../interfaces/Provice";
import { infoDetals, useUserStore } from "../../stores/userStore";
import { useEffect, useMemo, useState } from "react";
import { Country } from "../../interfaces/Country";
import { getCountries } from "../../api/nationality";
import { Link } from "react-router-dom";
import { Map, Marker } from "@vis.gl/react-google-maps";
// import { LocationIcon } from "../../assets/icons/LockationIcon";
import { getLookingFor } from "../../api/formSelectsData";
import ProfileSection from "./ProfileSection";
import TruncatedText from "./TruncatedText";

const INITIAL_CENTER = { lat: 40.416775, lng: -3.70379 };

export default function AboutUser({
  // provinces,
  fantasies,
  isLoading = false,
}: {
  provinces: Province[];
  fantasies: Fantasy[];
  isLoading?: boolean;
}) {
  const userData = useUserStore((state) => state.user);
  const [countries, setCountries] = useState<Country[]>([]);
  const [loading, setLoading] = useState(false);
  const [center, setCenter] = useState<
    { lat: number; lng: number } | undefined
  >();
  const [lookingForOptions, setLookingForOptions] = useState<
    { id: number; name: string }[]
  >([]);
  // const [showMore, setShowMore] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPartnerModalOpen, setIsPartnerModalOpen] = useState(false);

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      if (roleId !== "3") {
        const countriesRes = await getCountries();
        setCountries(countriesRes);
      } else {
        try {
          const aux = userData?.info?.ubicacion?.split("|");
          if (aux && aux.length === 2) {
            const lat = parseFloat(aux[0]?.toString());
            const lng = parseFloat(aux[1]?.toString());
            setCenter({ lat, lng });
          } else setCenter(INITIAL_CENTER);
        } catch (error) {
          console.error(error);
        }
      }
      setLoading(false);
    };

    getData();
  }, []);

  useEffect(() => {
    const fetchLookingFor = async () => {
      const data = await getLookingFor();
      if (data && data.info?.data) {
        setLookingForOptions(data.info.data[0]);
      }
    };
    fetchLookingFor();
  }, []);

  const looking = (data: string | undefined) => {
    if (!data) return [];

    const ids = data.split(",").map((id) => parseInt(id.trim())); // Convierte los IDs a números
    const combinedOptions = [
      ...(Array.isArray(userData?.info?.looking_id)
        ? userData.info.looking_id
        : []),
      ...lookingForOptions,
    ];

    // Encuentra los nombres correspondientes a los IDs y devuelve un arreglo
    return ids
      .map((id) => combinedOptions.find((option) => option.id === id)?.name)
      .filter(Boolean); // Filtra valores undefined o null
  };

  const nationality = useMemo(() => {
    if (countries?.length && userData?.info?.detalles?.nacionalidad_id) {
      const aux = countries.find(
        (e) => e.cca2 === userData?.info?.detalles?.nacionalidad_id
      );
      if (aux) return aux.name.common;
    }
    return "España";
  }, [countries]);

  const theyarelooking = looking(userData?.info?.looking_id?.toString());
  const roleId = userData?.role_id?.toString();
  const couple = userData?.info?.is_couple.toString();

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8 ">
      <div className="bg-white rounded-xl mt-2">
        {roleId !== "3" ? (
          <div className="divide-y divide-gray-200 border-1 border-color2/20 rounded-2xl">
            {/* Sección Principal */}
            <div className="p-6 flex justify-center items-start flex-col gap-6">
              <div
                className={`grid grid-cols-1  gap-8 ${
                  couple == "1" && "md:grid-cols-2"
                }`}
              >
                {userData?.role_id !== "3" && (
                  <div className="space-y-2">
                    <h3 className="font-medium w-full text-color1">Sobre mí</h3>
                    <TruncatedText
                      text={userData?.info?.detalles?.description?.value || ""}
                      maxCharsPerLine={180} // Cada línea tendrá hasta 30 caracteres (aproximadamente)
                      maxLines={3} // Se muestran 3 líneas máximo
                      onVerMas={() => setIsModalOpen(true)}
                    />
                    {/* Modal para "Sobre mí" */}
                    {isModalOpen && (
                      <div
                        className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50"
                        onClick={() => setIsModalOpen(false)}
                      >
                        <div
                          className="bg-white p-8 rounded-md shadow-lg max-w-3xl w-full mx-4 overflow-auto max-h-[90vh]"
                          onClick={(e) => e.stopPropagation()}
                        >
                          <h2 className="mb-4 text-lg font-semibold">
                            Descripción completa
                          </h2>
                          <p className="text-sm whitespace-normal break-words">
                            {userData?.info?.detalles?.description?.value ||
                              "Sin descripción"}
                          </p>
                          <button
                            onClick={() => setIsModalOpen(false)}
                            className="mt-4 inline-block px-4 py-2 text-sm bg-primary text-white rounded-md hover:bg-primary-dark"
                          >
                            Cerrar
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                )}

                {/* Sección "Mi pareja" */}
                {couple === "1" && (
                  <div className="space-y-2">
                    <h3 className="font-medium text-color1">Mi pareja</h3>
                    <TruncatedText
                      text={
                        userData?.info?.detalles?.partner_description?.value ||
                        ""
                      }
                      maxCharsPerLine={30} // Cada línea tendrá hasta 30 caracteres (aproximadamente)
                      maxLines={3} // Se muestran 3 líneas máximo
                      onVerMas={() => setIsPartnerModalOpen(true)}
                    />
                    {/* Modal para "Mi pareja" */}
                    {isPartnerModalOpen && (
                      <div
                        className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50"
                        onClick={() => setIsPartnerModalOpen(false)}
                      >
                        <div
                          className="bg-white p-8 rounded-md shadow-lg max-w-3xl w-full mx-4 overflow-auto max-h-[90vh]"
                          onClick={(e) => e.stopPropagation()}
                        >
                          <h2 className="mb-4 text-lg font-semibold">
                            Descripción completa de mi pareja
                          </h2>
                          <p className="text-sm whitespace-normal break-words">
                            {userData?.info?.detalles?.partner_description
                              ?.value || "Sin descripción"}
                          </p>
                          <button
                            onClick={() => setIsPartnerModalOpen(false)}
                            className="mt-4 inline-block px-4 py-2 text-sm bg-primary text-white rounded-md hover:bg-primary-dark"
                          >
                            Cerrar
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>

              {/* Información Adicional */}
              <div className="grid grid-cols-1 md:grid-cols-2  lg:grid-cols-3 gap-6  sm:px-0">
                {/* Qué Busco */}
                <div className=" flex flex-col  justify-center gap-2">
                  <h3 className="font-medium text-color1">Qué Busco</h3>
                  <div className="relative">
                    {theyarelooking.length > 0 ? (
                      <div className="inline-flex flex-wrap justify-start items-start gap-2">
                        <span className="font-medium text-sm w-full md:w-auto">
                          {theyarelooking[0]}
                        </span>

                        {theyarelooking.length > 1 && (
                          <>
                            <div className="hidden lg:flex">
                              <Tooltip
                                placement="bottom"
                                content={
                                  <div className=" py-2 w-full md:w-auto min-w-[200px]">
                                    {theyarelooking
                                      .slice(1)
                                      .map((item, index) => (
                                        <div
                                          key={index}
                                          className="px-4 py-1 hover:bg-gray-50 text-gray-700"
                                        >
                                          {item}
                                        </div>
                                      ))}
                                  </div>
                                }
                              >
                                <span className="font-semibold px-2 py-0.5 text-gray-700 bg-gray-100 rounded-lg text-sm">
                                  + {theyarelooking.length - 1}
                                </span>
                              </Tooltip>
                            </div>
                            <div className="lg:hidden">
                              <Popover placement="right">
                                <PopoverTrigger>
                                  <span className="font-semibold px-2 py-0.5 text-gray-700 bg-gray-100 rounded-lg text-sm">
                                    + {theyarelooking.length - 1}
                                  </span>
                                </PopoverTrigger>
                                <PopoverContent>
                                  <div className=" py-2 w-full md:w-auto min-w-[200px]">
                                    {theyarelooking
                                      .slice(1)
                                      .map((item, index) => (
                                        <div
                                          key={index}
                                          className="px-4 py-1 hover:bg-gray-50 text-gray-700"
                                        >
                                          {item}
                                        </div>
                                      ))}
                                  </div>
                                </PopoverContent>
                              </Popover>
                            </div>
                          </>
                        )}
                      </div>
                    ) : (
                      <div className=" py-2 w-full md:w-auto min-w-[200px]">
                        {theyarelooking.slice(1).map((item, index) => (
                          <div
                            key={index}
                            className="px-4 py-1 hover:bg-gray-50 text-gray-700"
                          >
                            {item}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>

                {/* Fantasías Destacadas */}
                <ProfileSection title="Fantasías Destacadas">
                  {isLoading ? (
                    <span className="text-gray-500">Cargando...</span>
                  ) : fantasies?.length ? (
                    <div className="flex flex-row flex-wrap w-full">
                      <div className="inline-flex flex-row flex-wrap w-full items-start jusrify-start ">
                        {fantasies.slice(0, 1).map((fantasy) => (
                          <Link
                            key={fantasy.id}
                            to={`/fantasia/${fantasy.slug}`}
                            className="w-full md:w-auto hover:text-primary"
                          >
                            <span className="font-semibold text-sm w-full mr-2 d:w-auto">
                              {fantasy.name}
                            </span>
                          </Link>
                        ))}
                        {fantasies.length > 1 && (
                          <>
                            <div className="hidden lg:flex">
                              <Tooltip
                                placement="bottom"
                                content={
                                  <span className=" w-full md:w-auto min-w-[200px]">
                                    {fantasies.slice(1, 5).map((fantasy) => (
                                      <Link
                                        key={fantasy.id}
                                        to={`/fantasia/${fantasy.slug}`}
                                        className="w-full"
                                      >
                                        <div className="px-4 py-2 hover:bg-gray-50 text-gray-700">
                                          {fantasy.name}
                                        </div>
                                      </Link>
                                    ))}
                                    {fantasies.length > 5 && (
                                      <Link to="/fantasies" className="w-full">
                                        <div className="px-4 py-2 mt-1 text-center text-sm font-medium text-gray-700 hover:bg-gray-50 border-t border-gray-200">
                                          Ver todas
                                        </div>
                                      </Link>
                                    )}
                                  </span>
                                }
                              >
                                <span className=" text-sm px-2 py-0.5 bg-gray-100 rounded-lg lg:w-full md:w-auto font-semibold">
                                  + {fantasies.length - 1}
                                </span>
                              </Tooltip>
                            </div>
                            <div className="lg:hidden">
                              <Popover placement="right">
                                <PopoverTrigger>
                                  <span className=" text-sm px-2 py-0.5 bg-gray-100 rounded-lg lg:w-full md:w-auto font-semibold">
                                    + {fantasies.length - 1}
                                  </span>
                                </PopoverTrigger>
                                <PopoverContent>
                                  <span className=" w-full md:w-auto min-w-[200px]">
                                    {fantasies.slice(1, 5).map((fantasy) => (
                                      <Link
                                        key={fantasy.id}
                                        to={`/fantasia/${fantasy.slug}`}
                                        className="w-full"
                                      >
                                        <div className="px-4 py-2 hover:bg-gray-50 text-gray-700">
                                          {fantasy.name}
                                        </div>
                                      </Link>
                                    ))}
                                    {fantasies.length > 5 && (
                                      <Link to="/fantasies" className="w-full">
                                        <div className="px-4 py-2 mt-1 text-center text-sm font-medium text-gray-700 hover:bg-gray-50 border-t border-gray-200">
                                          Ver todas
                                        </div>
                                      </Link>
                                    )}
                                  </span>
                                </PopoverContent>
                              </Popover>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  ) : (
                    <span className="text-gray-500">Sin Fantasías</span>
                  )}
                </ProfileSection>

                {/* Nacionalidad */}
                <div className=" flex items-start justify-start flex-col lg:pl-16  ">
                  <h3 className="font-medium text-color1 mb-1.5">
                    Nacionalidad
                  </h3>
                  <div className="flex flex-col md:flex-row items-start md:items-center gap-2 ">
                    {loading ? (
                      <span className="text-gray-500">Cargando...</span>
                    ) : (
                      <>
                        <Image
                          radius="md"
                          src={`https://flagcdn.com/${infoDetals()?.nacionalidad_id?.toLocaleLowerCase()}.svg`}
                          fallbackSrc="https://flagcdn.com/es.svg"
                          className="w-10  rounded-sm  mt-1"
                          loading="lazy"
                          classNames={{
                            wrapper: "bg-center bg-no-repeat bg-cover",
                          }}
                        />
                        <span className="text-gray-700 text-sm sm:text-base break-words">
                          {nationality}
                        </span>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="p-6">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
              <div className="space-y-2">
                <h3 className="text-lg font-medium text-gray-900">
                  Nombre del local
                </h3>
                <p className="text-gray-700">{userData?.info?.locals_name}</p>
              </div>
              <div className="space-y-2">
                <h3 className="text-lg font-medium text-gray-900">Teléfono</h3>
                <p className="text-gray-700">{userData?.info?.movil}</p>
              </div>
              <div className="space-y-2">
                <h3 className="text-lg font-medium text-gray-900">Dirección</h3>
                <p className="text-gray-700">
                  {userData?.info?.direccion ?? "..."}
                </p>
              </div>
            </div>
          </div>
        )}
      </div>

      {roleId === "3" && (
        <div className="mt-6">
          <div className="bg-white rounded-xl shadow-sm overflow-hidden h-[400px]">
            {!center ? (
              <div className="h-full flex items-center justify-center text-gray-500">
                Cargando...
              </div>
            ) : (
              <Map
                defaultCenter={center}
                defaultZoom={10}
                gestureHandling={"greedy"}
                disableDefaultUI={true}
                scrollwheel={false}
                zoomControl={true}
              >
                <Marker position={center} />
              </Map>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
