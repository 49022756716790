import { Key } from "react";
import { User } from "../../interfaces/Login";
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownSection,
  DropdownTrigger,
} from "@nextui-org/react";
import { MenuIcon } from "../../assets/icons/MenuIcon";
import { CopyIcon } from "../../assets/icons/CopyIcon";
import { useAuthStore } from "../../stores/authStore";
interface MenuItem {
  key: string;
  icon: JSX.Element;
  label: string;
}
const menuItems = [
  {
    key: "copy",
    icon: <CopyIcon />,
    label: "Copiar enlace al perfil",
  },
];
export default function ProfileActionsDropdown({
  userData,
  menuDangerItems,
  onItemClick,
}: {
  userData: User | null;
  menuDangerItems: MenuItem[];
  onItemClick: (key: Key) => void;
}) {
  const authData = useAuthStore((state) => state.user);
  return (
    <Dropdown>
      <DropdownTrigger>
        <Button
          className="border-1"
          variant="bordered"
          isIconOnly
          aria-label="menu"
        >
          <MenuIcon />
        </Button>
      </DropdownTrigger>
      <DropdownMenu
        variant="flat"
        aria-label="Dropdown menu"
        onAction={onItemClick}
      >
        <DropdownSection title="" showDivider={userData?.id != authData?.id}>
          {menuItems.map((e) => (
            <DropdownItem key={e.key} startContent={e.icon} color="primary">
              {e.label}
            </DropdownItem>
          ))}
        </DropdownSection>

        <DropdownSection
          title=""
          className={userData?.id == authData?.id ? "hidden" : ""}
        >
          {menuDangerItems.map((e) => (
            <DropdownItem
              key={e.key}
              className="text-danger"
              color="danger"
              startContent={e.icon}
            >
              {e.label}
            </DropdownItem>
          ))}
        </DropdownSection>
      </DropdownMenu>
    </Dropdown>
  );
}
