import { AxiosError } from "axios";
import { Api } from ".";
import axiosClient from "../utils/axios";

export async function sendFriendRequest(data: any) {
	try {
		return (await axiosClient.post(`${Api.friends.sendFriendRequest}`, data)).data;
	} catch (error) {
		return (error as AxiosError).response?.data;
	}
}
export async function respondFriendRequest(id: number, data: any) {
	try {
		return (await axiosClient.put(`${Api.friends.sendFriendRequest}/${id}`, data)).data;
	} catch (error) {
		return (error as AxiosError).response?.data;
	}
}
export async function getFriendRequests(enviadas: number) {
	try {
		const response = (await axiosClient.get(`${Api.friends.sendFriendRequest}?enviadas=${enviadas}`)).data;
		if (response?.info?.data[0]?.data) {
			response.info.data[0].data = (response?.info?.data[0]?.data as any[]).map((it) => {
				return {
					//TODO: clean this with a mapper
					...it,
					from: it.from?.id
						? {
								...it.from,
								info: {
									...it.from.perfil,
									como_soy: it.from.perfil?.como_soy
										? JSON.parse(it.from.perfil?.como_soy.toString())
										: null,
									detalles: it.from.perfil?.detalles
										? JSON.parse(it.from.perfil?.detalles.toString())
										: null,
									otros: it.from.perfil?.otros ? JSON.parse(it.from.perfil?.otros.toString()) : null,
								},
						  }
						: it.from,
					to: it.to?.id
						? {
								...it.to,
								info: {
									...it.to.perfil,
									como_soy: it.to.perfil?.como_soy
										? JSON.parse(it.to.perfil?.como_soy.toString())
										: null,
									detalles: it.to.perfil?.detalles
										? JSON.parse(it.to.perfil?.detalles.toString())
										: null,
									otros: it.to.perfil?.otros ? JSON.parse(it.to.perfil?.otros.toString()) : null,
								},
						  }
						: it.to,
				};
			});
		}
		return response;
	} catch (error) {
		return (error as AxiosError).response?.data;
	}
}

export async function getFriends(id: number) {
	try {
		const response = (await axiosClient.get(`${Api.friends.getFriends}/${id}`)).data;
		if (response?.info?.data[0]?.data) {
			response.info.data[0].data = (response?.info?.data[0]?.data as any[]).map((it) => {
				return {
					//TODO: clean this with a mapper
					...it,
					from: it.from?.id
						? {
								...it.from,
								info: {
									...it.from.perfil,
									como_soy: it.from.perfil?.como_soy
										? JSON.parse(it.from.perfil?.como_soy.toString())
										: null,
									detalles: it.from.perfil?.detalles
										? JSON.parse(it.from.perfil?.detalles.toString())
										: null,
									otros: it.from.perfil?.otros ? JSON.parse(it.from.perfil?.otros.toString()) : null,
								},
						  }
						: it.from,
					to: it.to?.id
						? {
								...it.to,
								info: {
									...it.to.perfil,
									como_soy: it.to.perfil?.como_soy
										? JSON.parse(it.to.perfil?.como_soy.toString())
										: null,
									detalles: it.to.perfil?.detalles
										? JSON.parse(it.to.perfil?.detalles.toString())
										: null,
									otros: it.to.perfil?.otros ? JSON.parse(it.to.perfil?.otros.toString()) : null,
								},
						  }
						: it.to,
				};
			});
		}
		return response;
	} catch (error) {
		return (error as AxiosError).response?.data;
	}
}
export async function getFriendsSlug(slug: string) {
	try {
		const response = (await axiosClient.get(`${Api.friends.getFriends}/${slug}`)).data;
		if (response?.info?.data[0]?.data) {
			response.info.data[0].data = (response?.info?.data[0]?.data as any[]).map((it) => {
				return {
					//TODO: clean this with a mapper
					...it,
					from: it.from?.id
						? {
								...it.from,
								info: {
									...it.from.perfil,
									como_soy: it.from.perfil?.como_soy
										? JSON.parse(it.from.perfil?.como_soy.toString())
										: null,
									detalles: it.from.perfil?.detalles
										? JSON.parse(it.from.perfil?.detalles.toString())
										: null,
									otros: it.from.perfil?.otros ? JSON.parse(it.from.perfil?.otros.toString()) : null,
								},
						  }
						: it.from,
					to: it.to?.id
						? {
								...it.to,
								info: {
									...it.to.perfil,
									como_soy: it.to.perfil?.como_soy
										? JSON.parse(it.to.perfil?.como_soy.toString())
										: null,
									detalles: it.to.perfil?.detalles
										? JSON.parse(it.to.perfil?.detalles.toString())
										: null,
									otros: it.to.perfil?.otros ? JSON.parse(it.to.perfil?.otros.toString()) : null,
								},
						  }
						: it.to,
				};
			});
		}
		return response;
	} catch (error) {
		return (error as AxiosError).response?.data;
	}
}

export async function deleteFriend(id: number) {
	try {
		return (await axiosClient.delete(`${Api.friends.getFriends}/${id}`)).data;
	} catch (error) {
		return (error as AxiosError).response?.data;
	}
}

export async function deleteFriendRequest(userId: number) {
	try {
		const response = await axiosClient.delete(`${Api.friends.sendFriendRequest}/${userId}`);
		return response.data;
	} catch (error) {
		return (error as AxiosError).response?.data;
	}
}

export async function blockUser(data: any) {
	try {
		return (await axiosClient.post(`${Api.block.index}`, data)).data;
	} catch (error) {
		return (error as AxiosError).response?.data;
	}
}

export async function getBlockedUsers(page?: number) {
	try {
		return (await axiosClient.get(`${Api.block.index}?page${page ?? 1}`)).data;
	} catch (error) {
		return (error as AxiosError).response?.data;
	}
}

export async function deleteBlockUser(id: number) {
	try {
		return (await axiosClient.delete(`${Api.block.index}/${id}`)).data;
	} catch (error) {
		return (error as AxiosError).response?.data;
	}
}
