import { Skeleton } from "@nextui-org/react";
import { GlobalVisitsIcon, GlobalFriendsIcon, GlobalReactionsIcon } from "../../assets/icons/GlobalDataIcons";
import { Global } from "../../interfaces/SingleUser";
import { Link } from "react-router-dom";
import { useAuthStore } from "../../stores/authStore";
export default function ProfileBigGlobal({
	globalData,
	isLoadingGlobal,
}: {
	globalData: Global;
	isLoadingGlobal: boolean;
}) {
	const authData = useAuthStore((state) => state.user);
	return (
		<ul className="p-4 p py-6 gap-y-4 flex flex-col gap-2 border-1 border-color2/20 rounded-xl">
			<li className="flex items-center gap-3">
				<div>
					<GlobalVisitsIcon className="size-8" />
				</div>
				<div className="flex gap-2">
					{isLoadingGlobal ? (
						<Skeleton className="w-24 h-4 rounded-full" />
					) : (
						<>
							<label className="font-medium text-[16px] text-color5">{globalData.visits}</label>
							<Link to={"/perfiles?visitas=true"} className="font-semibold text-[16px] text-color7">
								Visitas
							</Link>
						</>
					)}
				</div>
			</li>
			<li className="flex items-center gap-3">
				<div>
					<GlobalFriendsIcon className="size-8" />
				</div>
				<div className="flex gap-2 ">
					{isLoadingGlobal ? (
						<Skeleton className="w-24 h-4 rounded-full" />
					) : (
						<>
							<label className="font-medium text-[16px] text-color5">{globalData.friends}</label>
							<Link
								to={`/amigos/${authData?.slug}?tab=amigos`}
								className="font-semibold text-[16px] text-color7"
							>
								Amigos
							</Link>
						</>
					)}
				</div>
			</li>
			<li className="flex items-center gap-3">
				<div>
					<GlobalReactionsIcon className="size-8" />
				</div>
				<div className="flex gap-2">
					{isLoadingGlobal ? (
						<Skeleton className="w-24 h-4 rounded-full" />
					) : (
						<>
							<label className="font-medium text-[16px] text-color5">{globalData.reactions}</label>
							<label className="font-semibold text-[16px] text-color7">Me gusta</label>
						</>
					)}
				</div>
			</li>
		</ul>
	);
}
