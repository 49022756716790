import { useState } from "react";
import { Post } from "../../interfaces/Post";
import CommentPostModal from "../post/CommentPostModal";

export default function LastPhotos({
  lastPhotos = [],
}: {
  lastPhotos: Post[];
}) {
  const [post, setPost] = useState<Post | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  const handleClick = (post: Post) => {
    setPost(post);
    setIsOpen(true);
  };

  return (
    <section className=" border-1 border-gray-300 w-full  rounded-2xl">
      <div className="p-4">
        <h3 className="font-bold text-[16px] text-black">Últimas Fotos</h3>
      </div>
      <hr className="border-0.5 border-gray-300"></hr>
      <div className="p-5 px-4 flex flex-col  gap-4 ">
        {lastPhotos.length > 0 && (
          <div className="grid grid-cols-4 gap-3">
            {lastPhotos.map((e) => (
              <div
                onClick={() => handleClick(e)}
                key={e.id}
                style={{
                  backgroundColor: "#ececee",
                  backgroundImage: ` url(${
                    e.content
                      ? e.content.startsWith("http")
                        ? e.content
                        : import.meta.env.VITE_BASE_URL + e.content
                      : undefined
                  })`,
                }}
                className="w-[46px] h-[46px] 2xl:w-[52px] 2xl:h-[52px] rounded-xl bg-cover bg-center cursor-pointer"
              />
            ))}
          </div>
        )}
        {lastPhotos.length == 0 && "No hay publicaciones"}
      </div>
      {post != null && (
        <CommentPostModal
          showComments={true}
          isOpen={isOpen}
          closeModal={() => {
            setIsOpen(false);
            setPost(null);
          }}
          post={post}
        />
      )}
    </section>
  );
}
