import { AxiosError } from "axios";
import { Api } from ".";
import axiosClient from "../utils/axios";

export async function uploadFile(data: any, onUploadProgress?: any) {
  try {
    return (
      await axiosClient.post(Api.upload.uploadFile, data, {
        ...onUploadProgress,
      })
    ).data;
  } catch (error) {
    return (error as AxiosError).response?.data;
  }
}
