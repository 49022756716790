import React from "react";

const LabiosIcon: React.FC = () => (
	<svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 64 64">
		<path
			fill="gray"
			d="M54.738 29.2c-3.651 3.666-12.458-.012-22.747-.012s-19.1 3.678-22.747.012c-1.164 1.68-1.809 3.513-1.809 5.436c0 7.971 10.992 14.431 24.555 14.431c13.561 0 24.555-6.46 24.555-14.431c.002-1.923-.643-3.756-1.807-5.436"
		/>
		<path
			fill="currentColor"
			d="M56.13 24.802c-5.303-7.05-10.697-9.345-10.697-9.345s-4.546-2.732-7.481 1.292c-1.884 2.587-4.391 3.682-5.955 4.139c-1.563-.457-4.071-1.551-5.955-4.139c-2.936-4.02-7.482-1.292-7.482-1.292s-5.396 2.299-10.696 9.345c-5.302 7.05-7.859 5.03-7.859 5.03s8.521 8.627 19.407 1.869c0 0 3.855-3.01 12.584.877c8.729-3.886 12.584-.877 12.584-.877c10.886 6.758 19.407-1.869 19.407-1.869s-2.555 2.01-7.857-5.03"
		/>
		<path
			fill="#f4f5f5"
			d="M40.67 17.09s6.04-1.764 13.09 8.05c0 0-6.921-5.916-11.326-5.79s-1.764-2.264-1.764-2.264M20.14 43.18s-4.548 1.33-9.854-6.06c0 0 5.21 4.452 8.525 4.358c3.319-.096 1.329 1.704 1.329 1.704"
		/>
	</svg>
);

export default LabiosIcon;
