import { Input } from "@nextui-org/react";

export default function CodeInput({ isValid = false, setValid = () => {} }) {
  function focusNextInput(
    el: HTMLInputElement,
    prevId: string | null,
    nextId: string | null
  ) {
    if (el.value.length === 0) {
      if (prevId) document.getElementById(prevId)?.focus();
    } else {
      if (nextId) document.getElementById(nextId)?.focus();
    }
  }

  const onKeyUpEvent = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement;
    const prevId = target.getAttribute("data-focus-input-prev");
    const nextId = target.getAttribute("data-focus-input-next");
    focusNextInput(target, prevId, nextId);
  };

  const onChangeEvent = () => {
    setValid();

    //Generate the complete code in input id="code"
    let codeComplete = "";

    Array(4)
      .fill(0)
      .map((_, i) => {
        codeComplete += (
          document.getElementById(`code-${i + 1}`) as HTMLInputElement
        )?.value;
      });
    const inputCode = document.getElementById("code") as HTMLInputElement;
    inputCode.value = codeComplete;
  };

  return (
    <>
      {[0, 0, 0, 0].map((_, i) => (
        <div key={i}>
          <Input
            variant="bordered"
            classNames={{
              inputWrapper:
                "group-data-[focus=true]:border-color4 border-2 border-color4 md:w-[80px] md:h-[80px] w-[64px] h-[64px] rounded-md",
              input:
                "text-center mb-1 p-1 lg:p-2 text-[48px] font-medium text-color4 placeholder:text-color1 border-0 focus:ring-1 focus:ring-transparent",
            }}
            onChange={onChangeEvent}
            onKeyUp={onKeyUpEvent}
            type="text"
            maxLength={1}
            data-focus-input-init
            data-focus-input-prev={i > 0 ? `code-${i}` : ""}
            data-focus-input-next={`code-${i + 2}`}
            id={`code-${i + 1}`}
            name={`code-${i + 1}`}
            data-invalid={isValid}
            placeholder="0"
            required
          />
        </div>
      ))}
      <input id="code" name="code" className="hidden" />
    </>
  );
}
