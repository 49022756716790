import { Modal, ModalContent } from "@nextui-org/react";
import PrimaryButton from "./PrimaryButton";
import SecondaryButton from "./SecondaryButton";
import PremiumImage from "../../assets/premium-image.png";

export default function PremiumModal({
  isOpen = false,
  closeModal = () => {},
  onViewPlans,
}: {
  isOpen: boolean;
  closeModal?: () => void;
  onViewPlans: () => void;
}) {
  return (
    <>
      <Modal
        className="max-w-[400px]"
        isOpen={isOpen}
        placement="center"
        hideCloseButton
        classNames={{
          closeButton: "mt-4 mr-4",
          base: "rounded-xl border-none",
        }}
      >
        <ModalContent>
          <div className="p-6">
            <div className="flex justify-center">
              <div className="text-center">
                <img src={PremiumImage} className="" />
                <h3 className="mt-6 font-semibold text-color3 text-[18px]">
                  Hazte premium para disfrutar de todas las ventajas de Loouth
                </h3>
                <p className="mt-2 font-normal text-[14px] text-color5">
                  Solo los usuarios premium pueden conectar con otras personas,
                  ver perfiles y hacer uso de las funcionalidades sociales de la
                  plataforma. De esta forma, garantizamos la calidad de los
                  usuarios de Loouth.
                </p>
              </div>
            </div>
            <div className="mt-8 flex justify-center gap-[12px]">
              <SecondaryButton className="w-full" onClick={closeModal}>
                Cancelar
              </SecondaryButton>
              <PrimaryButton className="w-full" onClick={onViewPlans}>
                Ver Planes
              </PrimaryButton>
            </div>
          </div>
        </ModalContent>
      </Modal>
    </>
  );
}
