import { AxiosError } from "axios";
import { Api } from ".";
import axiosClient from "../utils/axios";
import { ChatsResponse } from "../interfaces/Chat";

interface Filters {
  page?: number;
}

export async function getChats(filters?: Filters) {
  try {
    const response = (
      await axiosClient.get(`${Api.chat.index}?page=${filters?.page ?? 1}`)
    ).data;
    if (response?.info?.data["0"]?.data) {
      response.info.data["0"].data = (
        response.info.data["0"].data as ChatsResponse[]
      ).map((e) => {
        return {
          ...e,
          name: JSON.parse(e.name.toString()),
        };
      });
    }

    return response;
  } catch (error) {
    return (error as AxiosError).response?.data;
  }
}

export async function getChat(id: number) {
  try {
    return (await axiosClient.get(`${Api.chat.index}/${id}`)).data;
  } catch (error) {
    return (error as AxiosError).response?.data;
  }
}
export async function sendMessageToUser(id: number, data: any) {
  try {
    return (await axiosClient.post(`${Api.chat.toUser}/${id}`, data)).data;
  } catch (error) {
    return (error as AxiosError).response?.data;
  }
}

export async function sendMessageOnChat(id: number, data: any) {
  try {
    return (await axiosClient.post(`${Api.chat.index}/chat/${id}`, data)).data;
  } catch (error) {
    return (error as AxiosError).response?.data;
  }
}

export async function markAsREad(data: string) {
  try {
    return (
      await axiosClient.post(`${Api.chat.messages}/read`, {
        messages: data,
      })
    ).data;
  } catch (error) {
    return (error as AxiosError).response?.data;
  }
}

export async function getPastMessages(lastId: number, chatId: number) {
  try {
    return (
      await axiosClient.get(
        `${Api.chat.messages}?desde=${lastId}&chat_id=${chatId}&paginate=15`
      )
    ).data;
  } catch (error) {
    return (error as AxiosError).response?.data;
  }
}
