import { Modal, ModalContent, Textarea } from "@nextui-org/react";
import SecondaryButton from "../common/SecondaryButton";
import PrimaryButton from "../common/PrimaryButton";
import { FormEvent, useState } from "react";
import { toast } from "react-toastify";
import { CloseIcon } from "../../assets/icons/CloseIcon";
import CustomInput from "../form/CustomInput";
import { reportUser } from "../../api/user";

export default function ReportUserModalpModal({
  isOpen = false,
  closeModal = () => {},
  name = "",
  id = 0,
}: {
  isOpen: boolean;
  name: string;
  id: number;
  closeModal?: () => void;
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState({
    user_id: id,
    title: "",
    resumen: "",
  });
  const setFormData = (name: string, value: string) => {
    setData({
      ...data,
      [name]: value,
    });
  };
  const onReport = async () => {
    setIsLoading(true);
    const response = await reportUser(data);
    if (response.error == "true" || !response.info)
      toast.error(response?.info?.message || "Ha ocurrido un error");
    else {
      toast.success("Usuario reportado con exito");
      closeModal();
    }

    setIsLoading(false);
  };
  const handleChangeInput = (e: FormEvent<HTMLInputElement>) => {
    const data = e.target as HTMLInputElement;
    //setFormData(data.name as VisibilityNm, data.value);
    setFormData(data.name, data.value);
  };
  return (
    <Modal
      className="max-w-[400px]"
      isOpen={isOpen}
      onClose={closeModal}
      placement="center"
      classNames={{ closeButton: "mt-4 mr-4", base: "rounded-xl border-none" }}
      closeButton={<CloseIcon />}
    >
      <ModalContent>
        <div className="p-6">
          <div className="flex justify-center">
            <div className="text-start">
              <div>
                <svg
                  width="57"
                  height="57"
                  viewBox="0 0 57 57"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    x="4.5"
                    y="4.5"
                    width="48"
                    height="48"
                    rx="24"
                    fill="#FEE4E2"
                  />
                  <path
                    d="M33 32.5L38 37.5M38 32.5L33 37.5M28.5 32H24C22.6044 32 21.9067 32 21.3389 32.1722C20.0605 32.56 19.06 33.5605 18.6722 34.8389C18.5 35.4067 18.5 36.1044 18.5 37.5M31 24C31 26.4853 28.9853 28.5 26.5 28.5C24.0147 28.5 22 26.4853 22 24C22 21.5147 24.0147 19.5 26.5 19.5C28.9853 19.5 31 21.5147 31 24Z"
                    stroke="#D92D20"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
              <h3 className="mt-4 font-semibold text-color3 text-[18px] mb-3">
                ¿Estas seguro de que quieres reportar a {name}?
              </h3>
              <div className="w-full mt-3">
                <CustomInput
                  placeholder="Causa del reporte"
                  label="Causa"
                  name="title"
                  required
                  showVisibility={false}
                  onChange={handleChangeInput}
                  value={data.title}
                ></CustomInput>
              </div>

              <div className="w-full mt-1 lg:col-span-2 ">
                <Textarea
                  id="description"
                  name="resumen"
                  isRequired
                  variant="bordered"
                  placeholder="Descripción"
                  label="Describe la causa del reporte"
                  labelPlacement="outside"
                  required
                  minRows={4}
                  color="primary"
                  classNames={{
                    inputWrapper: "rounded-lg border-small",
                    label: "text-color2",
                    input: "border-0 focus:ring-1 focus:ring-transparent",
                  }}
                  onChange={handleChangeInput}
                  value={data.resumen}
                />
              </div>
            </div>
          </div>
          <div className="mt-8 flex justify-center gap-[12px]">
            <SecondaryButton
              className="text-color4 w-full"
              onClick={closeModal}
              disabled={isLoading}
            >
              Cancelar
            </SecondaryButton>
            <PrimaryButton
              onClick={onReport}
              className="bg-color10 w-full"
              loading={isLoading}
            >
              Reportar
            </PrimaryButton>
          </div>
        </div>
      </ModalContent>
    </Modal>
  );
}
