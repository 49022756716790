import NavBar from "../components/common/Navbar";
import Footer from "../components/common/Footer";
import { useEffect, useState } from "react";
import { getSavedPosts } from "../api/publication";
import { SavedPost } from "../interfaces/Post";
import { Card, Skeleton } from "@nextui-org/react";
import PostCard from "../components/post/Post";
import { withErrorHandler } from "../utils/withErrorFallback";
import PrimaryButton from "../components/common/PrimaryButton";

export default function Saved() {
  const [posts, setPosts] = useState<SavedPost[]>([]);
  const [isLoadingPosts, setIsLoadingPosts] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const [page, setPage] = useState(0);

  const fetchPosts = withErrorHandler(async () => {
    setIsLoadingPosts(true);
    //const exclude = posts.map((e) => e.id);
    //const response = await getPublications("0");
    const data = await getSavedPosts(page + 1);
    console.log("response.info.data[0]", data?.info?.data);
    console.log(data.info.data.current_page, data.info.data.last_page);
    setHasMore(data.info.data.current_page != data.info.data.last_page);
    if (data.info.data.current_page) setPage(data.info.data.current_page);
    setPosts(
      [...posts, ...(data?.info?.data?.data ?? [])]
      // (response.info.data[0] as Publication[]).filter((e) => e.info.isSaved)
    );
    setIsLoadingPosts(false);
  });
  useEffect(() => {
    fetchPosts();
  }, []);

  const unSave = (id: number) => {
    setPosts([...posts.filter((e) => e.id != id)]);
  };
  return (
    <div className=" h-screen ">
      <NavBar />
      <main className="flex justify-center">
        <div className="flex max-w-8xl w-full overflow-hidden pt-6 md:pt-18 min-h-[100vh] flex-col">
          <div className="px-4 md:px-8 w-full max-w-8xl mb-4">
            <section className="w-full mb-10">
              <div className="mt-2 flex flex-col items-start">
                <h1 className="text-xl font-semibold">
                  Publicaciones guardadas
                </h1>
              </div>
            </section>
            <section className="w-full mb-4 masonry sm:masonry-sm md:masonry-md  gap-4 ">
              {posts.map((e) => (
                <div className=" break-inside mb-4">
                  <PostCard
                    key={e.id}
                    post={e.publicacion}
                    className="p-6 w-full border-1 rounded-3xl"
                    onUnsave={() => unSave(e.id)}
                  />
                </div>
              ))}
              {isLoadingPosts &&
                [0, 0, 0, 0].map((_, i) => (
                  <Card
                    key={i}
                    className=" h-[356px] space-y-5 p-4 rounded-lg break-inside mb-4"
                  >
                    <div className="max-w-[300px] w-full flex items-center gap-3">
                      <div>
                        <Skeleton className="flex rounded-full w-12 h-12" />
                      </div>
                      <div className="w-full flex flex-col gap-2">
                        <Skeleton className="h-3 w-3/5 rounded-lg" />
                        <Skeleton className="h-3 w-4/5 rounded-lg" />
                      </div>
                    </div>
                    <Skeleton className="rounded-lg">
                      <div className="h-32 rounded-lg bg-default-300"></div>
                    </Skeleton>
                    <div className="space-y-3">
                      <Skeleton className="w-4/5 rounded-lg">
                        <div className="h-3 w-4/5 rounded-lg bg-default-200"></div>
                      </Skeleton>
                      <Skeleton className="w-2/5 rounded-lg">
                        <div className="h-3 w-2/5 rounded-lg bg-default-300"></div>
                      </Skeleton>
                    </div>
                  </Card>
                ))}
              {!isLoadingPosts && !posts.length ? (
                <p>No hay publicaciones que mostrar</p>
              ) : (
                <></>
              )}
            </section>
            {hasMore && (
              <div className="flex items-center justify-center">
                <PrimaryButton
                  type="submit"
                  loading={isLoadingPosts}
                  disabled={isLoadingPosts}
                  className="mt-[20px] w-full max-w-sm"
                  onClick={fetchPosts}
                >
                  Cargar más
                </PrimaryButton>
              </div>
            )}
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
}
