import { useMemo } from "react";
import { useAuthStore } from "../../stores/authStore";
import { Link } from "react-router-dom";
import { Avatar } from "@nextui-org/react";
import { GlobalFriendsIcon, GlobalReactionsIcon, GlobalVisitsIcon } from "../../assets/icons/GlobalDataIcons";

export default function MyAbout({
	visits = 0,
	friends = 0,
	likes = 0,
}: {
	visits: number;
	friends: number;
	likes: number;
}) {
	const authData = useAuthStore((state) => state.user);

	const cover = useMemo(() => {
		return authData?.portada_path
			? authData?.portada_path.startsWith("http")
				? authData?.portada_path
				: import.meta.env.VITE_BASE_URL + authData?.portada_path
			: undefined;
	}, [authData]);

	return (
		<section className="w-full border-1 rounded-2xl">
			<div>
				<div
					className="w-full h-[75px] rounded-t-2xl bg-gradient-default  bg-center bg-cover"
					style={{
						backgroundImage: ` url(${cover})`,
					}}
				></div>

				<div className="-mt-9 w-full flex justify-center">
					<Link to={`/perfil/${authData?.slug}`}>
						<Avatar
							showFallback
							classNames={{
								base: "bg-zinc-100 border-3 border-white",
								icon: "text-black/50",
							}}
							className={`w-[68px] h-[68px]  ${authData?.is_payed ? "border-3 border-primary" : ""}`}
							src={
								authData?.profile_path
									? authData?.profile_path.startsWith("http")
										? authData?.profile_path
										: import.meta.env.VITE_BASE_URL + authData?.profile_path
									: undefined
							}
						/>
					</Link>
				</div>
				<div className="mt-1 flex flex-col items-center">
					<Link to={`/perfil/${authData?.slug}`}>
						<label className="font-bold text-[20px] text-color2 cursor-pointer hover:text-color4 transition-transform-colors-opacity">
							{authData?.name}
						</label>
					</Link>
					{authData?.is_payed ? (
						<p className="text-tiny font-semibold text-primary tracking-[4px] uppercase space-x-2">
							Premium
						</p>
					) : (
						<></>
					)}
				</div>
				<ul className="p-6 pl-7 flex flex-col gap-2">
					<li className="flex items-center gap-3">
						<div>
							<GlobalVisitsIcon className="size-8" />
						</div>
						<div className="flex flex-col">
							<Link to={"/perfiles?visitas=true"} className="font-semibold text-[16px] text-color7">
								Visitas al Perfil
							</Link>
							<label className="font-medium text-[16px] text-color5">{visits}</label>
						</div>
					</li>
					<li className="flex items-center gap-3">
						<div>
							<GlobalFriendsIcon className="size-8" />
						</div>
						<div className="flex flex-col">
							<Link
								to={`/amigos/${authData?.slug}?tab=amigos`}
								className="font-semibold text-[16px] text-color7"
							>
								Amigos
							</Link>
							<label className="font-medium text-[16px] text-color5">{friends}</label>
						</div>
					</li>
					<li className="flex items-center gap-3">
						<div>
							<GlobalReactionsIcon className="size-8" />
						</div>
						<div className="flex flex-col">
							<label className="font-semibold text-[16px] text-color7">Me gusta</label>
							<label className="font-medium text-[16px] text-color5">{likes}</label>
						</div>
					</li>
				</ul>
			</div>
		</section>
	);
}
