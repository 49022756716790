import { SVGProps } from "react";

export function UsersIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      {...props}
    >
      <g
        fill="none"
        stroke={props.stroke ?? "#888888"}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      >
        <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2"></path>
        <circle cx="9" cy="7" r="4"></circle>
        <path d="M22 21v-2a4 4 0 0 0-3-3.87m-3-12a4 4 0 0 1 0 7.75"></path>
      </g>
    </svg>
  );
}
export function PartnerIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="16"
      height="10"
      viewBox="0 0 16 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.33317 0.333984C4.2285 0.333984 3.33317 1.22932 3.33317 2.33398C3.33317 3.43865 4.2285 4.33398 5.33317 4.33398C6.43784 4.33398 7.33317 3.43865 7.33317 2.33398C7.33317 1.22932 6.43784 0.333984 5.33317 0.333984ZM10.6665 0.333984C9.56184 0.333984 8.6665 1.22932 8.6665 2.33398C8.6665 3.43865 9.56184 4.33398 10.6665 4.33398C11.7712 4.33398 12.6665 3.43865 12.6665 2.33398C12.6665 1.22932 11.7712 0.333984 10.6665 0.333984ZM5.33317 1.66732C5.7005 1.66732 5.99984 1.96665 5.99984 2.33398C5.99984 2.70132 5.7005 3.00065 5.33317 3.00065C4.96584 3.00065 4.6665 2.70132 4.6665 2.33398C4.6665 1.96665 4.96584 1.66732 5.33317 1.66732ZM10.6665 1.66732C11.0338 1.66732 11.3332 1.96665 11.3332 2.33398C11.3332 2.70132 11.0338 3.00065 10.6665 3.00065C10.2992 3.00065 9.99984 2.70132 9.99984 2.33398C9.99984 1.96665 10.2992 1.66732 10.6665 1.66732ZM2.50895 3.00195C2.21686 3.01317 1.58131 3.08845 1.16781 3.50195C0.616473 4.05329 0.667806 4.99935 0.667806 4.99935C0.667806 4.99935 1.61387 5.05068 2.1652 4.49935C2.71654 3.94802 2.6652 3.00195 2.6652 3.00195C2.6652 3.00195 2.60632 2.99821 2.50895 3.00195ZM5.33317 5.66732C3.33317 5.66732 0.666504 6.64332 0.666504 8.00065V9.66732H9.38525H9.99984H15.3332V8.00065C15.3332 6.64332 12.6665 5.66732 10.6665 5.66732C9.81046 5.66732 8.84095 5.85594 8.00244 6.16732C7.16236 5.85515 6.19098 5.66732 5.33317 5.66732ZM5.33317 7.00065C6.9065 7.00065 8.41317 7.68439 8.6665 8.06706V8.33398H1.99984V8.06706C2.25317 7.68439 3.75984 7.00065 5.33317 7.00065ZM10.6665 7.00065C12.2398 7.00065 13.7465 7.68439 13.9998 8.06706V8.33398H9.99984V8.00065C9.99984 7.67948 9.84673 7.38062 9.59229 7.11002C9.9387 7.04686 10.2964 7.00065 10.6665 7.00065Z"
        fill="white"
      />
    </svg>
  );
}

export function PeopleIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 16 16"
      {...props}
    >
      <path
        fill="currentColor"
        d="M3 3a2 2 0 1 1 4 0a2 2 0 0 1-4 0m2-1a1 1 0 1 0 0 2a1 1 0 0 0 0-2m4.779 2.584a2 2 0 1 1 2.442-3.168A2 2 0 0 1 9.78 4.584M11 2a1 1 0 1 0 0 2a1 1 0 0 0 0-2M2.5 6h2.67c-.11.313-.17.65-.17 1H2.5a.5.5 0 0 0-.5.5c0 .817.325 1.423.838 1.835c.236.19.519.343.839.455a2.5 2.5 0 0 0-.532.868a3.7 3.7 0 0 1-.933-.543C1.46 9.51 1 8.616 1 7.5A1.5 1.5 0 0 1 2.5 6m3.768 0a2 2 0 1 0 3.466 2a2 2 0 0 0-3.466-2m1.508.025A1.003 1.003 0 0 1 9 7q0 .116-.025.224a1 1 0 1 1-1.2-1.199m5.386 3.31c-.236.19-.519.343-.839.455a2.5 2.5 0 0 1 .531.868c.34-.139.655-.32.934-.543C14.54 9.51 15 8.616 15 7.5A1.5 1.5 0 0 0 13.5 6h-2.67c.11.313.17.65.17 1h2.5a.5.5 0 0 1 .5.5c0 .817-.325 1.423-.838 1.835M10.5 10a1.5 1.5 0 0 1 1.5 1.5c0 1.116-.459 2.01-1.212 2.615C10.047 14.71 9.053 15 8 15s-2.047-.29-2.788-.885C4.46 13.51 4 12.616 4 11.5A1.496 1.496 0 0 1 5.5 10zm0 1h-5a.5.5 0 0 0-.5.5c0 .817.325 1.423.838 1.835C6.364 13.757 7.12 14 8 14s1.636-.243 2.162-.665c.513-.412.838-1.018.838-1.835a.5.5 0 0 0-.5-.5"
      ></path>
    </svg>
  );
}
/**
 */
