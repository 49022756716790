import { SVGProps } from "react";

export default function PremiumIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.8333 1.66602L3.41118 10.5726C3.12051 10.9214 2.97517 11.0958 2.97295 11.2431C2.97102 11.3711 3.02808 11.4929 3.12768 11.5734C3.24226 11.666 3.46928 11.666 3.92333 11.666H9.99997L9.16663 18.3327L16.5888 9.42614C16.8794 9.07733 17.0248 8.90293 17.027 8.75563C17.0289 8.62758 16.9719 8.50576 16.8723 8.42527C16.7577 8.33268 16.5307 8.33268 16.0766 8.33268H9.99997L10.8333 1.66602Z"
        stroke={props.stroke ?? "#888888"}
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
