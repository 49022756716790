import { SVGProps } from "react";

export function GlobalVisitsIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        x="0.5"
        y="0.00012207"
        width="20"
        height="19.9999"
        rx="9.99995"
        fill="#FF01EE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.95197 10.1495C3.97738 10.2603 4.02278 10.3322 4.11357 10.476C4.86384 11.664 7.09709 14.6672 10.5004 14.6672C13.9038 14.6672 16.137 11.664 16.8873 10.476L16.8873 10.476C16.9781 10.3322 17.0235 10.2603 17.0489 10.1495C17.068 10.0662 17.068 9.93486 17.0489 9.85158C17.0235 9.74072 16.9781 9.66884 16.8873 9.52509L16.8873 9.52508C16.137 8.33709 13.9038 5.33386 10.5004 5.33386C7.09709 5.33386 4.86384 8.33709 4.11357 9.52508C4.02278 9.66884 3.97738 9.74072 3.95197 9.85158C3.93288 9.93486 3.93288 10.0662 3.95197 10.1495ZM12.5005 10.0001C12.5005 11.1047 11.605 12.0001 10.5005 12.0001C9.3959 12.0001 8.50047 11.1047 8.50047 10.0001C8.50047 8.89554 9.3959 8.00011 10.5005 8.00011C11.605 8.00011 12.5005 8.89554 12.5005 10.0001Z"
        fill="white"
      />
    </svg>
  );
}

export function GlobalFriendsIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 32 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect y="0.5" width="32" height="32" rx="16" fill="#0038E5" />
      <path
        d="M20.5405 19.4019C21.6422 19.9554 22.5869 20.8436 23.2763 21.9542C23.4129 22.1742 23.4811 22.2842 23.5048 22.4365C23.5527 22.7459 23.3411 23.1264 23.0529 23.2489C22.911 23.3091 22.7515 23.3091 22.4324 23.3091M19.027 16.1443C20.1483 15.5871 20.9189 14.43 20.9189 13.0929C20.9189 11.7558 20.1483 10.5987 19.027 10.0415M17.5135 13.0929C17.5135 14.9737 15.9888 16.4983 14.1081 16.4983C12.2273 16.4983 10.7026 14.9737 10.7026 13.0929C10.7026 11.2122 12.2273 9.6875 14.1081 9.6875C15.9888 9.6875 17.5135 11.2122 17.5135 13.0929ZM8.85558 21.7489C10.0621 19.9374 11.966 18.7686 14.1081 18.7686C16.2501 18.7686 18.154 19.9374 19.3605 21.7489C19.6248 22.1458 19.757 22.3442 19.7418 22.5977C19.7299 22.7951 19.6006 23.0367 19.4429 23.1559C19.2403 23.3091 18.9618 23.3091 18.4046 23.3091H9.81146C9.25434 23.3091 8.97579 23.3091 8.77325 23.1559C8.61555 23.0367 8.48617 22.7951 8.47432 22.5977C8.45911 22.3442 8.59127 22.1458 8.85558 21.7489Z"
        stroke="white"
        strokeWidth="1.72973"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
export function GlobalReactionsIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect x="-0.000488281" width="20" height="20" rx="10" fill="#A24BFD" />
      <path
        d="M9.61654 4.16798C9.50772 4.16905 9.40364 4.21264 9.32653 4.28943C9.24943 4.36623 9.20543 4.47014 9.20394 4.57895C9.19672 5.07695 8.97249 5.4817 8.57324 5.92498C8.174 6.36827 7.60655 6.81861 7.01969 7.34018C5.84597 8.38334 4.58398 9.77691 4.58398 12.0847C4.58398 13.2263 4.99938 14.3844 5.89909 15.2495C6.7988 16.1146 8.16798 16.668 10.0007 16.668C11.8333 16.668 13.2025 16.1146 14.1022 15.2495C15.0019 14.3844 15.4173 13.2263 15.4173 12.0847C15.4173 9.94811 13.9886 8.01041 12.6097 6.5866C11.2308 5.1628 9.85254 4.23879 9.85254 4.23879C9.78281 4.19194 9.70054 4.16726 9.61654 4.16798ZM10.3693 9.43817C10.4614 9.4452 10.5515 9.48238 10.6232 9.54884C12.1749 11.0018 12.5007 12.4743 12.5007 13.4527C12.5007 14.7885 11.3968 15.8343 9.98682 15.8338C8.93807 15.8338 8.05229 15.2657 7.67562 14.3511C7.3002 13.4394 7.54098 12.4031 8.30306 11.6476C8.46014 11.491 8.71181 11.4863 8.87598 11.6346C9.14014 11.8738 9.42821 11.907 9.68571 11.7266C10.0465 11.4749 10.2839 10.8299 9.95264 10.0095C9.8793 9.82821 9.94113 9.6202 10.1024 9.50978C10.1828 9.45436 10.2772 9.43114 10.3693 9.43817Z"
        fill="white"
      />
    </svg>
  );
}
