import { Link } from "react-router-dom";
import { User } from "../../interfaces/Login";
import { Avatar, Button, Skeleton } from "@nextui-org/react";
import SecondaryButton from "../common/SecondaryButton";
import { useEffect, useState } from "react";
import { getUser } from "../../api/user";
import { Fantasy } from "../../interfaces/Fantasy";
import { getFantasies } from "../../api/formSelectsData";

export default function ChatUserInfo({ user }: { user: User }) {
	const [description, setDescription] = useState("");
	const [fantasies, setFantasies] = useState<Fantasy[]>([]);
	const [userFantasies, setUserFantasies] = useState<Fantasy[]>([]);
	const [loading, setLoading] = useState(false);

	const getFantasiesData = async (user: User) => {
		let fantasiesArr = fantasies;
		if (fantasies.length == 0) {
			const response = await getFantasies();

			fantasiesArr = response?.info?.data[0] ? (response?.info?.data[0] as Fantasy[]) : [];

			setFantasies(fantasiesArr);
		}
		if (user?.info?.fantasias_id) {
			const data = fantasiesArr.filter((e) => user?.info?.fantasias_id?.split(",").includes(e.id.toString()));
			setUserFantasies(data);
		}
	};

	// useEffect(() => {
	//   getFantasiesData(user);
	// },[])

	useEffect(() => {
		const getUserData = async () => {
			setLoading(true);
			const userRes = await getUser(user.id);
			const userData = userRes.info.data[0];
			if (userData) {
				if (user.role_id == "3") setDescription(userData.info?.locals_name ?? "");
				else setDescription(userData.info?.detalles.description.value ?? "");

				await getFantasiesData(userData);
			}
			setLoading(false);
		};

		const setUserDetails = async () => {
			setLoading(true);
			if (user.role_id == "3") setDescription(user.info?.locals_name ?? "");
			else setDescription(user.info?.detalles.description.value ?? "");
			getFantasiesData(user);
			setLoading(false);
		};

		if (user.info?.detalles?.description?.value) setUserDetails();
		else getUserData();
	}, [user]);

	return (
		<div>
			<div
				className="w-full h-[75px] rounded-t-lg bg-gradient-default  bg-center bg-cover"
				style={{
					backgroundImage: ` url(${
						user?.portada_path
							? user?.portada_path.startsWith("http")
								? user?.portada_path
								: import.meta.env.VITE_BASE_URL + user?.portada_path
							: undefined
					})`,
				}}
			></div>

			<div className="-mt-9 w-full flex justify-center">
				<Link to={`/perfil/${user.slug}`}>
					<Avatar
						showFallback
						classNames={{
							base: "bg-zinc-100 border-3 border-white shadow-md",
							icon: "text-black/50",
						}}
						className={`w-[68px] h-[68px]  ${user.is_payed ? "border-3 border-primary" : ""}`}
						src={
							user?.profile_path
								? user?.profile_path.startsWith("http")
									? user?.profile_path
									: import.meta.env.VITE_BASE_URL + user?.profile_path
								: undefined
						}
					/>
				</Link>
			</div>
			<div className="mt-1 flex flex-col px-3 mb-2">
				<Link to={`/perfil/${user?.slug}`}>
					<label className="font-bold text-[20px] text-color2 cursor-pointer hover:text-color4 transition-transform-colors-opacity">
						{user?.name}
					</label>
				</Link>
				{/* <div className="text-zinc-500">
					{user?.email.slice(0, 30)}
					{user?.email.length > 30 && "..."}
				</div> */}
			</div>
			<ul className="p-3 pt-2 flex flex-col gap-2">
				<li className="flex items-center gap-3">
					<Link className=" w-full" to={`/perfil/${user?.slug}`}>
						<SecondaryButton className=" w-full">Ver perfil</SecondaryButton>
					</Link>
					{/* <Button
            isIconOnly
            aria-label="share"
            variant="bordered"
            className="border-1 border-zinc-200 rounded-md"
          >
            <svg
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4.83333 8.16667C4.05836 8.16667 3.67087 8.16667 3.35295 8.25185C2.49022 8.48302 1.81635 9.15689 1.58519 10.0196C1.5 10.3375 1.5 10.725 1.5 11.5V12.5C1.5 13.9001 1.5 14.6002 1.77248 15.135C2.01217 15.6054 2.39462 15.9878 2.86502 16.2275C3.3998 16.5 4.09987 16.5 5.5 16.5H12.5C13.9001 16.5 14.6002 16.5 15.135 16.2275C15.6054 15.9878 15.9878 15.6054 16.2275 15.135C16.5 14.6002 16.5 13.9001 16.5 12.5V11.5C16.5 10.725 16.5 10.3375 16.4148 10.0196C16.1836 9.15689 15.5098 8.48302 14.647 8.25185C14.3291 8.16667 13.9416 8.16667 13.1667 8.16667M12.3333 4.83333L9 1.5M9 1.5L5.66667 4.83333M9 1.5V11.5"
                stroke="#344054"
                strokeWidth="1.66667"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </Button> */}
				</li>
				<li className="flex gap-3 flex-col mt-3">
					<div className="text-sm font-medium">Sobre mi</div>
					{loading ? (
						<div className="flex flex-col text-sm gap-1">
							<Skeleton className="h-3 w-[80%] rounded-lg" />
							<Skeleton className="h-3 w-full rounded-lg" />
						</div>
					) : (
						<div className="text-sm ">{description ?? "..."}</div>
					)}
				</li>
				<li className="flex flex-col gap-3 mt-3">
					<p className="text-sm font-semibold">Fantasías Destacadas</p>
					<div>
						{loading ? (
							<div className="flex  text-sm gap-1">
								<Skeleton className="h-8 w-20 rounded-lg" />
								<Skeleton className="h-8 w-20 rounded-lg" />
							</div>
						) : (
							<div className="flex text-sm gap-1 flex-wrap">
								{userFantasies.map((e) => (
									<Link key={e.id} to={`/fantasia/${e.slug}`}>
										<Button
											size="sm"
											variant="bordered"
											className="border-1 border-zinc-200 rounded-lg"
										>
											{e.name}
										</Button>
									</Link>
								))}
								{userFantasies.length == 0 && (
									<div className="text-sm ">No hay fantasías que mostrar </div>
								)}
							</div>
						)}
					</div>
				</li>
			</ul>
		</div>
	);
}
