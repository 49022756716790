import { SVGProps } from "react";

export function CalendarIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="18"
      height="20"
      viewBox="0 0 18 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16.5 8.33268H1.5M12.3333 1.66602V4.99935M5.66667 1.66602V4.99935M5.5 18.3327H12.5C13.9001 18.3327 14.6002 18.3327 15.135 18.0602C15.6054 17.8205 15.9878 17.4381 16.2275 16.9677C16.5 16.4329 16.5 15.7328 16.5 14.3327V7.33268C16.5 5.93255 16.5 5.23249 16.2275 4.69771C15.9878 4.2273 15.6054 3.84485 15.135 3.60517C14.6002 3.33268 13.9001 3.33268 12.5 3.33268H5.5C4.09987 3.33268 3.3998 3.33268 2.86502 3.60517C2.39462 3.84485 2.01217 4.2273 1.77248 4.69771C1.5 5.23249 1.5 5.93255 1.5 7.33268V14.3327C1.5 15.7328 1.5 16.4329 1.77248 16.9677C2.01217 17.4381 2.39462 17.8205 2.86502 18.0602C3.3998 18.3327 4.09987 18.3327 5.5 18.3327Z"
        stroke="#A24BFD"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
