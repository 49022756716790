import { SVGProps } from "react";

export default function FantasyIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      id="Layer_1"
      enableBackground="new 0 0 256 256"
      viewBox="0 0 256 256"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g />
      <path d="m168.28 85.76c-17.11 4.58-37.245 21.865-37.245 21.865-1.669 1.433-4.401 1.433-6.07 0 0 0-20.135-17.285-37.245-21.865-33.85-9.07-67.96-2.164-67.96-2.164-2.156.437-3.867 2.593-3.802 4.792 0 0 2.022 68.331 48.892 82.711 24.98 7.66 59.539-8.8 59.539-8.8 1.986-.945 5.236-.945 7.223 0 0 0 34.559 16.46 59.528 8.8 46.88-14.38 48.902-82.711 48.902-82.711.064-2.199-1.647-4.355-3.803-4.792 0 0-34.109-6.906-67.959 2.164zm-96.69 54.54c-14.54-6.48-19.505-20.266-19.505-20.266-.745-2.07.312-4.444 2.348-5.275 0 0 13.567-5.538 28.117.942s19.507 20.267 19.507 20.267c.745 2.07-.313 4.444-2.35 5.275 0-.001-13.567 5.537-28.117-.943zm112.82 0c-14.55 6.48-28.117.942-28.117.942-2.037-.831-3.094-3.205-2.35-5.275 0 0 4.957-13.787 19.507-20.267s28.116-.942 28.116-.942c2.037.831 3.094 3.206 2.35 5.276 0 0-4.956 13.786-19.506 20.266z" />
    </svg>
  );
}
