/* eslint-disable no-mixed-spaces-and-tabs */
import {
	Avatar,
	Dropdown,
	DropdownMenu,
	DropdownItem,
	Button,
	DropdownTrigger,
	User,
	Spinner,
	Chip,
	DropdownSection,
} from "@nextui-org/react";
import NavBar from "../components/common/Navbar";
import SecondaryButton from "../components/common/SecondaryButton";
import Footer from "../components/common/Footer";
import PrimaryButton from "../components/common/PrimaryButton";

import { MenuIcon } from "../assets/icons/MenuIcon";
import { CopyIcon } from "../assets/icons/CopyIcon";
import { LocationIcon } from "../assets/icons/LockationIcon";
import { HeartIcon } from "../assets/icons/HeartIcon";
import { CalendarIcon } from "../assets/icons/CalendarIcon";
import { Key, useEffect, useMemo, useState } from "react";
import { getParty, likeParty, requestJoinParty, respondRequestJoinParty } from "../api/party";
import { Party as PartyData, PartyRequest } from "../interfaces/Party";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useAuthStore } from "../stores/authStore";
import { useDateFormatter } from "@react-aria/i18n";
import Moment from "react-moment";
import { FriendIcon } from "../assets/icons/UserIcon";
import PartyRequestsModal from "../components/party/PartyRequestsModal";
import { UsersIcon } from "../assets/icons/UsersIcon";
import PartyMembersModal from "../components/party/PartyMembersModal";
import PartyUsersModal from "../components/party/PartyUsersModal";
import { toast } from "react-toastify";
import { withErrorHandler } from "../utils/withErrorFallback";
import { TrashCanIcon } from "../assets/icons/TrashCanIcon";
import DeletePartyModal from "../components/party/DeletePartyModal";
import { usePartyStore } from "../stores/partyStore";
import { SexualOrientation } from "../interfaces/SexualOrientation";
import { Province } from "../interfaces/Provice";
import { getProvinces, getSexualOrientations } from "../api/formSelectsData";
import { FlagIcon } from "../assets/icons/FlagIcon";
import ReportPartyModal from "../components/party/PartyReportModal";

import { useChatStore } from "../stores/chatStore";
import { ClockIcon } from "../assets/icons/ClockIcon";
import { MapSearchIcon } from "../assets/icons/MapIcon";

const menuItems = [
	{
		key: "copy",
		icon: <CopyIcon />,
		label: "Copiar enlace de la fiesta",
	},
];

export default function Party() {
	const { partyId } = useParams();

	const [partyData, setPartyData] = useState<PartyData | undefined>();
	const authData = useAuthStore((state) => state.user);

	const [isLoading, setIsLoading] = useState(false);
	const [isOpenModal, setIsOpenModal] = useState(false);
	const [isOpenReportModal, setIsOpenReportModal] = useState(false);
	const [isOpenMembersModal, setIsOpenMembersModal] = useState(false);
	const [isOpenMaybeModal, setIsOpenMaybeModal] = useState(false);
	const [isOpenDeclienedModal, setIsOpenDeclienedModal] = useState(false);
	const [isOpenUsersModal, setIsOpenUsersModal] = useState(false);
	const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);

	const [isSendingRequest, setIsSendingRequest] = useState(false);
	const [isRejectingRequest, setIsRejectingRequest] = useState(false);
	const [isMaybeRequest, setIsMaybeRequest] = useState(false);
	const [isDeleting, setIsDeleting] = useState(false);
	const invite = usePartyStore((state) => state.invite);
	const setInvite = usePartyStore((state) => state.setInvite);
	const [info, setInfo] = useState({
		orientations: [] as SexualOrientation[],
		provinces: [] as Province[],
	});
	const setUserToChat = useChatStore((state) => state.setUserToChat);
	const navigate = useNavigate();
	useEffect(() => {
		const getData = withErrorHandler(async () => {
			setIsLoading(true);
			if (partyId) {
				const res = await getParty(parseInt(partyId));
				console.log(res);
				if (res) {
					setPartyData(res.info.data[0]);
					if (res.info.data[0].likes) {
						const data = res.info.data[0] as PartyData;
						setCheck(data.likes.find((e) => e.user_id == authData?.id) ? true : false);
					}
					if (invite) {
						setIsOpenUsersModal(true);
						setInvite(false);
					}
				}
			}

			setIsLoading(false);
			const orientations = await getSexualOrientations();
			const provinces = await getProvinces();
			setInfo({
				orientations: orientations?.info?.data[0] ?? [],
				provinces: provinces?.info?.data[0] ?? [],
			});
		});
		if (authData) getData();
	}, [authData]);

	const formatter = useDateFormatter({
		dateStyle: "long",
	});
	const [ckeck, setCheck] = useState(false);
	const handleRequestJoin = withErrorHandler(async () => {
		setIsSendingRequest(true);
		try {
			const payload = {
				fiesta_id: partyData?.id,
				user_id: authData?.id,
			};
			const res = await requestJoinParty(payload);
			// console.log(isSendingRequest);
			if (res.info && res.error == "false" && partyData && authData) {
				if (partyData.publica)
					setPartyData({
						...partyData,
						integrantes: [...partyData.integrantes, authData],
					});
				else
					setPartyData({
						...partyData,
						solicitudes: [
							...partyData.solicitudes,
							{
								id: res.info.data[0].id,
								user: authData,
								estado: { name: "pendiente" },
								invitacion: 0,
							},
						],
					});
			} else {
				if (res.error == "true" && res.info.message == "This user was invited before") {
					toast.error("Ya no puedes unirte a esta fiesta, debido a una previa solicitud rechazada");
				} else toast.error(res.info.message);
			}
		} catch (error) {
			console.error(error);
			toast.error("Ha ocurrido un error");
		} finally {
			setIsSendingRequest(false);
		}
	});
	const onDeleteRequest = withErrorHandler(async () => {
		if (!partyData) return;

		setIsDeleting(true);
		const payload = {
			accion: "eliminar",
		};

		const req = partyData.solicitudes.find((e) => e.user.id == authData?.id);
		if (req)
			try {
				const res = await respondRequestJoinParty(payload, req.id);
				// console.error(res);
				if (res?.error == "true" || !res.info) {
					toast.error(res.info.message);
				} else {
					setPartyData({
						...partyData,
						solicitudes: partyData?.solicitudes?.filter((e) => e.id != req.id),
					});
					toast.success("Solicitud cancelada con éxito");
				}
			} catch (error) {
				console.error(error);
				toast.error("Ha ocurrido un error");
			}

		setIsDeleting(false);
	});
	const amJoined = useMemo(() => {
		if (!partyData) return <></>;

		// Si eres el creador, puedes ver las solicitudes pendientes
		if (partyData?.user_id === authData?.id) {
			return (
				<>
					<PrimaryButton className="w-full max-w-sm mt-5" onClick={() => setIsOpenModal(true)}>
						<div className="flex items-center gap-2">
							<span>Solicitudes Pendientes</span>
						</div>
					</PrimaryButton>
				</>
			);
		}

		if (partyData?.talvez?.find((e) => e.user.id === authData?.id))
			return (
				<PrimaryButton className="w-full max-w-sm mt-5 bg-yellow-400 text-color2" disabled>
					<div className="flex items-center gap-2">
						<span>Tal vez asistiré</span>
					</div>
				</PrimaryButton>
			);

		if (partyData?.integrantes?.find((e) => e.id === authData?.id))
			return (
				<PrimaryButton className="w-full max-w-sm mt-5" disabled>
					<div className="flex items-center gap-2">
						<span>Estás apuntado</span>
					</div>
				</PrimaryButton>
			);

		if (partyData.solicitud?.user_id === authData?.id && partyData.solicitud?.invitacion === 0)
			return (
				<div className="max-w-sm flex flex-col">
					<PrimaryButton className="w-full max-w-sm mt-5" disabled>
						<div className="flex items-center gap-2">
							<span>Solicitud pendiente</span>
						</div>
					</PrimaryButton>
					<PrimaryButton
						onClick={onDeleteRequest}
						loading={isDeleting}
						className="w-full max-w-sm mt-5 bg-red-600"
					>
						<div className="flex items-center gap-2">
							<span>Cancelar</span>
						</div>
					</PrimaryButton>
				</div>
			);

		// Cuando existe una solicitud con invitación
		if (partyData.solicitud?.invitacion === 1 && partyData.solicitud?.user_id === authData?.id) {
			// Si el usuario es el creador, muestra los tres botones
			if (partyData.user_id === authData?.id) {
				return (
					<>
						<PrimaryButton
							onClick={() => onRespondRequest("aprobar")}
							className="w-full max-w-sm mt-5"
							loading={isSendingRequest}
						>
							<div className="flex items-center gap-2">
								<span>Asistiré</span>
							</div>
						</PrimaryButton>
						<SecondaryButton
							onClick={() => onRespondRequest("talvez")}
							className="w-full bg-yellow-400 max-w-sm mt-2"
							loading={isMaybeRequest}
						>
							<div className="flex items-center gap-2">
								<span>Tal vez</span>
							</div>
						</SecondaryButton>
						<SecondaryButton
							onClick={() => onRespondRequest("denegar")}
							className="w-full max-w-sm mt-2 bg-red-500 text-white"
							loading={isRejectingRequest}
						>
							<div className="flex items-center gap-2">
								<span>No asistiré</span>
							</div>
						</SecondaryButton>
					</>
				);
			} else {
				// Si no eres el creador, solo se mostrará el botón "Asistiré"
				return (
					<PrimaryButton
						onClick={() => onRespondRequest("aprobar")}
						className="w-full max-w-sm mt-5"
						loading={isSendingRequest}
					>
						<div className="flex items-center gap-2">
							<span>Asistiré</span>
						</div>
					</PrimaryButton>
				);
			}
		}

		if (partyData.capacidad > partyData.integrantes.length || partyData?.capacidad === 0)
			return (
				<PrimaryButton className="w-full max-w-sm mt-5" onClick={handleRequestJoin} loading={isSendingRequest}>
					<div className="flex items-center gap-2">
						<span>{partyData.publica ? "Unirse" : "Solicitar unirse"}</span>
					</div>
				</PrimaryButton>
			);

		return <></>;
	}, [partyData, isDeleting, isSendingRequest, isRejectingRequest]);

	const onRespondRequest = withErrorHandler(async (type: string) => {
		if (!partyData?.solicitud?.id || !authData) return;
		type == "aprobar"
			? setIsSendingRequest(true)
			: type == "denegar"
			? setIsRejectingRequest(true)
			: setIsMaybeRequest(true);

		const id = partyData?.solicitud?.id;
		const payload = {
			accion: type,
		};
		try {
			const res = await respondRequestJoinParty(payload, id);
			// console.log(res);
			if (res?.error == "true" || !res.info) {
				toast.error(res.info.message);
			} else {
				if (type == "aprobar") {
					toast.success("Respuesta enviada con éxito");

					setPartyData({
						...partyData,
						solicitudes: [...partyData.solicitudes.filter((e) => e.id != id)],
						integrantes: [...partyData.integrantes, authData],
					});
				} else if (type == "talvez") {
					setPartyData({
						...partyData,
						solicitudes: [...partyData.solicitudes.filter((e) => e.id != id)],
						talvez: [
							...partyData.talvez,
							{
								id: 1,
								user: authData,
								estado: {
									name: "talvez",
								},
								invitacion: 1,
							},
						],
					});
				} else {
					toast.success("Solicitud rechazada con éxito");
					setPartyData({
						...partyData,
						solicitudes: [...partyData.solicitudes.filter((e) => e.id != id)],
						denegado: [
							...partyData.denegado,
							{
								id: 1,
								user: authData,
								estado: {
									name: "denegado",
								},
								invitacion: 1,
							},
						],
					});
				}
			}
		} catch (error) {
			console.error(error);
			toast.error("Ha ocurrido un error");
		}

		type == "aprobar"
			? setIsSendingRequest(false)
			: type == "denegar"
			? setIsRejectingRequest(false)
			: setIsMaybeRequest(false);
	});

	const onRequestAction = (type: string, req: PartyRequest) => {
		const item = partyData?.solicitudes?.find((e) => e.id == req.id);
		if (item) {
			item.estado.name = type == "aprobar" ? "aprobado" : type == "denegar" ? "denegado" : "talvez";
		}
		if (partyData && item) {
			if (type == "eliminar" || type == "rechazar")
				setPartyData({
					...partyData,
					solicitudes: partyData?.solicitudes?.filter((e) => e.id != req.id),
				});
			else
				setPartyData({
					...partyData,
					solicitudes: [...partyData.solicitudes.filter((e) => e.id != req.id)],
					integrantes: [...partyData.integrantes, req.user],
				});
		}
	};
	const handleLike = async () => {
		if (partyData?.id) {
			// console.log(ckeck);
			setCheck(!ckeck);
			await likeParty(partyData?.id);
			// console.log(res);
		}
	};

	const dangerMenuitems = useMemo(() => {
		if (authData?.id == partyData?.user_id)
			return [
				{
					key: "delete",
					icon: <TrashCanIcon />,
					label: "Eliminar",
				},
			];

		return [
			{
				key: "report",
				icon: <FlagIcon fill="red" />,
				label: "Denunciar evento",
			},
		];
	}, [partyData]);

	const goToChat = () => {
		if (partyData?.user) setUserToChat(partyData?.user);
		navigate("/chat");
	};

	console.log(partyData?.user_id);
	console.log(authData?.id);

	return (
		<div className=" h-screen">
			<NavBar />
			<main className="p-4 lg:px-10 flex justify-center w-full overflow-hidden ">
				{isLoading && (
					<div className="w-full flex justify-center pt-20 h-[50vh]">
						<Spinner color="primary" />
					</div>
				)}
				{!isLoading && (
					<div className="w-full max-w-8xl">
						<section className="w-full pb-10">
							<div
								className={`h-96 rounded-md w-full bg-center bg-cover bg-[url('/assets/fantasyImage.jpeg')] `}
								style={{
									backgroundImage: ` url(${
										partyData?.imagen
											? partyData?.imagen.startsWith("http")
												? partyData?.imagen
												: import.meta.env.VITE_BASE_URL + partyData?.imagen
											: undefined
									})`,
								}}
							></div>
							<div className="flex flex-col md:flex-row flex-wrap justify-between lg:px-2 w-full mt-10">
								<div className="w-full flex justify-between">
									<div className="">
										<Link to={`/perfil/${partyData?.user.slug}`}>
											<User
												name={
													<span>
														Por <b>{partyData?.user?.name}</b>
													</span>
												}
												description={
													<span className="text-sm text-gray-500">
														<Moment fromNow locale="Es">
															{partyData?.created_at}
														</Moment>
													</span>
												}
												avatarProps={{
													src: partyData?.user?.profile_path
														? partyData?.user?.profile_path?.startsWith("http")
															? partyData?.user?.profile_path
															: import.meta.env.VITE_BASE_URL +
															  partyData?.user?.profile_path
														: undefined,
												}}
											/>
										</Link>
									</div>
									<div className="flex  gap-2">
										<Button
											className={`border-1 bg-color8 like ${ckeck ? "checked" : ""}`}
											variant="bordered"
											isIconOnly
											aria-label="like"
											onClick={handleLike}
										>
											<div className="checkmark flex items-center justify-center">
												<HeartIcon height={24} width={24} />
											</div>
										</Button>

										<Dropdown>
											<DropdownTrigger>
												<Button
													className="border-1"
													variant="bordered"
													isIconOnly
													aria-label="menu"
												>
													<MenuIcon />
												</Button>
											</DropdownTrigger>
											<DropdownMenu
												variant="flat"
												aria-label="party options"
												onAction={async (key: Key) => {
													if (key == "copy")
														await navigator.clipboard.writeText(location.href);
													else if (key == "report") {
														setIsOpenReportModal(true);
													} else setIsOpenDeleteModal(true);
												}}
											>
												<DropdownSection>
													{menuItems.map((e) => (
														<DropdownItem key={e.key} startContent={e.icon} color="primary">
															{e.label}
														</DropdownItem>
													))}
												</DropdownSection>
												<DropdownSection>
													{dangerMenuitems.map((e) => (
														<DropdownItem
															key={e.key}
															startContent={e.icon}
															color="danger"
															className="text-danger"
														>
															{e.label}
														</DropdownItem>
													))}
												</DropdownSection>
											</DropdownMenu>
										</Dropdown>
									</div>
								</div>

								<div className="flex gap-3 flex-col w-full lg:w-9/12 lg:pr-8">
									<div className="w-full flex flex-col mt-4">
										<h1 className="text-4xl text-color3 font-bold flex items-center gap-4">
											{partyData?.nombre}
											{partyData?.precio == "0" ? (
												<Chip variant="flat" color="primary" className="mt-2">
													Gratis
												</Chip>
											) : (
												<Chip variant="flat" color="success" className="mt-2">
													€ {partyData?.precio} 💸
												</Chip>
											)}
											{partyData?.capacidad == 0 && (
												<Chip
													variant="flat"
													color="primary"
													className="mt-2 bg-blue-200 text-blue-600"
												>
													Aforo ilimitado
												</Chip>
											)}
										</h1>

										{/* <p className="mt-6 text-color1">{partyData?.descripcion}</p> */}
										<div className="flex gap-2 mt-3">
											{partyData?.fantasias?.map((fantasy) => (
												<Link to={`/fantasia/${fantasy.slug}`} key={fantasy.id}>
													<Button
														radius="sm"
														variant="bordered"
														className=" border-1 border-color1/20"
														size="sm"
													>
														{fantasy.name}
													</Button>
												</Link>
											))}
										</div>
										<div className="flex flex-col md:flex-row gap-6 mt-5">
											<span className="text-gray-500 flex gap-2 itesms-center">
												<LocationIcon width={20} stroke={"#A24BFD"} /> Direccíon:{" "}
												{partyData?.direccion}
											</span>
											<span className="text-gray-500 flex gap-2 itesms-center">
												<MapSearchIcon width={20} className="text-primary size-6" /> Ciudad:{" "}
												{partyData?.ciudad.name}
											</span>
											{partyData && (
												<span className="text-gray-500  flex gap-2 items-center">
													<CalendarIcon width={20} stroke={"#A24BFD"} />{" "}
													{partyData.fecha_fin && partyData.fecha_inicio
														? formatter.formatRange(
																new Date(partyData.fecha_inicio),
																new Date(partyData.fecha_fin)
														  )
														: "--"}
												</span>
											)}
											<span className="inline-flex items-center gap-2 text-gray-500 text-start font-medium">
												<ClockIcon height={20} width={20} className="text-primary"></ClockIcon>
												{partyData?.hora_inicio ?? "Sin definir"}
											</span>
										</div>
										<div className="flex flex-col mt-6">
											<h3 className="text-2xl text-color3 font-semibold">Descripción</h3>
											<p className="mt-6 text-color1">{partyData?.descripcion}</p>
										</div>
									</div>
								</div>
								<div className="flex flex-col flex-wrap w-full lg:w-3/12 mt-6 lg:mt-0">
									<div className="w-full max-w-sm lg:w-auto sm:pr-5 lg:pr-0">
										{authData?.id != partyData?.user_id && (
											<button onClick={goToChat}>
												<div className="flex items-center mt-3 text-primary hover:text-color6 text-lg underline">
													<span className="">Escribir al organizador</span>
												</div>
											</button>
										)}
										{partyData?.user_id == authData?.id && (
											<SecondaryButton
												className="w-full mt-2"
												onClick={() => setIsOpenUsersModal(true)}
											>
												<FriendIcon />

												<label className=" inline-flex text-color2">Invitar amigos</label>
											</SecondaryButton>
										)}

										{amJoined}
										<div className="divider w-full  mt-2 h-0.5 bg-slate-300"></div>
										{partyData?.user_id === authData?.id && (
											<div className="flex gap-2 ">
												<PrimaryButton
													className="w-full max-w-sm mt-2 bg-yellow-400"
													onClick={() => setIsOpenMaybeModal(true)}
												>
													<div className="flex text-black items-center gap-2">
														<span>Tal vez asistirán</span>
													</div>
												</PrimaryButton>
												<PrimaryButton
													onClick={() => setIsOpenDeclienedModal(true)}
													className="w-full max-w-sm mt-2 bg-red-600"
												>
													<div className="flex items-center gap-2">
														<span>No asistirán</span>
													</div>
												</PrimaryButton>
											</div>
										)}
									</div>
									<div className="border-1 mt-5 border-gray-300 w-full max-w-sm rounded-2xl lg:ml-0 ">
										<div className="p-6">
											<h3 className="font-bold text-[16px] text-black">
												Usuarios apuntados ({partyData?.integrantes.length})
											</h3>
										</div>
										<hr className="border-0.5 border-gray-300"></hr>
										<div className="p-3  flex flex-col gap-6">
											<div className="flex flex-wrap gap-8 justify-start px-2">
												{partyData?.integrantes.map((user) => (
													<Link to={`/perfil/${user.slug}`} key={user.id}>
														<Avatar
															showFallback
															className="w-[48px] h-[48px] "
															classNames={{
																base: "bg-zinc-100",
																icon: "text-black/50",
															}}
															src={
																user?.profile_path
																	? user?.profile_path.startsWith("http")
																		? user?.profile_path
																		: import.meta.env.VITE_BASE_URL +
																		  user?.profile_path
																	: undefined
															}
															name={user.name}
														/>
													</Link>
												))}
												{partyData?.integrantes.length == 0 ? (
													<p>Nadie se ha apuntado</p>
												) : (
													<></>
												)}
											</div>

											<SecondaryButton
												className="w-full  mt-5"
												onClick={() => setIsOpenMembersModal(true)}
											>
												<div className="flex items-center gap-2">
													<UsersIcon width={20} height={20} />
													<span className="">Ver todos</span>
												</div>
											</SecondaryButton>
										</div>
									</div>
								</div>
							</div>
						</section>
					</div>
				)}
				{!!partyData && (
					<>
						<PartyRequestsModal
							isOpen={isOpenModal}
							party={partyData}
							closeModal={() => setIsOpenModal(false)}
							onRequestAction={onRequestAction}
						/>
						<PartyMembersModal
							integrantes={partyData.integrantes}
							title={` Integrantes de ${partyData.nombre}`}
							emptyMessage="Parece que no hay integrantes aún"
							isOpen={isOpenMembersModal}
							closeModal={() => setIsOpenMembersModal(false)}
						/>
						<PartyMembersModal
							integrantes={partyData.talvez.map((req) => req.user)}
							title={` Tal vez asistirán a ${partyData.nombre}`}
							emptyMessage="Parece que no hay usuarios aún"
							isOpen={isOpenMaybeModal}
							closeModal={() => setIsOpenMaybeModal(false)}
						/>
						<PartyMembersModal
							integrantes={partyData.denegado.map((req) => req.user)}
							title={` No asistirán a ${partyData.nombre}`}
							emptyMessage="Parece que no hay usuarios aún"
							isOpen={isOpenDeclienedModal}
							closeModal={() => setIsOpenDeclienedModal(false)}
						/>
						<PartyUsersModal
							party={partyData}
							isOpen={isOpenUsersModal}
							closeModal={() => setIsOpenUsersModal(false)}
							setPartyData={setPartyData}
							orientations={info.orientations}
							provinces={info.provinces}
						/>
						<DeletePartyModal
							id={partyData.id}
							isOpen={isOpenDeleteModal}
							name={partyData.nombre}
							closeModal={() => setIsOpenDeleteModal(false)}
						/>
						<ReportPartyModal
							isOpen={isOpenReportModal}
							closeModal={() => setIsOpenReportModal(false)}
							name={partyData?.nombre ?? ""}
							id={partyData?.id ?? 0}
						/>
					</>
				)}
			</main>
			<Footer />
		</div>
	);
}
