import { Route, Routes, useRouteError } from "react-router-dom";
import Login from "./Login";
import Home from "./Home";
import Register from "./Register";
import RecoveryPassword from "./RecoveryPassword";
import Fantasies from "./Fantasies";
import Fantasy from "./FantasyView";
import PrivateRoute from "../components/auth/PrivateRoute";
import PersonVerify from "../components/auth/PersonVerify";
import Profile from "./Profile";
import Trips from "./Trips";
import Trip from "./Trip";
import Parties from "./Parties";
import Party from "./Party";
import Friends from "./Friends";
import Profiles from "./Profiles";
import Chat from "./ChatPage";
import Saved from "./Saved";
import SuccessPaid from "./SuccessPaid";
import BlockedUsers from "./Blocked";
import Settings from "./Settings";
import { useEffect } from "react";
import * as Sentry from "@sentry/react";
import Perfil from "./Perfil";
import Fantasia from "./FantasiaView";
import Viaje from "./Viaje";
import Fiesta from "./Fiesta";
import Amigos from "./Amigos";

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);
const App: React.FC = () => {
	return (
		<SentryRoutes>
			<Route
				element={<PrivateRoute element={<Home />} />}
				errorElement={<YourCustomRootErrorBoundary />}
				path="/"
			/>
			<Route path="/login" element={<Login />} />
			<Route path="/register" element={<Register />} />
			<Route path="/recovery-password" element={<RecoveryPassword />} />

			<Route element={<PrivateRoute element={<Profile />} />} path="/profile/:userId" />
			<Route element={<PrivateRoute element={<Perfil />} />} path="/perfil/:slug" />
			<Route element={<PrivateRoute element={<Profiles />} />} path="/perfiles" />

			<Route element={<PrivateRoute element={<Amigos />} />} path="/amigos/:slug" />
			<Route element={<PrivateRoute element={<Friends />} />} path="/friends/:userId" />

			<Route element={<PrivateRoute element={<Fantasies />} />} path="/fantasias" />
			<Route element={<PrivateRoute element={<Fantasia />} />} path="/fantasia/:slug" />
			<Route element={<PrivateRoute element={<Fantasy />} />} path="/fantasy/:fantasyId" />

			<Route element={<PrivateRoute element={<Trips />} />} path="/trips" />
			<Route element={<PrivateRoute element={<Trips />} />} path="/viajes" />
			<Route element={<PrivateRoute element={<Trip />} />} path="/trips/:tripId" />
			<Route element={<PrivateRoute element={<Viaje />} />} path="/viajes/:slug" />

			<Route element={<PrivateRoute element={<Parties />} />} path="/fiestas" />
			<Route element={<PrivateRoute element={<Party />} />} path="/parties/:partyId" />
			<Route element={<PrivateRoute element={<Fiesta />} />} path="/fiestas/:slug" />

			<Route element={<PrivateRoute element={<Saved />} />} path="/saved" />
			<Route element={<PrivateRoute element={<Chat />} />} path="/chat" />
			<Route element={<PrivateRoute element={<SuccessPaid />} />} path="/success-paid" />
			<Route element={<PrivateRoute element={<BlockedUsers />} />} path="/blocked-users" />
			{/* Settings */}
			<Route element={<PrivateRoute element={<Settings />} />} path="/settings" />
			<Route path="/components-test" element={<PersonVerify isOpen />} />
			<Route path="*" element={<h1>Not Found</h1>} />
		</SentryRoutes>
	);
};

export function YourCustomRootErrorBoundary() {
	const error = useRouteError() as Error;

	useEffect(() => {
		Sentry.captureException(error);
	}, [error]);

	return (
		<div>
			<h1>Ouch!</h1>
		</div>
	);
}

export default App;
