import NavBar from "../components/common/Navbar";
import Footer from "../components/common/Footer";
import PrimaryButton from "../components/common/PrimaryButton";
import { useEffect, useState } from "react";
import PartyList from "../components/party/PartyList";
import { PartyIcon } from "../assets/icons/PartyIcon";
import CreatePartyModal from "../components/party/CreatePartyModal";
import { DateValue, RangeValue } from "@nextui-org/react";
import { parseDate } from "@internationalized/date";
import { Filters, Party } from "../interfaces/Party";
import { getParties } from "../api/party";
import { Pagination } from "../interfaces/pagination";

export default function Parties() {
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [filters, setFilters] = useState<Filters>({
    fantasias_id: new Set<string>([]),
    types_id: new Set<string>([]),
    ciudades: new Set<string>([]),
    user_id: undefined,
    estado: 1,
  });

  const [data, setData] = useState({
    parties: [] as Party[],
    total: 0,
  });

  const [date, setDate] = useState<RangeValue<DateValue>>({
    start: parseDate(new Date().toISOString().split("T")[0]),
    end: parseDate(
      new Date(new Date().setMonth(new Date().getMonth() + 4))
        .toISOString()
        .split("T")[0]
    ),
  });

  const [pagination, setPagination] = useState<Pagination>({
    current_page: 0,
    last_page: 0,
    total: 0,
    per_page: 1,
  });

  useEffect(() => {
    const getData = async () => {
      setIsLoading(true);

      const filtersData = {
        nombre: "",
        fecha_inicio: date.start,
        fecha_fin: date.end,
        fantasias_id:
          Array.from(filters.fantasias_id).length > 0
            ? Array.from(filters.fantasias_id).join(",")
            : undefined,
        tipo_fiesta:
          Array.from(filters.types_id).length > 0
            ? Array.from(filters.types_id).join(",")
            : undefined,
        ciudades:
          Array.from(filters.ciudades).length > 0
            ? Array.from(filters.ciudades).join(",")
            : undefined,
        estado: 1,
      };

      const parties = await getParties(filtersData);
      setData({
        ...data,
        parties: parties?.info?.data[0].data ?? [],
        total: parties?.info?.data[0].total ?? 0,
      });

      setPagination({
        current_page: parties?.info?.data[0].current_page,
        last_page: parties?.info?.data[0].last_page,
        total: parties?.info?.data[0].total,
        per_page: parties?.info?.data[0].per_page,
      });
      setIsLoading(false);
    };
    getData();
  }, [date, filters]);
  const onSuccessCreateParty = (party: Party) => {
    setData({
      ...data,
      parties: [party, ...data.parties],
    });
  };
  //TODO: refactor this
  const onPageChange = async (page: number, orderColumn?: string) => {
    setIsLoading(true);
    let filtersData = {
      nombre: "",
      fecha_inicio: date.start,
      fecha_fin: date.end,
      page: page,
      orderColumn: "",
      fantasias_id:
        Array.from(filters.fantasias_id).length > 0
          ? Array.from(filters.fantasias_id).join(",")
          : undefined,
      tipo_fiesta:
        Array.from(filters.types_id).length > 0
          ? Array.from(filters.types_id).join(",")
          : undefined,
      estado: 1,
    };
    if (orderColumn) {
      filtersData = {
        ...filtersData,
        orderColumn,
      };
    }
    const parties = await getParties(filtersData);
    setData({
      parties: parties?.info?.data[0].data ?? [],
      total: parties?.info?.data[0].total ?? 0,
    });
    setPagination({
      current_page: parties?.info?.data[0].current_page,
      last_page: parties?.info?.data[0].last_page,
      total: parties?.info?.data[0].total,
      per_page: parties?.info?.data[0].per_page,
    });
    setIsLoading(false);
  };

  return (
    <>
      <NavBar />
      <main className="flex items-center  w-full overflow-hidden pt-20 md:pt-8 min-h-[100vh] flex-col">
        <div className="px-4 md:px-14 w-full max-w-8xl">
          <section className="w-full flex flex-wrap items-center justify-center">
            <div className="mt-2 flex flex-col items-start w-full md:w-10/12">
              <h1 className="text-2xl font-semibold">Fiestas</h1>
              <p>
                Aquí encontrarás las próximas fiestas que tienen planeados los
                usuarios Loouth. Publica tus fiestas y vive experiencias únicas
                allá donde vayas.
              </p>
            </div>
            <div className="mt-2 flex justify-end w-full sm:w-3/4 md:w-2/12 md:pl-3">
              <PrimaryButton
                className="w-full"
                onClick={() => setShowModal(true)}
              >
                <div className="flex items-center gap-2">
                  <PartyIcon />
                  <span className="inline md:hidden lg:inline">
                    Crear fiesta
                  </span>
                </div>
              </PrimaryButton>
            </div>
          </section>
        </div>
        <PartyList
          isLoading={isLoading}
          parties={data.parties}
          dateRange={date}
          onChangeDate={setDate}
          total={data.total}
          pagination={pagination}
          onPageChange={onPageChange}
          filters={filters}
          setFilters={setFilters}
        />
      </main>
      <Footer />
      <CreatePartyModal
        isOpen={showModal}
        closeModal={() => setShowModal(false)}
        onSuccessCreateParty={onSuccessCreateParty}
      />
    </>
  );
}
