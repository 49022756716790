import Logo from "../components/common/Logo";
import CustomInput from "../components/form/CustomInput";
import PrimaryButton from "../components/common/PrimaryButton";
import { Link, useNavigate } from "react-router-dom";
import { FormEvent, useEffect, useState } from "react";
import CustomCheckBox from "../components/form/CustomCheckBox";
import { login } from "../api/auth";
import { toast } from "react-toastify";
import VerifyEmail from "../components/auth/VerifyEmail";
import { useAuthStore } from "../stores/authStore";
import { User } from "../interfaces/Login";
import { useStepperStore } from "../stores/stepperStore";
import { useLoginStore } from "../stores/loginDataStore";
import PersonVerify from "../components/auth/PersonVerify";
import { useHomeStore } from "../stores/homeData";
import { HomeData } from "../interfaces/homeData";
import LoginImage from "../components/auth/LoginImage";

export default function Login() {
	const [loading, setLoading] = useState(false);
	const [verifyModal, setVerifyModal] = useState(false);
	const user = useLoginStore((state) => state.user);

	const setUser = useLoginStore((state) => state.saveUser);
	const saveToken = useAuthStore((state) => state.saveToken);
	const saveUser = useAuthStore((state) => state.saveUser);
	const authData = useAuthStore((state) => state.user);

	const setHome = useHomeStore((state) => state.setHome);

	const setStep = useStepperStore((state) => state.setStep);

	const navigate = useNavigate();

	const [data, setData] = useState({ email: "", password: "" });

	const handleChange = (e: FormEvent<HTMLInputElement>) => {
		const target = e.target as HTMLInputElement;
		setData({
			...data,
			[target.name]: target.value,
		});
	};

	async function submit(e: FormEvent<HTMLFormElement>) {
		e.preventDefault();
		setUser(null);
		saveUser(null);
		setLoading(true);

		const responseData = await login(data);
		setLoading(false);
		try {
			if (responseData?.error == "true") {
				toast.error(responseData.info.message);
			} else {
				setUser(responseData.info.data.user as User);
				if (responseData.info.data.home?.home) {
					setHome(responseData.info.data.home.home as HomeData);
					// console.log("homeeeeeeeeeeee", responseData.info.data.home.home);
				}
				saveToken(responseData.info.data.token);
				if (responseData.info.data.user.email_verified_at == null) {
					// setToken(responseData.info.data.token);
					saveToken(responseData.info.data.token);
					setVerifyModal(true);
				}
			}
		} catch (error) {
			console.log(error);
			toast.error("Error desconocido");
		}
	}

	useEffect(() => {
		if (user) {
			//ref.current.play();
			// console.log(user);

			if (user.email_verified_at == null) {
				//setToken(authToken);

				setVerifyModal(true);
			} else if (!user.perfil_id || (!user.info && user.role_id == "3")) {
				// TODO: Verify this
				// console.log("userrrrrrrrrrrrrrrrrr", user);
				if (user.role_id == "2") setStep(1);
				else setStep(2);

				navigate("/register"); // go to fill your data
			} else if (user.info) {
				saveUser(user);

				if (user.verified) {
					navigate("/");
				} else if (
					// go to verification pending
					!user.verified &&
					(user.info.verification_path || user.role_id == "3")
				)
					navigate("/");
				// else setTimeout(() => navigate("/"), 2000);
				//   setTimeout(() => navigate("/"), 2000); // go to home and pay your subscription
				// } else {

				//   setTimeout(() => navigate("/"), 2000);
				//} // just go home
			}
		} else {
			setVerifyModal(false);
		}
	});

	return (
		<div className="flex justify-center">
			<VerifyEmail
				// TODO: check this
				isOpen={verifyModal}
				email={user?.email}
				closeModal={() => {
					setVerifyModal(false);
					setUser(null);
				}}
				next={() => null}
			/>
			<div className="w-full flex max-w-[1554px] h-screen justify-center overflow-hidden">
				<div className="flex row p-3 w-full items-center  overflow-hidden">
					<section className="col-12 xl:col-7 flex justify-center xl:justify-end xl:mr-36 items-center h-full ">
						<form onSubmit={submit} className="max-w-[360px] h-max text-center">
							<div className="flex justify-center">
								<Logo />
							</div>
							<h1 className="mt-6 font-semibold text-[30px] ">Iniciar Sesión</h1>
							<h3 className="mt-2 font-normal text-color1 text-[16px]">
								Introduce tu correo electrónico y tu contraseña
							</h3>
							<CustomInput
								required
								type="email"
								name="email"
								label="Email"
								placeholder="Introduce tu correo electrónico"
								className="pt-[28px]"
								value={data.email}
								onChange={handleChange}
							/>
							<CustomInput
								required
								type="password"
								name="password"
								label="Contraseña"
								placeholder="Introduce tu contraseña"
								className="pt-5"
								value={data.password}
								onChange={handleChange}
							/>
							<div className="mt-[20px] flex justify-between">
								<CustomCheckBox>Recuérdame</CustomCheckBox>
								<Link className="font-semibold text-[14px] text-color4" to="/recovery-password">
									Recuperar Contraseña
								</Link>
							</div>
							<PrimaryButton type="submit" loading={loading} className="mt-[20px] w-full">
								Iniciar Sesión
							</PrimaryButton>
							<div className="mt-[28px] flex justify-center gap-1">
								<p className="font-normal text-[14px] text-color5">¿No tienes una cuenta?</p>
								<Link className="font-semibold text-[14px] text-color4" to="/register">
									Regístrate
								</Link>
							</div>
						</form>
					</section>
					<section className="hidden xl:flex xl:col-5 justify-end items-center p-5 pl-0">
						<div className="relative w-[720px] h-[calc(100vh-20px)] max-h-[920px] flex items-center">
							<LoginImage />
						</div>
					</section>
				</div>
			</div>
			<PersonVerify
				isOpen={
					!!authData &&
					!!authData.email_verified_at &&
					!authData.verified &&
					!!authData.perfil_id &&
					!authData.info?.verification_path &&
					authData.role_id == "2"
				}
			/>
		</div>
	);
}
