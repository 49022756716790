import {
  Spinner,
  Selection,
  Dropdown,
  Button,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
  RangeValue,
  Divider,
  Chip,
} from "@nextui-org/react";
import NavBar from "../components/common/Navbar";
import SecondaryButton from "../components/common/SecondaryButton";
import Footer from "../components/common/Footer";
import { FormEvent, useEffect, useMemo, useState } from "react";
import { useAuthStore } from "../stores/authStore";
import { ActivityPost, Post } from "../interfaces/Post";
import { getActivity, getPublicationsByUser } from "../api/publication";
import { toast } from "react-toastify";
import {
  getFantasies,
  getProvinces,
  getSexualOrientations,
} from "../api/formSelectsData";
import { SexualOrientation } from "../interfaces/SexualOrientation";
import { Province } from "../interfaces/Provice";
import { Fantasy } from "../interfaces/Fantasy";
import { useUserStore } from "../stores/userStore";
import { getGlobal, getUserSlug } from "../api/user";
import { useParams } from "react-router-dom";
import ProfileCard from "../components/profile/ProfileCard";
import AboutUser from "../components/profile/AboutUser";
import ProfilePosts from "../components/profile/ProfilePosts";
import ProfileFeaturedContent from "../components/profile/ProfileFeaturedContent";
import PartyList from "../components/party/PartyList";
import TripList from "../components/trip/TripList";
import FantasiesList from "../components/fantasy/FantasiesList";
import FriendsTab from "../components/friend/FriendsTab";
import { Filters as TripFilters, Trip } from "../interfaces/Trip";
import { getTrips } from "../api/trip";
import { DateValue, parseDate } from "@internationalized/date";
import { getParties } from "../api/party";
import { Filters as PartyFilters, Party, City } from "../interfaces/Party";
import { useHomeStore } from "../stores/homeData";
import { ProfileDetails } from "../components/profile/ProfileDetails";
import { Pagination } from "../interfaces/pagination";
import ProfileFourthStepForm from "../components/auth/ProfileInfoFourthStepForm";
import ProfileFiveStepForm from "../components/auth/ProfileInfoFiveStepForm";
import ProfileThirdStepForm from "../components/auth/ProfileInfoThirdStepForm";
import { useStepperStore } from "../stores/stepperStore";
import { useRegisterStore } from "../stores/registerData";
import LocalInfoForm from "../components/auth/LocalEditInfo";
import { saveUserInfo } from "../api/info";
import Galery from "../components/profile/Gallery";
import { Global } from "../interfaces/SingleUser";
import LastVisits from "../components/home/LastVisits";
import { FriendReq, User } from "../interfaces/Login";
import Affinity from "../components/profile/Affinity";
import ProfileBigGlobal from "../components/profile/ProfileBigGlobal";
import { getFriends } from "../api/friends";

const tabs = new Map();
tabs.set("activity", "Actividad");
tabs.set("about", "Sobre mí");
tabs.set("parties", "Fiestas");
tabs.set("trips", "Viajes");
tabs.set("fantasies", "Fantasías");
tabs.set("friends", "Amigos");
tabs.set("photos", "Fotos");

export default function MyProfile() {
  const { slug } = useParams<{ slug?: string }>();
  if (!slug) throw new Error("Slug is missing");

  const [userId, setUserId] = useState<number | undefined>(undefined);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);

  const authData = useAuthStore((state) => state.user);
  const setAuthData = useAuthStore((state) => state.saveUser);
  const setUserData = useUserStore((state) => state.saveUser);
  const [localData, setLocalData] = useState({
    owners_name: "",
    locals_name: "",
    movil: "",
    CIF: "",
    direccion: "",
    ubicacion: "",
  });

  const [info, setInfo] = useState({
    orientations: [] as SexualOrientation[],
    provinces: [] as Province[],
    fantasies: [] as Fantasy[],
    cities: [] as City[],
  });
  const [partyFilters, setPartyFilters] = useState<PartyFilters>({
    fantasias_id: new Set<string>([]),
    types_id: new Set<string>([]),
    user_id: undefined,
    ciudades: new Set<string>([]),
    estado: 1,
    attended: false,
  });
  const [pagination, setPagination] = useState<Pagination>({
    current_page: 0,
    last_page: 0,
    total: 0,
    per_page: 1,
  });

  const [tripFilters, setTripFilters] = useState<TripFilters>({
    fantasias_id: new Set<string>([]),
    tipo_viaje: new Set<string>([]),
    pais: new Set<string>([]),
    user_id: undefined,
  });
  const [tripPagination, setTripPagination] = useState<Pagination>({
    current_page: 0,
    last_page: 0,
    total: 0,
    per_page: 1,
  });

  const [reactions, setReactions] = useState<ActivityPost[]>([]);
  const [lastPosts, setLastPosts] = useState<Post[]>([]);
  const [featuredContent, setFeaturedContent] = useState<Post[]>([]);
  const [isLoadingInfo, setIsLoadingInfo] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingPosts, setIsLoadingPosts] = useState(false);
  const [isLoadingreactions, setIsLoadingReactions] = useState(false);
  const [tab, setTab] = useState("activity");
  const userData = useUserStore((state) => state.user);
  const [selectedKeys, setSelectedKeys] = useState(new Set(["activity"]));
  const [data, setData] = useState({
    trips: [] as Trip[],
    totalTrips: 0,
    parties: [] as Party[],
    totalParties: 0,
  });
  const [isLoadingTrips, setIsLoadingTrips] = useState(false);
  const [isLoadingParties, setIsLoadingParties] = useState(false);
  const [isSavingLocal, setIsSavingLocal] = useState(false);

  const [date, setDate] = useState<RangeValue<DateValue>>({
    start: parseDate(new Date().toISOString().split("T")[0]),
    end: parseDate(
      new Date(new Date().setMonth(new Date().getMonth() + 4))
        .toISOString()
        .split("T")[0]
    ),
  });

  const newComment = useHomeStore((state) => state.newComment);
  const setNewComment = useHomeStore((state) => state.setNewComment);
  const setStep = useStepperStore((state) => state.setStep);
  const step = useStepperStore((state) => state.step);
  const [friends, setFriends] = useState<User[]>([]);
  const [loadingFriends, setloadingFriends] = useState(false);

  // all steps data
  const setStepTwoData = useRegisterStore((state) => state.setStepTwoData);
  const setStepThreeData = useRegisterStore((state) => state.setStepThreeData);
  const setStepFourData = useRegisterStore((state) => state.setStepFourData);
  const [globalData, setGlobalData] = useState<Global>({
    friends: 0,
    visits: 0,
    reactions: 0,
  });
  const [isLoadingGlobal, setIsLoadingGlobal] = useState(false);
  useEffect(() => {
    setSelectedKeys(new Set(["activity"]));
    setTab("activity");

    const getData = async () => {
      setIsLoading(true);
      setIsLoadingGlobal(true);
      setloadingFriends(true);

      const userRes = await getUserSlug(slug);
      if (!userRes || userRes?.error === "true") {
        toast.error(userRes?.info?.message ?? "Ha ocurrido un error");
        setIsLoading(false);
        return;
      }

      const id = userRes.info.data[0]?.id;
      setUserId(id); // Guardamos el ID del usuario en el estado

      setUserData(userRes.info.data[0]);
      setPartyFilters({
        fantasias_id: new Set<string>([]),
        types_id: new Set<string>([]),
        ciudades: new Set<string>([]),
        user_id: id,
        estado: 1,
      });
      setTripFilters({
        fantasias_id: new Set<string>([]),
        tipo_viaje: new Set<string>([]),
        pais: new Set<string>([]),
        user_id: id,
      });

      setIsLoading(false);
      setIsLoadingInfo(true);
      setIsLoadingPosts(true);
      setIsLoadingReactions(true);

      if (id == authData?.id) {
        setStep(2);
        if (authData?.role_id == "2") {
          if (authData?.info?.detalles) {
            setStepTwoData({
              ...authData?.info?.detalles,
              is_couple: authData.info.is_couple,
              fantasias_id: authData.info.fantasias_id,
              provincia_id: authData.info.provincia_id,
              looking_id: authData.info.looking_id?.toString() ?? undefined,
            });
          }
          if (authData?.info?.como_soy)
            setStepThreeData(authData?.info?.como_soy);

          if (authData?.info?.otros) {
            setStepFourData({
              ...authData?.info?.otros,
              idiomas_id: authData?.info?.idiomas_id?.toString(),
              horoscopo_id: authData?.info?.horoscopo_id?.toString(),
              profesion_id: authData?.info?.profesion_id?.toString(),
              partner_idioma_id: authData?.info?.otros?.partner_idioma_id,
            });
          }
        } else if (authData?.role_id == "3") {
          setLocalData({
            owners_name: authData?.info?.owners_name ?? "",
            locals_name: authData?.info?.locals_name ?? "",
            movil: authData?.info?.movil ?? "",
            CIF: authData?.info?.CIF ?? "",
            direccion: authData?.info?.direccion ?? "",
            ubicacion: authData?.info?.ubicacion ?? "",
          });
        }
      }

      const orientations = await getSexualOrientations();
      const provinces = await getProvinces();
      const fantasies = await getFantasies();
      if (id) {
        const global = await getGlobal(id);
        if (global) setGlobalData(global as Global);
      }

      setIsLoadingGlobal(false);
      setInfo({
        ...info,
        orientations: orientations?.info?.data[0] ?? [],
        provinces: provinces?.info?.data[0] ?? [],
        fantasies: fantasies?.info?.data[0] ?? [],
      });

      setIsLoadingInfo(false);
    };

    if (slug) getData();
  }, [slug, authData]); // Se ejecuta cuando `slug` o `authData` cambian

  // Segundo useEffect: Se ejecuta cuando `userId` ya ha sido asignado
  useEffect(() => {
    if (!userId) return;

    const fetchFriendsAndPosts = async () => {
      setloadingFriends(true);

      // Obtener amigos
      const response = await getFriends(userId);
      const friends = (response?.info?.data[0]?.data as FriendReq[]).map(
        (e) => {
          const user = e.to == "me" ? e.from : e.to;
          return { ...(user as User), reqId: e.id };
        }
      );
      setFriends(friends ?? []);
      setloadingFriends(false);

      // Obtener publicaciones del usuario
      const postsRes = await getPublicationsByUser({ user_id: userId });
      const highlightRes = await getPublicationsByUser(
        { user_id: userId, highlight: true },
        true
      );

      setIsLoadingPosts(false);
      if (postsRes?.error == "true" || !postsRes) {
        toast.error(postsRes?.info.message + " ");
      }
      if (postsRes?.info?.data[0]?.data) {
        setLastPosts(postsRes.info.data[0].data);
      }
      if (highlightRes?.info?.data[0]) {
        setFeaturedContent(highlightRes.info.data[0]);
      }

      // Obtener actividad del usuario
      const res = await getActivity(userId);
      if (res.error == "true") {
        toast.error(res.info.message + " ");
      }
      if (res?.info?.data) {
        setReactions(res.info.data[0]);
      }
      setIsLoadingReactions(false);
    };

    fetchFriendsAndPosts();
  }, [userId]); // Se ejecuta cuando `userId` cambia

  useEffect(() => {
    // console.log(partyFilters);
    if (tab == "trips") getTripsData();
    if (tab == "parties") getPartiesData();
  }, [date, partyFilters, tripFilters, tab]);

  useEffect(() => {
    if (newComment) {
      // console.log("newComment", newComment);
      const upd = reactions.map((p) => {
        if (p.id == newComment.post && p.info) {
          p.info.comentarios += newComment.count;
        }
        return p;
      });

      setReactions(upd);
      const postUpd = lastPosts.map((p) => {
        if (p.id == newComment.post && p.info) {
          p.info.comentarios += newComment.count;
        }
        return p;
      });

      setLastPosts(postUpd);
      setNewComment(null);
    }
  }, [newComment]);

  const fantasies = useMemo(() => {
    if (info.fantasies && userData?.info?.fantasias_id)
      return info.fantasies.filter((e) =>
        userData?.info?.fantasias_id?.split(",").includes(e.id.toString())
      );
    return [];
  }, [userData, info.fantasies]);

  const selectedValue = useMemo(
    () => Array.from(selectedKeys).join(", ").replaceAll("_", " "),
    [selectedKeys]
  );
  const onSelectionChange = (key: Selection) => {
    // console.log(key != "all" ? key.keys() : "");
    const selected: string[] = [];

    if (key != "all")
      key.forEach((el) => {
        selected.push(el.toString());
      });

    setSelectedKeys(new Set([...selected]));
  };
  useEffect(() => {
    setTab(Array.from(selectedKeys).join(", ").replaceAll("_", " "));
  }, [selectedKeys]);

  const getTripsData = async (page?: number, orderColumn?: string) => {
    setIsLoadingTrips(true);

    const filters = {
      nombre: "",
      fecha_inicio: date.start,
      fecha_fin: date.end,
      page,
      orderColumn,
      fantasias_id:
        Array.from(tripFilters.fantasias_id).length > 0
          ? Array.from(tripFilters.fantasias_id).join(",")
          : undefined,
      user_id: userData?.id,
      tipo_viaje:
        Array.from(tripFilters.tipo_viaje).length > 0
          ? Array.from(tripFilters.tipo_viaje).join(",")
          : undefined,
      pais:
        Array.from(tripFilters.pais).length > 0
          ? Array.from(tripFilters.pais).join(",")
          : undefined,
    };

    const trips = await getTrips(filters);
    setData({
      ...data,
      trips: trips?.info?.data[0].data ?? [],
      totalTrips: trips?.info?.data[0].total,
    });
    setTripPagination({
      current_page: trips?.info?.data[0].current_page,
      last_page: trips?.info?.data[0].last_page,
      total: trips?.info?.data[0].total,
      per_page: trips?.info?.data[0].per_page,
    });
    setIsLoadingTrips(false);
  };

  const getPartiesData = async (page?: number, orderColumn?: string) => {
    setIsLoadingParties(true);

    const filtersData = {
      nombre: "",
      fecha_inicio: date.start,
      fecha_fin: date.end,
      page,
      fantasias_id:
        Array.from(partyFilters.fantasias_id).length > 0
          ? Array.from(partyFilters.fantasias_id).join(",")
          : undefined,
      user_id: userData?.id,
      orderColumn,
      tipo_fiesta:
        Array.from(partyFilters.types_id).length > 0
          ? Array.from(partyFilters.types_id).join(",")
          : undefined,
      ciudades:
        Array.from(partyFilters.ciudades).length > 0
          ? Array.from(partyFilters.ciudades).join(",")
          : undefined,
      estado: partyFilters.estado,
      attended: partyFilters.attended,
    };
    //console.log(filtersData.user_id);
    const parties = await getParties(filtersData);
    setPagination({
      current_page: parties?.info?.data[0].current_page,
      last_page: parties?.info?.data[0].last_page,
      total: parties?.info?.data[0].total,
      per_page: parties?.info?.data[0].per_page,
    });
    setData({
      ...data,
      parties: parties?.info?.data[0].data ?? [],
      totalParties: parties?.info?.data[0].total,
    });
    setIsLoadingParties(false);
  };

  const onPageChange = async (page: number, orderColumn?: string) => {
    getPartiesData(page, orderColumn);
  };
  const onTripsPageChange = async (page: number, orderColumn?: string) => {
    getTripsData(page, orderColumn);
  };
  const backStep = () => {
    setStep(step - 1);
  };
  const onEditClick = () => {
    setSelectedKeys(new Set(["edit"]));
    setTab("edit");
    setStep(2);
  };

  async function submitForLocal(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();

    const data = {
      ...localData,
      user_id: authData?.id,
    };

    setIsSavingLocal(true);
    const response = await saveUserInfo(data);
    if (response.error == "true") {
      toast.error(response.info.message);
    } else {
      toast.success("Los datos del local se guardaron correctamente");
      setAuthData(response.info.data[0]);
    }
    setIsSavingLocal(false);
  }

  return (
    <>
      <NavBar />
      <div className="w-full">
        <main className=" flex justify-center w-full overflow-hidden">
          <div className="w-full max-w-8xl">
            {!isLoading && (
              <section className="w-full">
                <ProfileCard
                  globalData={globalData}
                  isLoadingGlobal={isLoadingGlobal}
                  orientations={info.orientations}
                  onEditClick={onEditClick}
                  provinces={info.provinces}
                  child={
                    <div className="hidden lg:flex w-full gap-2">
                      <SecondaryButton
                        className={`font-semibold text-[14px] ${
                          tab == "activity" ? "text-color4" : "text-color2"
                        }`}
                        onClick={() => setTab("activity")}
                      >
                        Actividad
                      </SecondaryButton>
                      {userData?.role_id == "2" && (
                        <SecondaryButton
                          className={`font-semibold text-[14px] ${
                            tab == "about" ? "text-color4" : "text-color2"
                          }`}
                          onClick={() => setTab("about")}
                        >
                          Sobre mí
                        </SecondaryButton>
                      )}

                      <SecondaryButton
                        className={`font-semibold text-[14px] ${
                          tab == "friends" ? "text-color4" : "text-color2"
                        }`}
                        onClick={() => setTab("friends")}
                      >
                        Amigos
                      </SecondaryButton>

                      <SecondaryButton
                        className={`font-semibold text-[14px] ${
                          tab == "parties" ? "text-color4" : "text-color2"
                        }`}
                        onClick={() => setTab("parties")}
                      >
                        Fiestas
                      </SecondaryButton>
                      {userData?.role_id == "2" && (
                        <SecondaryButton
                          className={`font-semibold text-[14px] ${
                            tab == "fantasies" ? "text-color4" : "text-color2"
                          }`}
                          onClick={() => setTab("fantasies")}
                        >
                          Fantasías
                        </SecondaryButton>
                      )}
                      {userData?.role_id == "2" && (
                        <SecondaryButton
                          className={`font-semibold text-[14px] ${
                            tab == "trips" ? "text-color4" : "text-color2"
                          }`}
                          onClick={() => setTab("trips")}
                        >
                          Viajes
                        </SecondaryButton>
                      )}
                      <SecondaryButton
                        className={`font-semibold text-[14px] ${
                          tab == "photos" ? "text-color4" : "text-color2"
                        }`}
                        onClick={() => setTab("photos")}
                      >
                        Galería
                      </SecondaryButton>
                    </div>
                  }
                  mobileChild={
                    <div className="w-full flex gap-4 justify-end">
                      <Dropdown>
                        <DropdownTrigger>
                          <Button
                            variant="bordered"
                            radius="sm"
                            className="capitalize border-1 md:px-10"
                            endContent={
                              <svg
                                className="mt-1"
                                width="12"
                                height="8"
                                viewBox="0 0 12 8"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M1 1.5L6 6.5L11 1.5"
                                  stroke="#344054"
                                  strokeWidth="1.66667"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            }
                          >
                            {tabs.get(selectedValue)}
                          </Button>
                        </DropdownTrigger>
                        <DropdownMenu
                          aria-label="Single selection example"
                          variant="flat"
                          disallowEmptySelection
                          selectionMode="single"
                          selectedKeys={selectedKeys}
                          onSelectionChange={onSelectionChange}
                        >
                          <DropdownItem key="activity">Actividad</DropdownItem>
                          <DropdownItem key="about">Sobre mí</DropdownItem>
                          <DropdownItem key="friends">Amigos</DropdownItem>
                          <DropdownItem key="parties">Fiestas</DropdownItem>
                          <DropdownItem
                            hidden={userData?.role_id == "3"}
                            key="fantasies"
                          >
                            Fantasías
                          </DropdownItem>

                          <DropdownItem
                            hidden={userData?.role_id == "3"}
                            key="trips"
                          >
                            Viajes
                          </DropdownItem>

                          <DropdownItem key="photos">Galería</DropdownItem>
                        </DropdownMenu>
                      </Dropdown>
                    </div>
                  }
                />
                <Divider className="mt-4 max-w-8xl h-[2px] bg-zinc-200" />
                {tab == "about" && (
                  <>
                    <ProfileDetails
                      orientations={info.orientations}
                      userData={userData}
                      provinces={info.provinces}
                      fantasies={fantasies}
                      isLoading={isLoadingInfo}
                    />
                  </>
                )}

                {tab == "activity" && (
                  <>
                    <div className="flex w-full px-4 ">
                      <div className="w-4/12 pt-10 lg:min-w-[243px] lg:w-[243px] hidden md:block">
                        {userData?.id != authData?.id &&
                          userData?.role_id != "3" &&
                          authData?.role_id != "3" && (
                            <>
                              {userData && <Affinity userData={userData} />}
                              <div className="flex lg:hidden gap-2 mt-3 items-center">
                                <Chip variant="flat" color="primary">
                                  {userData?.afinidad
                                    ? Math.round(userData?.afinidad)
                                    : "?"}
                                  %
                                </Chip>
                                <span className="font-medium  text-color1">
                                  Afinidad
                                </span>
                                <span className="font-medium  text-color2">
                                  {(userData?.afinidad ?? 0) > 70
                                    ? "Muy Alta"
                                    : (userData?.afinidad ?? 0) > 50
                                    ? "Alta"
                                    : "Baja"}
                                </span>
                              </div>
                            </>
                          )}
                        <ProfileBigGlobal
                          globalData={globalData}
                          isLoadingGlobal={isLoadingGlobal}
                        />
                      </div>
                      <div className="flex-1 min-w-0 flex-col">
                        <AboutUser
                          provinces={info.provinces}
                          fantasies={fantasies}
                          isLoading={isLoadingInfo}
                        />

                        {isLoadingPosts || featuredContent.length ? (
                          <>
                            <div className="mt-10 flex justify-start px-6">
                              <h3 className="font-bold text-[18px] text-color3 ">
                                Contenido Destacado{" "}
                                <button className="text-primary text-sm font-medium ml-3">
                                  Ver todo
                                </button>
                              </h3>
                            </div>
                            <ProfileFeaturedContent
                              posts={featuredContent}
                              isLoadingPosts={isLoadingPosts}
                            />
                          </>
                        ) : (
                          <></>
                        )}
                        {isLoadingPosts || lastPosts.length ? (
                          <>
                            <div className="mt-10 flex justify-start px-6">
                              <h3 className="font-bold text-[18px] text-color3">
                                Contenido Destacado{" "}
                                <button
                                  className="text-primary text-sm font-medium ml-3"
                                  onClick={handleOpenModal}
                                >
                                  Ver todo
                                </button>
                              </h3>
                            </div>

                            <ProfilePosts
                              isLoadingPosts={isLoadingPosts}
                              posts={lastPosts.slice(0, 3)}
                            />
                          </>
                        ) : (
                          <div className="mt-10 flex justify-start px-6 flex-col ">
                            <h3 className="font-bold text-[18px] text-color3  mb-5">
                              Últimas publicaciones
                            </h3>
                            <p className="px-2">
                              No hay publicaciones que mostrar
                            </p>
                          </div>
                        )}

                        {/* Modal */}
                        {isModalOpen && (
                          <div
                            className="fixed inset-0 bg-black/50 flex items-center justify-center z-50"
                            onClick={handleCloseModal}
                          >
                            {/* Contenedor del modal */}
                            <div
                              className="bg-white p-6 rounded-lg max-w-3xl w-full max-h-[80%] overflow-y-scroll relative"
                              onClick={(e) => e.stopPropagation()} // Evitar cerrar el modal al hacer clic dentro
                            >
                              <div className="flex justify-between items-center mb-4">
                                <h2 className="text-lg font-bold">
                                  Todas las publicaciones
                                </h2>
                                <button
                                  onClick={handleCloseModal}
                                  className="text-red-500 font-bold"
                                >
                                  Cerrar
                                </button>
                              </div>
                              <div className="mt-4">
                                <ProfilePosts
                                  isLoadingPosts={isLoadingPosts}
                                  posts={lastPosts}
                                />
                              </div>
                            </div>
                          </div>
                        )}
                        {isLoadingreactions || reactions.length ? (
                          <>
                            <div className="mt-10 flex justify-start px-6 flex-col">
                              <h3 className="font-bold text-[18px] text-color3 ">
                                Últimas reacciones
                              </h3>
                            </div>

                            <ProfilePosts
                              isLoadingPosts={isLoadingreactions}
                              posts={reactions.slice(0, 3)}
                            />
                          </>
                        ) : (
                          <div className="mt-10 flex justify-start px-6 flex-col">
                            <h3 className="font-bold text-[18px] text-color3 mb-5">
                              Últimas reacciones
                            </h3>
                            <p className="px-2">
                              No hay publicaciones que mostrar
                            </p>
                          </div>
                        )}
                      </div>
                      {/* aside friends */}
                      <div className="w-4/12 hidden xl:block min-w-[243px] xl:w-[243px] pt-10">
                        <LastVisits
                          isLoading={loadingFriends}
                          title={"Amigos de " + userData?.name}
                          lastVisits={friends}
                        />
                      </div>
                    </div>
                  </>
                )}
                {tab == "parties" && (
                  <PartyList
                    isLoading={isLoadingParties}
                    parties={data.parties}
                    dateRange={date}
                    onChangeDate={setDate}
                    filters={partyFilters}
                    setFilters={setPartyFilters}
                    total={pagination.total}
                    pagination={pagination}
                    onPageChange={onPageChange}
                    showVisibiltySelect={authData?.id == userData?.id}
                    showTypeSelect
                  />
                )}
                {tab == "trips" && (
                  <TripList
                    isLoading={isLoadingTrips}
                    trips={data.trips}
                    dateRange={date}
                    onChangeDate={setDate}
                    filters={tripFilters}
                    setFilters={setTripFilters}
                    pagination={tripPagination}
                    total={tripPagination.total}
                    onPageChange={onTripsPageChange}
                    provinces={info.provinces}
                    orientations={info.orientations}
                  />
                )}
                {tab == "fantasies" && (
                  <FantasiesList isLoading={isLoading} fantasies={fantasies} />
                )}
                {tab == "photos" && userData && <Galery user={userData} />}
                {tab == "friends" && userData?.id != undefined && (
                  <FriendsTab
                    userId={userData?.id.toString()}
                    orientations={info.orientations}
                    provinces={info.provinces}
                  />
                )}
                {tab == "edit" && (
                  <div className="w-full flex justify-center">
                    {userData?.role_id == "2" ? (
                      <div className="max-w-screen-xl w-full">
                        {/*Third step for register user "Detalles del perfil" */}
                        <div className="w-full flex justify-center  hide-scroll lg:px-16">
                          <ProfileThirdStepForm
                            isEditing
                            isLocal={false}
                            participants={authData?.info?.is_couple ? 2 : 1}
                            backStep={backStep}
                            next={() => console.log("next")}
                          />
                        </div>
                        {/*Fourth step for register user "Como soy" */}
                        <div className="w-full flex justify-center lg:px-16">
                          <ProfileFourthStepForm
                            isEditing
                            participants={authData?.info?.is_couple ? 2 : 1}
                            backStep={backStep}
                          />
                        </div>
                        {/*Fifth step for register user "Otros datos" */}
                        <div className="w-full flex justify-center lg:px-16">
                          <ProfileFiveStepForm
                            isEditing
                            isLocal={false}
                            participants={authData?.info?.is_couple ? 2 : 1}
                            backStep={backStep}
                          />
                        </div>
                      </div>
                    ) : (
                      <div className="w-full">
                        <LocalInfoForm
                          isEditing
                          localData={localData}
                          loading={isSavingLocal}
                          handleSubmit={submitForLocal}
                          setLocalData={setLocalData}
                        />
                      </div>
                    )}
                  </div>
                )}
              </section>
            )}
            {isLoading && (
              <div className="w-full flex justify-center pt-20 h-[50vh]">
                <Spinner color="primary" />
              </div>
            )}
          </div>
        </main>
        <Footer />
      </div>
    </>
  );
}
