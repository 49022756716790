/* eslint-disable no-mixed-spaces-and-tabs */
import { Button, Image, Spinner, Tooltip } from "@nextui-org/react";
import NavBar from "../components/common/Navbar";
import Post from "../components/post/Post";
import { useEffect, useState } from "react";
import { useAuthStore } from "../stores/authStore";
import { useHomeStore } from "../stores/homeData";
// import { User } from "../interfaces/Login";
import { Post as Publication } from "../interfaces/Post";
import { NewPostCard } from "../components/post/NewPostCard";
import { useParams } from "react-router-dom";
import { getFantasiesPublications } from "../api/publication";
import { getFantasy, getFantasySlug } from "../api/formSelectsData";
import InfiniteScroll from "react-infinite-scroll-component";
import LastPhotos from "../components/home/LastPhotos";
import LastVideos from "../components/home/LastVideos";
// import NewUsers from "../components/home/NewUsers";
import { Fantasy } from "../interfaces/Fantasy";
import { StarIcon } from "../assets/icons/StarIcon";
import { getInfoPayload, useRegisterStore } from "../stores/registerData";
import { saveUserInfo } from "../api/info";
import { toast } from "react-toastify";
import { withErrorHandler } from "../utils/withErrorFallback";
import LightBoxImage from "../components/common/LightBoxImage";
import PostSkeleton from "../components/post/PostSkeleton";

export default function FantasiaView() {
  const { slug } = useParams<{ slug?: string }>();
  if (!slug) throw new Error("Slug is missing");

  const authData = useAuthStore((state) => state.user);
  // const homeData = useHomeStore((state) => state.home);

  const [posts, setPosts] = useState<Publication[]>([]);
  const [lastPhotos, setLastPhotos] = useState<Publication[]>([]);
  const [lastVideos, setLastVideos] = useState<Publication[]>([]);
  const [fantasyData, setFantasyData] = useState<Fantasy | undefined>();
  const [hasMore, setHasMore] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [checked, setCheck] = useState(false);
  const [isOpenLightbox, setIsOpenLightbox] = useState(false);

  const newPost = useHomeStore((state) => state.newFantasyPost);
  const setNewPost = useHomeStore((state) => state.setNewFantasyPost);

  const setAuthData = useAuthStore((state) => state.saveUser);
  // all steps data
  const setStepTwoData = useRegisterStore((state) => state.setStepTwoData);
  const stepTwoData = useRegisterStore((state) => state.stepTwoData);
  const setStepThreeData = useRegisterStore((state) => state.setStepThreeData);
  const setStepFourData = useRegisterStore((state) => state.setStepFourData);
  const [fantasyId, setFantasyId] = useState<string | undefined>(undefined);

  useEffect(() => {
    const loadFantasyId = async () => {
      setIsLoading(true);
      // Resetear estados al cambiar slug
      setFantasyId(undefined);
      setFantasyData(undefined);
      setPosts([]);
      setLastPhotos([]);
      setLastVideos([]);
      setHasMore(true);

      try {
        const response = await getFantasySlug(slug);
        if (response?.info?.data?.[0]?.id) {
          setFantasyId(response.info.data[0].id);
        } else {
          toast.error("Fantasía no encontrada");
          // Redirigir si es necesario
        }
      } catch (error) {
        console.error("Error cargando fantasyId:", error);
        toast.error("Error cargando la fantasía");
      }
      setIsLoading(false);
    };

    loadFantasyId();
  }, [slug]);

  const fetchPosts = withErrorHandler(async () => {
    if (!fantasyId || !hasMore) return;

    const excludeIds = posts.map((p) => p.id).join(",") || "0";
    const response = await getFantasiesPublications(excludeIds, fantasyId);

    if (!response.info.data[0].length) {
      setHasMore(false);
    } else {
      setPosts((prev) => [...prev, ...response.info.data[0]]);
    }

    // Actualizar contenido lateral
    if (response.info.data.sidebar) {
      setLastPhotos(response.info.data.sidebar.posts_photos);
      setLastVideos(response.info.data.sidebar.posts_videos);
    }
  });
  useEffect(() => {
    const loadData = async () => {
      if (!fantasyId) return;

      setIsLoading(true);

      // 1. Cargar datos de la fantasía
      const fantasyResponse = await getFantasy(parseInt(fantasyId));
      if (fantasyResponse) setFantasyData(fantasyResponse.info.data[0]);

      // 2. Cargar publicaciones iniciales
      fetchPosts();

      // 3. Actualizar checks de usuario
      setCheck(!!authData?.info?.fantasias_id?.includes(fantasyId));

      setIsLoading(false);
    };

    loadData();
  }, [fantasyId]); // Solo se ejecuta cuando fantasyId cambia

  useEffect(() => {
    // console.log(authData?.info?.fantasias_id);
    const getData = async () => {
      setIsLoading(true);
      if (fantasyId) {
        setCheck(
          !!fantasyId &&
            !!authData?.info?.fantasias_id?.split(",").includes(fantasyId)
        );
        const fantasyRes = await getFantasy(parseInt(fantasyId));
        if (fantasyRes) {
          setFantasyData(fantasyRes.info.data[0]);
          // console.log(fantasyRes);
        }
      }
      if (authData?.info?.detalles) {
        const details = {
          ...authData?.info?.detalles,
          is_couple: !!authData.info.is_couple,
          fantasias_id: authData.info.fantasias_id,
          provincia_id: authData.info.provincia_id,
        };
        setStepTwoData(details);
      }
      if (authData?.info?.como_soy) setStepThreeData(authData?.info?.como_soy);

      if (authData?.info?.otros) {
        const others = {
          ...authData?.info?.otros,
          idiomas_id: authData?.info?.idiomas_id?.toString(),
          horoscopo_id: authData?.info?.horoscopo_id?.toString(),
          profesion_id: authData?.info?.profesion_id?.toString(),
          partner_idioma_id: authData?.info?.otros?.partner_idioma_id,
        };
        setStepFourData(others);
      }
      await fetchPosts();
      setIsLoading(false);
    };
    getData();
  }, []);

  // Actualización optimizada al añadir nuevo post
  useEffect(() => {
    if (newPost) {
      setPosts((prev) => [newPost, ...prev]);
      setNewPost(undefined);
    }
  }, [newPost]);

  const handleLike = withErrorHandler(async () => {
    if (!fantasyId) return;
    const arr = authData?.info?.fantasias_id?.split(",");

    if (arr?.includes(fantasyId)) {
      arr.filter((e) => e != fantasyId);
      const details = {
        ...stepTwoData,
        fantasias_id: arr.join(","),
      };
      setStepTwoData(details);
      // console.log(arr);
    } else if (arr) {
      arr.push(fantasyId);
      const details = {
        ...stepTwoData,
        fantasias_id: arr.join(","),
      };
      setStepTwoData(details);
    }
    const data = getInfoPayload(
      authData?.info?.is_couple ? 1 : 0,
      authData?.id
    );
    const response = await saveUserInfo(data);
    if (response.error == "true") {
      toast.error(response.info.message);
    } else {
      //toast.success("Las fantasias  correctamente");
      setAuthData(response.info.data[0]);
      // console.log(response.info.data[0]);
    }
  });
  const handleChack = () => {
    setCheck(!checked);
    handleLike();
  };
  const [showMore, setShowMore] = useState(false);

  return (
    <div className="contenedor h-screen">
      <NavBar />
      <main className=" sm:p-4 pt-0 lg:px-10 flex w-full items-center flex-col ">
        <div className="max-w-8xl w-full">
          {isLoading ? (
            <div className="w-full flex justify-center">
              <Spinner color="primary" />
            </div>
          ) : (
            <>
              {fantasyData && (
                <div className="px-10 mb-8 flex flex-col md:flex-row gap-8">
                  <div className="md:size-28 mt-4 md:mt-0">
                    <div className="relative md:w-28 md:h-28">
                      <Image
                        alt={fantasyData.name}
                        fallbackSrc="/assets/fantasyImage.jpeg"
                        src={
                          fantasyData.avatar
                            ? fantasyData.avatar?.startsWith("http")
                              ? fantasyData.avatar
                              : import.meta.env.VITE_BASE_URL +
                                "storage/" +
                                fantasyData.avatar
                            : undefined
                        }
                        className="md:w-28 md:h-28 object-cover rounded-none"
                        onClick={() => setIsOpenLightbox(true)}
                        isZoomed
                      />
                    </div>
                  </div>
                  <div className="w-full">
                    <div className="flex mb-3">
                      <Tooltip
                        content="Añadir a mis fantasías"
                        placement="bottom"
                      >
                        <Button
                          className={`bg-color8/70 mr-3 like ${
                            checked ? "checked" : ""
                          }`}
                          variant="flat"
                          isIconOnly
                          radius="sm"
                          aria-label="like"
                          onClick={handleChack}
                        >
                          <div className="checkmark flex items-center justify-center">
                            <StarIcon height={24} width={24} />
                          </div>
                        </Button>
                      </Tooltip>
                      <h1 className="text-3xl font-semibold text-color2">
                        {fantasyData?.name}
                      </h1>
                    </div>
                    <p>
                      {fantasyData?.description.length <= 400 || showMore
                        ? fantasyData?.description
                        : fantasyData?.description.slice(0, 400) + "..."}{" "}
                      {fantasyData?.description.length > 400 && (
                        <span
                          onClick={() => setShowMore(!showMore)}
                          className="cursor-pointer text-primary ml-2 text-nowrap"
                        >
                          {showMore ? "Ver menos" : "Ver más"}
                        </span>
                      )}
                    </p>
                  </div>
                </div>
              )}
              <div className="flex w-full justify-between sm:gap-2 2xl:gap-6 overflow-hidden scrollbar-hide">
                <div className="flex-grow flex justify-center w-full hide-scroll">
                  <div
                    id="scrollableDiv"
                    className=" Xbg-color9 bg-zinc-100 border-1 border-zinc-100 shadow-inner  rounded-3xl  w-full p-4 md:p-8 lg:ml-5 max-h-main flex flex-col gap-8 hide-scroll"
                  >
                    <NewPostCard isFantasy={1} fantasyId={fantasyId} />

                    <InfiniteScroll
                      dataLength={posts.length}
                      next={fetchPosts}
                      hasMore={hasMore}
                      loader={
                        <div className="w-full">
                          <PostSkeleton />
                        </div>
                      }
                      scrollableTarget="scrollableDiv"
                      endMessage={
                        <p style={{ textAlign: "center" }}>
                          <b>
                            Yay! Parece que has visto todas las publicaciones
                          </b>
                        </p>
                      }
                    >
                      {posts.map((post) => (
                        <Post
                          key={post.id}
                          post={post}
                          className="p-5 md:p-10 bg-white rounded-2xl mb-10"
                        />
                      ))}
                    </InfiniteScroll>
                  </div>
                </div>

                <div className="hidden xl:block ml-6  w-4/12 max-h-main max-h-main-fantasy">
                  {lastPhotos && <LastPhotos lastPhotos={lastPhotos} />}
                  {lastVideos && <LastVideos videos={lastVideos} />}
                </div>
              </div>
            </>
          )}
        </div>
      </main>
      <LightBoxImage
        src={
          fantasyData?.avatar
            ? fantasyData.avatar?.startsWith("http")
              ? fantasyData.avatar
              : import.meta.env.VITE_BASE_URL + "storage/" + fantasyData.avatar
            : ""
        }
        isOpen={isOpenLightbox}
        type="image"
        poster={
          fantasyData?.avatar
            ? fantasyData.avatar?.startsWith("http")
              ? fantasyData.avatar
              : import.meta.env.VITE_BASE_URL + "storage/" + fantasyData.avatar
            : ""
        }
        closeModal={() => setIsOpenLightbox(false)}
      />
    </div>
  );
}
