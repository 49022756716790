/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button } from "@nextui-org/react";

export default function SecondaryButton({
  type,
  as,
  onClick = () => {},
  className,
  href,
  children,
  target,
  loading = false,
  variant = "shadow",
  disabled = false,
}: {
  type?: "button" | "submit" | "reset" | undefined;
  as?: any;
  onClick?: Function;
  className?: string;
  href?: string;
  children?: any;
  target?: string;
  loading?: boolean;
  variant?: "solid" | "bordered" | "ghost" | "light" | "shadow";
  disabled?: boolean;
}) {
  return (
    <Button
      type={type}
      href={href}
      as={as}
      variant={variant}
      className={
        "h-[44px] border-small shadow-none text-[16px] text-color2 rounded-small font-semibold bg-white " +
        className
      }
      onClick={() => onClick()}
      target={target}
      isLoading={loading}
      disabled={disabled}
    >
      {children}
    </Button>
  );
}
