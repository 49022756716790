import { Dropdown, DropdownItem, DropdownMenu, DropdownTrigger, Input } from "@nextui-org/react";
import { IconamoonSendLight } from "../../assets/icons/SendIcon";
import EmojiPicker, { EmojiStyle } from "emoji-picker-react";
import { EmojiIcon } from "../../assets/icons/EmojiIcon";
import { FormEvent, useEffect, useRef, useState } from "react";
import { ImageIcon } from "../../assets/icons/ImageIcon";
import { CloseIcon } from "../../assets/icons/CloseIcon";

export default function ChatInput({
	text,
	isDisaled = false,
	handleSendMessage,
	onEmojiClick,
	setText,
	atachFile,
}: {
	text: string;
	isDisaled?: boolean;
	setText: (value: string) => void;
	handleSendMessage: (event: FormEvent) => void;
	onEmojiClick: (event: unknown, _: unknown) => void;
	atachFile: (file: File | undefined) => void;
}) {
	const [file, setFile] = useState<File | null>(null);
	const [fileUrl, setFileUrl] = useState("");
	const inputRef = useRef(null);

	const handleChange = (e: FormEvent<HTMLInputElement>) => {
		const data = e.target as HTMLInputElement;
		setText(data.value);
	};

	const onFileUpload = () => {
		if (inputRef.current) (inputRef.current as HTMLInputElement).click();
	};
	const handleChangeInput = (e: FormEvent<HTMLInputElement>) => {
		if (e.nativeEvent.target instanceof HTMLInputElement && e.nativeEvent.target.files) {
			// Aquí puedes procesar los archivos seleccionados
			const selectedFiles = Array.from(e.nativeEvent.target.files);
			//console.log(selectedFiles);
			if (selectedFiles.length > 0) {
				setFile(selectedFiles[0]);
			}
			// console.log(file);
		}
	};

	useEffect(() => {
		if (file) {
			const fileUrl = URL.createObjectURL(file);
			// console.log(fileUrl);
			atachFile(file);
			setFileUrl(fileUrl);
			setText(fileUrl);

			return () => {
				URL.revokeObjectURL(fileUrl);
			};
		} else atachFile(undefined);
	}, [file]);

	const handleSubmit = (e: FormEvent) => {
		handleSendMessage(e);
		setText("");
		setFileUrl("");
	};
	const onCancel = () => {
		setText("");
		setFileUrl("");
		atachFile(undefined);
	};
	return (
		<form onSubmit={handleSubmit} className="w-full p-3 overflow-visible relative ">
			<Input
				isDisabled={isDisaled}
				className="min-w-sm text-input w-full "
				id="search"
				type="text"
				variant="bordered"
				label=""
				placeholder="Enviar mensaje..."
				required
				value={text}
				onChange={handleChange}
				classNames={{ inputWrapper: " border-1 rounded-lg" }}
				startContent={
					<Dropdown className="">
						<DropdownTrigger>
							<button className="focus:outline-none z-1 bg-zinc-300/10 mt">
								<EmojiIcon stroke={"#667085"} className="cursor-pointer" width={16} height={16} />
							</button>
						</DropdownTrigger>
						<DropdownMenu
							aria-label="Static Actions"
							emptyContent={
								<EmojiPicker
									onEmojiClick={onEmojiClick}
									emojiStyle={"native" as EmojiStyle}
									open={true}
								/>
							}
						>
							{[].map(() => (
								<DropdownItem></DropdownItem>
							))}
						</DropdownMenu>
					</Dropdown>
				}
				endContent={
					<div className="flex gap-1">
						<button
							aria-label="send"
							type="button"
							className="focus:outline-none  bg-zinc-300/10 mr-1"
							onClick={onFileUpload}
						>
							<ImageIcon height={17} width={17} stroke={"#667085"} />
						</button>
						<button aria-label="send" className="focus:outline-none  bg-zinc-300/10 mt" type="submit">
							<IconamoonSendLight height={24} width={24} stroke={"#667085"} />
						</button>
					</div>
				}
			/>
			{fileUrl.length > 0 && (
				<div className="bg-white z-20 h-[300px] w-[97%] absolute bottom-0 border-1 border-b-white rounded-t-xl border-zinc-200">
					<div
						style={{ backgroundImage: ` url(${fileUrl})` }}
						className="w-full flex flex-grow bg-slate-300 items-start justify-end rounded-t-xl h-[250px] bg-center bg-cover relative"
					>
						<div className="p-2 bg-black/20 rounded-full cursor-pointer mt-1 mr-1" onClick={onCancel}>
							{" "}
							<CloseIcon height={16} width={16} stroke="white" />
						</div>
					</div>
					<div className="w-full flex justify-end pt-2">
						<button aria-label="send" className="focus:outline-none  bg-zinc-300/10 mt" type="submit">
							<IconamoonSendLight height={24} width={24} stroke={"#667085"} />
						</button>
					</div>
				</div>
			)}
			<input
				ref={inputRef}
				className="hidden"
				type="file"
				accept={"image/*"}
				onChange={handleChangeInput}
			></input>
		</form>
	);
}
