/* eslint-disable no-mixed-spaces-and-tabs */
import {
  Modal,
  ModalContent,
  Skeleton,
  Badge,
  Avatar,
} from "@nextui-org/react";
import { CloseIcon } from "../../assets/icons/CloseIcon";
import { Fragment, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import BuildingIcon from "../../assets/icons/BuildingIcon";
import { Post, PostReaction } from "../../interfaces/Post";
import { getPostsReactions } from "../../api/publication";
import Moment from "react-moment";

export default function ReacionsModal({
  isOpen = false,
  data,
  closeModal = () => {},
}: {
  isOpen: boolean;
  data: Post;
  closeModal?: () => void;
}) {
  const [isLoading, setIsLoading] = useState(false);

  const [reactions, setReactions] = useState<PostReaction[]>([]);

  const getData = async () => {
    setIsLoading(true);

    const res = await getPostsReactions(data.id);
    if (res.error == "false" && res?.info?.data) {
      console.log(res?.info?.data);
      setReactions(res.info.data.reacciones.data);
    } else toast.error("Ha ocurrido un error cargando los datos.");

    setIsLoading(false);
  };
  useEffect(() => {
    if (isOpen) getData();
  }, [isOpen]);

  const onclose = () => {
    closeModal();
  };

  return (
    <Modal
      className=""
      isOpen={isOpen}
      placement="center"
      onClose={onclose}
      classNames={{
        closeButton: "mt-4 mr-4",
        base: "rounded-xl border-none",
      }}
      closeButton={<CloseIcon />}
      scrollBehavior="inside"
      size="xl"
    >
      <ModalContent>
        <div className="">
          <div className="flex ">
            <div className="text-start w-full">
              <h3 className="mt-6 font-semibold text-color3 text-xl  px-6">
                Reacciones
              </h3>
              <p className="mt-2 font-normal text-[14px] text-color5"></p>

              <div className="h-[330px] mt-4 py-2 gap-1 flex flex-col    overflow-auto w-full  px-6">
                {isLoading ? (
                  [0, 0].map((_, i) => (
                    <Fragment key={"k" + i}>
                      <div className="max-w-[300px] w-full flex items-center gap-3 p-2">
                        <div>
                          <Skeleton className="flex rounded-full w-12 h-12" />
                        </div>
                        <div className="w-full flex flex-col gap-2">
                          <Skeleton className="h-3 w-3/5 rounded-lg" />
                          <Skeleton className="h-3 w-4/5 rounded-lg" />
                        </div>
                      </div>
                      <div className="max-w-[300px] w-full flex items-center gap-3 p-2">
                        <div>
                          <Skeleton className="flex rounded-full w-12 h-12" />
                        </div>
                        <div className="w-full flex flex-col gap-2">
                          <Skeleton className="h-3 w-3/5 rounded-lg" />
                          <Skeleton className="h-3 w-4/5 rounded-lg" />
                        </div>
                      </div>
                    </Fragment>
                  ))
                ) : !reactions.length ? (
                  // Si la búsqueda no está vacía y no hay usuarios, fiestas o viajes
                  <div>No hay ningún resultado</div>
                ) : (
                  <div className="flex flex-col">
                    {/* USERS */}

                    {reactions.map((e) => (
                      <Link
                        to={`/perfil/${e.user.slug}`}
                        key={"u-" + e.id}
                        className="cursor-pointer hover:bg-primary/10 rounded-md transition-colors ease-in-out p-2"
                      >
                        <div className="flex gap-2">
                          <Badge
                            color="primary"
                            isOneChar
                            placement="bottom-right"
                            className="bg-green-500"
                            isInvisible={e?.user.role_id != "3"}
                            content={
                              e?.user.role_id == "3" && (
                                <BuildingIcon
                                  height={12}
                                  width={12}
                                ></BuildingIcon>
                              )
                            }
                          >
                            <Avatar
                              showFallback
                              classNames={{
                                base: "bg-zinc-100",
                                icon: "text-black/50",
                              }}
                              className="cursor-pointer flex-shrink-0 min-w-10 h-10"
                              name={e.user.name}
                              src={
                                e?.user.profile_path
                                  ? e?.user.profile_path?.startsWith("http")
                                    ? e?.user.profile_path
                                    : import.meta.env.VITE_BASE_URL +
                                      e?.user.profile_path
                                  : undefined
                              }
                            ></Avatar>
                          </Badge>

                          <div>
                            <p className="text-sm">{e.user.name}</p>
                            <p className="text-[12px] text-gray-400">
                              {e.comentario_id
                                ? "A comentado "
                                : "Le ha dado me gusta "}
                              <Moment fromNow locale="Es">
                                {e.created_at}
                              </Moment>
                            </p>
                          </div>
                        </div>
                      </Link>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </ModalContent>
    </Modal>
  );
}
