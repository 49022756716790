import { Tooltip } from "@nextui-org/react";
import { Global } from "../../interfaces/SingleUser";
import { GlobalFriendsIcon, GlobalReactionsIcon, GlobalVisitsIcon } from "../../assets/icons/GlobalDataIcons";
import { Link } from "react-router-dom";
import { useAuthStore } from "../../stores/authStore";

export default function ProfileGlobalData({
	globalData,
	isMinimunVersion,
}: {
	globalData: Global;
	isMinimunVersion?: boolean;
}) {
	const authData = useAuthStore((state) => state.user);
	return (
		<div className="flex flex-wrap item-scenter gap-4">
			{/* Visitas */}
			<Tooltip content="Visitas" placement="bottom">
				<div className="hidden lg:flex items-center gap-2 border-1 border-color2/15 text-color1 font-semibold  px-2 py-1 rounded-lg">
					<div className="">
						<GlobalVisitsIcon />
					</div>
					<span className="">{globalData.visits + 1}</span>
				</div>
			</Tooltip>
			<div
				className={`flex lg:hidden text-sm items-center gap-0.5 text-color1 font-semibold px-1 py-1 rounded-lg ${
					isMinimunVersion ? "px-1" : "border-1 gap-1 border-color2/15"
				}`}
			>
				<div className="">
					<GlobalVisitsIcon />
				</div>
				<span className="text-black">{globalData.visits}</span>
				<Link to={"/perfiles?visitas=true"} className="font-semibold text-[16px] text-color7">
					Visitas
				</Link>
			</div>

			{/* Amigos */}
			<Tooltip content="Amigos" placement="bottom">
				<div className="hidden lg:flex items-center gap-1 border-1 border-color2/15 text-color1 font-semibold  px-2 py-1 rounded-lg">
					<div>
						<GlobalFriendsIcon />
					</div>
					<span className="">{globalData.friends}</span>
				</div>
			</Tooltip>

			<div
				className={`flex lg:hidden text-sm items-center gap-1 text-color1 font-semibold px-1 py-1 rounded-lg ${
					isMinimunVersion ? "px-1" : " border-1 gap-1 border-color2/15"
				} `}
			>
				<div>
					<GlobalFriendsIcon />
				</div>
				<span className="text-black">{globalData.friends}</span>
				<Link to={`/amigos/${authData?.slug}?tab=amigos`} className="">
					Amigos
				</Link>
			</div>

			{/* Me gusta */}
			<Tooltip content="Likes" placement="bottom">
				<div className="hidden lg:flex items-center gap-2 border-1 border-color2/15 text-color1 font-semibold px-2 py-1 rounded-lg">
					<div>
						<GlobalReactionsIcon />
					</div>
					<span className="">{globalData.reactions}</span>
				</div>
			</Tooltip>

			<div
				className={`flex lg:hidden text-sm items-center gap-1 text-color1 font-semibold  px-1 py-1 rounded-lg ${
					isMinimunVersion ? "px-1" : "border-1  gap-1 border-color2/15"
				} `}
			>
				<div>
					<GlobalReactionsIcon />
				</div>
				<span className="text-black line">{globalData.reactions}</span>
				<span className="">Me gusta</span>
			</div>
		</div>
	);
}
