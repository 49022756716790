import { StylesConfig } from "react-select"; // Importa los tipos de estilos de react-select

interface OptionType {
	label: string;
	value: string;
}

const customStyles: StylesConfig<OptionType, true> = {
	control: (provided, state) => ({
		...provided,
		"backgroundColor": state.isFocused || state.hasValue ? "#ede9fe" : "white",
		"borderColor": state.isFocused ? "#a78bfa" : "#d1d5db",
		"boxShadow": state.isFocused ? "0 0 0 2px #c4b5fd" : undefined,
		"&:hover": { borderColor: "#a78bfa" },
	}),
	input: (provided) => ({
		...provided,
		backgroundColor: "transparent",
		color: "#6b21a8",
	}),
	option: (provided, state) => ({
		...provided,
		"backgroundColor": state.isSelected ? "#ede9fe" : state.isFocused ? "#f3e8ff" : "white",
		"color": state.isSelected ? "#6b21a8" : "black",
		"&:hover": {
			backgroundColor: "#f3e8ff",
		},
	}),
	multiValue: (provided) => ({
		...provided,
		backgroundColor: "#ede9fe",
		color: "#6b21a8",
	}),
	multiValueLabel: (provided) => ({
		...provided,
		color: "#6b21a8",
	}),
	multiValueRemove: (provided) => ({
		...provided,
		"color": "#6b21a8",
		"&:hover": {
			backgroundColor: "#f3e8ff",
			color: "#4c1d95",
		},
	}),
};

export default customStyles;
