/* eslint-disable no-mixed-spaces-and-tabs */
import { FormEvent, useEffect, useMemo, useState } from "react";
import { useAuthStore } from "../../stores/authStore";
import {
  Modal,
  Button,
  Card,
  Checkbox,
  Pagination,
  Select,
  SelectItem,
  Skeleton,
  Slider,
  SliderValue,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "@nextui-org/react";
import SelectNew, { MultiValue, ActionMeta } from "react-select";
import { FriendReq, RequestData, User } from "../../interfaces/Login";
import { getFriendRequests, getFriendsSlug } from "../../api/friends";
import CustomInput from "../form/CustomInput";
import { SearchIcon } from "../../assets/icons/SearchIcon";
import SecondaryButton from "../common/SecondaryButton";
import { toast } from "react-toastify";
import FriendshipModal from "./FriendshipModal";
import FriendCard from "./FriendCard";
import { useHomeStore } from "../../stores/homeData";
import { useQuery } from "../../hooks/useQuery";
import { useNavigate } from "react-router-dom";
import { SexualOrientation } from "../../interfaces/SexualOrientation";
import { Province } from "../../interfaces/Provice";
import { getUsers } from "../../api/user";
import { Fantasy } from "../../interfaces/Fantasy";
import { Country } from "../../interfaces/Country";
import { Profesion } from "../../interfaces/Profesion";
import { Horoscope } from "../../interfaces/Horoscope";
import { Lang } from "../../interfaces/Lang";
import {
  races,
  complexions,
  eyesColor,
  hairColors,
  noseTypes,
  skincolors,
  piercingCount,
  tattoosCount,
  drink,
  smoke,
  hairCuts,
  lipsOpt,
} from "../../utils/selectOptions";
import { Pagination as PaginationData } from "../../interfaces/pagination";
import { joinSet } from "../../utils/set";
import { getCountries } from "../../api/nationality";
import {
  getFantasies,
  getHoroscopes,
  getLangueages,
  getProfesions,
  getProvinces,
  getSexualOrientations,
} from "../../api/formSelectsData";

interface Filters {
  fantasias_id: Set<string>;
  provincias_id: Set<string>;
  sex: Set<string>;
  complexions_id: Set<string>;
  races_id: Set<string>;
  eye_colors_id: Set<string>;
  skin_colors_id: Set<string>;
  hair_colors_id: Set<string>;
  nacionalidades_id: Set<string>;
  hair_length: Set<string>;
  lips: Set<string>;
  piercings: Set<string>;
  tattoos: Set<string>;
  you_drink: Set<string>;
  you_smoke: Set<string>;
  children: Set<string>;
  type_of_nose: Set<string>;
  profesion_id: Set<string>;
  idiomas_id: Set<string>;
  horoscopo_id: Set<string>;
  sexualOrientation: Set<string>;
  // Añade cualquier otro campo que uses
}

const initialFilters: Filters = {
  fantasias_id: new Set(),
  provincias_id: new Set(),
  sex: new Set(),
  complexions_id: new Set(),
  races_id: new Set(),
  eye_colors_id: new Set(),
  skin_colors_id: new Set(),
  hair_colors_id: new Set(),
  nacionalidades_id: new Set(),
  hair_length: new Set(),
  lips: new Set(),
  piercings: new Set(),
  tattoos: new Set(),
  you_drink: new Set(),
  you_smoke: new Set(),
  children: new Set(),
  type_of_nose: new Set(),
  profesion_id: new Set(),
  idiomas_id: new Set(),
  horoscopo_id: new Set(),
  sexualOrientation: new Set<string>(),
};

const childs = [
  { id: 1, name: "Se lo reserva" },
  {
    id: 2,
    name: "Si",
  },
  {
    id: 3,
    name: "No",
  },
];

const sexTypes = [
  { id: "f", name: "Mujer" },
  {
    id: "m",
    name: "Hombre",
  },
  {
    id: "c",
    name: "Pareja",
  },
];

interface SelectOption {
  label: string;
  value: number; // o string, según tus datos
}
interface SelectOptionNew {
  label: string;
  value: string;
}

export default function AmigosTab({
  slug,
  orientations,
  provinces,
}: {
  slug: string;
  orientations: SexualOrientation[];
  provinces: Province[];
}) {
  const [search, setSearch] = useState("");
  const [userId, setUserId] = useState<number | undefined>(undefined);
  // const [selectedKeys, setSelectedKeys] = useState(new Set(["friends"]));
  const [friends, setFriends] = useState<RequestData[]>([]);
  const [friendRequests, setFriendRequests] = useState<RequestData[]>([]);
  const [friendRequestsSent, setFriendRequestsSent] = useState<RequestData[]>(
    []
  );
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingFriends, setIsLoadingFriends] = useState(false);
  const [isLoadingReq, setIsLoadingReq] = useState(false);
  const [isLoadingReqSent, setIsLoadingReqSent] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [tab, setTab] = useState("amigos");
  const [, setOrientations] = useState<SexualOrientation[]>([]);
  const authData = useAuthStore((state) => state.user);

  const [friendToDelete, setFriendToDelete] = useState<RequestData | null>(
    null
  );
  const setHome = useHomeStore((state) => state.setHome);
  const home = useHomeStore((state) => state.home);

  const query = useQuery();
  const navigate = useNavigate();
  const [filters, setFilters] = useState(initialFilters);
  const [byPremium, setByPremium] = useState(false);
  const [onlyOnline, setOnlyOnline] = useState(false);
  const [selectsData, setSelectsData] = useState({
    provinces: [] as Province[],
    fantasies: [] as Fantasy[],
    nationalities: [] as Country[],
    languages: [] as Lang[],
    profesions: [] as Profesion[],
    horoscope: [] as Horoscope[],
  });
  const [pagination, setPagination] = useState<PaginationData>({
    current_page: 0,
    last_page: 0,
    total: 0,
    per_page: 1,
  });

  const [ageRange, setAgeRange] = useState<SliderValue>([18, 95]);

  const getUserFriends = async (page?: number) => {
    if (authData) {
      // Primero: obtener el usuario asociado al slug
      const res = await getFriendsSlug(slug);
      console.log(res.info.data[0].data[0]?.to_id);
      console.log(authData.id);
      // const res = await getFriendsSlug(slug);
      // Extraer el userId desde la respuesta (asegúrate de que la estructura es la correcta)
      const fetchedUserId = res.info.data[0].data[0]?.to_id;
      setUserId(fetchedUserId);

      // Determinar si el usuario autenticado es el mismo que el obtenido por el slug
      const itsMe = authData.id === fetchedUserId;

      // Definir los filtros (estos se usan solo si es "yo")
      const filtersData = {
        provincias_id: joinSet(filters.provincias_id),
        sex: joinSet(filters.sex),
        fantasias_id: joinSet(filters.fantasias_id),
        complexions_id: joinSet(filters.complexions_id),
        races_id: joinSet(filters.races_id),
        eye_colors_id: joinSet(filters.eye_colors_id),
        skin_colors_id: joinSet(filters.skin_colors_id),
        hair_colors_id: joinSet(filters.hair_colors_id),
        nacionalidades_id: joinSet(filters.nacionalidades_id),
        hair_length: joinSet(filters.hair_length),
        lips: joinSet(filters.lips),
        piercings: joinSet(filters.piercings),
        tattoos: joinSet(filters.tattoos),
        you_drink: joinSet(filters.you_drink),
        you_smoke: joinSet(filters.you_smoke),
        children: joinSet(filters.children),
        type_of_nose: joinSet(filters.type_of_nose),
        profesion_id: joinSet(filters.profesion_id),
        idiomas_id: joinSet(filters.idiomas_id),
        horoscopo_id: joinSet(filters.horoscopo_id),
        name: search ?? "",
        email: search ?? "",
        subscription_payed: byPremium ? 1 : undefined,
        edad_to: Array.isArray(ageRange) ? ageRange[1] : "",
        edad_from: Array.isArray(ageRange) ? ageRange[0] : "",
        status: onlyOnline ? "connected" : undefined,
        sexualOrientation: joinSet(filters.sexualOrientation),
        friends: true,
      };

      // Si es "yo", usamos getUsers con filtros; de lo contrario, usamos la respuesta de getFriendsSlug
      const response = itsMe ? await getUsers(page ?? 1, filtersData) : res;

      // Mapear los amigos según la respuesta obtenida
      const friends = itsMe
        ? response.info.data[0].data
        : (response?.info?.data[0].data as FriendReq[]).map((e) => {
            const user = e.to == "me" ? e.from : e.to;
            return {
              ...(user as User),
              reqId: e.id,
            };
          });
      setFriends(friends ?? []);
      setPagination({
        current_page: response?.info?.data[0].current_page,
        last_page: response?.info?.data[0].last_page,
        total: response?.info?.data[0].total,
        per_page: response?.info?.data[0].per_page,
      });
    }
  };

  const getUserFriendReq = async (page?: number) => {
    if (authData && userId) {
      const itsMe = authData.id == userId;
      const filtersData = {
        provincias_id: joinSet(filters.provincias_id),
        fantasias_id: joinSet(filters.fantasias_id),
        complexions_id: joinSet(filters.complexions_id),
        races_id: joinSet(filters.races_id),
        eye_colors_id: joinSet(filters.eye_colors_id),
        skin_colors_id: joinSet(filters.skin_colors_id),
        hair_colors_id: joinSet(filters.hair_colors_id),
        nacionalidades_id: joinSet(filters.nacionalidades_id),
        hair_length: joinSet(filters.hair_length),
        lips: joinSet(filters.lips),
        piercings: joinSet(filters.piercings),
        tattoos: joinSet(filters.tattoos),
        you_drink: joinSet(filters.you_drink),
        you_smoke: joinSet(filters.you_smoke),
        children: joinSet(filters.children),
        type_of_nose: joinSet(filters.type_of_nose),
        profesion_id: joinSet(filters.profesion_id),
        idiomas_id: joinSet(filters.idiomas_id),
        horoscopo_id: joinSet(filters.horoscopo_id),
        name: search ?? "",
        email: search ?? "",
        subscription_payed: byPremium ? 1 : undefined,
        edad_to: Array.isArray(ageRange) ? ageRange[1] : "",
        edad_from: Array.isArray(ageRange) ? ageRange[0] : "",
        status: onlyOnline ? "connected" : undefined,
        friendRequest: "to",
      };

      const response = itsMe
        ? await getUsers(page ?? 1, filtersData)
        : await getFriendRequests(0);

      const friends = itsMe
        ? response.info.data[0].data
        : (response?.info?.data[0]?.data as FriendReq[]).map((e) => {
            return {
              ...(e.from as User),
              reqId: e.id,
            };
          });
      setFriendRequests(friends ?? []);
      setPagination({
        current_page: response?.info?.data[0].current_page,
        last_page: response?.info?.data[0].last_page,
        total: response?.info?.data[0].total,
        per_page: response?.info?.data[0].per_page,
      });
    }
  };
  const getUserFriendReqSent = async (page?: number) => {
    if (authData && userId) {
      const itsMe = authData.id == userId;
      const filtersData = {
        provincias_id: joinSet(filters.provincias_id),
        fantasias_id: joinSet(filters.fantasias_id),
        complexions_id: joinSet(filters.complexions_id),
        races_id: joinSet(filters.races_id),
        eye_colors_id: joinSet(filters.eye_colors_id),
        skin_colors_id: joinSet(filters.skin_colors_id),
        hair_colors_id: joinSet(filters.hair_colors_id),
        nacionalidades_id: joinSet(filters.nacionalidades_id),
        hair_length: joinSet(filters.hair_length),
        lips: joinSet(filters.lips),
        piercings: joinSet(filters.piercings),
        tattoos: joinSet(filters.tattoos),
        you_drink: joinSet(filters.you_drink),
        you_smoke: joinSet(filters.you_smoke),
        children: joinSet(filters.children),
        type_of_nose: joinSet(filters.type_of_nose),
        profesion_id: joinSet(filters.profesion_id),
        idiomas_id: joinSet(filters.idiomas_id),
        horoscopo_id: joinSet(filters.horoscopo_id),
        name: search ?? "",
        email: search ?? "",
        subscription_payed: byPremium ? 1 : undefined,
        edad_to: Array.isArray(ageRange) ? ageRange[1] : "",
        edad_from: Array.isArray(ageRange) ? ageRange[0] : "",
        status: onlyOnline ? "connected" : undefined,
        friendRequest: "from",
      };

      const response = itsMe
        ? await getUsers(page ?? 1, filtersData)
        : await getFriendRequests(1);

      const friends = itsMe
        ? response.info.data[0].data
        : (response?.info?.data[0]?.data as FriendReq[]).map((e) => {
            return {
              ...(e.from as User),
              reqId: e.id,
            };
          });
      setFriendRequestsSent(friends ?? []);
      setPagination({
        current_page: response?.info?.data[0].current_page,
        last_page: response?.info?.data[0].last_page,
        total: response?.info?.data[0].total,
        per_page: response?.info?.data[0].per_page,
      });
    }
  };
  const setDataToSelect = async () => {
    const sexualOrientations = await getSexualOrientations();
    setOrientations(sexualOrientations?.info?.data[0]);
    const fantasies = await getFantasies();
    const provinces = await getProvinces();
    const countries = await getCountries();
    const languages = await getLangueages();
    const profesions = await getProfesions();
    const horoscope = await getHoroscopes();

    setSelectsData({
      ...selectsData,
      fantasies: fantasies.info.data[0],
      provinces: provinces.info.data[0],
      nationalities: countries,
      languages: languages.info.data[0],
      profesions: profesions.info.data[0],
      horoscope: horoscope.info.data[0],
    });
  };
  useEffect(() => {
    const getData = async () => {
      setIsLoading(true);
      if (authData && userId) {
        try {
          await setDataToSelect();
        } catch (error) {
          console.error(error);
          toast.error("Ha ocurrido un error cargando los datos");
        }
      }
      if (query.get("tab") == "amigos" || query.get("tab") == null)
        setTab("amigos");
      else if (query.get("tab") == "requests") setTab("requests");
      else setTab("requestsSent");

      setIsLoading(false);
    };
    if (authData) getData();
  }, [userId]);

  const handleChange = (e: FormEvent<HTMLInputElement>) => {
    const data = e.target as HTMLInputElement;
    setSearch(data.value);
  };
  const getData = async (page: number) => {
    if (query.get("tab") == "requests") {
      setTab("requests");
      setIsLoadingReq(true);
      //friends
      await getUserFriendReq(page);
      setIsLoadingReq(false);
    } else if (query.get("tab") == "amigos" || query.get("tab") == null) {
      setTab("amigos");
      setIsLoadingFriends(true);
      await getUserFriends(page);
      setIsLoadingFriends(false);
    } else {
      setTab("requestsSent");
      setIsLoadingReqSent(true);
      getUserFriendReqSent(page);
      setIsLoadingReqSent(false);
    }
  };
  useEffect(() => {
    getData(1);
  }, [query, filters]);

  const data = useMemo(() => {
    if (tab == "amigos") return friends;
    if (tab == "requests") return friendRequests;
    return friendRequestsSent;
  }, [friendRequestsSent, tab, friends, friendRequests]);

  const onTabChange = (tab: string) => {
    setTab(tab);
    if (query.get("tab") != null) navigate(`/amigos/${slug}?tab=${tab}`);
  };

  const onSuccessDelete = () => {
    if (friendToDelete) {
      setFriends(friends.filter((e) => e.id != friendToDelete.id));
      setHome({
        ...home,
        amigos: [
          ...home.amigos.filter(
            (e) =>
              e.from_id != friendToDelete.id && e.to_id != friendToDelete.id
          ),
        ],
      });
    }
  };
  const onSuccessAccept = (friendToAccept: RequestData) => {
    setFriends([...friends, friendToAccept]);
    setFriendRequests(friendRequests.filter((e) => e.id != friendToAccept.id));
  };
  const onSuccessReject = (friendToreject: RequestData) => {
    setFriendRequests(friendRequests.filter((e) => e.id != friendToreject.id));
  };

  const sexs = useMemo(() => {
    if (authData?.role_id == "3") return sexTypes;
    const isCouple = authData?.info?.is_couple;
    const sex = authData?.info?.detalles.sex.value;
    const orientation = authData?.info?.detalles.sexualOrentation.value;
    if (isCouple) {
      const partnerSex = authData?.info?.detalles.partner_sex?.value;
      const partnerOrientation =
        authData?.info?.detalles?.partner_sexualOrentation?.value;

      const noBi = orientation != "3" || partnerOrientation != "3";
      const noBiSex = orientation != "3" ? sex : partnerSex;

      if (sex == partnerSex) {
        // they are homo or bi
        if (orientation != "3" && partnerOrientation != "3")
          //they are homo
          return sexTypes.filter((s) => s.id == sex || s.id == "c");
        else if (noBi) {
          // one of them is homo and the other is bi
          return sexTypes.filter((s) => s.id == noBiSex || s.id == "c");
        }
        // in other case both are bi
      } else {
        if (orientation != "3" && partnerOrientation != "3")
          //they are hetero
          return sexTypes.filter((s) => s.id == "c");
        else if (noBi) {
          // one of them is hetero and the other is bi
          return sexTypes.filter((s) => s.id != noBiSex);
        }
        // in other case both are bi
      }
    } else {
      if (orientation == "2" && sex)
        return sexTypes.filter((s) => s.id != sex); //hetero
      else if (orientation == "4" && sex)
        return sexTypes.filter((s) => s.id == sex || s.id == "c"); //homo
    }
    return sexTypes; // bi
  }, [authData]);

  const handleChangeSelect = (e: FormEvent<HTMLSelectElement>) => {
    const data = e.target as HTMLSelectElement;
    let aux = data.value.split(",");
    if (aux.length && aux[0] == "") aux = aux.slice(1);
    setFilters({
      ...filters,
      [data.name]: new Set(aux),
    });
  };

  function handleChangeReactSelect(
    selected: MultiValue<SelectOption>,
    actionMeta: ActionMeta<SelectOption>
  ) {
    const fieldName = actionMeta.name as string;

    if (!selected || selected.length === 0) {
      setFilters((prev) => ({
        ...prev,
        [fieldName]: new Set(), // Set vacío
      }));
      return;
    }

    // Cada opción tiene { label: string, value: number }
    // Conviertes 'value' (number) a string para tu Set<string>
    const values = selected.map((opt) => String(opt.value));

    setFilters((prev) => ({
      ...prev,
      [fieldName]: new Set(values),
    }));
  }
  function handleChangeReactSelectString(
    selected: MultiValue<SelectOptionNew>,
    actionMeta: ActionMeta<SelectOptionNew>
  ) {
    const fieldName = actionMeta.name as string;

    if (!selected || selected.length === 0) {
      setFilters((prev) => ({
        ...prev,
        [fieldName]: new Set(), // Set vacío
      }));
      return;
    }

    // Cada opción tiene { label: string, value: number }
    // Conviertes 'value' (number) a string para tu Set<string>
    const values = selected.map((opt) => String(opt.value));

    setFilters((prev) => ({
      ...prev,
      [fieldName]: new Set(values),
    }));
  }

  const onPageChange = async (page: number) => {
    getData(page);
  };

  const orientationOpt = useMemo(() => {
    if (orientations.length === 0) return [];

    if (authData?.role_id === "3") return orientations;

    const isCouple = authData?.info?.is_couple;
    const sex = authData?.info?.detalles?.sex?.value;
    const orientation = authData?.info?.detalles.sexualOrentation?.value;

    if (isCouple) {
      const partnerSex = authData?.info?.detalles?.partner_sex?.value;
      const partnerOrientation =
        authData?.info?.detalles?.partner_sexualOrentation?.value;
      const noBi = orientation !== "3" || partnerOrientation !== "3";

      if (sex === partnerSex) {
        if (orientation !== "3" && partnerOrientation !== "3")
          return orientations.filter((o) => o.id !== 2); // Ambos son homo
        if (noBi) return orientations.filter((o) => o.id !== 2); // Uno homo, el otro bi
      } else {
        if (orientation !== "3" && partnerOrientation !== "3")
          return orientations.filter((o) => o.id !== 4); // Ambos hetero
        if (noBi) return orientations.filter((o) => o.id !== 4); // Uno hetero, el otro bi
      }
    } else {
      if (orientation === "2") return orientations.filter((o) => o.id !== 4); // Hetero
      if (orientation === "4") return orientations.filter((o) => o.id !== 2); // Homo
    }

    return orientations; // Bi
  }, [authData, orientations]);

  return (
    <>
      <div className="flex max-w-8xl w-full overflow-hidden pt-6 md:pt-18 min-h-[100vh] flex-col">
        <div className="px-4 md:px-8 w-full max-w-8xl mb-4">
          <section className="lg:flex  lg:text-center items-center w-full justify-center">
            <h1 className="text-2xl pb-3 font-semibold">Amigos</h1>

            <div className=" flex flex-wrap gap-2 w-full justify-start pr-6 lg:justify-end">
              <div className="mb-2 w-full lg:w-auto">
                <CustomInput
                  className="w-full text-input"
                  id="search"
                  type="text"
                  label=""
                  startIcon={<SearchIcon />}
                  placeholder="Buscar..."
                  required
                  value={search}
                  onChange={handleChange}
                />
              </div>

              <SecondaryButton
                className={`font-semibold text-[14px] ${
                  tab == "amigos" ? "text-color4" : "text-color2"
                }`}
                onClick={() => onTabChange("amigos")}
              >
                Amigos
              </SecondaryButton>
              {/* 📌 BOTÓN PARA ABRIR MODAL EN MÓVIL */}
              <SecondaryButton
                onClick={() => setIsModalOpen(true)}
                className="text-color4 font-semibold text-[14px] lg:hidden"
              >
                Filtros
              </SecondaryButton>

              {/* 📌 MODAL PARA FILTROS EN MÓVIL */}
              <Modal
                isOpen={isModalOpen}
                onOpenChange={setIsModalOpen}
                className="sm:max-w-md w-full rounded-t-xl "
              >
                <ModalContent>
                  {(onClose) => (
                    <>
                      <ModalHeader className="text-lg font-bold text-center">
                        Filtros
                      </ModalHeader>
                      <ModalBody className="overflow-y-auto max-h-[60vh] px-4">
                        <div className="flex flex-col gap-4">
                          {/* ✅ Aquí van todos los filtros, los mismos que en PC */}
                          <Checkbox
                            isSelected={onlyOnline}
                            onValueChange={() => setOnlyOnline(!onlyOnline)}
                            classNames={{
                              wrapper:
                                "border-small before:border-small rounded-[4px] before:rounded-[4px] after:rounded-[4px] after:bg-color4",
                              base: "font-medium text-[14px] text-color2",
                            }}
                          >
                            Solo en línea
                          </Checkbox>

                          <Checkbox
                            isSelected={byPremium}
                            onValueChange={() => setByPremium(!byPremium)}
                            classNames={{
                              wrapper:
                                "border-small before:border-small rounded-[4px] before:rounded-[4px] after:rounded-[4px] after:bg-color4",
                              base: "font-medium text-[14px] text-color2",
                            }}
                          >
                            Premium
                          </Checkbox>

                          {/* SEXO AQUI MOVIL*/}
                          {/* SEXO AQUI PC*/}
                          <label>Sexo</label>
                          <SelectNew<SelectOptionNew, true>
                            isMulti
                            name="sex"
                            options={sexs?.map((x) => ({
                              label: x.name,
                              value: x.id,
                            }))}
                            placeholder="Sexo"
                            className="w-full"
                            classNamePrefix="select"
                            value={sexs
                              .filter((s) =>
                                Array.from(filters.sex).includes(s.id)
                              )
                              .map((x) => ({
                                label: x.name,
                                value: x.id,
                              }))}
                            onChange={handleChangeReactSelectString}
                            styles={{
                              menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                            }}
                          />

                          {/* Select "Orientación" */}
                          {orientationOpt.length > 0 && (
                            <>
                              <label className="text-gray-900 font-semibold text-sm ">
                                Orientación
                              </label>
                              <SelectNew
                                isMulti
                                name="sexualOrientation"
                                options={orientationOpt.map((x) => ({
                                  label: x.name,
                                  value: x.id,
                                }))}
                                placeholder="Seleccione"
                                className="max-w-xs"
                                classNamePrefix="select"
                                value={orientationOpt
                                  .filter((o) =>
                                    Array.from(
                                      filters.sexualOrientation
                                    ).includes(o.id.toString())
                                  )
                                  .map((x) => ({
                                    label: x.name,
                                    value: x.id,
                                  }))}
                                onChange={handleChangeReactSelect}
                                menuPortalTarget={document.body}
                                styles={{
                                  menuPortal: (base) => ({
                                    ...base,
                                    zIndex: 9999,
                                  }),
                                }}
                              />
                            </>
                          )}

                          {/* Select "Ciudad" */}
                          <label className="text-gray-900 font-semibold text-sm ">
                            Provincias
                          </label>
                          <SelectNew<SelectOption, true>
                            isMulti
                            name="provincias_id"
                            options={selectsData.provinces.map((p) => ({
                              label: p.name,
                              value: p.id, // <-- Aquí pasas el id como número
                            }))}
                            placeholder="Seleccione"
                            className="max-w-xs"
                            classNamePrefix="select"
                            value={selectsData.provinces
                              // Sigues filtrando con tu Set<string>, pero conviertes p.id a string:
                              .filter((p) =>
                                filters.provincias_id.has(String(p.id))
                              )
                              .map((p) => ({
                                label: p.name,
                                value: p.id, // <-- Sigue siendo número
                              }))}
                            onChange={handleChangeReactSelect}
                            menuPortalTarget={document.body}
                            styles={{
                              menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                            }}
                          />

                          <Slider
                            label="Edad"
                            step={1}
                            maxValue={95}
                            minValue={18}
                            value={ageRange}
                            onChange={setAgeRange}
                            className="max-w-md"
                            onChangeEnd={async () => {
                              await getUserFriends(1);
                            }}
                          />

                          <Select
                            selectionMode="multiple"
                            labelPlacement="outside"
                            variant="bordered"
                            label="Nacionalidad"
                            name="nacionalidades_id"
                            classNames={{
                              trigger: "h-[44px] rounded-lg border-small",
                              label:
                                "text-[14px] font-medium group-data-[filled-within=true]:text-color2",
                              popoverContent: "rounded-md",
                            }}
                            className="max-w-xs"
                            onChange={handleChangeSelect}
                            placeholder={
                              selectsData.nationalities?.length
                                ? "Seleccione"
                                : "Cargando..."
                            }
                            selectedKeys={filters.nacionalidades_id}
                          >
                            {selectsData.nationalities?.map((item) => (
                              <SelectItem key={item.cca2} value={item.cca2}>
                                {item.name.common}
                              </SelectItem>
                            ))}
                          </Select>
                          <Select
                            selectionMode="multiple"
                            variant="bordered"
                            label="Provincias"
                            name="provincias_id"
                            labelPlacement="outside"
                            classNames={{
                              trigger: "h-[44px] rounded-lg border-small",
                              label:
                                "text-[14px] font-medium group-data-[filled-within=true]:text-color2",
                              popoverContent: "rounded-md",
                            }}
                            className="max-w-xs"
                            onChange={handleChangeSelect}
                            placeholder={
                              provinces?.length ? "Seleccione" : "Cargando..."
                            }
                            selectedKeys={filters.provincias_id}
                          >
                            {provinces?.map((item) => (
                              <SelectItem
                                key={item.id.toString()}
                                value={item.id.toString()}
                              >
                                {item.name}
                              </SelectItem>
                            ))}
                          </Select>
                          <Select
                            name="fantasias_id"
                            selectionMode="multiple"
                            variant="bordered"
                            label="Fantasías"
                            labelPlacement="outside"
                            classNames={{
                              trigger: "h-[44px] rounded-lg border-small",
                              label:
                                "text-[14px] font-medium group-data-[filled-within=true]:text-color2",
                              popoverContent: "rounded-md",
                            }}
                            className="max-w-xs"
                            onChange={handleChangeSelect}
                            placeholder={
                              selectsData.fantasies?.length
                                ? "Seleccione"
                                : "Cargando..."
                            }
                            selectedKeys={filters.fantasias_id}
                          >
                            {selectsData.fantasies?.map((item) => (
                              <SelectItem
                                key={item.id.toString()}
                                value={item.id.toString()}
                              >
                                {item.name}
                              </SelectItem>
                            ))}
                          </Select>
                          <Select
                            selectionMode="multiple"
                            variant="bordered"
                            label="Idiomas"
                            name="idiomas_id"
                            labelPlacement="outside"
                            classNames={{
                              trigger: "h-[44px] rounded-lg border-small",
                              label:
                                "text-[14px] font-medium group-data-[filled-within=true]:text-color2",
                              popoverContent: "rounded-md",
                            }}
                            className="max-w-xs"
                            onChange={handleChangeSelect}
                            placeholder={
                              selectsData.languages?.length
                                ? "Seleccione"
                                : "Cargando..."
                            }
                            selectedKeys={filters.idiomas_id}
                          >
                            {selectsData.languages?.map((item) => (
                              <SelectItem
                                key={item.id.toString()}
                                value={item.id.toString()}
                              >
                                {item.name}
                              </SelectItem>
                            ))}
                          </Select>
                          <Select
                            selectionMode="multiple"
                            variant="bordered"
                            label="Horóscopo"
                            name="horoscopo_id"
                            labelPlacement="outside"
                            classNames={{
                              trigger: "h-[44px] rounded-lg border-small",
                              label:
                                "text-[14px] font-medium group-data-[filled-within=true]:text-color2",
                              popoverContent: "rounded-md",
                            }}
                            className="max-w-xs"
                            onChange={handleChangeSelect}
                            placeholder={
                              selectsData.languages?.length
                                ? "Seleccione"
                                : "Cargando..."
                            }
                            selectedKeys={filters.horoscopo_id}
                          >
                            {selectsData.horoscope?.map((item) => (
                              <SelectItem
                                key={item.id.toString()}
                                value={item.id.toString()}
                              >
                                {item.name}
                              </SelectItem>
                            ))}
                          </Select>
                          <Select
                            selectionMode="multiple"
                            variant="bordered"
                            label="Profesión"
                            name="profesion_id"
                            labelPlacement="outside"
                            classNames={{
                              trigger: "h-[44px] rounded-lg border-small",
                              label:
                                "text-[14px] font-medium group-data-[filled-within=true]:text-color2",
                              popoverContent: "rounded-md",
                            }}
                            className="max-w-xs"
                            onChange={handleChangeSelect}
                            placeholder={
                              selectsData.profesions?.length
                                ? "Seleccione"
                                : "Cargando..."
                            }
                            selectedKeys={filters.profesion_id}
                          >
                            {selectsData.profesions?.map((item) => (
                              <SelectItem
                                key={item.id.toString()}
                                value={item.id.toString()}
                              >
                                {item.name}
                              </SelectItem>
                            ))}
                          </Select>
                          <Select
                            name="races_id"
                            selectionMode="multiple"
                            variant="bordered"
                            label="Raza"
                            labelPlacement="outside"
                            classNames={{
                              trigger: "h-[44px] rounded-lg border-small",
                              label:
                                "text-[14px] font-medium group-data-[filled-within=true]:text-color2",
                              popoverContent: "rounded-md",
                            }}
                            className="max-w-xs"
                            onChange={handleChangeSelect}
                            placeholder="Seleccione"
                            selectedKeys={filters.races_id}
                          >
                            {races?.map((item) => (
                              <SelectItem
                                key={item.id.toString()}
                                value={item.id.toString()}
                              >
                                {item.name}
                              </SelectItem>
                            ))}
                          </Select>
                          <Select
                            name="skin_colors_id"
                            selectionMode="multiple"
                            variant="bordered"
                            label="Color de piel"
                            labelPlacement="outside"
                            classNames={{
                              trigger: "h-[44px] rounded-lg border-small",
                              label:
                                "text-[14px] font-medium group-data-[filled-within=true]:text-color2",
                              popoverContent: "rounded-md",
                            }}
                            className="max-w-xs"
                            onChange={handleChangeSelect}
                            placeholder="Seleccione"
                            selectedKeys={filters.skin_colors_id}
                          >
                            {skincolors?.map((item) => (
                              <SelectItem
                                key={item.id.toString()}
                                value={item.id.toString()}
                              >
                                {item.name}
                              </SelectItem>
                            ))}
                          </Select>
                          <Select
                            name="complexions_id"
                            selectionMode="multiple"
                            variant="bordered"
                            label="Complexión"
                            labelPlacement="outside"
                            classNames={{
                              trigger: "h-[44px] rounded-lg border-small",
                              label:
                                "text-[14px] font-medium group-data-[filled-within=true]:text-color2",
                              popoverContent: "rounded-md",
                            }}
                            className="max-w-xs"
                            onChange={handleChangeSelect}
                            placeholder="Seleccione"
                            selectedKeys={filters.complexions_id}
                          >
                            {complexions?.map((item) => (
                              <SelectItem
                                key={item.id.toString()}
                                value={item.id.toString()}
                              >
                                {item.name}
                              </SelectItem>
                            ))}
                          </Select>
                          <Select
                            name="eye_colors_id"
                            selectionMode="multiple"
                            variant="bordered"
                            label="Color de ojos"
                            labelPlacement="outside"
                            classNames={{
                              trigger: "h-[44px] rounded-lg border-small",
                              label:
                                "text-[14px] font-medium group-data-[filled-within=true]:text-color2",
                              popoverContent: "rounded-md",
                            }}
                            className="max-w-xs"
                            onChange={handleChangeSelect}
                            placeholder="Seleccione"
                            selectedKeys={filters.eye_colors_id}
                          >
                            {eyesColor?.map((item) => (
                              <SelectItem
                                key={item.id.toString()}
                                value={item.id.toString()}
                              >
                                {item.name}
                              </SelectItem>
                            ))}
                          </Select>
                          <Select
                            name="hair_colors_id"
                            selectionMode="multiple"
                            variant="bordered"
                            label="Color de cabello"
                            labelPlacement="outside"
                            classNames={{
                              trigger: "h-[44px] rounded-lg border-small",
                              label:
                                "text-[14px] font-medium group-data-[filled-within=true]:text-color2",
                              popoverContent: "rounded-md",
                            }}
                            className="max-w-xs"
                            onChange={handleChangeSelect}
                            placeholder="Seleccione"
                            selectedKeys={filters.hair_colors_id}
                          >
                            {hairColors?.map((item) => (
                              <SelectItem
                                key={item.id.toString()}
                                value={item.id.toString()}
                              >
                                {item.name}
                              </SelectItem>
                            ))}
                          </Select>
                          <Select
                            name="hair_length"
                            selectionMode="multiple"
                            variant="bordered"
                            label="Corte de cabello"
                            labelPlacement="outside"
                            classNames={{
                              trigger: "h-[44px] rounded-lg border-small",
                              label:
                                "text-[14px] font-medium group-data-[filled-within=true]:text-color2",
                              popoverContent: "rounded-md",
                            }}
                            className="max-w-xs"
                            onChange={handleChangeSelect}
                            placeholder="Seleccione"
                            selectedKeys={filters.hair_length}
                          >
                            {hairCuts?.map((item) => (
                              <SelectItem
                                key={item.id.toString()}
                                value={item.id.toString()}
                              >
                                {item.name}
                              </SelectItem>
                            ))}
                          </Select>
                          <Select
                            name="lips"
                            selectionMode="multiple"
                            variant="bordered"
                            label="Labios"
                            labelPlacement="outside"
                            classNames={{
                              trigger: "h-[44px] rounded-lg border-small",
                              label:
                                "text-[14px] font-medium group-data-[filled-within=true]:text-color2",
                              popoverContent: "rounded-md",
                            }}
                            className="max-w-xs"
                            onChange={handleChangeSelect}
                            placeholder="Seleccione"
                            selectedKeys={filters.lips}
                          >
                            {lipsOpt?.map((item) => (
                              <SelectItem
                                key={item.id.toString()}
                                value={item.id.toString()}
                              >
                                {item.name}
                              </SelectItem>
                            ))}
                          </Select>
                          <Select
                            name="type_of_nose"
                            selectionMode="multiple"
                            variant="bordered"
                            label="Tipo de nariz"
                            labelPlacement="outside"
                            classNames={{
                              trigger: "h-[44px] rounded-lg border-small",
                              label:
                                "text-[14px] font-medium group-data-[filled-within=true]:text-color2",
                              popoverContent: "rounded-md",
                            }}
                            className="max-w-xs"
                            onChange={handleChangeSelect}
                            placeholder="Seleccione"
                            selectedKeys={filters.lips}
                          >
                            {noseTypes?.map((item) => (
                              <SelectItem
                                key={item.id.toString()}
                                value={item.id.toString()}
                              >
                                {item.name}
                              </SelectItem>
                            ))}
                          </Select>
                          <Select
                            name="piercings"
                            selectionMode="multiple"
                            variant="bordered"
                            label="Piercings"
                            labelPlacement="outside"
                            classNames={{
                              trigger: "h-[44px] rounded-lg border-small",
                              label:
                                "text-[14px] font-medium group-data-[filled-within=true]:text-color2",
                              popoverContent: "rounded-md",
                            }}
                            className="max-w-xs"
                            onChange={handleChangeSelect}
                            placeholder="Seleccione"
                            selectedKeys={filters.piercings}
                          >
                            {piercingCount?.map((item) => (
                              <SelectItem
                                key={item.id.toString()}
                                value={item.id.toString()}
                              >
                                {item.name}
                              </SelectItem>
                            ))}
                          </Select>
                          <Select
                            name="tattoos"
                            selectionMode="multiple"
                            variant="bordered"
                            label="Tatuajes"
                            labelPlacement="outside"
                            classNames={{
                              trigger: "h-[44px] rounded-lg border-small",
                              label:
                                "text-[14px] font-medium group-data-[filled-within=true]:text-color2",
                              popoverContent: "rounded-md",
                            }}
                            className="max-w-xs"
                            onChange={handleChangeSelect}
                            placeholder="Seleccione"
                            selectedKeys={filters.tattoos}
                          >
                            {tattoosCount?.map((item) => (
                              <SelectItem
                                key={item.id.toString()}
                                value={item.id.toString()}
                              >
                                {item.name}
                              </SelectItem>
                            ))}
                          </Select>
                          <Select
                            name="you_drink"
                            selectionMode="multiple"
                            variant="bordered"
                            label="Bebe"
                            labelPlacement="outside"
                            classNames={{
                              trigger: "h-[44px] rounded-lg border-small",
                              label:
                                "text-[14px] font-medium group-data-[filled-within=true]:text-color2",
                              popoverContent: "rounded-md",
                            }}
                            className="max-w-xs"
                            onChange={handleChangeSelect}
                            placeholder="Seleccione"
                            selectedKeys={filters.you_drink}
                          >
                            {drink?.map((item) => (
                              <SelectItem
                                key={item.id.toString()}
                                value={item.id.toString()}
                              >
                                {item.name}
                              </SelectItem>
                            ))}
                          </Select>
                          <Select
                            name="you_smoke"
                            selectionMode="multiple"
                            variant="bordered"
                            label="Fuma"
                            labelPlacement="outside"
                            classNames={{
                              trigger: "h-[44px] rounded-lg border-small",
                              label:
                                "text-[14px] font-medium group-data-[filled-within=true]:text-color2",
                              popoverContent: "rounded-md",
                            }}
                            className="max-w-xs"
                            onChange={handleChangeSelect}
                            placeholder="Seleccione"
                            selectedKeys={filters.you_smoke}
                          >
                            {smoke?.map((item) => (
                              <SelectItem
                                key={item.id.toString()}
                                value={item.id.toString()}
                              >
                                {item.name}
                              </SelectItem>
                            ))}
                          </Select>
                          <Select
                            name="children"
                            selectionMode="multiple"
                            variant="bordered"
                            label="Hijos"
                            labelPlacement="outside"
                            classNames={{
                              trigger: "h-[44px] rounded-lg border-small",
                              label:
                                "text-[14px] font-medium group-data-[filled-within=true]:text-color2",
                              popoverContent: "rounded-md",
                            }}
                            className="max-w-xs"
                            onChange={handleChangeSelect}
                            placeholder="Seleccione"
                            selectedKeys={filters.children}
                          >
                            {childs?.map((item) => (
                              <SelectItem
                                key={item.id.toString()}
                                value={item.id.toString()}
                              >
                                {item.name}
                              </SelectItem>
                            ))}
                          </Select>
                        </div>
                      </ModalBody>
                      <ModalFooter>
                        <Button
                          onClick={onClose}
                          className="bg-white text-black px-4 py-2 rounded-lg  border-1"
                        >
                          Cerrar
                        </Button>
                      </ModalFooter>
                    </>
                  )}
                </ModalContent>
              </Modal>

              {userId == authData?.id.toString() && (
                <>
                  <SecondaryButton
                    className={`font-semibold text-[14px] ${
                      tab == "requests" ? "text-color4" : "text-color2"
                    }`}
                    onClick={() => onTabChange("requests")}
                  >
                    Solicitudes recibidas
                  </SecondaryButton>
                  <SecondaryButton
                    className={`font-semibold text-[14px] ${
                      tab == "requestsSent" ? "text-color4" : "text-color2"
                    }`}
                    onClick={() => onTabChange("requestsSent")}
                  >
                    Solicitudes enviadas
                  </SecondaryButton>
                </>
              )}
            </div>
          </section>
        </div>
        <div className="max-w-9xl flex w-full">
          {userId == authData?.id.toString() && (
            <div className="hidden lg:block w-3/12 max-h-main hide-scroll pl-2">
              <div className="w-full  px-1 py-2 rounded-small ">
                <div className="px-0 py-2 w-full overflow-auto max-h-[calc(100vh-170px)]  bg-zinc-200/20 rounded-lg">
                  <p className="font-bold text-foreground overflow-auto mb-2 px-2">
                    Filtros
                  </p>
                  <div className=" flex flex-col gap-4 w-full mt-3 px-3 pr-4">
                    <Checkbox
                      isSelected={onlyOnline}
                      onValueChange={() => setOnlyOnline(!onlyOnline)}
                      classNames={{
                        wrapper:
                          "border-small before:border-small rounded-[4px] before:rounded-[4px] after:rounded-[4px] after:bg-color4",
                        base: "font-medium text-[14px] text-color2",
                      }}
                    >
                      Solo en línea
                    </Checkbox>

                    <Checkbox
                      isSelected={byPremium}
                      onValueChange={() => setByPremium(!byPremium)}
                      classNames={{
                        wrapper:
                          "border-small before:border-small rounded-[4px] before:rounded-[4px] after:rounded-[4px] after:bg-color4",
                        base: "font-medium text-[14px] text-color2",
                      }}
                    >
                      Premium
                    </Checkbox>

                    {/* SEXO AQUI PC*/}
                    <label>Sexo</label>
                    <SelectNew<SelectOptionNew, true>
                      isMulti
                      name="sex"
                      options={sexs?.map((x) => ({
                        label: x.name,
                        value: x.id,
                      }))}
                      placeholder="Sexo"
                      className="w-full"
                      classNamePrefix="select"
                      value={sexs
                        .filter((s) => Array.from(filters.sex).includes(s.id))
                        .map((x) => ({
                          label: x.name,
                          value: x.id,
                        }))}
                      onChange={handleChangeReactSelectString}
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                      }}
                    />

                    {/* Select "Orientación" */}
                    {orientationOpt.length > 0 && (
                      <>
                        <label className="text-gray-900 font-semibold text-sm ">
                          Orientación
                        </label>
                        <SelectNew
                          isMulti
                          name="sexualOrientation"
                          options={orientationOpt.map((x) => ({
                            label: x.name,
                            value: x.id,
                          }))}
                          placeholder="Seleccione"
                          className="max-w-xs"
                          classNamePrefix="select"
                          value={orientationOpt
                            .filter((o) =>
                              Array.from(filters.sexualOrientation).includes(
                                o.id.toString()
                              )
                            )
                            .map((x) => ({
                              label: x.name,
                              value: x.id,
                            }))}
                          onChange={handleChangeReactSelect}
                          menuPortalTarget={document.body}
                          styles={{
                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                          }}
                        />
                      </>
                    )}

                    <Slider
                      label="Edad"
                      step={1}
                      maxValue={95}
                      minValue={18}
                      value={ageRange}
                      onChange={setAgeRange}
                      className="max-w-md"
                      onChangeEnd={async () => {
                        await getUserFriends(1);
                      }}
                    />

                    <Select
                      selectionMode="multiple"
                      labelPlacement="outside"
                      variant="bordered"
                      label="Nacionalidad"
                      name="nacionalidades_id"
                      classNames={{
                        trigger: "h-[44px] rounded-lg border-small",
                        label:
                          "text-[14px] font-medium group-data-[filled-within=true]:text-color2",
                        popoverContent: "rounded-md",
                      }}
                      className="max-w-xs"
                      onChange={handleChangeSelect}
                      placeholder={
                        selectsData.nationalities?.length
                          ? "Seleccione"
                          : "Cargando..."
                      }
                      selectedKeys={filters.nacionalidades_id}
                    >
                      {selectsData.nationalities?.map((item) => (
                        <SelectItem key={item.cca2} value={item.cca2}>
                          {item.name.common}
                        </SelectItem>
                      ))}
                    </Select>
                    {/* Select "Province" */}
                    <label className="text-gray-900 font-semibold text-sm ">
                      Provincias
                    </label>
                    <SelectNew<SelectOption, true>
                      isMulti
                      name="provincias_id"
                      options={selectsData.provinces.map((p) => ({
                        label: p.name,
                        value: p.id, // <-- Aquí pasas el id como número
                      }))}
                      placeholder="Seleccione"
                      className="max-w-xs"
                      classNamePrefix="select"
                      value={provinces
                        // Sigues filtrando con tu Set<string>, pero conviertes p.id a string:
                        .filter((p) => filters.provincias_id.has(String(p.id)))
                        .map((p) => ({
                          label: p.name,
                          value: p.id, // <-- Sigue siendo número
                        }))}
                      onChange={handleChangeReactSelect}
                      menuPortalTarget={document.body}
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                      }}
                    />
                    {/* <Select
                      selectionMode="multiple"
                      variant="bordered"
                      label="Provincias"
                      name="provincias_id"
                      labelPlacement="outside"
                      classNames={{
                        trigger: "h-[44px] rounded-lg border-small",
                        label:
                          "text-[14px] font-medium group-data-[filled-within=true]:text-color2",
                        popoverContent: "rounded-md",
                      }}
                      className="max-w-xs"
                      onChange={handleChangeSelect}
                      placeholder={
                        provinces?.length ? "Seleccione" : "Cargando..."
                      }
                      selectedKeys={filters.provincias_id}
                    >
                      {provinces?.map((item) => (
                        <SelectItem
                          key={item.id.toString()}
                          value={item.id.toString()}
                        >
                          {item.name}
                        </SelectItem>
                      ))}
                    </Select> */}
                    <Select
                      name="fantasias_id"
                      selectionMode="multiple"
                      variant="bordered"
                      label="Fantasías"
                      labelPlacement="outside"
                      classNames={{
                        trigger: "h-[44px] rounded-lg border-small",
                        label:
                          "text-[14px] font-medium group-data-[filled-within=true]:text-color2",
                        popoverContent: "rounded-md",
                      }}
                      className="max-w-xs"
                      onChange={handleChangeSelect}
                      placeholder={
                        selectsData.fantasies?.length
                          ? "Seleccione"
                          : "Cargando..."
                      }
                      selectedKeys={filters.fantasias_id}
                    >
                      {selectsData.fantasies?.map((item) => (
                        <SelectItem
                          key={item.id.toString()}
                          value={item.id.toString()}
                        >
                          {item.name}
                        </SelectItem>
                      ))}
                    </Select>
                    <Select
                      selectionMode="multiple"
                      variant="bordered"
                      label="Idiomas"
                      name="idiomas_id"
                      labelPlacement="outside"
                      classNames={{
                        trigger: "h-[44px] rounded-lg border-small",
                        label:
                          "text-[14px] font-medium group-data-[filled-within=true]:text-color2",
                        popoverContent: "rounded-md",
                      }}
                      className="max-w-xs"
                      onChange={handleChangeSelect}
                      placeholder={
                        selectsData.languages?.length
                          ? "Seleccione"
                          : "Cargando..."
                      }
                      selectedKeys={filters.idiomas_id}
                    >
                      {selectsData.languages?.map((item) => (
                        <SelectItem
                          key={item.id.toString()}
                          value={item.id.toString()}
                        >
                          {item.name}
                        </SelectItem>
                      ))}
                    </Select>
                    <Select
                      selectionMode="multiple"
                      variant="bordered"
                      label="Horóscopo"
                      name="horoscopo_id"
                      labelPlacement="outside"
                      classNames={{
                        trigger: "h-[44px] rounded-lg border-small",
                        label:
                          "text-[14px] font-medium group-data-[filled-within=true]:text-color2",
                        popoverContent: "rounded-md",
                      }}
                      className="max-w-xs"
                      onChange={handleChangeSelect}
                      placeholder={
                        selectsData.languages?.length
                          ? "Seleccione"
                          : "Cargando..."
                      }
                      selectedKeys={filters.horoscopo_id}
                    >
                      {selectsData.horoscope?.map((item) => (
                        <SelectItem
                          key={item.id.toString()}
                          value={item.id.toString()}
                        >
                          {item.name}
                        </SelectItem>
                      ))}
                    </Select>
                    <Select
                      selectionMode="multiple"
                      variant="bordered"
                      label="Profesión"
                      name="profesion_id"
                      labelPlacement="outside"
                      classNames={{
                        trigger: "h-[44px] rounded-lg border-small",
                        label:
                          "text-[14px] font-medium group-data-[filled-within=true]:text-color2",
                        popoverContent: "rounded-md",
                      }}
                      className="max-w-xs"
                      onChange={handleChangeSelect}
                      placeholder={
                        selectsData.languages?.length
                          ? "Seleccione"
                          : "Cargando..."
                      }
                      selectedKeys={filters.profesion_id}
                    >
                      {selectsData.profesions?.map((item) => (
                        <SelectItem
                          key={item.id.toString()}
                          value={item.id.toString()}
                        >
                          {item.name}
                        </SelectItem>
                      ))}
                    </Select>
                    <Select
                      name="races_id"
                      selectionMode="multiple"
                      variant="bordered"
                      label="Raza"
                      labelPlacement="outside"
                      classNames={{
                        trigger: "h-[44px] rounded-lg border-small",
                        label:
                          "text-[14px] font-medium group-data-[filled-within=true]:text-color2",
                        popoverContent: "rounded-md",
                      }}
                      className="max-w-xs"
                      onChange={handleChangeSelect}
                      placeholder="Seleccione"
                      selectedKeys={filters.races_id}
                    >
                      {races?.map((item) => (
                        <SelectItem
                          key={item.id.toString()}
                          value={item.id.toString()}
                        >
                          {item.name}
                        </SelectItem>
                      ))}
                    </Select>
                    <Select
                      name="skin_colors_id"
                      selectionMode="multiple"
                      variant="bordered"
                      label="Color de piel"
                      labelPlacement="outside"
                      classNames={{
                        trigger: "h-[44px] rounded-lg border-small",
                        label:
                          "text-[14px] font-medium group-data-[filled-within=true]:text-color2",
                        popoverContent: "rounded-md",
                      }}
                      className="max-w-xs"
                      onChange={handleChangeSelect}
                      placeholder="Seleccione"
                      selectedKeys={filters.skin_colors_id}
                    >
                      {skincolors?.map((item) => (
                        <SelectItem
                          key={item.id.toString()}
                          value={item.id.toString()}
                        >
                          {item.name}
                        </SelectItem>
                      ))}
                    </Select>
                    <Select
                      name="complexions_id"
                      selectionMode="multiple"
                      variant="bordered"
                      label="Complexión"
                      labelPlacement="outside"
                      classNames={{
                        trigger: "h-[44px] rounded-lg border-small",
                        label:
                          "text-[14px] font-medium group-data-[filled-within=true]:text-color2",
                        popoverContent: "rounded-md",
                      }}
                      className="max-w-xs"
                      onChange={handleChangeSelect}
                      placeholder="Seleccione"
                      selectedKeys={filters.complexions_id}
                    >
                      {complexions?.map((item) => (
                        <SelectItem
                          key={item.id.toString()}
                          value={item.id.toString()}
                        >
                          {item.name}
                        </SelectItem>
                      ))}
                    </Select>
                    <Select
                      name="eye_colors_id"
                      selectionMode="multiple"
                      variant="bordered"
                      label="Color de ojos"
                      labelPlacement="outside"
                      classNames={{
                        trigger: "h-[44px] rounded-lg border-small",
                        label:
                          "text-[14px] font-medium group-data-[filled-within=true]:text-color2",
                        popoverContent: "rounded-md",
                      }}
                      className="max-w-xs"
                      onChange={handleChangeSelect}
                      placeholder="Seleccione"
                      selectedKeys={filters.eye_colors_id}
                    >
                      {eyesColor?.map((item) => (
                        <SelectItem
                          key={item.id.toString()}
                          value={item.id.toString()}
                        >
                          {item.name}
                        </SelectItem>
                      ))}
                    </Select>
                    <Select
                      name="hair_colors_id"
                      selectionMode="multiple"
                      variant="bordered"
                      label="Color de cabello"
                      labelPlacement="outside"
                      classNames={{
                        trigger: "h-[44px] rounded-lg border-small",
                        label:
                          "text-[14px] font-medium group-data-[filled-within=true]:text-color2",
                        popoverContent: "rounded-md",
                      }}
                      className="max-w-xs"
                      onChange={handleChangeSelect}
                      placeholder="Seleccione"
                      selectedKeys={filters.hair_colors_id}
                    >
                      {hairColors?.map((item) => (
                        <SelectItem
                          key={item.id.toString()}
                          value={item.id.toString()}
                        >
                          {item.name}
                        </SelectItem>
                      ))}
                    </Select>
                    <Select
                      name="hair_length"
                      selectionMode="multiple"
                      variant="bordered"
                      label="Corte de cabello"
                      labelPlacement="outside"
                      classNames={{
                        trigger: "h-[44px] rounded-lg border-small",
                        label:
                          "text-[14px] font-medium group-data-[filled-within=true]:text-color2",
                        popoverContent: "rounded-md",
                      }}
                      className="max-w-xs"
                      onChange={handleChangeSelect}
                      placeholder="Seleccione"
                      selectedKeys={filters.hair_length}
                    >
                      {hairCuts?.map((item) => (
                        <SelectItem
                          key={item.id.toString()}
                          value={item.id.toString()}
                        >
                          {item.name}
                        </SelectItem>
                      ))}
                    </Select>
                    <Select
                      name="lips"
                      selectionMode="multiple"
                      variant="bordered"
                      label="Labios"
                      labelPlacement="outside"
                      classNames={{
                        trigger: "h-[44px] rounded-lg border-small",
                        label:
                          "text-[14px] font-medium group-data-[filled-within=true]:text-color2",
                        popoverContent: "rounded-md",
                      }}
                      className="max-w-xs"
                      onChange={handleChangeSelect}
                      placeholder="Seleccione"
                      selectedKeys={filters.lips}
                    >
                      {lipsOpt?.map((item) => (
                        <SelectItem
                          key={item.id.toString()}
                          value={item.id.toString()}
                        >
                          {item.name}
                        </SelectItem>
                      ))}
                    </Select>
                    <Select
                      name="type_of_nose"
                      selectionMode="multiple"
                      variant="bordered"
                      label="Tipo de nariz"
                      labelPlacement="outside"
                      classNames={{
                        trigger: "h-[44px] rounded-lg border-small",
                        label:
                          "text-[14px] font-medium group-data-[filled-within=true]:text-color2",
                        popoverContent: "rounded-md",
                      }}
                      className="max-w-xs"
                      onChange={handleChangeSelect}
                      placeholder="Seleccione"
                      selectedKeys={filters.lips}
                    >
                      {noseTypes?.map((item) => (
                        <SelectItem
                          key={item.id.toString()}
                          value={item.id.toString()}
                        >
                          {item.name}
                        </SelectItem>
                      ))}
                    </Select>
                    <Select
                      name="piercings"
                      selectionMode="multiple"
                      variant="bordered"
                      label="Piercings"
                      labelPlacement="outside"
                      classNames={{
                        trigger: "h-[44px] rounded-lg border-small",
                        label:
                          "text-[14px] font-medium group-data-[filled-within=true]:text-color2",
                        popoverContent: "rounded-md",
                      }}
                      className="max-w-xs"
                      onChange={handleChangeSelect}
                      placeholder="Seleccione"
                      selectedKeys={filters.piercings}
                    >
                      {piercingCount?.map((item) => (
                        <SelectItem
                          key={item.id.toString()}
                          value={item.id.toString()}
                        >
                          {item.name}
                        </SelectItem>
                      ))}
                    </Select>
                    <Select
                      name="tattoos"
                      selectionMode="multiple"
                      variant="bordered"
                      label="Tatuajes"
                      labelPlacement="outside"
                      classNames={{
                        trigger: "h-[44px] rounded-lg border-small",
                        label:
                          "text-[14px] font-medium group-data-[filled-within=true]:text-color2",
                        popoverContent: "rounded-md",
                      }}
                      className="max-w-xs"
                      onChange={handleChangeSelect}
                      placeholder="Seleccione"
                      selectedKeys={filters.tattoos}
                    >
                      {tattoosCount?.map((item) => (
                        <SelectItem
                          key={item.id.toString()}
                          value={item.id.toString()}
                        >
                          {item.name}
                        </SelectItem>
                      ))}
                    </Select>
                    <Select
                      name="you_drink"
                      selectionMode="multiple"
                      variant="bordered"
                      label="Bebe"
                      labelPlacement="outside"
                      classNames={{
                        trigger: "h-[44px] rounded-lg border-small",
                        label:
                          "text-[14px] font-medium group-data-[filled-within=true]:text-color2",
                        popoverContent: "rounded-md",
                      }}
                      className="max-w-xs"
                      onChange={handleChangeSelect}
                      placeholder="Seleccione"
                      selectedKeys={filters.you_drink}
                    >
                      {drink?.map((item) => (
                        <SelectItem
                          key={item.id.toString()}
                          value={item.id.toString()}
                        >
                          {item.name}
                        </SelectItem>
                      ))}
                    </Select>
                    <Select
                      name="you_smoke"
                      selectionMode="multiple"
                      variant="bordered"
                      label="Fuma"
                      labelPlacement="outside"
                      classNames={{
                        trigger: "h-[44px] rounded-lg border-small",
                        label:
                          "text-[14px] font-medium group-data-[filled-within=true]:text-color2",
                        popoverContent: "rounded-md",
                      }}
                      className="max-w-xs"
                      onChange={handleChangeSelect}
                      placeholder="Seleccione"
                      selectedKeys={filters.you_smoke}
                    >
                      {smoke?.map((item) => (
                        <SelectItem
                          key={item.id.toString()}
                          value={item.id.toString()}
                        >
                          {item.name}
                        </SelectItem>
                      ))}
                    </Select>
                    <Select
                      name="children"
                      selectionMode="multiple"
                      variant="bordered"
                      label="Hijos"
                      labelPlacement="outside"
                      classNames={{
                        trigger: "h-[44px] rounded-lg border-small",
                        label:
                          "text-[14px] font-medium group-data-[filled-within=true]:text-color2",
                        popoverContent: "rounded-md",
                      }}
                      className="max-w-xs"
                      onChange={handleChangeSelect}
                      placeholder="Seleccione"
                      selectedKeys={filters.children}
                    >
                      {childs?.map((item) => (
                        <SelectItem
                          key={item.id.toString()}
                          value={item.id.toString()}
                        >
                          {item.name}
                        </SelectItem>
                      ))}
                    </Select>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="flex-grow flex flex-col  w-full  hide-scroll">
            <div className="w-full max-w-8xl px-4 md:px-14">
              <section className="w-full flex">
                <div className=" flex flex-col w-full">
                  <div className="w-full gap-4 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
                    {isLoadingFriends ||
                    isLoading ||
                    isLoadingReq ||
                    isLoadingReqSent ? (
                      [0, 0, 0, 0, 0, 0, 0, 0].map((_, i) => (
                        <Card
                          className="w-full space-y-5 p-4 shadow-none border-1 border-gray-100"
                          radius="lg"
                          key={i}
                        >
                          <Skeleton className="rounded-lg">
                            <div className="h-24 rounded-lg bg-default-300"></div>
                          </Skeleton>
                          <div className="space-y-3">
                            <Skeleton className="w-3/5 rounded-lg">
                              <div className="h-3 w-3/5 rounded-lg bg-default-200"></div>
                            </Skeleton>
                            <Skeleton className="w-4/5 rounded-lg">
                              <div className="h-3 w-4/5 rounded-lg bg-default-200"></div>
                            </Skeleton>
                            <Skeleton className="w-2/5 rounded-lg">
                              <div className="h-3 w-2/5 rounded-lg bg-default-300"></div>
                            </Skeleton>
                          </div>
                        </Card>
                      ))
                    ) : data.length == 0 ? (
                      <p>
                        No hay
                        {tab == "amigos" ? " amigos " : " solicitudes "}
                        para mostrar
                      </p>
                    ) : (
                      data.map((it) => (
                        <FriendCard
                          key={it.id}
                          data={it}
                          userId={userId ?? 0}
                          type={
                            tab == "amigos"
                              ? "friend"
                              : tab == "requests"
                              ? "request"
                              : "requestSent"
                          }
                          setFriendToDelete={() => setFriendToDelete(it)}
                          setFriendRequestsSent={() =>
                            setFriendRequestsSent(
                              friendRequestsSent.filter((e) => e.id != it.id)
                            )
                          }
                          onSuccessAccept={() => onSuccessAccept(it)}
                          onSuccessReject={() => onSuccessReject(it)}
                          orientations={orientations}
                          provinces={provinces}
                        />
                      ))
                    )}
                  </div>
                </div>
              </section>
            </div>
            {pagination && pagination?.total > 1 && (
              <div className="px-4 mt-3 mb-2 w-full">
                <div className="flex justify-center">
                  <Pagination
                    showControls
                    total={pagination.last_page}
                    page={pagination.current_page}
                    initialPage={1}
                    onChange={onPageChange}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <FriendshipModal
        isOpen={friendToDelete != null}
        closeModal={() => setFriendToDelete(null)}
        name={friendToDelete?.name ?? ""}
        id={friendToDelete?.id ?? 0}
        onSuccessDelete={onSuccessDelete}
      />
    </>
  );
}
