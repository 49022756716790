import { Button } from "@nextui-org/react";
import NavBar from "../components/common/Navbar";
import { AddIcon } from "../assets/icons/PlusIcon";
import UsersModal from "../components/chat/UsersModal";

import { ArrowLeftIcon } from "../assets/icons/ArrowIcon";
import { useEffect, useState } from "react";
import { ChatsResponse, createFakeChat } from "../interfaces/Chat";
import { getChats } from "../api/chat";
import ChatList from "../components/chat/ChatList";
import { useChatStore } from "../stores/chatStore";
import ChatPanel from "../components/chat/ChatPanel";
import { useAuthStore } from "../stores/authStore";
import { User as UserData } from "../interfaces/Login";
import ChatUserInfo from "../components/chat/ChatUserInfo";
import { withErrorHandler } from "../utils/withErrorFallback";

export default function NewChat() {
  const authData = useAuthStore((state) => state.user);

  const [tab, setTab] = useState("chats"); // chats | chat-panel | user ;

  const [chatSelected, setChatSelected] = useState("");
  const chats = useChatStore((state) => state.chats);
  const setChats = useChatStore((state) => state.setChats);
  const [isLoadingChats, setIsLoadingChats] = useState(false);

  const setChatsMessages = useChatStore((state) => state.setChatMessages);
  const chatsMessages = useChatStore((state) => state.chatMessages);

  const newMessages = useChatStore((state) => state.newMessages);
  const setNewMessages = useChatStore((state) => state.setNewMessages);
  // const [, forceUpdate] = useReducer((x) => x + 1, 0);

  const setHasUnreadMessages = useChatStore(
    (state) => state.setHasUnreadMessages
  );

  //user to chat directly from profile button 'message'
  const setUserToChat = useChatStore((state) => state.setUserToChat);
  const userToChat = useChatStore((state) => state.userToChat);

  const getData = withErrorHandler(async (page?: number, setFirst = true) => {
    setIsLoadingChats(true);

    try {
      const response = await getChats({ page: page });
      if (response && response?.error == "false") {
        const chs = (response.info.data["0"] as ChatsResponse[])
          ?.map((e) => {
            return {
              ...e,
              name: JSON.parse(e.name.toString()),
            };
          })
          .filter((e) => e.integrantes?.findIndex((x) => x.user == null) == -1);

        const sortedChats = chs.sort((a: ChatsResponse, b: ChatsResponse) => {
          let x = "";
          let y = "";

          x = a.lastMessage?.created_at ?? new Date().toISOString();
          y = b.lastMessage?.created_at ?? new Date().toISOString();
          //console.log(new Date(x), new Date(y), new Date(x) <= new Date(y));
          return new Date(y).getTime() - new Date(x).getTime();
        });
        setChatsMessages([]);
        setChats(sortedChats);

        let id = 0;
        let max = "";
        for (let i = 0; i < chs.length; i++) {
          const element = chs[i];
          if (element.lastMessage.created_at > max) {
            max = element.lastMessage.created_at;
            id = element.id;
          }
        }
        if (userToChat) {
          onAddNewUser(userToChat);
          setUserToChat(undefined);
        } else if (setFirst) setChatSelected(id.toString());
        console.log("id.toString()", id.toString());
      }
    } catch (error) {
      console.error(error);
    }
    setHasUnreadMessages(0);
    setIsLoadingChats(false);
  });

  useEffect(() => {
    getData();
  }, []);

  const handleSelectChat = (id: string) => {
    setChatSelected(id);
    setTab("chat-panel");
  };
  const handleBackToChats = () => {
    setChatSelected("");
    setTab("chats");
  };
  const getMember = withErrorHandler((chat?: ChatsResponse) => {
    if (chat && chat.integrantes) {
      const member = chat.integrantes.find((e) => e.user_id != authData?.id);
      if (member) return member.user;
    }
    return undefined;
  });

  const [isOpenModal, setIsOpenModal] = useState(false);

  const onAddNewUser = withErrorHandler((user: UserData) => {
    if (authData) {
      if (chats.find((e) => e.name[user.id])) {
        setChatSelected(`${chats.find((e) => e.name[user.id])?.id}`);
        //console.log(chats.find((e) => e.name[user.id]));
      } else {
        const chat = createFakeChat(user, authData);
        setChatsMessages([
          {
            ...chat,
            integrantes: [...chat.integrantes!],
            messages: {
              data: [],
            },
          },
          ...chatsMessages,
        ]);
        // console.log(chat);
        setChats([chat, ...chats]);

        setChatSelected(`${chat.id}`);
      }
      setTab("chat-panel");
    }
  });

  useEffect(() => {
    if (newMessages.length > 0 && !isLoadingChats) {
      //console.log("newMessages", chatsMessages);
      const userId = newMessages[0]?.user_id;
      const chatSaved = chatsMessages.find((e) =>
        e?.integrantes.find((u) => u.user_id == userId)
      );
      console.log("chatSaved", chatSaved);
      if (chatSaved) {
        // console.log("algoooooooooooo", chatSaved);
        const idx = chatsMessages.findIndex((e) => e?.id == chatSaved.id);
        const messagesData = chatsMessages;

        if (idx != -1) {
          messagesData[idx] = {
            ...messagesData[idx],
            messages: {
              ...messagesData[idx].messages,
              data: [...messagesData[idx].messages.data, ...newMessages],
            },
          };
          console.log("messagesData", messagesData[idx]);
          setChatsMessages(messagesData);
        }
      } else {
        //TODO: check this
        getData();
        // setChats([
        //   {
        //     id: newMessages[0].chat_id ?? Math.floor(Math.random() * 10000),
        //     tipo: "chat",
        //     name: {
        //       [authData!.id]: authData!.name,
        //       [userId]: newMessages[0].user.name,
        //     },
        //     created_at: new Date().toISOString(),
        //     lastMessage: newMessages[newMessages.length - 1],
        //   },
        //   ...chats,
        // ]);
      }
      if (chatSelected == newMessages[0]?.chat_id?.toString()) {
        setHasUnreadMessages(0);
      }
      setNewMessages([]);
      // forceUpdate();
    }
  }, [newMessages.length, isLoadingChats]);

  return (
    <>
      <NavBar />
      <main className="home sm:p-4 pt-0 lg:pt-5 lg:px-10 flex w-full items-center flex-col fixed top-[65px] hide-scroll">
        <div className="max-w-8xl w-full">
          <div className="flex lg:hidden  sm:gap-2 2xl:gap-6 overflow-auto w-full">
            {/* Chats section */}
            {tab == "chats" ? (
              <div className="w-full">
                <div className="flex justify-between p-3 border-b-1 w-full border-zinc-200 mb-4">
                  <h1 className="text-xl font-semibold">Chats</h1>
                  <Button
                    onClick={() => setIsOpenModal(true)}
                    color="primary"
                    size="sm"
                  >
                    <AddIcon stroke="white" /> Nuevo chat
                  </Button>
                </div>

                <div className="p-4">
                  {" "}
                  {isLoadingChats ? (
                    <p className="p-2"> Cargando...</p>
                  ) : chats.length == 0 ? (
                    <span className="px-2">No hay chats para mostrar</span>
                  ) : (
                    <ChatList
                      chats={chats}
                      chatSelected={chatSelected}
                      onChatSelected={handleSelectChat}
                    />
                  )}
                </div>
              </div>
            ) : tab == "chat-panel" ? (
              <div className="flex-grow flex justify-center w-full hide-scroll">
                <div
                  id="scrollableDiv"
                  className=" Xbg-color9 border-1 border-zinc-100 border-b-white rounded-lg shadow-lg  w-full lg:ml-5 max-h-chat flex flex-col  hide-scroll"
                >
                  <ChatPanel
                    chatSelected={chatSelected}
                    name={
                      getMember(
                        chats.find((e) => e.id == parseInt(chatSelected))
                      )?.name ?? ""
                    }
                    slot={
                      <Button
                        variant="light"
                        size="sm"
                        isIconOnly
                        aria-label="back"
                        onClick={handleBackToChats}
                      >
                        <ArrowLeftIcon />
                      </Button>
                    }
                  />
                </div>
              </div>
            ) : (
              <div className=" ml-5 border-1 border-zinc-100 rounded-lg shadow-lg max-h-main hide-scroll">
                <section className="w-full rounded-lg">info</section>
              </div>
            )}
          </div>

          <div className="hidden lg:flex  justify-between sm:gap-2 2xl:gap-6 overflow-hidden w-full">
            <div className="hidden bg-zinc-white border-1 border-zinc-100 rounded-lg shadow-lg lg:block w-4/12 lg:min-w-[243px] max-h-main hide-scroll">
              <div className="flex justify-between p-3 border-b-1 border-zinc-200 mb-4">
                <h1 className="text-xl font-semibold">Chats</h1>
                <Button
                  onClick={() => setIsOpenModal(true)}
                  color="primary"
                  size="sm"
                >
                  <AddIcon stroke="white" /> Nuevo chat
                </Button>
              </div>
              <div className="">
                {" "}
                {isLoadingChats ? (
                  <p className="p-2"> Cargando...</p>
                ) : chats.length == 0 ? (
                  <span className="px-2">No hay chats para mostrar</span>
                ) : (
                  <ChatList
                    chats={chats}
                    chatSelected={chatSelected}
                    onChatSelected={handleSelectChat}
                  />
                )}
              </div>
            </div>
            <div className="flex-grow flex justify-center w-full hide-scroll">
              <div
                id="scrollableDiv"
                className=" Xbg-color9 border-1 border-zinc-100 rounded-lg shadow-lg  w-full lg:ml-5 max-h-main flex flex-col  hide-scroll"
              >
                <ChatPanel
                  chatSelected={chatSelected}
                  name={
                    getMember(chats.find((e) => e.id == parseInt(chatSelected)))
                      ?.name ?? "Selecciona un chat"
                  }
                  slot={<></>}
                />
              </div>
            </div>
            <div className="hidden xl:block ml-5 border-1 border-zinc-100 rounded-lg shadow-lg   w-4/12 lg:min-w-[243px] max-h-main hide-scroll">
              <section className="w-full rounded-lg">
                {chatSelected &&
                chats.find((e) => e.id == parseInt(chatSelected)) ? (
                  <section className="w-full rounded-lg">
                    <ChatUserInfo
                      user={
                        getMember(
                          chats.find((e) => e.id == parseInt(chatSelected))
                        )!
                      }
                    />
                  </section>
                ) : (
                  <div className="p-2 mt-3 text-center">Selecciona un chat</div>
                )}
              </section>
            </div>
          </div>
        </div>
      </main>
      <UsersModal
        isOpen={isOpenModal}
        closeModal={() => setIsOpenModal(false)}
        onSelectUser={onAddNewUser}
      />
    </>
  );
}
