import { SVGProps } from "react";

export function VideoIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M11.943 1.25h.114c2.309 0 4.118 0 5.53.19c1.444.194 2.584.6 3.479 1.494c.895.895 1.3 2.035 1.494 3.48c.19 1.411.19 3.22.19 5.529v.114c0 2.309 0 4.118-.19 5.53c-.194 1.444-.6 2.584-1.494 3.479c-.895.895-2.035 1.3-3.48 1.494c-1.411.19-3.22.19-5.529.19h-.114c-2.309 0-4.118 0-5.53-.19c-1.444-.194-2.584-.6-3.479-1.494c-.895-.895-1.3-2.035-1.494-3.48c-.19-1.411-.19-3.22-.19-5.529v-.114c0-2.309 0-4.118.19-5.53c.194-1.444.6-2.584 1.494-3.479c.895-.895 2.035-1.3 3.48-1.494c1.411-.19 3.22-.19 5.529-.19M6.25 2.982c-1.065.183-1.742.5-2.255 1.013c-.514.513-.83 1.19-1.013 2.255H6.25zm1.5-.162v3.43h3.5v-3.5c-1.395.002-2.54.011-3.5.07m5-.07v3.5h3.5V2.82c-.96-.059-2.105-.068-3.5-.07m5 .232V6.25h3.268c-.183-1.065-.5-1.742-1.013-2.255c-.513-.514-1.19-.83-2.255-1.013m3.43 4.768H2.82c-.07 1.126-.07 2.508-.07 4.25s0 3.124.07 4.25h18.36c.07-1.126.07-2.508.07-4.25s0-3.124-.07-4.25m-.162 10H17.75v3.268c1.065-.183 1.742-.5 2.255-1.013c.514-.513.83-1.19 1.013-2.255m-4.768 3.43v-3.43h-3.5v3.5c1.395-.002 2.54-.011 3.5-.07m-5 .07v-3.5h-3.5v3.43c.96.059 2.105.068 3.5.07m-5-.232V17.75H2.982c.183 1.065.5 1.742 1.013 2.255c.513.514 1.19.83 2.255 1.013m6.53-11.27l.05.034l.046.031c.49.33.927.623 1.235.907c.334.307.639.714.639 1.28s-.305.973-.639 1.28c-.308.284-.745.578-1.235.907l-.046.031l-.05.034c-.494.332-.938.63-1.312.803c-.392.181-.949.336-1.482-.016c-.49-.324-.624-.866-.68-1.3c-.056-.439-.056-1.016-.056-1.688v-.102c0-.672 0-1.25.056-1.688c.056-.434.19-.976.68-1.3c.533-.352 1.09-.198 1.482-.016c.373.173.818.471 1.311.803m-1.96.55l.018.008c.251.116.595.344 1.155.72c.55.371.89.601 1.102.797a.8.8 0 0 1 .154.177l-.003.007a.8.8 0 0 1-.151.17c-.213.196-.551.426-1.102.796c-.56.377-.904.605-1.155.72l-.019.01a2 2 0 0 1-.025-.154c-.043-.332-.044-.811-.044-1.549s.002-1.217.044-1.55q.012-.094.025-.152"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

/**
 */
export function PlayVideoSmallIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5 4.98951C5 4.01835 5 3.53277 5.20249 3.2651C5.37889 3.03191 5.64852 2.88761 5.9404 2.87018C6.27544 2.85017 6.67946 3.11953 7.48752 3.65823L18.0031 10.6686C18.6708 11.1137 19.0046 11.3363 19.1209 11.6168C19.2227 11.8621 19.2227 12.1377 19.1209 12.383C19.0046 12.6635 18.6708 12.886 18.0031 13.3312L7.48752 20.3415C6.67946 20.8802 6.27544 21.1496 5.9404 21.1296C5.64852 21.1122 5.37889 20.9679 5.20249 20.7347C5 20.467 5 19.9814 5 19.0103V4.98951Z"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
