import axios from "axios";
import { useAuthStore } from "../stores/authStore";
import { useNotificationsStore } from "../stores/notificationStore";

const instance = axios.create({
  baseURL: import.meta.env.VITE_BASE_URL + "api",
});

instance.interceptors.request.use(
  async (config) => {
    try {
      const accessToken = useAuthStore.getState().token;
      if (
        accessToken &&
        !config.headers.Authorization &&
        !axios.getUri(config).includes("login") &&
        !axios.getUri(config).includes("register")
      ) {
        config.headers.Authorization = `Bearer ${accessToken}`;
      }
      // console.log("config", axios.getUri(config));
    } catch (error) {
      console.error(error);
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
instance.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    if (response.data?.info?.data?.notificaciones >= 0) {
      useNotificationsStore.setState({
        count: response.data?.info?.data?.notificaciones,
      });
    }

    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  }
);
export default instance;
