import { SVGProps } from "react";

export function NotificationIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.7952 17.4993C8.38281 18.018 9.15469 18.3327 10.0001 18.3327C10.8454 18.3327 11.6173 18.018 12.2049 17.4993M15.0001 6.66602C15.0001 5.33993 14.4733 4.06816 13.5356 3.13048C12.5979 2.1928 11.3261 1.66602 10.0001 1.66602C8.67397 1.66602 7.40221 2.1928 6.46452 3.13048C5.52684 4.06816 5.00006 5.33993 5.00006 6.66602C5.00006 9.24117 4.35045 11.0043 3.62478 12.1705C3.01266 13.1542 2.7066 13.6461 2.71783 13.7833C2.73025 13.9352 2.76244 13.9932 2.88487 14.084C2.99544 14.166 3.49388 14.166 4.49077 14.166H15.5093C16.5062 14.166 17.0047 14.166 17.1152 14.084C17.2377 13.9932 17.2699 13.9352 17.2823 13.7833C17.2935 13.6461 16.9875 13.1542 16.3753 12.1705C15.6497 11.0043 15.0001 9.24117 15.0001 6.66602Z"
        stroke={props.stroke ?? "#667085"}
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
