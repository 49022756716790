import PrimaryButton from "../common/PrimaryButton";
import SecondaryButton from "../common/SecondaryButton";
import Stepper from "../common/Stepper";
import { useStepperStore } from "../../stores/stepperStore";
import CustomSelect from "../form/CustomSelect";
import CustomRadioGroup from "../form/CustomRadioGroup";
import { FormEvent, useEffect, useReducer, useState } from "react";
import {
  getHoroscopes,
  getLangueages,
  getProfesions,
} from "../../api/formSelectsData";
import { getInfoPayload, useRegisterStore } from "../../stores/registerData";
import { useAuthStore } from "../../stores/authStore";
import Logo from "../common/Logo";
import CustomInput from "../form/CustomInput";
import { Lang } from "../../interfaces/Lang";
import { Profesion } from "../../interfaces/Profesion";
import { Horoscope } from "../../interfaces/Horoscope";
import { toast } from "react-toastify";
import {
  childs,
  nippleSizes,
  possibilitiesOfHosting,
  possibilitiesOfMoving,
} from "../../utils/selectOptions";
import { useLoginStore } from "../../stores/loginDataStore";
import { saveUserInfo } from "../../api/info";
import { useNavigate } from "react-router-dom";

type VisibilityNm =
  | "children"
  | "member_measurement"
  | "partner_member_measurement"
  | "nipple_size"
  | "partner_nipple_size"
  | "partner_profesion_id"
  | "partner_children";

const initialErrors = {
  horoscopo_id: false,
  idiomas_id: false,
  profesion_id: false,
  children: false,
  partner_children: false,
  member_measurement: false,
  partner_member_measurement: false,
  nipple_size: false,
  partner_nipple_size: false,
  possibility_of_moving: false,
  possibility_of_hosting: false,
  partner_horoscopo_id: false,
  partner_idioma_id: false,
  partner_profesion_id: false,
};

export default function ProfileFiveStepForm({
  participants = 1,
  backStep,
  isEditing = false,
}: {
  isLocal: boolean;
  isEditing?: boolean;
  participants?: number;
  backStep: () => void;
}) {
  // currrent step
  const step = useStepperStore((state) => state.step);
  // authenticated user
  const user = useLoginStore((state) => state.user); //not persisted
  const saveUser = useAuthStore((state) => state.saveUser); //not persisted
  const authData = useAuthStore((state) => state.user); // persisted
  const setUser = useLoginStore((state) => state.saveUser); // persisted

  // all steps data
  const stepTwoData = useRegisterStore((state) => state.stepTwoData);
  const stepFourData = useRegisterStore((state) => state.stepFourData);
  const setStepFourData = useRegisterStore((state) => state.setStepFourData);
  // const [extras, setExtras] = useState({ profesionVisibility: "all" });

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const setStep = useStepperStore((state) => state.setStep);
  //Validations
  const [errors, setErrors] = useState(initialErrors);
  const [, forceUpdate] = useReducer((x) => x + 1, 0);

  const validate = () => {
    let isValid = true;
    // setMessagesErrors(initialMessages);
    let finalErrors = initialErrors;
    const arr = Object.keys(initialErrors);
    arr.forEach((name) => {
      switch (name) {
        case "children":
        case "possibility_of_moving":
        case "possibility_of_hosting":
          if (!stepFourData[name]?.value) {
            isValid = false;
            finalErrors = {
              ...finalErrors,
              [name]: true,
            };
            //console.log(stepFourData[name]);
          }
          break;
        case "partner_children":
          // console.log(stepTwoData[name]?.value);
          if (!stepFourData[name]?.value && participants == 2) {
            isValid = false;
            finalErrors = {
              ...finalErrors,
              [name]: true,
            };
          }
          break;
        case "idiomas_id":
        case "profesion_id":
        case "horoscopo_id":
          if (!stepFourData[name]) {
            isValid = false;
            finalErrors = {
              ...finalErrors,
              [name]: true,
            };
            //console.log(stepFourData[name]);
          }
          break;
        case "partner_idioma_id":
        case "partner_profesion_id":
        case "partner_horoscopo_id":
          if (!stepFourData[name]?.value && participants == 2) {
            isValid = false;
            finalErrors = {
              ...finalErrors,
              [name]: true,
            };
            //console.log(stepFourData[name]);
          }
          break;
        case "member_measurement":
          if (!stepFourData[name] && stepTwoData.sex?.value == "m") {
            isValid = false;
            finalErrors = {
              ...finalErrors,
              [name]: true,
            };
            // console.log(stepFourData[name]);
          }
          break;
        case "partner_member_measurement":
          if (
            !stepFourData[name] &&
            stepTwoData.partner_sex?.value == "m" &&
            participants == 2
          ) {
            isValid = false;
            finalErrors = {
              ...finalErrors,
              [name]: true,
            };
            //console.log(stepFourData[name]);
          }
          break;
        case "nipple_size":
          if (!stepFourData[name] && stepTwoData.sex?.value == "f") {
            isValid = false;
            finalErrors = {
              ...finalErrors,
              [name]: true,
            };
            //console.log(stepFourData[name]);
          }
          break;
        case "partner_nipple_size":
          if (
            !stepFourData[name] &&
            stepTwoData.partner_sex?.value == "f" &&
            participants == 2
          ) {
            isValid = false;
            finalErrors = {
              ...finalErrors,
              [name]: true,
            };
            //console.log(stepFourData[name]);
          }
          break;
        default:
          break;
      }
    });
    setErrors(finalErrors);
    forceUpdate();
    // console.log(errors);
    return isValid;
  };
  // selects optionons (Api)
  const [selectsData, setSelectsData] = useState({
    languages: [] as Lang[],
    profesions: [] as Profesion[],
    horoscope: [] as Horoscope[],
  });

  useEffect(() => {
    const getData = async () => {
      if (step == 2) {
        const languages = await getLangueages();
        const profesions = await getProfesions();
        const horoscope = await getHoroscopes();

        setSelectsData({
          ...selectsData,
          languages: languages.info.data[0],
          profesions: profesions.info.data[0],
          horoscope: horoscope.info.data[0],
        });
      }
    };
    getData();
  }, [step]);

  const setFormData = (name: string, value: string) => {
    switch (name) {
      case "idiomas_id":
      case "profesion_id":
      case "horoscopo_id":
        setStepFourData({
          ...stepFourData,
          [name]: value,
        });
        break;
      default:
        setStepFourData({
          ...stepFourData,
          [name]: {
            value: value,
            visibility: stepFourData[name as VisibilityNm]?.visibility ?? "all",
          },
        });
        break;
    }
  };
  const handleChangeSelect = (e: FormEvent<HTMLSelectElement>) => {
    const data = e.target as HTMLSelectElement;

    // console.log(data.value);
    setFormData(data.name, data.value);
  };

  const handleChangeInput = (e: FormEvent<HTMLInputElement>) => {
    const data = e.target as HTMLInputElement;
    setFormData(data.name, data.value);
  };

  // TODO: Clear this code please
  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const isValid = validate();
    if (!isValid) return;
    const submitter = (e.nativeEvent as SubmitEvent).submitter?.innerText;
    if (submitter == "Volver") {
      setStep(step - 1);
      return;
    }
    setLoading(true);
    // console.log(stepFourData);
    if (user || isEditing) {
      const data = getInfoPayload(participants - 1, user?.id ?? authData?.id);
      console.log("data", data);
      //return;
      const response = await saveUserInfo(data);
      // console.log(response);
      if (response?.error == "true" || !response) {
        toast.error("Ha ocurrido un error");
      } else {
        toast.success("Los datos se guardaron correctamente");
        saveUser(response.info.data[0]);
        setUser(response.info.data[0]);
        // console.log(response.info.data[0]);
        if (!isEditing) navigate("/login");
      }
    } else {
      toast.error("Debes autenticarte primero");
      navigate("/login");
    }
    setLoading(false);
  };

  // Save visiblities
  const handleVisibiltyChange = (name: VisibilityNm, value: string) => {
    if (name)
      setStepFourData({
        ...stepFourData,
        [name]: {
          value: stepFourData[name]!.value,
          visibility: value,
        },
      });
  };
  const handleExtrasVisibiltyChange = (value: string) => {
    // setExtras({ profesionVisibility: value });
    setStepFourData({
      ...stepFourData,
      ["profesion_visibility"]: value,
    });
  };

  return (
    <form
      hidden={step != 4}
      onSubmit={handleSubmit}
      className={` m-6 pb-10 text-center items-center  xl:overflow-hidden xl:overflow-y-auto xl:p-5 ${
        isEditing ? "w-full" : "max-w-[768px] max-h-[90vh]"
      }`}
    >
      <div
        hidden={isEditing}
        className="md:absolute mb-8 md:mb-0 justify-center top-[27px] left-[26px]"
      >
        <SecondaryButton onClick={backStep} className="bg-white z-10">
          Volver atrás
        </SecondaryButton>
      </div>
      {!isEditing && (
        <div className="flex justify-center">
          <Logo />
        </div>
      )}
      <Stepper step={step - 2} />
      <h1 className="mt-14 font-semibold text-[30px] ">
        {participants == 1 ? "Yo solo/a" : "En pareja"}
      </h1>
      <h3 className="mt-2 font-normal text-color1 text-[16px]">
        {participants == 1
          ? "¡Muy bien! Ahora rellena tu información."
          : "¡Muy bien! Ahora rellenad la información de cada uno de vosotros."}
      </h3>
      <div className="mt-8 flex w-full justify-center">
        <div className="grid w-full grid-cols-1 md:grid-cols-2 gap-10">
          <div className="text-start">
            <CustomRadioGroup
              title="¿Tengo hijos?"
              required
              name="children"
              value={stepFourData.children?.value}
              onChange={handleChangeInput}
              items={childs.map((el) => ({
                label: el.name,
                value: el.id.toString(),
              }))}
              visibility_name="children_visibility"
              visibilityValue={`children_visibility_${
                stepFourData.children?.visibility ?? "all"
              }`}
              onVisibilityChange={(value) => {
                handleVisibiltyChange("children", value);
              }}
              isInvalid={errors.children}
              errorMessage={"Este campo es requerido"}
            />
          </div>
          <div className="text-start" hidden={participants == 2 ? false : true}>
            <CustomRadioGroup
              title="¿Tiene hijos mi pareja?"
              name="partner_children"
              value={stepFourData.partner_children?.value}
              visibilityValue={`partner_children_visibility_${
                stepFourData.partner_children?.visibility ?? "all"
              }`}
              onChange={handleChangeInput}
              required={participants == 2}
              items={childs.map((el) => ({
                label: el.name,
                value: el.id.toString(),
              }))}
              visibility_name="partner_children_visibility"
              onVisibilityChange={(value) => {
                handleVisibiltyChange("partner_children", value);
              }}
              isInvalid={errors.partner_children}
              errorMessage={"Este campo es requerido"}
            />
          </div>
          <div className="text-start">
            <CustomSelect
              visibilityAll
              title="Idiomas que hablo"
              selectionMode="multiple"
              name="idiomas_id"
              onChange={handleChangeSelect}
              value={stepFourData.idiomas_id}
              required
              items={
                selectsData.languages.length
                  ? selectsData.languages.map((x) => ({
                      label: x.name,
                      value: x.id.toString(),
                    }))
                  : []
              }
              placeholder={
                selectsData.languages.length ? "Seleccione" : "Cargando..."
              }
              defaultSelectedKeys={
                isEditing && stepFourData.idiomas_id
                  ? stepFourData.idiomas_id.split(",")
                  : []
              }
              isInvalid={errors.idiomas_id}
              errorMessage={"Este campo es requerido"}
            />
          </div>
          <div className="text-start" hidden={participants == 2 ? false : true}>
            <CustomSelect
              visibilityAll
              title="Idiomas que habla mi pareja"
              selectionMode="multiple"
              name="partner_idioma_id"
              onChange={handleChangeSelect}
              value={stepFourData.partner_idioma_id?.value}
              required={participants == 2}
              items={
                selectsData.languages.length
                  ? selectsData.languages.map((x) => ({
                      label: x.name,
                      value: x.id.toString(),
                    }))
                  : []
              }
              placeholder={
                selectsData.languages.length ? "Seleccione" : "Cargando..."
              }
              defaultSelectedKeys={
                isEditing && stepFourData.partner_idioma_id?.value
                  ? stepFourData.partner_idioma_id?.value?.split(",")
                  : []
              }
              isInvalid={errors.partner_idioma_id}
              errorMessage={"Este campo es requerido"}
            />
          </div>
          <div className="text-start">
            <CustomSelect
              title="Mi profesión"
              name="profesion_id"
              onChange={handleChangeSelect}
              value={stepFourData.profesion_id}
              required
              items={
                selectsData.profesions.length
                  ? selectsData.profesions.map((x) => ({
                      label: x.name,
                      value: x.id.toString(),
                    }))
                  : []
              }
              placeholder={
                selectsData.profesions.length ? "Seleccione" : "Cargando..."
              }
              visibility_name="profesion_visibility"
              visibilityValue={`profesion_visibility_${
                stepFourData.profesion_visibility ?? "all"
              }`}
              onVisibilityChange={(value) => {
                handleExtrasVisibiltyChange(value);
              }}
              defaultSelectedKeys={
                isEditing && stepFourData.profesion_id
                  ? [stepFourData.profesion_id]
                  : []
              }
              isInvalid={errors.profesion_id}
              errorMessage={"Este campo es requerido"}
            />
          </div>
          <div className="text-start" hidden={participants == 2 ? false : true}>
            <CustomSelect
              title="Profesión de mi pareja"
              required={participants == 2}
              name="partner_profesion_id"
              onChange={handleChangeSelect}
              value={stepFourData.partner_profesion_id?.value}
              items={
                selectsData.profesions.length
                  ? selectsData.profesions.map((x) => ({
                      label: x.name,
                      value: x.id.toString(),
                    }))
                  : []
              }
              placeholder={
                selectsData.profesions.length ? "Seleccione" : "Cargando..."
              }
              visibility_name="partner_profesion_visibility"
              onVisibilityChange={(value) => {
                handleVisibiltyChange("partner_profesion_id", value);
              }}
              visibilityValue={`partner_profesion_visibility_${
                stepFourData.partner_profesion_id?.visibility ?? "all"
              }`}
              defaultSelectedKeys={
                isEditing && stepFourData.partner_profesion_id?.value
                  ? [stepFourData.partner_profesion_id?.value]
                  : []
              }
              isInvalid={errors.partner_profesion_id}
              errorMessage={"Este campo es requerido"}
            />
          </div>
          <div className="text-start">
            <CustomSelect
              title="Mi horóscopo"
              required
              name="horoscopo_id"
              visibilityAll
              onChange={handleChangeSelect}
              value={stepFourData.horoscopo_id}
              items={
                selectsData.horoscope.length
                  ? selectsData.horoscope.map((x) => ({
                      label: x.name,
                      value: x.id.toString(),
                    }))
                  : []
              }
              placeholder={
                selectsData.horoscope.length ? "Seleccione" : "Cargando..."
              }
              defaultSelectedKeys={
                isEditing && stepFourData.horoscopo_id
                  ? [stepFourData.horoscopo_id]
                  : []
              }
              isInvalid={errors.horoscopo_id}
              errorMessage={"Este campo es requerido"}
            />
          </div>
          <div className="text-start" hidden={participants == 2 ? false : true}>
            <CustomSelect
              title="Horóscopo de mi pareja"
              required={participants == 2}
              name="partner_horoscopo_id"
              visibilityAll
              onChange={handleChangeSelect}
              value={stepFourData.partner_horoscopo_id?.value}
              items={
                selectsData.horoscope.length
                  ? selectsData.horoscope.map((x) => ({
                      label: x.name,
                      value: x.id.toString(),
                    }))
                  : []
              }
              placeholder={
                selectsData.horoscope.length ? "Seleccione" : "Cargando..."
              }
              defaultSelectedKeys={
                isEditing && stepFourData.partner_horoscopo_id?.value
                  ? [stepFourData.partner_horoscopo_id?.value]
                  : []
              }
              isInvalid={errors.partner_horoscopo_id}
              errorMessage={"Este campo es requerido"}
            />
          </div>

          <div
            className="text-start"
            hidden={
              participants == 2 && stepTwoData.partner_sex?.value == "f"
                ? false
                : true
            }
          >
            <CustomRadioGroup
              title="Tamaño del pecho de mi pareja"
              name="partner_nipple_size"
              visibility_name="partner_nipple_size_visibility"
              onVisibilityChange={(value) => {
                handleVisibiltyChange("partner_nipple_size", value);
              }}
              visibilityValue={`partner_nipple_size_visibility_${
                stepFourData.partner_nipple_size?.visibility ?? "all"
              }`}
              onChange={handleChangeInput}
              value={stepFourData.partner_nipple_size?.value}
              required={
                participants == 2 && stepTwoData.partner_sex?.value == "f"
              }
              items={nippleSizes}
              isInvalid={errors.partner_nipple_size}
              errorMessage={"Este campo es requerido"}
            />
          </div>
          <div className="text-start" hidden={stepTwoData.sex?.value == "f"}>
            <CustomInput
              placeholder="Introduce la medida"
              label="Medida de miembro (en cm)"
              name="member_measurement"
              required={stepTwoData.sex?.value == "m"}
              onChange={handleChangeInput}
              value={stepFourData.member_measurement?.value}
              visibility_name="member_measurement_visibility"
              visibilityValue={`member_measurement_visibility_${
                stepFourData.member_measurement?.visibility ?? "all"
              }`}
              onVisibilityChange={(value) => {
                handleVisibiltyChange("member_measurement", value);
              }}
              showVisibility
              isInvalid={errors.member_measurement}
              errorMessage={"Este campo es requerido"}
            />
          </div>
          <div
            className="text-start"
            hidden={
              participants == 2 && stepTwoData.partner_sex?.value == "m"
                ? false
                : true
            }
          >
            <CustomInput
              placeholder="Introduce la medida"
              label="Medida de miembro de mi pareja (en cm)"
              name="partner_member_measurement"
              required={
                !!(participants == 2 && stepTwoData.partner_sex?.value == "m")
              }
              onChange={handleChangeInput}
              value={stepFourData.partner_member_measurement?.value}
              visibility_name="partner_member_measurement_visibility"
              visibilityValue={`partner_member_measurement_visibility_${
                stepFourData.partner_member_measurement?.visibility ?? "all"
              }`}
              onVisibilityChange={(value) => {
                handleVisibiltyChange("partner_member_measurement", value);
              }}
              showVisibility
              isInvalid={errors.partner_member_measurement}
              errorMessage={"Este campo es requerido"}
            />
          </div>
          <div className="text-start" hidden={stepTwoData.sex?.value == "m"}>
            <CustomRadioGroup
              title="Tamaño de mi pecho"
              name="nipple_size"
              visibility_name="nipple_size_visibility"
              onVisibilityChange={(value) => {
                handleVisibiltyChange("nipple_size", value);
              }}
              visibilityValue={`nipple_size_visibility_${
                stepFourData.nipple_size?.visibility ?? "all"
              }`}
              onChange={handleChangeInput}
              value={stepFourData.nipple_size?.value}
              required={stepTwoData.sex?.value == "f"}
              items={nippleSizes}
              isInvalid={errors.nipple_size}
              errorMessage={"Este campo es requerido"}
            />
          </div>
          <div className="text-start">
            <CustomRadioGroup
              visibilityAll
              title="Capacidad para alojar gente"
              name="possibility_of_hosting"
              value={stepFourData.possibility_of_hosting.value}
              onChange={handleChangeInput}
              required
              items={possibilitiesOfHosting.map((el) => ({
                label: el.name,
                value: el.id.toString(),
              }))}
              isInvalid={errors.possibility_of_hosting}
              errorMessage={"Este campo es requerido"}
            />
          </div>
          <div className="text-start">
            <CustomRadioGroup
              title="Capacidad para desplazarme"
              name="possibility_of_moving"
              visibilityAll
              value={stepFourData.possibility_of_moving.value}
              onChange={handleChangeInput}
              required
              items={possibilitiesOfMoving.map((el) => ({
                label: el.name,
                value: el.id.toString(),
              }))}
              isInvalid={errors.possibility_of_moving}
              errorMessage={"Este campo es requerido"}
            />
          </div>
        </div>
      </div>
      <div className="mt-12 flex justify-center gap-4">
        {isEditing && (
          <>
            <PrimaryButton
              type="submit"
              disabled={loading}
              className="w-full bg-white border-primary border-1 text-primary"
            >
              Volver
            </PrimaryButton>
          </>
        )}
        <PrimaryButton loading={loading} type="submit" className="w-full">
          {isEditing ? "Guardar" : "Completar registro"}
        </PrimaryButton>
      </div>
    </form>
  );
}
