/* eslint-disable no-mixed-spaces-and-tabs */
import { Card, Pagination, Skeleton } from "@nextui-org/react";
// import SecondaryButton from "../common/SecondaryButton";
import { Fantasy } from "../../interfaces/Fantasy";
import FantasyCard from "./FantasyCard";
import { useEffect, useMemo } from "react";
import { useAuthStore } from "../../stores/authStore";
import { getInfoPayload, useRegisterStore } from "../../stores/registerData";
import { saveUserInfo } from "../../api/info";
import { toast } from "react-toastify";

interface PaginationData {
	current_page: number;
	last_page: number;
	per_page: number;
	total: number;
}

interface FantasiesListProps {
	isLoading: boolean;
	fantasies: Fantasy[];
	pagination?: PaginationData;
	onChange?: (page: number) => void;
}

export default function FantasiesList({ isLoading, fantasies, pagination, onChange }: FantasiesListProps) {
	const authData = useAuthStore((state) => state.user);
	const setAuthData = useAuthStore((state) => state.saveUser);
	// all steps data
	const setStepTwoData = useRegisterStore((state) => state.setStepTwoData);
	const stepTwoData = useRegisterStore((state) => state.stepTwoData);
	const setStepThreeData = useRegisterStore((state) => state.setStepThreeData);
	const setStepFourData = useRegisterStore((state) => state.setStepFourData);

	useEffect(() => {
		if (authData?.info?.detalles) {
			const details = {
				...authData?.info?.detalles,
				is_couple: authData.info.is_couple,
				fantasias_id: authData.info.fantasias_id,
				provincia_id: authData.info.provincia_id,
			};
			setStepTwoData(details);
		}
		if (authData?.info?.como_soy) setStepThreeData(authData?.info?.como_soy);

		if (authData?.info?.otros) {
			const others = {
				...authData?.info?.otros,
				idiomas_id: authData?.info?.idiomas_id?.toString(),
				horoscopo_id: authData?.info?.horoscopo_id?.toString(),
				profesion_id: authData?.info?.profesion_id?.toString(),
				partner_idioma_id: authData?.info?.otros?.partner_idioma_id,
			};
			setStepFourData(others);
		}
	}, []);

	const userFantasies = useMemo(() => {
		console.log(authData?.info);
		if (fantasies && authData?.info?.fantasias_id)
			return fantasies.filter((e) => authData?.info?.fantasias_id.split(",").includes(e.id.toString()));
		return [];
	}, [fantasies, authData, isLoading]);

	const handleLike = async (id: number) => {
		const arr = authData?.info?.fantasias_id?.split(",");

		if (arr?.includes(id.toString())) {
			arr.filter((e) => e != id.toString());
			const details = {
				...stepTwoData,
				fantasias_id: arr.filter((it) => it != id.toString()).join(","),
			};
			setStepTwoData(details);
			console.log(arr);
		} else if (arr) {
			arr.push(id.toString());
			const details = {
				...stepTwoData,
				fantasias_id: arr.join(","),
			};
			setStepTwoData(details);
		}
		const data = getInfoPayload(authData?.info?.is_couple ? 1 : 0, authData?.id);
		const response = await saveUserInfo(data);
		if (response.error == "true") {
			toast.error(response.info.message);
		} else {
			//toast.success("Las fantasias  correctamente");
			setAuthData(response.info.data[0]);
		}
	};

	return (
		<>
			<div className="mt-10 w-full max-w-8xl px-4 md:px-14">
				<section className="w-full flex">
					<div className=" mt-2 flex flex-col w-full">
						{/* <div className="w-full">
              <SecondaryButton>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="#888888"
                    d="M10 18v-2h4v2zm-4-5v-2h12v2zM3 8V6h18v2z"
                  />
                </svg>
                Filtros
              </SecondaryButton>
            </div> */}
						<div className="w-full gap-4 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 pt-3 ">
							{isLoading
								? [0, 0, 0, 0, 0, 0, 0, 0].map((_, i) => (
										<Card
											className="w-full space-y-5 p-4 shadow-none border-1 border-gray-100"
											radius="lg"
											key={i}
										>
											<Skeleton className="rounded-lg">
												<div className="h-24 rounded-lg bg-default-300"></div>
											</Skeleton>
											<div className="space-y-3">
												<Skeleton className="w-3/5 rounded-lg">
													<div className="h-3 w-3/5 rounded-lg bg-default-200"></div>
												</Skeleton>
												<Skeleton className="w-4/5 rounded-lg">
													<div className="h-3 w-4/5 rounded-lg bg-default-200"></div>
												</Skeleton>
												<Skeleton className="w-2/5 rounded-lg">
													<div className="h-3 w-2/5 rounded-lg bg-default-300"></div>
												</Skeleton>
											</div>
										</Card>
								  ))
								: fantasies.map((fantasy) => (
										<FantasyCard
											fantasy={fantasy}
											key={fantasy.id}
											isChecked={!!userFantasies.find((e) => e.id == fantasy.id)}
											handleLike={() => handleLike(fantasy.id)}
										/>
								  ))}
						</div>
					</div>
				</section>
			</div>
			{pagination && onChange && (
				<div className="px-4 md:px-14 mt-16 w-full max-w-8xl">
					<div className="flex sm:justify-center">
						<Pagination
							showControls
							total={pagination.last_page}
							page={pagination.current_page}
							onChange={onChange}
						/>
					</div>
				</div>
			)}
		</>
	);
}
