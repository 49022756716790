import { create } from "zustand";

interface PartyState {
  invite: boolean;
  setInvite: (val: boolean) => void;
}

export const usePartyStore = create<PartyState>()((set) => ({
  invite: false,
  setInvite: (val) => set(() => ({ invite: val })),
}));
