import { SVGProps } from "react";

export function CheckIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect x="0.5" y="1" width="19" height="19" rx="9.5" fill="#A24BFD" />
      <rect x="0.5" y="1" width="19" height="19" rx="9.5" stroke="#A24BFD" />
      <path
        d="M14.6668 7L8.25016 13.4167L5.3335 10.5"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function UnCheckedIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect x="1" y="0.5" width="15" height="15" rx="7.5" fill="white" />
      <rect x="1" y="0.5" width="15" height="15" rx="7.5" stroke="#D0D5DD" />
    </svg>
  );
}
