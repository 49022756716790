/* eslint-disable no-mixed-spaces-and-tabs */
import { Avatar, Skeleton, Tooltip } from "@nextui-org/react";
import { Link } from "react-router-dom";
import { User } from "../../interfaces/Login";

export default function LastVisits({
	lastVisits = [],
	title = "Últimas Visitas al Perfil",
	isLoading = false,
}: {
	lastVisits: User[];
	title?: string;
	isLoading?: boolean;
}) {
	return (
		<section className="border-1 mb-4 border-gray-300 w-full  rounded-2xl">
			<div className="p-4">
				<h3 className="font-bold text-[16px] text-black">{title}</h3>
			</div>
			<hr className="border-0.5 border-gray-300"></hr>
			<div className="p-5 px-4 flex flex-col gap-4">
				<div className="grid grid-cols-4 gap-3 ">
					{isLoading
						? [0, 0, 0, 0].map((_, i) => (
								<Skeleton key={i} className="size-[46px] 2xl:size-[50px] rounded-full" />
						  ))
						: lastVisits.map((e) => (
								<Link to={`/perfil/${e?.slug}`} key={e.id}>
									<Tooltip content={e.name} placement="left">
										<Avatar
											showFallback
											className="size-[46px] 2xl:size-[50px]"
											classNames={{
												base: "bg-zinc-100",
												icon: "text-black/50",
												name: "text-black/50 capitalize font-bold text-[15px]",
											}}
											src={
												e.profile_path
													? e.profile_path.startsWith("http")
														? e.profile_path
														: import.meta.env.VITE_BASE_URL + e.profile_path
													: undefined
											}
											name={e.name}
										/>
									</Tooltip>
									<div
										className={
											"relative -top-3.5 -right-[33px] w-3 h-3 rounded-full  " +
											(e.status == "disconnected" ? "" : "border-2 border-white bg-green-500")
										}
									></div>
								</Link>
						  ))}
				</div>
				{!isLoading && lastVisits.length == 0 && "No hay usuarios que mostrar"}
			</div>
		</section>
	);
}
