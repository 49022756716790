import { AxiosError } from "axios";
import { Api } from ".";
import axiosClient from "../utils/axios";

export async function getHome() {
	try {
		return (await axiosClient.get(Api.mine.home)).data;
	} catch (error) {
		return (error as AxiosError).response?.data;
	}
}

export async function createSubscription(data: any) {
	try {
		return (await axiosClient.post(Api.mine.subscription, data)).data;
	} catch (error) {
		return (error as AxiosError).response?.data;
	}
}

export async function searchAll(name: string) {
	try {
		return (await axiosClient.get(`${Api.mine.search}?name=${name}`)).data;
	} catch (error) {
		return (error as AxiosError).response?.data;
	}
}

export async function getCustomerPortalLink() {
	try {
		return (await axiosClient.get(`${Api.mine.customerPortal}`)).data;
	} catch (error) {
		return (error as AxiosError).response?.data;
	}
}
