import { AxiosError } from "axios";
import { Api } from ".";
import axiosClient from "../utils/axios";

export async function login(data: { email: string; password: string }) {
  try {
    const response = (await axiosClient.post(Api.auth.login, data)).data;

    console.log("info", response);
    if (response.info?.data?.user?.info[0])
      response.info.data.user.info = {
        ...response.info?.data?.user?.info[0],
      };
    else response.info.data.user.info = undefined;

    if (response.info?.data?.user?.info?.como_soy) {
      response.info.data.user.info.como_soy = JSON.parse(
        response.info?.data?.user?.info?.como_soy
      );
    }
    if (response.info?.data?.user?.info?.detalles) {
      response.info.data.user.info.detalles = JSON.parse(
        response.info?.data?.user?.info?.detalles
      );
    }
    if (response.info?.data?.user?.info?.otros) {
      response.info.data.user.info.otros = JSON.parse(
        response.info?.data?.user?.info?.otros
      );
    }
    return response;
  } catch (error) {
    return (error as AxiosError).response?.data;
  }
}

export async function register(data: {
  email: string;
  password: string;
  username: string;
  role_id: string;
}) {
  try {
    const response = (await axiosClient.post(Api.auth.register, data)).data;
    if (response.info?.data?.user?.info[0])
      response.info.data.user.info = {
        ...response.info?.data?.user?.info[0],
      };
    else response.info.data.user.info = undefined;

    console.log("info", response.info.data.user.info);

    if (response.info?.data?.user?.info?.como_soy) {
      response.info.data.user.info.como_soy = JSON.parse(
        response.info?.data?.user?.info?.como_soy
      );
    }
    if (response.info?.data?.user?.info?.detalles) {
      response.info.data.user.info.detalles = JSON.parse(
        response.info?.data?.user?.info?.detalles
      );
    }
    if (response.info?.data?.user?.info?.otros) {
      response.info.data.user.info.otros = JSON.parse(
        response.info?.data?.user?.info?.otros
      );
    }
    return response;
  } catch (error) {
    return (error as AxiosError).response?.data;
  }
}

export async function verifyCode(data: { code: string }) {
  try {
    return (await axiosClient.post(Api.auth.verifyCode, data)).data;
  } catch (error) {
    return (error as AxiosError).response?.data;
  }
}

export async function resendCode() {
  try {
    return (await axiosClient.post(Api.auth.resendCode, {})).data;
  } catch (error) {
    return (error as AxiosError).response?.data;
  }
}

export async function resetPassword(data: { email: string; code?: string }) {
  try {
    return (await axiosClient.post(Api.auth.resetPass, data)).data;
  } catch (error) {
    return (error as AxiosError).response?.data;
  }
}

export async function updateUser(id: number, data: any) {
  try {
    return (await axiosClient.put(Api.user.getUser + `/${id}`, data)).data;
  } catch (error) {
    return (error as AxiosError).response?.data;
  }
}
