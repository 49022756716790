import { Modal, ModalContent, Skeleton } from "@nextui-org/react";

export default function LoadingPostModal({
  isOpen = false,
}: {
  isOpen: boolean;
  closeModal?: () => void;
}) {
  return (
    <Modal
      className="max-w-[700px] p-6"
      isOpen={isOpen}
      placement="center"
      hideCloseButton
      classNames={{ closeButton: "mt-4 mr-4", base: "rounded-xl border-none" }}
    >
      <ModalContent>
        <div className="p-6 flex gap-2 items-center">
          <div>
            <Skeleton className="flex rounded-full w-12 h-12" />
          </div>
          <div className="w-full flex flex-col gap-2">
            <Skeleton className="h-3 w-3/5 rounded-lg" />
            <Skeleton className="h-3 w-4/5 rounded-lg" />
          </div>
        </div>
        <Skeleton className="rounded-lg">
          <div className="h-32 rounded-lg bg-secondary"></div>
        </Skeleton>
        <div className="space-y-3 mt-4">
          <Skeleton className="w-3/5 rounded-lg">
            <div className="h-3 w-full rounded-lg bg-secondary"></div>
          </Skeleton>
          <Skeleton className="w-4/5 rounded-lg">
            <div className="h-3 w-full rounded-lg bg-secondary-300"></div>
          </Skeleton>
          <Skeleton className="w-2/5 rounded-lg">
            <div className="h-3 w-full rounded-lg bg-secondary-200"></div>
          </Skeleton>
          <Skeleton className="w-4/5 rounded-lg">
            <div className="h-3 w-full rounded-lg bg-secondary-300"></div>
          </Skeleton>
        </div>
      </ModalContent>
    </Modal>
  );
}
