import { Skeleton } from "@nextui-org/react";

export default function PostSkeleton() {
  return (
    <div className="p-5 w-full md:p-10 bg-white border border-slate-100 rounded-3xl mb-10">
      <div className=" flex gap-2 items-center">
        <div>
          <Skeleton className="flex rounded-full w-12 h-12" />
        </div>
        <div className="w-full flex flex-col gap-2">
          <Skeleton className="h-3 w-3/5 rounded-lg" />
          <Skeleton className="h-3 w-4/5 rounded-lg" />
        </div>
      </div>
      <Skeleton className="rounded-lg mt-2">
        <div className="h-52 rounded-lg bg-secondary"></div>
      </Skeleton>

      <div className="mt-4 flex justify-between items-end px-3">
        <div className="flex">
          <Skeleton className="flex rounded-full w-10 h-10 border-2 border-white" />
          <Skeleton className=" -ml-3 flex rounded-full w-10 h-10 border-2 border-white" />
        </div>
        <Skeleton className="h-6 w-28 rounded-xl" />
      </div>
      <div className="flex justify-between gap-x-4 mt-4">
        <Skeleton className="h-8 w-full rounded-full" />
        <Skeleton className="h-8 w-full rounded-full" />
        <Skeleton className="h-8 w-full rounded-full" />
      </div>
    </div>
  );
}
