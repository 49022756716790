import { Avatar, Card, CardBody, CardFooter, Image } from "@nextui-org/react";
import PrimaryButton from "../common/PrimaryButton";
import { Link } from "react-router-dom";
import { BlockedData } from "../../interfaces/Login";
import { useState } from "react";
import { deleteBlockUser } from "../../api/friends";
import { toast } from "react-toastify";

export default function BloquedUserCard({
	user,
	onSuccessDelete,
}: {
	user: BlockedData;
	onSuccessDelete: (id: number) => void;
}) {
	const [isLoading, setIsLoading] = useState(false);

	const onUnlockUser = async () => {
		setIsLoading(true);

		const response = await deleteBlockUser(user.id);
		console.log("response?.info", response?.info);
		if (response.error == "true" || !response.info) toast.error(response?.info?.message || "Ha ocurrido un error");
		else {
			toast.success("Usuerio desbloqueado con exito");
			onSuccessDelete(user.id);
		}
		setIsLoading(false);
	};
	return (
		<Card shadow="sm" key={user.id} radius="md" className="w-full flexflex-grow items-start h-full">
			<CardBody className="overflow-visible p-0">
				<div className="">
					<Image
						shadow="none"
						radius="md"
						width="100%"
						alt="alt"
						className="w-full object-cover h-[140px]"
						fallbackSrc="/assets/fantasyImage.jpeg"
						src={
							user?.to.portada_path
								? user?.to.portada_path?.startsWith("http")
									? user?.to.portada_path
									: import.meta.env.VITE_BASE_URL + user?.to.portada_path
								: undefined
						}
					/>
					<div className="w-full flex justify-center">
						<Link to={`/profile/${user.id}`} key={user.id}>
							<Avatar
								src={
									user?.to.profile_path
										? user?.to.profile_path?.startsWith("http")
											? user?.to.profile_path
											: import.meta.env.VITE_BASE_URL + user?.to.profile_path
										: undefined
								}
								showFallback
								classNames={{
									base: "bg-zinc-100",
									icon: "text-black/50",
								}}
								className="-mt-[40px] z-20 w-20 h-20 border-3 border-white"
							/>
						</Link>
					</div>
				</div>

				<div className="text-small flex flex-col items-start justify-start px-3">
					<b className="hover:text-primary text-xl">
						<Link to={`/profile/${user.id}`} key={user.id}>
							{user.to.name}
						</Link>
					</b>

					{/* <p className="font-semibold text-color2 mb-2">
            {user.to.email.slice(0, 26)}
            {user.to.email.length > 26 ? "..." : ""}
          </p> */}
				</div>
			</CardBody>
			<CardFooter className="text-small flex flex-col items-start justify-start">
				<PrimaryButton loading={isLoading} onClick={onUnlockUser} className="w-full">
					Desbloquear
				</PrimaryButton>
			</CardFooter>
		</Card>
	);
}
