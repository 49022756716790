import React from "react";

interface TruncatedTextProps {
	text: string;
	maxCharsPerLine?: number; // Por defecto 30
	maxLines?: number; // Por defecto 3 líneas
	onVerMas: () => void;
}

/**
 * Función que recibe un texto y lo separa en líneas sin romper palabras, tratando de
 * que cada línea tenga hasta `maxCharsPerLine` caracteres.
 */
const splitTextIntoLines = (text: string, maxCharsPerLine: number): string[] => {
	const words = text.split(" ");
	const lines: string[] = [];
	let currentLine = "";

	for (const word of words) {
		// Si agregar la palabra al currentLine no excede el límite:
		if ((currentLine.length === 0 ? word.length : currentLine.length + 1 + word.length) <= maxCharsPerLine) {
			currentLine += (currentLine.length === 0 ? "" : " ") + word;
		} else {
			// Si ya hay contenido en la línea, la guardamos y comenzamos una nueva
			if (currentLine) {
				lines.push(currentLine);
			}
			// Si la palabra en sí es muy larga y excede el límite,
			// la separamos en pedazos.
			if (word.length > maxCharsPerLine) {
				let start = 0;
				while (start < word.length) {
					const part = word.slice(start, start + maxCharsPerLine);
					lines.push(part);
					start += maxCharsPerLine;
				}
				currentLine = "";
			} else {
				currentLine = word;
			}
		}
	}
	if (currentLine) {
		lines.push(currentLine);
	}
	return lines;
};

const TruncatedText: React.FC<TruncatedTextProps> = ({ text, maxCharsPerLine = 30, maxLines = 3, onVerMas }) => {
	// Separamos el texto en líneas según el límite de caracteres
	const allLines = splitTextIntoLines(text, maxCharsPerLine);
	const isTruncated = allLines.length > maxLines;
	// Si hay más líneas de las permitidas, mostramos sólo las primeras maxLines
	const linesToShow = isTruncated ? allLines.slice(0, maxLines) : allLines;

	return (
		<div className="text-sm w-full">
			<p>
				{linesToShow.map((line, index) => (
					<span key={index}>
						{line}
						{/* Insertamos salto de línea salvo en la última línea mostrada */}
						{index < linesToShow.length - 1 && <br />}
					</span>
				))}
				{isTruncated && " ..."}
			</p>
			{isTruncated && (
				<button onClick={onVerMas} className="mt-1 text-xs text-blue-500 hover:underline">
					Ver más
				</button>
			)}
		</div>
	);
};

export default TruncatedText;
