import { useState } from "react";
import { Post } from "../../interfaces/Post";
import CommentPostModal from "../post/CommentPostModal";
import { PlayVideoSmallIcon } from "../../assets/icons/VideoIcon";

export default function LastVideos({ videos = [] }: { videos: Post[] }) {
	const [post, setPost] = useState<Post | null>(null);
	const [isOpen, setIsOpen] = useState(false);
	const handleClick = (post: Post) => {
		setPost(post);
		setIsOpen(true);
	};

	return (
		<section className="mt-4 border-1 border-gray-300 w-full  rounded-2xl">
			<div className="p-4">
				<h3 className="font-bold text-[16px] text-black">Últimos Vídeos</h3>
			</div>
			<hr className="border-0.5 border-gray-300"></hr>
			<div className="p-4 x-4 flex flex-col  items-start gap-4">
				{videos.length > 0 && (
					<div className="grid grid-cols-4 gap-3 ">
						{videos.map((e) => (
							<div
								onClick={() => handleClick(e)}
								key={e.id}
								style={{
									backgroundColor: "#ececee",
									backgroundImage: ` url(${
										e.content.startsWith("http")
											? e.content.replace("content", "frame.jpg")
											: import.meta.env.VITE_BASE_URL + e.content.replace("content", "frame.jpg")
									})`,
								}}
								className="w-[46px] h-[46px] flex items-center justify-center 2xl:w-[52px] 2xl:h-[52px] rounded-xl bg-cover bg-center cursor-pointer"
							>
								<PlayVideoSmallIcon />
							</div>
						))}
					</div>
				)}
				{videos.length == 0 && "No hay publicaciones"}
			</div>
			{post != null && (
				<CommentPostModal
					showComments={true}
					isOpen={isOpen}
					closeModal={() => {
						setIsOpen(false);
						setPost(null);
					}}
					post={post}
				/>
			)}
		</section>
	);
}
