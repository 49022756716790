import { Modal, ModalContent } from "@nextui-org/react";
import SecondaryButton from "../common/SecondaryButton";
import PrimaryButton from "../common/PrimaryButton";
import { useState } from "react";
import { toast } from "react-toastify";
import { blockUser, deleteBlockUser } from "../../api/friends";
import { CloseIcon } from "../../assets/icons/CloseIcon";

export default function BlockUserModal({
  isOpen = false,
  closeModal = () => {},
  name = "",
  id = 0,
  onSuccessDelete,
  type = "lock",
}: {
  isOpen: boolean;
  name: string;
  id: number;
  type?: string;
  closeModal?: () => void;
  onSuccessDelete: (id: number) => void;
}) {
  const [isLoading, setIsLoading] = useState(false);

  const onBlockUser = async () => {
    setIsLoading(true);
    if (type == "lock") {
      const response = await blockUser({
        user_id: id,
      });
      //console.log("response?.info", response?.info);
      if (response.error == "true" || !response.info)
        toast.error(response?.info?.message || "Ha ocurrido un error");
      else {
        toast.success("Usuerio bloqueado con exito");
        onSuccessDelete(response.info?.data[0]?.id);
        closeModal();
      }
    } else {
      const response = await deleteBlockUser(id);
      // console.log("response?.info", response?.info);
      if (response.error == "true" || !response.info)
        toast.error(response?.info?.message || "Ha ocurrido un error");
      else {
        toast.success("Usuerio desbloqueado con exito");
        onSuccessDelete(id);
        closeModal();
      }
    }
    setIsLoading(false);
  };
  return (
    <Modal
      className="max-w-[400px]"
      isOpen={isOpen}
      onClose={closeModal}
      placement="center"
      classNames={{ closeButton: "mt-4 mr-4", base: "rounded-xl border-none" }}
      closeButton={<CloseIcon />}
    >
      <ModalContent>
        <div className="p-6">
          <div className="flex justify-center">
            <div className="text-start">
              <div>
                <svg
                  width="57"
                  height="57"
                  viewBox="0 0 57 57"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    x="4.5"
                    y="4.5"
                    width="48"
                    height="48"
                    rx="24"
                    fill="#FEE4E2"
                  />
                  <path
                    d="M33 32.5L38 37.5M38 32.5L33 37.5M28.5 32H24C22.6044 32 21.9067 32 21.3389 32.1722C20.0605 32.56 19.06 33.5605 18.6722 34.8389C18.5 35.4067 18.5 36.1044 18.5 37.5M31 24C31 26.4853 28.9853 28.5 26.5 28.5C24.0147 28.5 22 26.4853 22 24C22 21.5147 24.0147 19.5 26.5 19.5C28.9853 19.5 31 21.5147 31 24Z"
                    stroke="#D92D20"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
              <h3 className="mt-4 font-semibold text-color3 text-[18px]">
                ¿Estas seguro de que quieres{" "}
                {type == "lock" ? "bloquear" : "desbloquear"} a {name}?
              </h3>
              <p className="mt-1 font-normal text-[14px] text-color5">
                {type == "lock"
                  ? `No podrás volver a contactar con él/ella a no ser que vuelvas a
                desbloquearlo.`
                  : "Ahora podrás volver a contactar con él/ella."}
              </p>
            </div>
          </div>
          <div className="mt-8 flex justify-center gap-[12px]">
            <SecondaryButton
              className="text-color4 w-full"
              onClick={closeModal}
              disabled={isLoading}
            >
              Cancelar
            </SecondaryButton>
            <PrimaryButton
              onClick={onBlockUser}
              className={` w-full ${
                type == "lock" ? "bg-color10" : "bg-primary"
              }`}
              loading={isLoading}
            >
              {type == "lock" ? "Bloquear usuario" : "Desbloquear"}
            </PrimaryButton>
          </div>
        </div>
      </ModalContent>
    </Modal>
  );
}
