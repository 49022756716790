import { useEffect, useState } from "react";
import StepIcon from "../../assets/icons/StepIcon";
import StepOkIcon from "../../assets/icons/StepOkIcon";
import { useStepperStore } from "../../stores/stepperStore";

export default function Stepper({
  step = 0,
  disabledSteps = [],
}: {
  step?: number;
  disabledSteps?: number[];
}) {
  const [state, setState] = useState([0, 0, 0]);

  const setStep = useStepperStore((state) => state.setStep);

  useEffect(() => {
    if (step == 0) setState([0, 0, 0]);
    if (step == 1) setState([1, 0, 0]);
    if (step == 2) setState([1, 1, 0]);
  }, [step]);
  return (
    <div className="mt-6">
      <div className="mx-4">
        <div className="flex items-center">
          <button
            className="flex items-center relative justify-center disabled:opacity-50"
            disabled={disabledSteps?.includes(0)}
            onClick={() => setStep(2)}
          >
            {state[0] == 0 ? <StepIcon /> : <StepOkIcon />}
            <h3
              className={
                "absolute text-center mt-[84px] w-20 text-[14px] font-semibold " +
                (step == 0 ? "text-color4" : "text-color2")
              }
            >
              Detalles del perfil
            </h3>
          </button>
          <hr
            className={
              "flex-auto border-t-2 transition duration-500 ease-in-out " +
              (state[0] == 1 ? " border-color4" : " border-gray-300")
            }
          />
          <button
            className="flex items-center relative justify-center disabled:opacity-50"
            onClick={() => setStep(3)}
            disabled={disabledSteps?.includes(1)}
          >
            {state[1] == 0 ? <StepIcon /> : <StepOkIcon />}
            <h3
              className={
                "absolute text-center mt-[84px] w-20 text-[14px] font-semibold " +
                (step == 1 ? "text-color4" : "text-color2")
              }
            >
              Como soy
            </h3>
          </button>
          <hr
            className={
              "flex-auto border-t-2 transition duration-500 ease-in-out " +
              (state[1] == 1 ? " border-color4" : " border-gray-300")
            }
          />
          <button
            className="flex items-center relative justify-center disabled:opacity-50"
            onClick={() => setStep(4)}
            disabled={disabledSteps?.includes(2)}
          >
            {state[2] == 0 ? <StepIcon /> : <StepOkIcon />}
            <h3
              className={
                "absolute text-center mt-[84px] w-20 text-[14px] font-semibold " +
                (step == 2 ? "text-color4" : "text-color2")
              }
            >
              Otros datos
            </h3>
          </button>
        </div>
      </div>
    </div>
  );
}
