import { Autocomplete, AutocompleteItem, Spinner } from "@nextui-org/react";
import { Country } from "../../interfaces/Country";
import { useEffect, useState } from "react";

export default function NationalityAutocomplete({
  label,
  placeholder,
  selectedKey,
  isDisabled,
  nationalities,
  isRequired = false,
  onSelectionChange,
  isInvalid = false,
  errorMessage = "Este campo es requerido",
}: {
  label: string;
  placeholder: string;
  selectedKey: string | number | null;
  isDisabled: boolean;
  nationalities: Country[];
  isRequired?: boolean;
  errorMessage?: string;
  isInvalid?: boolean;
  onSelectionChange: (value: string | number | null) => void;
}) {
  const [isValid, setIsValid] = useState(!isInvalid);

  const handleChange = (value: string | number | null) => {
    if (isRequired && !value) {
      setIsValid(false);
    } else setIsValid(true);
    onSelectionChange(value);
  };

  useEffect(() => {
    // console.log(isInvalid);
    setIsValid(!isInvalid);
  }, [isInvalid]);

  return (
    <Autocomplete
      label={label}
      errorMessage={errorMessage}
      variant="bordered"
      defaultItems={nationalities.length ? nationalities : []}
      placeholder={placeholder}
      isRequired={isRequired}
      labelPlacement="outside"
      isInvalid={!isValid}
      classNames={{
        popoverContent: "rounded-md",
      }}
      inputProps={{
        classNames: {
          input: "ml-1",
          inputWrapper: "h-[48px] border-1 rounded-md",
          label:
            "text-[14px] font-medium group-data-[filled-within=true]:text-color2 z-0",
        },
      }}
      className="w-full"
      selectedKey={selectedKey}
      onSelectionChange={handleChange}
      isDisabled={isDisabled}
      startContent={
        !nationalities?.length ? (
          <Spinner size="sm" color="primary" />
        ) : undefined
      }
    >
      {(item) => (
        <AutocompleteItem key={item.cca2} textValue={item.name.common}>
          <div className="flex items-center gap-2">
            <img
              alt={item.name.common}
              src={item.flags.svg}
              className="rounded-sm w-5 "
            ></img>
            <span>{item.name.common}</span>
          </div>
        </AutocompleteItem>
      )}
    </Autocomplete>
  );
}
