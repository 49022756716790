import { useEffect, useRef, useState } from "react";
// Puedes usar un ícono de alguna librería; aquí se usa Heroicons:
import { ChevronDownIcon } from "@heroicons/react/solid";

export interface Fantasy {
  id: number;
  name: string;
  description?: string;
  status?: string;
  avatar?: string;
}

interface CustomSearchableSelectProps {
  info: Fantasy[]; // Lista de opciones
  handleChangeSelect: (selected: number[]) => void; // Callback para notificar selección
  data: { fantasias: number[] }; // Valores seleccionados
}

const CustomSearchableSelect: React.FC<CustomSearchableSelectProps> = ({
  info,
  handleChangeSelect,
  data,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [search, setSearch] = useState<string>("");
  const [selectedValues, setSelectedValues] = useState<number[]>(
    data.fantasias || []
  );
  const listRef = useRef<HTMLDivElement>(null);

  // Filtrar opciones según el término de búsqueda
  const filteredOptions = info.filter((item) =>
    item.name.toLowerCase().includes(search.toLowerCase())
  );

  const handleSelect = (id: number) => {
    // Si ya está seleccionado, se quita; si no, se agrega.
    const updatedValues = selectedValues.includes(id)
      ? selectedValues.filter((value) => value !== id)
      : [...selectedValues, id];
    setSelectedValues(updatedValues);
    handleChangeSelect(updatedValues);
  };
  useEffect(() => {
    // Handler para detectar clicks
    function handleClickOutside(event: MouseEvent) {
      if (listRef.current && !listRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    }

    // Agregar el event listener
    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup: remover el event listener
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div ref={listRef} className="w-full mt-1 relative">
      {/* Trigger del select con estilos similares a NextUI */}
      <div
        onClick={() => setIsOpen((prev) => !prev)}
        className={`w-full h-[44px] border rounded-lg flex items-center px-3 cursor-pointer bg-white ${
          isOpen ? "border-indigo-500" : "border-gray-300"
        }`}
      >
        <span
          className={`flex-1 font-sans text-small ${
            selectedValues.length === 0 ? "text-gray-500" : "text-gray-900"
          }`}
        >
          {selectedValues.length > 0
            ? selectedValues
                .map((id) => info.find((item) => item.id === id)?.name)
                .join(", ")
            : "Seleccionar..."}
        </span>
        {/* Flecha para abajo con ícono similar */}
        <ChevronDownIcon className="w-5 h-5 text-gray-500" />
      </div>

      {isOpen && (
        <div
          className="absolute z-50 w-full bg-white border rounded-lg mt-1 shadow-lg transition-all duration-300 transform"
          style={{ maxHeight: "200px", overflowY: "auto" }}
        >
          <div className="p-2">
            <input
              type="text"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              placeholder="Buscar..."
              className="w-full p-2 border rounded-lg font-sans text-base"
            />
          </div>
          <ul className="p-2">
            {filteredOptions.length > 0 ? (
              filteredOptions.map((option) => (
                <li
                  key={option.id}
                  onClick={() => handleSelect(option.id)}
                  className={`p-2 cursor-pointer rounded-lg font-sans text-base ${
                    selectedValues.includes(option.id)
                      ? "bg-primary-200 text-gray-900"
                      : "hover:bg-gray-300 hover:text-gray-600 text-gray-700"
                  }`}
                >
                  {option.name}
                </li>
              ))
            ) : (
              <li className="p-2 text-center text-gray-500 font-sans text-base">
                Sin resultados
              </li>
            )}
          </ul>
        </div>
      )}
    </div>
  );
};

export default CustomSearchableSelect;
