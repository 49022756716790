import { AxiosError } from "axios";
import { Api } from ".";
import axiosClient from "../utils/axios";

export async function getReactionTypes() {
  try {
    return (await axiosClient.get(`${Api.reactions.type}`)).data?.info
      .data[0][0];
  } catch (error) {
    return (error as AxiosError).response?.data;
  }
}
