import { AxiosError } from "axios";
import { Api } from ".";
import axiosClient from "../utils/axios";

export async function getNotifications(page?: number) {
  try {
    return (await axiosClient.get(`${Api.notification.index}?page=${page}`))
      .data;
  } catch (error) {
    return (error as AxiosError).response?.data;
  }
}

export async function deleteFromList(id: number) {
  try {
    return (await axiosClient.post(`${Api.notification.index}/notificar/${id}`))
      .data;
  } catch (error) {
    return (error as AxiosError).response?.data;
  }
}

export async function markAsReaded() {
  try {
    return (await axiosClient.post(`${Api.notification.index}/bulkVisto`)).data;
  } catch (error) {
    return (error as AxiosError).response?.data;
  }
}
export async function deleteAll() {
  try {
    return (await axiosClient.post(`${Api.notification.index}/bulkNotificar`))
      .data;
  } catch (error) {
    return (error as AxiosError).response?.data;
  }
}
