/* eslint-disable no-mixed-spaces-and-tabs */
import PrimaryButton from "../common/PrimaryButton";
import SecondaryButton from "../common/SecondaryButton";
import Stepper from "../common/Stepper";
import { useStepperStore } from "../../stores/stepperStore";
import CustomTextAreaInput from "../form/CustomTextAreaInput";
import CustomSelect from "../form/CustomSelect";
import CustomRadioGroup from "../form/CustomRadioGroup";
import { FormEvent, useEffect, useMemo, useReducer, useState } from "react";
import {
  getFantasies,
  getLookingFor,
  getProvinces,
  getSexualOrientations,
} from "../../api/formSelectsData";
import { getInfoPayload, useRegisterStore } from "../../stores/registerData";
import { Province } from "../../interfaces/Provice";
import { Fantasy } from "../../interfaces/Fantasy";
import { LookingFor } from "../../interfaces/LookingFor";
import Logo from "../common/Logo";
import { SexualOrientation } from "../../interfaces/SexualOrientation";
import { CalendarDate, parseDate } from "@internationalized/date";
import { DateInput } from "@nextui-org/react";
import VisibilityState from "../form/VisibilityState";
import { toast } from "react-toastify";
import { useLoginStore } from "../../stores/loginDataStore";
import { useNavigate } from "react-router-dom";
import { Country } from "../../interfaces/Country";
import { getCountries } from "../../api/nationality";
import { saveUserInfo } from "../../api/info";
import { useAuthStore } from "../../stores/authStore";
import { StepTwoInitialState } from "../../stores/initialStates";
import NationalityAutocomplete from "../form/CustomAutocomplete";
import Select from "react-select";

// type VisibilityNm = "sex" | "partner_sex";

const initialMessages = {
  fantasias_id: "",
  provincia_id: "",
  birthDate: "",
  partner_birthDate: "",
  nacionalidad_id: "",
  partner_nacionalidad_id: "",
  sex: "",
  partner_sex: "",
  sexualOrentation: "",
  partner_sexualOrentation: "",
  description: "",
  partner_description: "",
  looking_id: "",
};

export default function ProfileThirdStepForm({
  isLocal = false,
  participants = 1,
  backStep,
  isEditing = false,
  disabledSteps,
}: {
  isLocal: boolean;
  isEditing?: boolean;
  participants?: number;
  backStep: () => void;
  next?: () => void;
  disabledSteps?: number[];
}) {
  const step = useStepperStore((state) => state.step);
  const setStep = useStepperStore((state) => state.setStep);

  const stepTwoData = useRegisterStore((state) => state.stepTwoData);
  const setStepTwoData = useRegisterStore((state) => state.setStepTwoData);

  const [loading, setLoading] = useState(false);

  const user = useLoginStore((state) => state.user);
  const [date, setDate] = useState(
    parseDate(stepTwoData.birthDate ?? StepTwoInitialState.birthDate)
  );
  const [partnerDate, setPartnerDate] = useState(
    parseDate(stepTwoData.partner_birthDate ?? StepTwoInitialState.birthDate)
  );
  const [country, setCountry] = useState<string | number | null>("");
  const [partnerCountry, setPartnerCountry] = useState<string | number | null>(
    ""
  );
  const [provinces, setProvinces] = useState<Province[]>([]);
  const [fantasies, setFantasies] = useState<Fantasy[]>([]);
  const [lookingFor, setLookingFor] = useState<LookingFor[]>([]);
  const [orientations, setOrientations] = useState<SexualOrientation[]>([]);
  const [nationalities, setNationalities] = useState<Country[]>([]);

  const saveUser = useAuthStore((state) => state.saveUser);
  const authData = useAuthStore((state) => state.user);
  const navigate = useNavigate();
  const [, forceUpdate] = useReducer((x) => x + 1, 0);
  const [messagesErrors, setMessagesErrors] = useState(initialMessages);

  function validateOrientationRules(
    allowedOrientations: string | undefined,
    sex: string | undefined,
    orientation: string | undefined,
    partnerSex: string | undefined,
    partnerOrientation: string | undefined,
    participants: number
  ): boolean {
    if (!allowedOrientations) return false;
    const allowedValues = allowedOrientations.split(","); // ["m-2","f-2","c-2",...]

    // Validación para individuo (1 persona)
    if (participants === 1 && sex && orientation) {
      // "m-2" si es hombre heterosexual, "f-4" si es mujer homosexual, etc.
      const singleCode = `${sex}-${orientation}`;
      return allowedValues.includes(singleCode);
    }

    // couple case:
    if (
      participants === 2 &&
      sex &&
      partnerSex &&
      orientation &&
      partnerOrientation
    ) {
     
      // Both are hetero "2" => c-2
      if (orientation === "2" && partnerOrientation === "2") {
        return allowedValues.includes("c-2");
      }
      // both are bi "3" => c-3
      else if (orientation === "3" && partnerOrientation === "3") {
        return allowedValues.includes("c-3");
      }
      // both are homo "4" => c-?-4
      else if (orientation === "4" && partnerOrientation === "4") {
        return allowedValues.includes(`c-${sex}-4`);
      }
      else { // one is bi
        const noBiSex = orientation != "3" ? sex : partnerSex;
        const noBiOrientation = orientation != "3" ? orientation : partnerOrientation;
        const coupleCode = `${noBiSex}-${noBiOrientation}`;
        console.log(coupleCode, allowedValues);
        return allowedValues.includes(coupleCode);
      }
    }

    return false;
  }

  const validateOrientation = (
    orientation: string | undefined,
    sex: string | undefined,
    partnerSex: string | undefined,
    participants: number
  ) => {
    if (!orientation) {
      return "Este campo es requerido";
    }

    // Mapa de orientaciones permitidas
    const allowedValues = new Map([
      ["f-2", "Mujer heterosexual"],
      ["m-2", "Hombre heterosexual"],
      ["c-2", "Pareja heterosexual"],
      ["c-3", "Pareja bisexual"], // Opcional, si aplica
      // Agrega otras combinaciones permitidas aquí
    ]);

    const isAllowed = (code: string) => allowedValues.has(code);

    // Validación para parejas
    if (participants === 2 && sex && partnerSex) {
      const pairCode = `c-2`; // Código para parejas heterosexuales

      if (!isAllowed(pairCode)) {
        return "Selecciona una orientación sexual válida para parejas heterosexuales.";
      }
    }

    // // Validación para individuos
    // if (participants === 1 && sex) {
    //   const singleCode = `${sex}-${orientation}`;

    //   if (!isAllowed(singleCode)) {
    //     return "Selecciona una orientación sexual válida para tu sexo.";
    //   }
    // }

    return ""; // Validación exitosa
  };

  const filteredLookingForOptions = useMemo(() => {
    const mySex = stepTwoData.sex?.value;
    const myOrientation = stepTwoData.sexualOrentation?.value;
    const partnerSex = stepTwoData.partner_sex?.value;
    const partnerOrientation = stepTwoData.partner_sexualOrentation?.value;

    return lookingFor
      .filter((item) => {
        return validateOrientationRules(
          item.allowed_sex_orientation,
          mySex,
          myOrientation,
          partnerSex,
          partnerOrientation,
          participants
        );
      })
      .map((item) => ({
        label: item.name,
        value: item.id.toString(),
      }));
  }, [
    lookingFor,
    stepTwoData.sex?.value,
    stepTwoData.sexualOrentation?.value,
    stepTwoData.partner_sex?.value,
    stepTwoData.partner_sexualOrentation?.value,
    participants,
  ]);

  const selectedOptions = useMemo(() => {
    if (!stepTwoData.looking_id) return [];
    const splittedIds = stepTwoData.looking_id.split(",");
    // De las opciones filtradas, tomamos solo las que coincidan
    return filteredLookingForOptions.filter((option) =>
      splittedIds.includes(option.value)
    );
  }, [stepTwoData.looking_id, filteredLookingForOptions]);

  const validate = () => {
    let isValid = true;
    let finalErrors = initialMessages;

    const arr = Object.keys(initialMessages);

    arr.forEach((name) => {
      switch (name) {
        case "fantasias_id":
        case "provincia_id":
        case "birthDate":
        case "nacionalidad_id":
          if (!stepTwoData[name]) {
            isValid = false;
            finalErrors = {
              ...finalErrors,
              [name]: "Este campo es requerido",
            };
          }
          break;

        case "looking_id": {
          const lookingIdValue = stepTwoData["looking_id"] ?? ""; // Garantiza un string
          if (!lookingIdValue) {
            isValid = false;
            finalErrors = {
              ...finalErrors,
              [name]: "Este campo es requerido",
            };
          } else {
            const mess = isValidLookingFor(lookingIdValue);
            if (mess.length) {
              isValid = false;
              finalErrors = {
                ...finalErrors,
                [name]: mess,
              };
            }
          }
          break;
        }

        case "sexualOrentation": {
          const error = validateOrientation(
            stepTwoData["sexualOrentation"]?.value,
            stepTwoData["sex"]?.value,
            stepTwoData["partner_sex"]?.value,
            participants
          );
          if (error) {
            isValid = false;
            finalErrors = {
              ...finalErrors,
              [name]: error,
            };
          }
          break;
        }

        case "partner_sexualOrentation": {
          // Solo validamos si es pareja
          if (participants === 2) {
            const error = validateOrientation(
              stepTwoData["partner_sexualOrentation"]?.value,
              stepTwoData["sex"]?.value,
              stepTwoData["partner_sex"]?.value,
              participants
            );
            if (error) {
              isValid = false;
              finalErrors = {
                ...finalErrors,
                [name]: error,
              };
            }
          }
          break;
        }

        case "sex":
        case "description":
          if (!stepTwoData[name]?.value) {
            isValid = false;
            finalErrors = {
              ...finalErrors,
              [name]: "Este campo es requerido",
            };
          }
          break;

        case "partner_birthDate":
        case "partner_nacionalidad_id":
          if (!stepTwoData[name] && participants === 2) {
            isValid = false;
            finalErrors = {
              ...finalErrors,
              [name]: "Este campo es requerido",
            };
          }
          break;

        case "partner_sex":
        case "partner_description":
          if (!stepTwoData[name]?.value && participants === 2) {
            isValid = false;
            finalErrors = { ...finalErrors, [name]: "Este campo es requerido" };
          }
          break;
      }
    });

    setMessagesErrors(finalErrors);
    forceUpdate();
    return isValid;
  };

  const isValidLookingFor = (value: string) => {
    if (!value) return "Este campo es requerido";

    // Usamos EXACTAMENTE la misma lógica que "filteredLookingForOptions" usa:
    const splitted = value.split(",");

    // Recorremos cada id que el usuario eligió
    for (const id of splitted) {
      const item = lookingFor.find((l) => l.id.toString() === id);
      // Si no existe en la data, error
      if (!item) {
        return "El valor seleccionado no es válido.";
      }
      // Volvemos a ejecutar la MISMA validación
      const pasaFiltro = validateOrientationRules(
        item.allowed_sex_orientation,
        stepTwoData.sex?.value,
        stepTwoData.sexualOrentation?.value,
        stepTwoData.partner_sex?.value,
        stepTwoData.partner_sexualOrentation?.value,
        participants
      );
      if (!pasaFiltro) {
        return "Una o más opciones no son válidas para la combinación de la pareja.";
      }
    }
    return ""; // Todos fueron válidos
  };

  useEffect(() => {
    const getData = async () => {
      if (step == 2) {
        const fantasies = await getFantasies();
        setFantasies(fantasies.info.data[0]);
        const lookingFor = await getLookingFor();
        setLookingFor(lookingFor.info.data[0]);
        const provinces = await getProvinces();
        setProvinces(provinces.info.data[0]);
        const orientations = await getSexualOrientations();
        setOrientations(orientations?.info?.data[0]);
        const countries = await getCountries();
        setNationalities(countries);

        if (isEditing) {
          if (stepTwoData.nacionalidad_id)
            setCountry(stepTwoData.nacionalidad_id);
          if (stepTwoData.partner_nacionalidad_id)
            setPartnerCountry(stepTwoData.partner_nacionalidad_id);
        }
      }
    };
    getData();
  }, [step]);

  const setFormData = (name: string, value: string | null) => {
    switch (name) {
      case "fantasias_id":
      case "provincia_id":
      case "birthDate":
      case "partner_birthDate":
      case "nacionalidad_id":
      case "partner_nacionalidad_id":
        setStepTwoData({
          ...stepTwoData,
          [name]: value,
        });
        break;
      case "sex":
      case "partner_sex":
      case "sexualOrentation":
      case "partner_sexualOrentation":
      case "description":
      case "partner_description":
        setStepTwoData({
          ...stepTwoData,
          [name]: {
            value: value,
            visibility: stepTwoData[name]?.visibility ?? "all",
          },
        });
        break;
      case "looking_id":
        setStepTwoData({
          ...stepTwoData,
          [name]: value ?? undefined,
        });
        break;
      default:
        break;
    }
  };
  const handleChangeSelect = (e: FormEvent<HTMLSelectElement>) => {
    const data = e.target as HTMLSelectElement;
    setFormData(data.name, data.value);
  };

  const handleChangeInput = (e: FormEvent<HTMLInputElement>) => {
    const data = e.target as HTMLInputElement;
    setFormData(data.name, data.value);
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    console.log("Paso a validar. stepTwoData:", stepTwoData);
    const isValid = validate();
    console.log("Result isValid:", isValid);
    console.log("Messages errors:", messagesErrors);

    forceUpdate();
    if (!isValid) return;
    const submitter = (e.nativeEvent as SubmitEvent).submitter?.innerText;

    if (!user && !isEditing) {
      toast.error("Debes autenticarte primero");
      navigate("/login");
    }
    // console.log(stepTwoData);
    if (submitter == "Guardar") await onSaveEditData();
    else setStep(step + 1);
  };

  useEffect(() => {
    try {
      setFormData("birthDate", date.toString());
    } catch (error) {
      console.error(error);
      setFormData("birthDate", null);
    }
  }, [date]);

  useEffect(() => {
    try {
      setFormData("partner_birthDate", partnerDate.toString());
    } catch (error) {
      console.error(error);
      setFormData("partner_birthDate", null);
    }
  }, [partnerDate]);

  // save nationallity
  useEffect(() => {
    if (country) {
      setFormData("nacionalidad_id", country.toString());
    } else setFormData("nacionalidad_id", "");
    //console.log(stepTwoData.fantasias_id.split(","));
  }, [country]);

  useEffect(() => {
    if (partnerCountry) {
      setFormData("partner_nacionalidad_id", partnerCountry.toString());
    } else setFormData("partner_nacionalidad_id", "");
  }, [partnerCountry]);

  const onSaveEditData = async () => {
    setLoading(true);
    const data = getInfoPayload(participants - 1, authData?.id);
    const response = await saveUserInfo(data);
    if (response?.error == "true" || !response) {
      toast.error("Ha ocurrido un error");
    } else {
      toast.success("Los datos se guardaron correctamente");
      saveUser(response.info.data[0]);
    }
    setLoading(false);
  };

  const birthdateError = () => {
    if (messagesErrors.birthDate.length) {
      return StepTwoInitialState.birthDate &&
        parseDate(StepTwoInitialState.birthDate) < date
        ? "Debe ser mayor de edad"
        : "Este campo es requerido";
    }
  };
  const partnerBirthdateError = () => {
    if (messagesErrors.partner_birthDate.length) {
      return StepTwoInitialState.partner_birthDate &&
        parseDate(StepTwoInitialState.partner_birthDate) < partnerDate
        ? "Debe ser mayor de edad"
        : "Este campo es requerido";
    }
  };
  const defaultFantasies = useMemo(() => {
    const res = fantasies
      .filter((x) =>
        stepTwoData.fantasias_id.split(",").includes(x.id.toString())
      )
      .map((x) => ({
        label: x.name,
        value: x.id.toString(),
      }));
    return res;
  }, [fantasies, stepTwoData.fantasias_id]);

  const isSOInvalid = useMemo(() => {
    return messagesErrors.sexualOrentation.length > 0;
  }, [messagesErrors.sexualOrentation]);

  const isPSOInvalid = useMemo(() => {
    return messagesErrors.partner_sexualOrentation.length > 0;
  }, [messagesErrors.partner_sexualOrentation]);

  return (
    <div
      hidden={!isLocal && step == 2 ? false : true}
      className={` m-6 pb-10 text-center items-center  xl:overflow-hidden xl:overflow-y-auto xl:p-5 ${
        isEditing ? "w-full lg:max-w-[90%]" : "max-w-[768px] max-h-[90vh]"
      }`}
    >
      <div
        hidden={isEditing}
        className="md:absolute mb-8 md:mb-0 justify-center top-[27px] left-[26px]"
      >
        <SecondaryButton
          onClick={backStep}
          variant="solid"
          className="bg-white z-10"
        >
          Volver atrás
        </SecondaryButton>
      </div>
      {!isEditing && (
        <div className="flex justify-center">
          <Logo />
        </div>
      )}
      <Stepper step={step - 2} disabledSteps={disabledSteps} />
      <h1 className="mt-14 font-semibold text-[30px] ">
        {participants == 1 ? "Yo solo/a" : "En pareja"}
      </h1>
      <h3 className="mt-2 font-normal text-color1 text-[16px]">
        {participants == 1
          ? "¡Muy bien! Ahora rellena tu información."
          : "¡Muy bien! Ahora rellenad la información de cada uno de vosotros."}
      </h3>
      <form className="w-full" onSubmit={handleSubmit} id="1">
        <div className="mt-8 w-full flex justify-center">
          <div className="grid w-full grid-cols-1 md:grid-cols-2 gap-10">
            {!isEditing && (
              <>
                <div className="text-start w-full">
                  <CustomRadioGroup
                    visibilityAll
                    value={stepTwoData.sex.value}
                    onChange={handleChangeInput}
                    name="sex"
                    visibility_name="sex_visibility"
                    title="Mi sexo"
                    required
                    items={[
                      { label: "Hombre", value: "m" },
                      { label: "Mujer", value: "f" },
                    ]}
                    isInvalid={messagesErrors.sex.length > 0}
                    errorMessage={
                      messagesErrors.sex.length
                        ? messagesErrors.sex
                        : "Este campo es requerido"
                    }
                  />
                </div>
                <div
                  className="text-start w-full"
                  hidden={participants == 2 ? false : true}
                >
                  <CustomRadioGroup
                    visibilityAll
                    name="partner_sex"
                    visibility_name="partner_sex_visibility"
                    value={stepTwoData.partner_sex?.value}
                    onChange={handleChangeInput}
                    title="Sexo de mi pareja"
                    required={participants == 2}
                    items={[
                      { label: "Hombre", value: "m" },
                      { label: "Mujer", value: "f" },
                    ]}
                    isInvalid={messagesErrors.partner_sex.length > 0}
                    errorMessage={
                      messagesErrors.partner_sex.length
                        ? messagesErrors.partner_sex
                        : "Este campo es requerido"
                    }
                  />
                </div>
                <div className="text-start">
                  <CustomRadioGroup
                    visibilityAll
                    name="sexualOrentation"
                    title="Mi orientación sexual"
                    required
                    value={stepTwoData.sexualOrentation.value}
                    onChange={handleChangeInput}
                    items={
                      orientations?.length
                        ? orientations.map((it) => ({
                            label: it.name,
                            value: it.id.toString(),
                          }))
                        : []
                    }
                    isInvalid={isSOInvalid}
                    errorMessage={
                      messagesErrors.sexualOrentation.length
                        ? messagesErrors.sexualOrentation
                        : "Este campo es requerido"
                    }
                  ></CustomRadioGroup>
                </div>
                <div
                  className="text-start w-full"
                  hidden={participants == 2 ? false : true}
                >
                  <CustomRadioGroup
                    name="partner_sexualOrentation"
                    visibilityAll
                    title="Orientacion sexual de mi pareja"
                    required={participants == 2}
                    value={stepTwoData.partner_sexualOrentation?.value}
                    onChange={handleChangeInput}
                    items={
                      orientations?.length
                        ? orientations.map((it) => ({
                            label: it.name,
                            value: it.id.toString(),
                          }))
                        : []
                    }
                    isInvalid={isPSOInvalid}
                    errorMessage={
                      messagesErrors.partner_sexualOrentation.length
                        ? messagesErrors.partner_sexualOrentation
                        : "Este campo es requerido"
                    }
                  />
                </div>
              </>
            )}
            <div className="text-start w-full profile-control">
              <label className="text-[14px] font-medium text-color2 group-data-[filled-within=true]:text-color2">
                ¿Qué busco?
                <span className="text-red-500">*</span>
              </label>
              {lookingFor?.length ? (
                <Select
                  isMulti
                  name="looking_id"
                  placeholder="Seleccione"
                  className="mt-1"
                  classNamePrefix="select"
                  options={filteredLookingForOptions} // Solo las compatibles
                  value={selectedOptions} // Las ya seleccionadas
                  onChange={(selectedArr) => {
                    // Cuando cambia, reconstruimos el string con los IDs
                    const newValue = selectedArr
                      .map((opt) => opt.value)
                      .join(",");
                    setFormData("looking_id", newValue);
                  }}
                />
              ) : (
                <div className="text-[12px] text-color2 h-[44px] w-full px-4 py-2 rounded-sm border border-gray-100">
                  Cargando...
                </div>
              )}

              {messagesErrors.looking_id.length > 0 && (
                <span className="text-[12px] text-danger-400">
                  {messagesErrors.looking_id.length
                    ? messagesErrors.looking_id
                    : "Este campo es requerido"}
                </span>
              )}
              <VisibilityState visibilityAll />
            </div>

            <div className="text-start w-full profile-control">
              <label className="text-[14px] font-medium text-color2 group-data-[filled-within=true]:text-color2">
                {participants == 2 ? "Nuestras fantasías" : "Mis fantasías"}
                <span className="text-red-500">*</span>
              </label>
              {fantasies?.length ? (
                <Select
                  isMulti
                  name="fantasias_id"
                  options={fantasies.map((x) => ({
                    label: x.name,
                    value: x.id.toString(),
                  }))}
                  placeholder={"Seleccione"}
                  className=" mt-1"
                  classNamePrefix="select"
                  required
                  defaultValue={defaultFantasies}
                  onChange={(value) => {
                    const newValue = value
                      .map((e) => e.value.toString())
                      .join(",");
                    setFormData("fantasias_id", newValue);
                  }}
                />
              ) : (
                <div className="text-[12px] text-color2 h-[44px] w-full px-4 py-2 rounded-sm border border-gray-100">
                  Cargando...
                </div>
              )}

              {messagesErrors.fantasias_id.length > 0 && (
                <span className="text-[12px] text-danger-400">
                  {messagesErrors.fantasias_id.length
                    ? messagesErrors.fantasias_id
                    : "Este campo es requerido"}
                </span>
              )}
              <VisibilityState visibilityAll />
            </div>
            <div className="text-start w-full">
              <DateInput
                label="Mi fecha de nacimiento"
                labelPlacement="outside"
                className="w-full"
                radius="sm"
                variant="bordered"
                isRequired
                value={date}
                onChange={setDate}
                defaultValue={parseDate(StepTwoInitialState.birthDate)}
                maxValue={parseDate(StepTwoInitialState.birthDate)}
                placeholderValue={new CalendarDate(1995, 11, 6)}
                isInvalid={
                  messagesErrors.birthDate.length > 0 ||
                  (StepTwoInitialState.birthDate != undefined &&
                    parseDate(StepTwoInitialState.birthDate) < date)
                }
                errorMessage={birthdateError()}
              />
              <div>
                <VisibilityState
                  visibilityAll={true}
                  visibility_name="birthdate_visibility"
                />
              </div>
            </div>
            <div
              className="text-start"
              hidden={participants == 2 ? false : true}
            >
              <DateInput
                name="partner_birthDate"
                isRequired={participants === 2}
                label="Fecha de nacimiento de mi pareja"
                radius="sm"
                labelPlacement="outside"
                className="w-full group-data-[filled-within=true]:text-color2 rounded-sm"
                variant="bordered"
                value={partnerDate}
                onChange={setPartnerDate}
                defaultValue={parseDate(StepTwoInitialState.birthDate)}
                maxValue={parseDate(StepTwoInitialState.birthDate)}
                placeholderValue={new CalendarDate(1995, 11, 6)}
                isInvalid={
                  messagesErrors.partner_birthDate.length > 0 ||
                  (StepTwoInitialState.partner_birthDate != undefined &&
                    parseDate(StepTwoInitialState.partner_birthDate) <
                      partnerDate)
                }
                errorMessage={partnerBirthdateError()}
              />
              <div>
                <VisibilityState
                  visibilityAll={true}
                  visibility_name="partner_birthdate_visibility"
                />
              </div>
            </div>
            <div className="text-start">
              <div>
                <NationalityAutocomplete
                  label="Mi nacionalidad"
                  placeholder={
                    nationalities.length ? "Seleccione" : "Cargando..."
                  }
                  isRequired
                  selectedKey={country}
                  onSelectionChange={setCountry}
                  isDisabled={!nationalities?.length}
                  nationalities={nationalities}
                  isInvalid={messagesErrors.nacionalidad_id.length > 0}
                  errorMessage={
                    messagesErrors.nacionalidad_id.length
                      ? messagesErrors.nacionalidad_id
                      : "Este campo es requerido"
                  }
                />
                <VisibilityState visibilityAll />
              </div>
            </div>

            <div hidden={participants != 2} className="text-start">
              <div>
                <NationalityAutocomplete
                  label="Nacionalidad de mi pareja"
                  placeholder={
                    nationalities.length ? "Seleccione" : "Cargando..."
                  }
                  isRequired
                  selectedKey={partnerCountry}
                  onSelectionChange={setPartnerCountry}
                  isDisabled={!nationalities?.length}
                  nationalities={nationalities}
                  isInvalid={messagesErrors.partner_nacionalidad_id.length > 0}
                  errorMessage={
                    messagesErrors.partner_nacionalidad_id.length
                      ? messagesErrors.partner_nacionalidad_id
                      : "Este campo es requerido"
                  }
                />
                <VisibilityState visibilityAll />
              </div>
            </div>
            <div className="text-start">
              <CustomSelect
                name="provincia_id"
                visibilityAll
                title={participants == 2 ? "Nuestra provincia" : "Mi provincia"}
                required
                items={
                  provinces?.length
                    ? provinces.map((x) => ({
                        label: x.name,
                        value: x.id.toString(),
                      }))
                    : []
                }
                placeholder={provinces?.length ? "Seleccione" : "Cargando..."}
                onChange={handleChangeSelect}
                value={stepTwoData.provincia_id.toString()}
                defaultSelectedKeys={
                  isEditing && stepTwoData.provincia_id
                    ? [stepTwoData.provincia_id.toString()]
                    : []
                }
                isInvalid={messagesErrors.provincia_id.length > 0}
                errorMessage={
                  messagesErrors.provincia_id.length
                    ? messagesErrors.provincia_id
                    : "Este campo es requerido"
                }
              />
            </div>
          </div>
        </div>
        <div className="mt-10">
          <div className="text-start">
            <CustomTextAreaInput
              id="description"
              name="description"
              visibilityAll
              title="Mi descripción"
              required
              isInvalid={messagesErrors.description.length > 0}
              errorMessage={
                messagesErrors.description.length
                  ? messagesErrors.description
                  : "Este campo es requerido"
              }
              onChange={handleChangeInput}
              value={stepTwoData.description.value}
            />
          </div>
          <div
            className="text-start mt-4"
            hidden={participants == 2 ? false : true}
          >
            <CustomTextAreaInput
              required={participants == 2}
              id="partner_description"
              onChange={handleChangeInput}
              value={stepTwoData.partner_description?.value}
              visibilityAll
              isInvalid={messagesErrors.partner_description.length > 0}
              errorMessage={
                messagesErrors.partner_description.length
                  ? messagesErrors.partner_description
                  : "Este campo es requerido"
              }
              name="partner_description"
              title="Descripción de mi pareja"
            />
          </div>
        </div>
        <div className="mt-12 flex justify-center gap-4">
          {isEditing ? (
            <>
              <PrimaryButton
                loading={loading}
                // onClick={onSaveEditData}
                type="submit"
                className="w-full "
              >
                Guardar
              </PrimaryButton>
              <PrimaryButton
                disabled={loading}
                type="submit"
                className="w-full bg-white border-primary border-1 text-primary"
              >
                Continuar
              </PrimaryButton>
            </>
          ) : (
            <PrimaryButton loading={loading} type="submit" className="w-full">
              Continuar
            </PrimaryButton>
          )}
        </div>
      </form>
    </div>
  );
}
