import { SVGProps } from "react";

export function PartyIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.5 3.33268V1.66602M11.5 13.3327V11.666M5.66667 7.49935H7.33333M15.6667 7.49935H17.3333M13.8333 9.83268L14.8333 10.8327M13.8333 5.16602L14.8333 4.16602M1.5 17.4993L9 9.99935M9.16667 5.16602L8.16667 4.16602"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
