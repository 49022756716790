import { AxiosError } from "axios";
import { Api } from ".";
import axiosClient from "../utils/axios";

const addFilters = (data: any) => {
  let query = "";
  if (data.name) query += `&name=${data.name}`;
  if (data.role_id) query += `&role_id=${data.role_id}`;
  if (data.fantasias_id) query += `&fantasias_id=${data.fantasias_id}`;
  if (data.edad_from) query += `&edad_from=${data.edad_from}`;
  if (data.edad_to) query += `&edad_to=${data.edad_to}`;
  if (data.provincias_id) query += `&provincias_id=${data.provincias_id}`;
  if (data.complexions_id) query += `&complexions_id=${data.complexions_id}`;
  if (data.races_id) query += `&races_id=${data.races_id}`;
  if (data.eye_colors_id) query += `&eye_colors_id=${data.eye_colors_id}`;
  if (data.skin_colors_id) query += `&skin_colors_id=${data.skin_colors_id}`;
  if (data.hair_colors_id) query += `&hair_colors_id=${data.hair_colors_id}`;
  if (data.hair_length) query += `&hair_length=${data.hair_length}`;
  if (data.lips) query += `&lips=${data.lips}`;
  if (data.piercings) query += `&piercings=${data.piercings}`;
  if (data.tattoos) query += `&tattoos=${data.tattoos}`;
  if (data.you_drink) query += `&you_drink=${data.you_drink}`;
  if (data.you_smoke) query += `&you_smoke=${data.you_smoke}`;
  if (data.type_of_nose) query += `&type_of_nose=${data.type_of_nose}`;
  if (data.children) query += `&children=${data.children}`;
  if (data.horoscopo_id) query += `&horoscopo_id=${data.horoscopo_id}`;
  if (data.profesion_id) query += `&profesion_id=${data.profesion_id}`;
  if (data.idiomas_id) query += `&idiomas_id=${data.idiomas_id}`;
  if (data.status) query += `&status=${data.status}`;
  if (data.friends) query += `&friends=${data.friends}`;
  if (data.friendRequest) query += `&friendRequest=${data.friendRequest}`;
  if (data.limit) query += `&limit=${data.limit}`;
  else query += `&limit=8`;
  if (data.sexualOrientation)
    query += `&sexualOrentation=${data.sexualOrientation}`;
  if (data.sex) query += `&sex=${data.sex}`;
  if (data.sex && !data.couple) query += `&is_couple=0`;
  if (data.couple && !data.sex) query += `&is_couple=1`;
  if (data.nacionalidades_id)
    query += `&nacionalidades_id=${data.nacionalidades_id}`;
  if (data.vistas) query += `&vistas=${data.vistas}`;

  return query;
};

//TODO: clean this with a mapperglobal
export async function getUserSlug(slug: string) {
  try {
    const response = (await axiosClient.get(`${Api.user.getUser}/${slug}`, {}))
      .data;
    if (response.info?.data[0]?.info[0])
      response.info.data[0].info = {
        ...response.info?.data[0]?.info[0],
      };

    if (response.info?.data[0]?.info?.como_soy) {
      response.info.data[0].info.como_soy = JSON.parse(
        response.info.data[0]?.info?.como_soy
      );
    }
    if (response.info?.data[0]?.info?.detalles) {
      response.info.data[0].info.detalles = JSON.parse(
        response.info?.data[0]?.info?.detalles
      );
    }
    if (response.info?.data[0]?.info?.otros) {
      response.info.data[0].info.otros = JSON.parse(
        response.info?.data[0]?.info?.otros
      );
    }
    return response;
  } catch (error) {
    return (error as AxiosError).response?.data;
  }
}
export async function getUser(id: number) {
  try {
    const response = (await axiosClient.get(`${Api.user.getUser}/${id}`, {}))
      .data;
    if (response.info?.data[0]?.info[0])
      response.info.data[0].info = {
        ...response.info?.data[0]?.info[0],
      };

    if (response.info?.data[0]?.info?.como_soy) {
      response.info.data[0].info.como_soy = JSON.parse(
        response.info.data[0]?.info?.como_soy
      );
    }
    if (response.info?.data[0]?.info?.detalles) {
      response.info.data[0].info.detalles = JSON.parse(
        response.info?.data[0]?.info?.detalles
      );
    }
    if (response.info?.data[0]?.info?.otros) {
      response.info.data[0].info.otros = JSON.parse(
        response.info?.data[0]?.info?.otros
      );
    }
    return response;
  } catch (error) {
    return (error as AxiosError).response?.data;
  }
}
export async function getGlobal(id: number) {
  try {
    const response = (
      await axiosClient.get(`${Api.user.getUser}/global/${id}`, {})
    ).data;
    let data = {
      friends: 0,
      visits: 0,
      reactions: 0,
    };
    const user = response?.info?.data[0];
    console.log(user.likesCount, "likesCount");
    if (user) {
      data = {
        friends: user.amigos,
        visits: user.visitas,
        reactions: user.likesCount,
      };
    }
    return data;
  } catch (error) {
    return (error as AxiosError).response?.data;
  }
}
export async function getUsers(page: number, data?: any) {
  try {
    let query = "";
    if (data) query = addFilters(data);
    console.log("QUERY", query);
    const response = (
      await axiosClient.get(
        `${Api.user.getUser}?verified=1&banned=0&page=${page}${query}`
      )
    ).data;
    if (response?.info?.data[0]?.data) {
      response.info.data[0].data = (response?.info?.data[0]?.data as any[]).map(
        (it) => {
          return {
            //TODO: clean this with a mapper
            ...it,
            info: {
              ...it.info[0],
              como_soy: it.info[0]?.como_soy
                ? JSON.parse(it.info[0]?.como_soy.toString())
                : null,
              detalles: it.info[0]?.detalles
                ? JSON.parse(it.info[0]?.detalles.toString())
                : null,
              otros: it.info[0]?.otros
                ? JSON.parse(it.info[0]?.otros.toString())
                : null,
            },
          };
        }
      );
    }

    return response;
  } catch (error) {
    return (error as AxiosError).response?.data;
  }
}

export async function reportUser(data: any) {
  try {
    const response = (await axiosClient.post(`${Api.user.report}`, data)).data;
    return response;
  } catch (error) {
    return (error as AxiosError).response?.data;
  }
}

export async function likeUser(id: number) {
  try {
    const response = (await axiosClient.post(`${Api.user.getUser}/${id}/like`))
      .data;
    return response;
  } catch (error) {
    return (error as AxiosError).response?.data;
  }
}
