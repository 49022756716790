import NavBar from "../components/common/Navbar";
import Footer from "../components/common/Footer";
import { FormEvent, useEffect, useRef, useState } from "react";
import { Fantasy } from "../interfaces/Fantasy";
import { getFantasiesPersonal } from "../api/formSelectsData";
import FantasiesList from "../components/fantasy/FantasiesList";
import { Input } from "@nextui-org/react";
import { SearchIcon } from "../assets/icons/SearchIcon";
import { useNavigate } from "react-router-dom"; // Importa useNavigate
import Select, { SingleValue } from "react-select";

type OptionType = {
  value: number;
  label: string;
};

export default function Fantasies() {
  const [info, setInfo] = useState({
    fantasies: [] as Fantasy[],
  });
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState("");
  const container = useRef(null);
  // Estados para la paginación
  const [pagination, setPagination] = useState({
    current_page: 1,
    last_page: 1,
    per_page: 8,
    total: 0,
  });

  const navigate = useNavigate(); // Inicializa useNavigate

  const handleChange = (e: FormEvent<HTMLInputElement>) => {
    const data = e.target as HTMLInputElement;
    setSearch(data.value);
    // Opcional: resetear la página a 1 cuando cambie la búsqueda
    setPagination((prev) => ({
      ...prev,
      current_page: 1,
    }));
  };

  // Función para traer los datos de fantasías
  const getData = async (searchTerm: string, page: number) => {
    setIsLoading(true);

    // Ajusta tu función para que soporte "page": getFantasiesPersonal(searchTerm, page)
    const res = await getFantasiesPersonal(searchTerm, page);

    if (res?.info?.data?.[0]) {
      const pageData = res.info.data[0];

      // Actualizamos la lista de fantasías en 'info'
      setInfo({
        fantasies: pageData.data ?? [],
      });

      // Actualizamos la info de paginación (current_page, last_page, etc.)
      setPagination({
        current_page: pageData.current_page,
        last_page: pageData.last_page,
        per_page: pageData.per_page,
        total: pageData.total,
      });
    }

    setIsLoading(false);
  };

  //Sin delay
  // useEffect(() => {
  // 	getData(search);
  // 	// eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [search]);

  // Llamamos a getData con "debounce" de 300ms cuando cambie la búsqueda o la página
  useEffect(() => {
    const delay = setTimeout(() => {
      getData(search, pagination.current_page);
    }, 300);

    return () => clearTimeout(delay);
  }, [search, pagination.current_page]);

  // En móvil, si selecciona una fantasía del dropdown, navegamos a su detalle
  const handleSelectChange = (selectedOption: SingleValue<OptionType>) => {
    if (selectedOption?.value) {
      navigate(`/fantasia/${selectedOption.value}`);
    }
  };

  // Cuando el usuario cambie de página (usando el componente de paginación), actualizamos el estado
  const handlePageChange = (newPage: number) => {
    if (container.current) {
      (container.current as HTMLElement).scrollIntoView({
        behavior: "instant",
        block: "start",
      });
    }

    setPagination((prev) => ({
      ...prev,
      current_page: newPage,
    }));
  };

  return (
    <div className="h-screen flex flex-col overflow-y-auto">
      <NavBar />
      <div></div>

      <main className="flex-1 w-full  pt-10 md:pt-18  flex flex-col items-center">
        <div className="px-4 md:px-14 w-full max-w-8xl">
          <section className="w-full ">
            <div className="mt-2 flex flex-col items-start">
              <h1 className="text-2xl font-semibold">Fantasías</h1>
              <p>
                Aquí encontrarás las fantasías que tienen planeados los usuarios
                Loouth. Publica tus propias fantasías y vive experiencias cerca
                de tí.
              </p>

              {/* Búsqueda escritorio */}
              <div className="w-full mt-6 hidden md:block">
                <Input
                  className="min-w-sm text-input w-full"
                  type="text"
                  variant="bordered"
                  placeholder="Buscar..."
                  value={search}
                  onChange={handleChange}
                  startContent={<SearchIcon />}
                  classNames={{
                    inputWrapper: "border-1 rounded-lg",
                  }}
                />
              </div>

              {/* Búsqueda móvil con react-select */}
              <div className="w-full mt-6 md:hidden">
                <Select
                  isLoading={isLoading}
                  isSearchable
                  placeholder="Buscar y seleccionar fantasía..."
                  options={info.fantasies.map((fantasy) => ({
                    label: fantasy.name,
                    value: fantasy.id,
                  }))}
                  onChange={handleSelectChange}
                  className="w-full"
                  classNamePrefix="select"
                  menuPortalTarget={document.body}
                  styles={{
                    menuPortal: (base) => ({
                      ...base,
                      zIndex: 9999,
                    }),
                  }}
                />
              </div>
            </div>
          </section>
        </div>

        {/* Lista de fantasías */}
        <FantasiesList
          isLoading={isLoading}
          fantasies={info.fantasies}
          pagination={pagination}
          onChange={handlePageChange}
        />
      </main>

      <Footer />
    </div>
  );
}
