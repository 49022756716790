import { Avatar, AvatarGroup, Chip, Tooltip } from "@nextui-org/react";
import { Post } from "../../interfaces/Post";
import Moment from "react-moment";
import { Link } from "react-router-dom";
import { FormEvent, ReactNode, useMemo, useState } from "react";
import { useAuthStore } from "../../stores/authStore";
import {
  addPostReaction,
  deleteHighlightPost,
  deletePostSaved,
  highlightPost,
  savePost,
} from "../../api/publication";
import CommentPostModal from "./CommentPostModal";
import { SaveIcon } from "../../assets/icons/SaveIcon";
import { CommentIcon } from "../../assets/icons/CommentIcon";
import { HeartIcon } from "../../assets/icons/HeartIcon";
import LightBoxImage from "../common/LightBoxImage";
import { useHomeStore } from "../../stores/homeData";
import { HighlightIcon } from "../../assets/icons/HighlightIcon";
import videojs from "video.js";
import ReactionsModal from "./ReactionsModal";

export default function PostCard({
  minimumVersion = false,
  className = "",
  post,
  fantasy,
  slot,
  slotDescription,
  onUnsave,
}: {
  minimumVersion?: boolean;
  className?: string;
  post: Post;
  fantasy?: string;
  slot?: ReactNode;
  slotDescription?: ReactNode;
  onUnsave?: () => void;
}) {
  const [ckeck, setCheck] = useState(post.info.myReacted);
  const [saved, setSaved] = useState(post.info.isSaved);
  const [highlighted, setHighlighted] = useState(post.info.isDestacado);
  const [newLike, setNewLike] = useState(false);
  const [showCommentArea, setShowCommentArea] = useState(false);
  const [showComments, setShowComments] = useState(false);
  const [isOpenLightbox, setIsOpenLightbox] = useState({
    open: false,
    type: "image" as "image" | "video",
    poster: "",
    src: "",
  });
  const authData = useAuthStore((state) => state.user);
  const reactionType = useHomeStore((state) => state.reactionType);
  const totalLikes = post.info?.reacciones || 0;

  const handleReact = async (value: boolean) => {
    setCheck(value);
    setNewLike(value);

    if (value)
      await addPostReaction({
        publicacion_id: post.id,
        reaccion_id: reactionType, // TODO: integrate all type of reactions
      });
    else {
      await addPostReaction({
        publicacion_id: post.id,
        reaccion_id: 0,
      });
      // console.log(res);
    }
  };

  const onCheck = async (e: FormEvent<HTMLInputElement>) => {
    const input = e.target as HTMLInputElement;
    const value = input.checked;
    //
    await handleReact(value);
  };
  const url = useMemo(() => {
    if (!post.hasMedia) return "";
    let res = "";
    if (!post.content.startsWith("http")) res += import.meta.env.VITE_BASE_URL;

    res += post.content;
    if (post.media == "video") {
      res = res.replace("content", "frame.jpg");
      // console.log(res);
    }

    return res;
  }, [post.media]);

  const urlVideo = useMemo(() => {
    if (!post.hasMedia || post.media != "video") return "";
    let res = "";
    if (!post.content.startsWith("http")) res += import.meta.env.VITE_BASE_URL;

    res += post.content;

    return res;
  }, [post.media]);

  const lastReactions = useMemo(() => {
    if (
      !ckeck ||
      (ckeck &&
        newLike &&
        post.info?.lastReacciones?.find((it) => it.user_id == authData?.id))
    )
      return post.info.lastReacciones.filter(
        (it) => it.user_id != authData?.id
      );

    return post?.info?.lastReacciones;
  }, [ckeck]);
  const handleComment = () => {
    setShowCommentArea(true);
    setShowComments(true);
  };

  const firstUserName = newLike
    ? authData?.name // tu nombre
    : lastReactions?.[0]?.user?.name;

  const likesText = useMemo(() => {
    if (totalLikes === 0) return null;

    if (totalLikes === 1 && firstUserName) {
      return (
        <>
          a <span className="font-bold">{firstUserName}</span> le ha gustado la
          publicación.
        </>
      );
    } else if (totalLikes > 1 && firstUserName) {
      return (
        <>
          a <span className="font-bold">{firstUserName}</span> y{" "}
          <span
            onClick={() => setShowReactions(true)}
            className="font-bold text-primary cursor-pointer"
          >
            {totalLikes - 1} más{" "}
          </span>{" "}
          le ha gustado la publicación.
        </>
      );
    }

    return null;
  }, [totalLikes, firstUserName]);

  const handleSave = async () => {
    // console.log(saved);
    setSaved(!saved);
    if (!saved) {
      await savePost({
        publicacion_id: post.id,
      });
      // console.log(res);
    } else if (post.info.save) {
      await deletePostSaved(post.info.save.id);
      if (onUnsave) onUnsave();
    }
  };
  const handleHighlight = async () => {
    // console.log(saved);
    setHighlighted(!highlighted);
    if (!highlighted) {
      const res = await highlightPost(post.id);
      console.log("res", res);
    } else if (post.info.save) {
      await deleteHighlightPost(post.info.save.id);
    }
  };

  const [showReactions, setShowReactions] = useState(false);
  return (
    <article className={"flex flex-col " + className}>
      <div className="flex px-2 md:px-0 items-center gap-4 md:gap-8">
        <Link to={`/perfil/${post.user?.slug}`}>
          <Avatar
            showFallback
            classNames={{
              base: "bg-zinc-100",
              icon: "text-black/50",
            }}
            className="w-14 h-14 md:w-[68px] md:h-[68px]"
            src={
              post.user?.profile_path
                ? post.user?.profile_path.startsWith("http")
                  ? post.user?.profile_path
                  : import.meta.env.VITE_BASE_URL + post.user?.profile_path
                : undefined
            }
          />
        </Link>
        <div className="flex flex-col justify-between w-full">
          <label className="font-bold text-[21px] text-back">
            <Link
              className="hover:text-primary"
              to={`/perfil/${post.user?.slug}`}
            >
              {post.user?.name}
            </Link>
          </label>
          <label className="font-bold text-[16px] text-color7">
            {post.created_at && (
              <Moment fromNow locale="Es">
                {new Date(post.created_at) ?? "1976-04-19T12:59-0500"}
              </Moment>
            )}
          </label>
        </div>
        {fantasy && post.fantasia_id && (
          <Link to={`/fantasy/${post.fantasia_id}`}>
            <Chip variant="flat" color="primary" className="mt-2 font-semibold">
              <span className="font-semibold">{fantasy}</span>
            </Chip>
          </Link>
        )}
        {slot}
        {post.user_id == authData?.id && minimumVersion && post.media && (
          <Tooltip content="Añadir a destacados" placement="top">
            <div
              onClick={handleHighlight}
              className={`flex items-center -top-4 focus:ring-transparent like ${
                highlighted ? "checked" : ""
              }`}
            >
              <div className="checkmark flex items-center justify-center">
                <HighlightIcon className="size-9 cursor-pointer" />
              </div>
            </div>
          </Tooltip>
        )}
      </div>
      <p
        hidden={post.media == null ? false : true}
        className="mt-8 font-medium px-4 md:px-0 text-[16px] text-black flex-1"
      >
        {post.content}
      </p>
      <div
        hidden={post.media == "image" || post.media == "video" ? false : true}
        className={"mt-6 relative w-full"}
      >
        {minimumVersion ? (
          <>
            <div
              className="bg-cover bg-center bg-no-repeat w-full  md:rounded-2xl overflow-hidden h-[250px] "
              style={{
                backgroundImage: `url(${url})`,
              }}
            >
              {post.blur && authData?.id != post.user_id && (
                <div className="w-full h-full backdrop-blur-lg bg-white/10">
                  <Tooltip
                    className="translate-y-44"
                    content={
                      <div className="max-w-60">
                        <p>Contenido solo para amigos.</p>
                        <p>
                          Envíale una solicitud de amistad para desbloquearlo.
                        </p>
                      </div>
                    }
                    placement="top"
                  >
                    <div className="w-full h-full"></div>
                  </Tooltip>
                </div>
              )}
            </div>
          </>
        ) : post.media == "video" ? (
          <div className=" w-full md:rounded-2xl overflow-hidden">
            <div
              style={{
                backgroundImage: ` url(${url})`,
              }}
              className="bg-cover bg-center bg-no-repeat w-full  md:rounded-2xl cursor-pointer h-full aspect-[16/9] "
            />
          </div>
        ) : (
          <div className="relative bg-black w-full h-[422px]  md:rounded-2xl overflow-hidden flex items-center justify-center">
            {/* descripción de la foto */}
            <p
              hidden={post.description == null ? false : true}
              className="absolute top-4 left-4 font-medium text-[16px] text-white"
            >
              {post.description}
            </p>

            <div
              style={{
                backgroundImage: ` url(${url})`,
              }}
              className="absolute aspect-[16/9] h-full w-full bg-cover bg-center bg-no-repeat cursor-pointer"
              onClick={() =>
                setIsOpenLightbox({
                  open: true,
                  type: "image",
                  poster: "",
                  src: url,
                })
              }
            />
            {post.blur && authData?.id != post.user_id && (
              <div className="absolute inset-0 z-10  md:rounded-2xl backdrop-blur-lg bg-white/10"></div>
            )}

            {post.blur && authData?.id != post.user_id && (
              <div className="absolute p-4 md:p-1 rounded-full z-20">
                <label className="text-white font-semibold ">
                  Debes ser amigo de {post.user.name} para poder ver el
                  contenido privado.
                </label>
              </div>
            )}
          </div>
        )}

        {post.media === "video" && (
          <div className="absolute inset-0  md:rounded-xl overflow-hidden flex items-center justify-center bg-black/20">
            <video
              id="my-video"
              className="video-js vjs-default-skin"
              controls
              preload="metadata"
              poster={url}
              ref={(node) => {
                if (node) {
                  videojs(node, {
                    autoplay: false,
                    controls: true,
                    responsive: true,
                    fluid: true,
                  });
                }
              }}
            >
              <source src={urlVideo} type="application/x-mpegURL" />
              <p className="vjs-no-js">
                Para ver este video, habilite JavaScript y considere actualizar
                a un navegador web que
                <a
                  href="https://videojs.com/html5-video-support/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  soporte video HTML5
                </a>
                .
              </p>
            </video>
          </div>
        )}
      </div>
      <div hidden={minimumVersion}>
        <div className="mt-6 px-4 md:px-0 flex justify-start	">
          <AvatarGroup max={3} className="border-none">
            {newLike && (
              <Link key={authData?.id} to={`/perfil/${authData?.slug}`}>
                <Avatar
                  showFallback
                  classNames={{
                    base: "bg-zinc-100 border-2 border-white",
                  }}
                  name={authData?.name}
                  src={
                    authData?.profile_path
                      ? authData?.profile_path.startsWith("http")
                        ? authData?.profile_path
                        : import.meta.env.VITE_BASE_URL + authData?.profile_path
                      : undefined
                  }
                />
              </Link>
            )}
            {lastReactions?.length &&
              lastReactions?.map((it) => (
                <Link key={it.user?.id} to={`/perfil/${it.user?.slug}`}>
                  <Avatar
                    showFallback
                    classNames={{
                      base: "bg-zinc-100 border-2 border-white",
                    }}
                    name={it.user?.name}
                    src={
                      it.user?.profile_path
                        ? it.user?.profile_path.startsWith("http")
                          ? it.user?.profile_path
                          : import.meta.env.VITE_BASE_URL +
                            it.user?.profile_path
                        : undefined
                    }
                  />
                </Link>
              ))}
          </AvatarGroup>
          {/* Texto de a quién le ha gustado (siempre que haya mínimo 1 like) */}
          {likesText && (
            <p className="text-sm text-gray-400 font-medium ml-2 mt-3">
              {likesText}
            </p>
          )}
        </div>
      </div>
      <hr hidden={minimumVersion} className="mt-6 border-color7"></hr>
      <div className="mt-4 px-4 md:px-0 flex items-center justify-between">
        {/* Contenedor de botones */}
        <div className="flex items-center gap-4">
          <label className="like">
            <input onChange={onCheck} checked={ckeck} type="checkbox"></input>
            <div className="checkmark flex">
              <HeartIcon height={24} width={24} />
            </div>
          </label>
          <button
            onClick={handleComment}
            className="flex items-center justify-center focus:ring-transparent"
          >
            <CommentIcon />
          </button>

          <button
            onClick={handleSave}
            className={`flex items-center justify-center focus:ring-transparent`}
          >
            <div className={`flex items-center like ${saved ? "checked" : ""}`}>
              <div className="checkmark flex items-center justify-center">
                <SaveIcon />
              </div>
            </div>
          </button>
        </div>

        {/* Indicador de comentarios */}
        <span
          onClick={handleComment}
          className="font-bold text-[16px] text-color7 ml-auto cursor-pointer"
        >
          {post.info?.comentarios} Comentarios
        </span>
      </div>

      <CommentPostModal
        showComments={showComments}
        isOpen={showCommentArea}
        closeModal={() => setShowCommentArea(false)}
        post={post}
      />
      <LightBoxImage
        src={isOpenLightbox.src}
        isOpen={isOpenLightbox.open}
        type={isOpenLightbox.type}
        poster={isOpenLightbox.poster}
        closeModal={() =>
          setIsOpenLightbox({
            open: false,
            type: "image",
            poster: "",
            src: "",
          })
        }
      />

      {slotDescription}
      <ReactionsModal
        isOpen={showReactions}
        data={post}
        closeModal={() => setShowReactions(false)}
      />
    </article>
  );
}
