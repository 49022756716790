export default function StepIcon({ className = "" }: { className?: string }) {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g filter="url(#filter0_d_304_3635)">
        <g clipPath="url(#clip0_304_3635)">
          <rect x="4" y="4" width="24" height="24" rx="12" fill="#F9F5FF" />
          <rect
            x="4.75"
            y="4.75"
            width="22.5"
            height="22.5"
            rx="11.25"
            fill="#F9F5FF"
          />
          <rect
            x="4.75"
            y="4.75"
            width="22.5"
            height="22.5"
            rx="11.25"
            stroke="#A24BFD"
            strokeWidth="1.5"
          />
          <circle cx="16" cy="16" r="4" fill="#A24BFD" />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d_304_3635"
          x="0"
          y="0"
          width="32"
          height="32"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.956863 0 0 0 0 0.921569 0 0 0 0 1 0 0 0 1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_304_3635"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_304_3635"
            result="shape"
          />
        </filter>
        <clipPath id="clip0_304_3635">
          <rect x="4" y="4" width="24" height="24" rx="12" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
