import { Modal, ModalContent, Image, Card, Button } from "@nextui-org/react";
import { CloseIcon } from "../../assets/icons/CloseIcon";
import VideoPlayer from "./VideoPlayer";

export default function LightBoxImage({
	isOpen = false,
	closeModal = () => {},
	src,
	type = "image",
	poster = "",
}: {
	isOpen: boolean;
	closeModal?: () => void;
	src: string;
	type?: "image" | "video";
	poster?: string;
}) {
	return (
		<Modal
			scrollBehavior="inside"
			backdrop="blur"
			className="lg:max-w-[800px] max-w-[560px] w-full "
			isOpen={isOpen}
			placement="center"
			onClose={closeModal}
			classNames={{
				closeButton:
					"bg-black/20 border-1 border-white/10 z-20 top-4 right-4 hover:bg-black/30 backdrop-blur-md",
				base: "rounded-2xl border-none relative",
			}}
			closeButton={
				<Button
					radius="full"
					isIconOnly
					aria-label="previous"
					variant="flat"
					className="bg-white/20 border-1 border-white/10"
				>
					<CloseIcon stroke="#ffffffbd" />
				</Button>
			}
			size="lg"
		>
			<ModalContent className="w-full">
				<Card isFooterBlurred radius="lg" className="border-none bg-slate-200 object-cover w-full">
					{type == "image" ? (
						<Image
							alt="Post image"
							width="100%"
							height="auto"
							className="object-contain w-full max-h-[90vh]"
							src={src}
						/>
					) : (
						<>
							<VideoPlayer
								options={{
									autoplay: false,
									controls: true,
									responsive: true,
									fluid: true,
									preload: "none",
									experimentalSvgIcons: true,
									poster: poster,
									sources: [
										{
											src: src,
											type: "application/x-mpegURL",
										},
									],
								}}
								onReady={() => console.log("The video is readyyy")}
							/>
						</>
					)}
				</Card>
			</ModalContent>
		</Modal>
	);
}
