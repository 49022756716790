import { Modal, ModalContent } from "@nextui-org/react";
import SecondaryButton from "../common/SecondaryButton";
import PrimaryButton from "../common/PrimaryButton";
import { useState } from "react";
import { toast } from "react-toastify";
import { CloseIcon } from "../../assets/icons/CloseIcon";
import { useNavigate } from "react-router-dom";
import { deleteTrip } from "../../api/trip";

export default function DeleteTripModal({
  isOpen = false,
  closeModal = () => {},
  name = "",
  id = 0,
}: {
  isOpen: boolean;
  name: string;
  id: number;
  closeModal?: () => void;
}) {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const onDeleteFriend = async () => {
    setIsLoading(true);
    const response = await deleteTrip(id);
    if (response.error == "true" || !response.info)
      toast.error(response?.info?.message || "Ha ocurrido un error");
    else {
      toast.success("Viaje eliminado con exito");
      navigate("/trips");
      closeModal();
    }

    setIsLoading(false);
  };
  return (
    <Modal
      className="max-w-[400px]"
      isOpen={isOpen}
      onClose={closeModal}
      placement="center"
      classNames={{ closeButton: "mt-4 mr-4", base: "rounded-xl border-none" }}
      closeButton={<CloseIcon />}
    >
      <ModalContent>
        <div className="p-6">
          <div className="flex justify-center">
            <div className="text-start">
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="currentColor"
                    d="m19 19.425l-2.125 2.1l-1.4-1.4l2.1-2.125l-2.1-2.125l1.4-1.4l2.125 2.1l2.125-2.1l1.4 1.4l-2.1 2.125l2.1 2.125l-1.4 1.4zM6 22q-1.25 0-2.125-.875T3 19v-3h3V2h15v10.375q-.475-.175-.975-.262T19 12.025V4H8v12h5.35q-.175.475-.262.975T13 18H5v1q0 .425.288.713T6 20h7.35q.2.575.5 1.075t.7.925zM9 9V7h9v2zm0 3v-2h9v2zm4.35 8H5z"
                  />
                </svg>
              </div>
              <h3 className="mt-4 font-semibold text-color3 text-[18px]">
                ¿Estas seguro de que quieres eliminar este viaje {name}?
              </h3>
            </div>
          </div>
          <div className="mt-8 flex justify-center gap-[12px]">
            <SecondaryButton
              className="text-color4 w-full"
              onClick={closeModal}
              disabled={isLoading}
            >
              Cancelar
            </SecondaryButton>
            <PrimaryButton
              onClick={onDeleteFriend}
              className="bg-color10 w-full"
              loading={isLoading}
            >
              Eliminar viaje
            </PrimaryButton>
          </div>
        </div>
      </ModalContent>
    </Modal>
  );
}
