/* eslint-disable no-mixed-spaces-and-tabs */
import { useRef, useState, useEffect, useCallback } from "react";
import { User } from "../../interfaces/Login";
import { Card, Skeleton, Image, Button } from "@nextui-org/react";
import { ArrowLeftIcon, ArrowRightIcon } from "../../assets/icons/ArrowIcon";
import fallback from "../../assets/fantasyImage.jpeg";

export default function NewsContent({ isLoadingPosts, users }: { isLoadingPosts: boolean; users: User[] }) {
	const containerPostsRef = useRef<HTMLDivElement | null>(null);
	const [isAtStart, setIsAtStart] = useState(true);
	const [isAtEnd, setIsAtEnd] = useState(false);

	const updateScrollState = useCallback(() => {
		if (containerPostsRef.current) {
			const { scrollLeft, scrollWidth, clientWidth } = containerPostsRef.current;
			setIsAtStart(scrollLeft === 0);
			setIsAtEnd(scrollLeft + clientWidth >= scrollWidth - 1);
		}
	}, []);

	useEffect(() => {
		const container = containerPostsRef.current;

		if (container) {
			container.addEventListener("scroll", updateScrollState);
			updateScrollState(); // Check initial state

			const handleWheel = (event: WheelEvent) => {
				if (event.deltaY !== 0) {
					const direction = event.deltaY > 0 ? "right" : "left";
					handleScroll(direction);
				}
			};

			container.addEventListener("wheel", handleWheel, { passive: true });

			return () => {
				if (container) {
					container.removeEventListener("scroll", updateScrollState);
					container.removeEventListener("wheel", handleWheel);
				}
			};
		}
	}, [updateScrollState]);

	const handleScroll = (direction: "left" | "right") => {
		if (containerPostsRef.current) {
			const scrollAmount = direction === "left" ? -360 : 360;
			containerPostsRef.current.scrollBy({ left: scrollAmount, behavior: "smooth" });
		}
	};

	return (
		<div className="relative bottom-3">
			<div
				ref={containerPostsRef}
				className="flex scroll-smooth pb-3 pt-5 justify-start overflow-x-auto hide-scroll gap-3"
			>
				{isLoadingPosts
					? Array.from({ length: 4 }).map((_, i) => (
							<Card
								key={i}
								className={`w-[120px] h-[160px] sm:w-[140px] sm:h-[180px] lg:w-[200px] lg:h-[240px] shrink-0 space-y-5 p-4 rounded-xl shadow-none border-1 border-gray-200 ${
									i === 0 ? "ml-4" : ""
								}`}
							>
								<Skeleton className="rounded-lg">
									<div className="h-28 sm:h-32 lg:h-44 rounded-lg bg-default-300"></div>
								</Skeleton>
								<div className="space-y-3">
									<Skeleton className="w-4/5 rounded-lg">
										<div className="h-3 w-4/5 rounded-lg bg-default-200"></div>
									</Skeleton>
									<Skeleton className="w-2/5 rounded-lg">
										<div className="h-3 w-2/5 rounded-lg bg-default-300"></div>
									</Skeleton>
								</div>
							</Card>
					  ))
					: users.map((user, index) => (
							<Card
								key={user.id}
								className={`relative shrink-0 w-[120px] h-[160px] sm:w-[140px] sm:h-[180px] lg:w-[171.53px] lg:h-[240px] rounded-[20px] overflow-hidden shadow-[0px_0px_7.53px_#A24BFDA6] border-b-[6.4px] border-[#A24BFD] ${
									index === 0 ? "ml-4" : ""
								}`}
							>
								<a href={`/perfil/${user.slug}`} className="relative w-full h-full ">
									{/* Imagen de la tarjeta */}
									<Image
										alt={`Perfil de ${user.name}`}
										className="w-full h-[160px] sm:h-[180px] lg:h-[240px] object-cover"
										src={
											user.profile_path?.startsWith("http")
												? user.profile_path
												: user.profile_path
												? import.meta.env.VITE_BASE_URL + user.profile_path
												: fallback
										}
									/>

									<div
										className="absolute bottom-0 left-0 w-full h-[30px] sm:h-[34px] lg:h-[38.24px] flex items-center justify-center text-white font-semibold text-[12px] sm:text-[14px] lg:text-[16px] rounded-b-[20px] z-10"
										style={{
											background:
												"linear-gradient(0deg, rgba(162, 75, 253, 0.7) 0%, rgba(96, 45, 151, 0.4) 40.5%, rgba(0, 0, 0, 0) 100%)",
										}}
									>
										{user.name}
									</div>
								</a>
							</Card>
					  ))}
			</div>

			{/* Botones de navegación */}
			{!isAtStart && (
				<Button
					isIconOnly
					variant="faded"
					color="primary"
					className="font-semibold absolute top-[46%] text-[14px] ml-1 text-color4 z-10"
					onClick={() => handleScroll("left")}
					aria-label="Scroll Left"
				>
					<ArrowLeftIcon />
				</Button>
			)}
			{!isAtEnd && (
				<Button
					isIconOnly
					variant="faded"
					color="primary"
					className="font-semibold absolute top-[46%] right-0 mr-1 z-10 text-[14px] text-color4"
					onClick={() => handleScroll("right")}
					aria-label="Scroll Right"
				>
					<ArrowRightIcon />
				</Button>
			)}
		</div>
	);
}
