import NavBar from "../components/common/Navbar";
import PrimaryButton from "../components/common/PrimaryButton";
// import Footer from "../components/Footer";
import Post from "../components/post/Post";
import { useEffect, useState } from "react";
import { useAuthStore } from "../stores/authStore";
// import PremiumModal from "../components/PremiumModal";
import VerificationModal from "../components/auth/VerificationPendingModal";
import { useHomeStore } from "../stores/homeData";
import { Post as Publication } from "../interfaces/Post";
import { NewPostCard } from "../components/post/NewPostCard";
import { getPublications } from "../api/publication";
import InfiniteScroll from "react-infinite-scroll-component";
import LastVisits from "../components/home/LastVisits";
import LastPhotos from "../components/home/LastPhotos";
// import NewUsers from "../components/home/NewUsers";
import LastVideos from "../components/home/LastVideos";
import MyFriends from "../components/home/MyFriends";
import MyAbout from "../components/home/MyAbout";
import { Link } from "react-router-dom";
import { getHome } from "../api/mine";
import { toast } from "react-toastify";
import { getFantasies } from "../api/formSelectsData";
import { Fantasy } from "../interfaces/Fantasy";
import PostSkeleton from "../components/post/PostSkeleton";
import { getUsers } from "../api/user";
// import { Avatar, Skeleton } from "@nextui-org/react";
import { User } from "../interfaces/Login";
import { withErrorHandler } from "../utils/withErrorFallback";
import { getReactionTypes } from "../api/reactions";
import { NewsIcon } from "../assets/icons/NewsIcon";
import NewsContent from "../components/home/News";

export default function Home() {
  // const [showSideBar, setShowSideBar] = useState(true);
  const authData = useAuthStore((state) => state.user);
  const homeData = useHomeStore((state) => state.home);
  const setHomeData = useHomeStore((state) => state.setHome);
  const setReactionType = useHomeStore((state) => state.setReactionType);

  const newPost = useHomeStore((state) => state.newPost);
  const setNewPost = useHomeStore((state) => state.setNewPost);

  const newComment = useHomeStore((state) => state.newComment);
  const setNewComment = useHomeStore((state) => state.setNewComment);

  const [posts, setPosts] = useState<Publication[]>([]);
  const [hasMore, setHasMore] = useState(true);
  const [loadingConnected, setLoadingConnected] = useState(false);
  const [loading, setLoading] = useState(false);
  const [totalOnlineUsers, setTotalOnlineUsers] = useState(0);
  const [onlineUsers, setOnlineUsers] = useState<User[]>([]);
  // const [isLoading, setIsLoading] = useState(false);
  const [fantasies, setFantasies] = useState<Fantasy[]>([]);

  // const handleClick = () => {
  //   setShowSideBar(!showSideBar);
  //   console.log(showSideBar);
  // };

  const fetchPosts = withErrorHandler(async () => {
    const exclude = posts.map((e) => e.id);
    const response = await getPublications(
      exclude.length ? exclude.join(",") : "0"
    );
    if (!response.info.data[0].length) setHasMore(false);
    else setPosts([...posts, ...response.info.data[0]]);
  });

  useEffect(() => {
    if (newPost) {
      setPosts([newPost, ...posts]);
      return () => {
        setNewPost(undefined);
      };
    }
  }, [newPost]);

  useEffect(() => {
    if (newComment) {
      const upd = posts.map((p) => {
        if (p.id == newComment.post && p.info) {
          p.info.comentarios += newComment.count;
        }
        return p;
      });
      const lastvideos = homeData.posts_video.map((p) => {
        if (p.id == newComment.post && p.info) {
          p.info.comentarios += newComment.count;
        }
        return p;
      });
      const lastphotos = homeData.posts_photo.map((p) => {
        if (p.id == newComment.post && p.info) {
          p.info.comentarios += newComment.count;
        }
        return p;
      });
      setHomeData({
        ...homeData,
        posts_photo: lastphotos,
        posts_video: lastvideos,
      });
      setPosts(upd);
      setNewComment(null);
    }
  }, [newComment]);

  useEffect(() => {
    const getData = async () => {
      try {
        setLoadingConnected(true);
        setLoading(true);
        const fantasies = await getFantasies();
        setFantasies(fantasies.info.data[0]);
        const reactionType = await getReactionTypes();
        setReactionType(reactionType?.id ?? 0);
        fetchPosts();
        const response = await getHome();
        setLoading(false);
        if (response.info?.data[0]?.home)
          setHomeData(response.info.data[0].home);

        const data = await getUsers(1, {
          status: "connected",
        });
        if (data.info?.data[0]) {
          setOnlineUsers(data.info?.data[0]?.data);
          setTotalOnlineUsers(data.info?.data[0].total);
        }
        setLoadingConnected(false);
      } catch (error) {
        toast.error("Ha ocurrido un error cargando los datos");
        setLoadingConnected(false);
      }
    };
    getData();
  }, []);

  const getFantasyName = (id?: number) => {
    if (fantasies.length) {
      return fantasies.find((e) => e.id == id)?.name;
    }
    return undefined;
  };

  return (
    <>
      <NavBar />
      <main
        className={`home sm:p-4 pt-0 lg:pt-5 lg:px-10 flex w-full items-center flex-col fixed  hide-scroll transition-all ease-soft-spring top-[65px]`}
      >
        <div className="max-w-8xl w-full">
          <div className="flex justify-between sm:gap-2 2xl:gap-6 overflow-hidden w-full">
            <div className="hidden lg:block w-4/12 lg:w-[243px] max-h-main hide-scroll pb-5">
              <MyAbout
                visits={homeData.global?.visitas}
                friends={homeData.global?.amigos}
                likes={homeData.global?.reacciones}
              />
              <MyFriends friends={homeData.amigos} />

              <section className="mt-6">
                <h3 className="font-bold text-[16px] text-black">
                  Próximas Fiestas
                </h3>
                <ul className="mt-6 flex flex-col gap-8">
                  {homeData.fiestas?.map((party) => (
                    <li key={party.id}>
                      <article className="max-w-[291px]">
                        <div className="flex justify-between flex-wrap items-center">
                          <h2 className="font-semibold text-[18px] text-color3">
                            {party.nombre}
                          </h2>
                          <div className="min-w-[90px] max-w-max font-medium  text-[12px] text-color4 rounded-2xl border-1 border-gray-300 pl-2 pr-2 pb-0.5 pt-0.5 bg-color8">
                            {party.integrantes?.length ?? 0}/
                            {party.capacidad == 0 ? "∞" : party.capacidad} Cupos
                          </div>
                        </div>
                        <div className="mt-0.5">
                          <p className="font-normal text-[14px] text-color5">
                            {party.descripcion.slice(0, 300)}...
                          </p>
                        </div>
                        <div className="mt-4">
                          <Link to={`/fiestas/${party.slug}`}>
                            <PrimaryButton>Más Información</PrimaryButton>
                          </Link>
                        </div>
                        <hr className="mt-5 border-gray-300"></hr>
                      </article>
                    </li>
                  ))}
                </ul>
                {(homeData.fiestas?.length == 0 || !homeData.fiestas) && (
                  <p>No hay fiestas próximamente</p>
                )}
              </section>
            </div>
            <div className=" flex-1 min-w-0 flex justify-center w-full hide-scroll">
              <div
                id="scrollableDiv"
                className=" Xbg-color9 flex-1 min-w-0 bg-zinc-100 border-1 border-zinc-100 shadow-inner  md:rounded-3xl  w-full py-4 md:p-6 lg:ml-5 max-h-main flex flex-col gap-4 md:gap-6 hide-scroll"
              >
                <NewPostCard
                  onlineUsers={totalOnlineUsers.toString()}
                  globalData={{
                    visits: homeData.global?.visitas,
                    friends: homeData.global?.amigos,
                    reactions: homeData.global?.reacciones,
                  }}
                />
                <div className="w-full flex-1 min-w-0 bg-[#F0E3FF] min-h-64 lg:min-h-80 py-5 md:rounded-2xl">
                  <div className="flex items-center gap-2 px-5">
                    <NewsIcon />
                    <span className="font-medium">Novedades</span>
                  </div>
                  <div className="flex-1 min-w-0">
                    <NewsContent
                      isLoadingPosts={loading}
                      users={homeData.nuevos_usuarios}
                    />
                  </div>
                </div>
                <InfiniteScroll
                  dataLength={posts.length}
                  next={fetchPosts}
                  hasMore={hasMore}
                  loader={
                    <div className="w-full">
                      <PostSkeleton />
                    </div>
                  }
                  scrollableTarget="scrollableDiv"
                  endMessage={
                    <p style={{ textAlign: "center" }}>
                      <b>Yay! Parece que has visto todas las publicaciones</b>
                    </p>
                  }
                >
                  {posts.map((e, idx) => (
                    <Post
                      key={e.id + " - " + idx}
                      post={e}
                      className="py-5 md:p-10 bg-white md:rounded-2xl mb-4 md:mb-10"
                      fantasy={
                        e.isFantasia ? getFantasyName(e.fantasia_id) : undefined
                      }
                    />
                  ))}
                </InfiniteScroll>
              </div>
            </div>
            <div className="hidden xl:block ml-5  w-4/12 lg:w-[243px] max-h-main hide-scroll pb-5">
              {homeData.visitantes.length > 0 && (
                <LastVisits lastVisits={homeData.visitantes} />
              )}

              {onlineUsers.length > 0 && (
                <LastVisits
                  title={
                    "Usuarios en linea " +
                    (!loadingConnected ? `(${totalOnlineUsers})` : "")
                  }
                  lastVisits={onlineUsers}
                />
              )}
              {/* <NewUsers newUsers={homeData.nuevos_usuarios} /> */}

              {homeData.posts_photo.length > 0 && (
                <LastPhotos lastPhotos={homeData.posts_photo} />
              )}
              {homeData.posts_video.length > 0 && (
                <LastVideos videos={homeData.posts_video} />
              )}
            </div>
          </div>
        </div>
      </main>

      <VerificationModal
        isOpen={
          !authData?.verified &&
          (!!authData?.info?.verification_path || authData?.role_id == "3")
        }
      />
      {/* TODO:  uncoment this when payment integration its done */}
      {/* <PlanModal  
        isOpen={!!authData?.verified && !authData?.subscription_payed}
      /> */}
    </>
  );
}
