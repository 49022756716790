import { create } from "zustand";
import { User } from "../interfaces/Login";

interface LoginState {
  token: string;
  user: User | null;
  saveToken: (tk: string) => void;
  saveUser: (data: User | null) => void;
}

export const useLoginStore = create<LoginState>()((set) => ({
  token: "",
  user: null,
  saveToken: (tk) => set(() => ({ token: tk })),
  saveUser: (data) => set(() => ({ user: data })),
}));
