import { ReactNode } from "react";

interface ProfileSectionProps {
	title: string;
	icon?: ReactNode;
	children: ReactNode;
}

const ProfileSection = ({ title, icon, children }: ProfileSectionProps) => (
	<div className="space-y-2">
		<h3 className="font-medium text-color1 flex items-center gap-2">
			{icon}
			{title}
		</h3>
		<div>{children}</div>
	</div>
);

export default ProfileSection;
