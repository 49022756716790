import {
  Button,
  Dropdown,
  DropdownItem,
  Selection,
  DropdownMenu,
  DropdownTrigger,
  Pagination,
  RangeValue,
  DateValue,
  DateRangePicker,
  Card,
  Skeleton,
  ButtonGroup,
} from "@nextui-org/react";
import { CalendarIcon } from "../../assets/icons/CalendarIcon";
import { SearchIcon } from "../../assets/icons/SearchIcon";
import PrimaryButton from "../common/PrimaryButton";
import { useEffect, useState } from "react";
import { City, Filters, Party, PartyType } from "../../interfaces/Party";
import PartyCard from "./PartyCard";
import { Pagination as DataPagination } from "../../interfaces/pagination";
import { parseDate } from "@internationalized/date";
import SecondaryButton from "../common/SecondaryButton";
import { Fantasy } from "../../interfaces/Fantasy";
import { getCities, getFantasies } from "../../api/formSelectsData";
import { getPartyTypes } from "../../api/party";
import Select from "react-select";
export default function PartyList({
  isLoading,
  parties,
  dateRange,
  total,
  pagination,
  filters,
  showVisibiltySelect = false,
  showTypeSelect = false,
  onPageChange,
  onChangeDate,
  setFilters,
}: {
  isLoading: boolean;
  parties: Party[];
  dateRange: RangeValue<DateValue>;
  onChangeDate: (dateRange: RangeValue<DateValue>) => void;
  total?: number;
  pagination?: DataPagination;
  filters: Filters;
  showVisibiltySelect?: boolean;
  showTypeSelect?: boolean;
  setFilters: (value: Filters) => void;
  onPageChange?: (page: number, name?: string) => void;
}) {
  const [selectedKeys, setSelectedKeys] = useState<Set<string>>(new Set([]));

  const [page, setPage] = useState(pagination?.current_page ?? 1);
  const [selectsData, setSelectsData] = useState({
    fantasies: [] as Fantasy[],
    partyTypes: [] as PartyType[],
    cities: [] as City[],
  });

  const onSelectionChange = (key: Selection) => {
    const selected: string[] = [];

    if (key != "all")
      key.forEach((el) => {
        selected.push(el.toString());
      });
    if (onPageChange && selected[0]) onPageChange(1, selected[0]);
    setSelectedKeys(new Set([...selected]));
  };

  const clearFilters = () => {
    setSelectedKeys(new Set([]));
    setFilters({
      ...filters,
      fantasias_id: new Set([]),
      types_id: new Set([]),
      ciudades: new Set([]),
      estado: 1,
    });

    onChangeDate({
      start: parseDate(new Date().toISOString().split("T")[0]),
      end: parseDate(
        new Date(new Date().setMonth(new Date().getMonth() + 4))
          .toISOString()
          .split("T")[0]
      ),
    });
  };

  useEffect(() => {
    if (onPageChange)
      onPageChange(page, Array.from(selectedKeys)[0] ?? undefined);
  }, [page]);

  useEffect(() => {
    const getSelectsData = async () => {
      const fantasies = await getFantasies();
      const types = await getPartyTypes();
      const cities = await getCities();
      console.log("cities", cities);

      setSelectsData({
        fantasies: fantasies.info.data[0],
        partyTypes: types?.info?.data[0] ?? [],
        cities: cities?.info?.data[0] ?? [],
      });
    };
    getSelectsData();
  }, []);

  // const handleChangeSelect = (e: FormEvent<HTMLSelectElement>) => {
  //   const data = e.target as HTMLSelectElement;

  //   let aux = data.value.split(",");
  //   if (aux.length && aux[0] == "") aux = aux.slice(1);
  //   setFilters({
  //     ...filters,
  //     [data.name]: new Set(aux),
  //   });
  //   // console.log(filters);
  // };

  return (
    <>
      <div className="mt-10 w-full max-w-8xl px-4 md:px-14">
        <section className="w-full flex justify-center">
          <div className=" mt-2 flex flex-col w-full">
            <div className="w-full my-3 grid sm:grid-cols-2 lg:grid-cols-8  gap-4">
              <div className="w-full lg:col-span-2">
                <DateRangePicker
                  label="Seleccione la fecha"
                  labelPlacement="outside"
                  color="primary"
                  radius="sm"
                  variant="bordered"
                  className="w-full"
                  selectorIcon={<CalendarIcon />}
                  value={dateRange}
                  onChange={onChangeDate}
                  classNames={{
                    inputWrapper: " rounded-lg border-small",
                    label:
                      "text-[14px] font-medium group-data-[filled-within=true]:text-color2",
                    input: "border-0 focus:ring-1 focus:ring-transparent",
                  }}
                />
              </div>
              <div className="w-full party-list-control lg:col-span-2">
                {/* <UiSelect
                  name="fantasias_id"
                  selectionMode="multiple"
                  color="primary"
                  variant="bordered"
                  label="Fantasías"
                  labelPlacement="outside"
                  classNames={{
                    trigger: "w-full rounded-lg border-small mt-0.5",
                    label:
                      "text-[14px] font-medium group-data-[filled-within=true]:text-color2",
                    popoverContent: "rounded-md",
                  }}
                  className="w-full"
                  onChange={handleChangeSelect}
                  placeholder={
                    selectsData.fantasies?.length ? "Seleccione" : "Cargando..."
                  }
                  selectedKeys={filters.fantasias_id}
                >
                  {selectsData.fantasies?.map((item) => (
                    <SelectItem
                      key={item.id.toString()}
                      value={item.id.toString()}
                    >
                      {item.name}
                    </SelectItem>
                  ))}
                </UiSelect> */}
                <label className="text-[14px] mb-2 font-medium text-primary group-data-[filled-within=true]:text-color2">
                  Fantasías
                </label>
                <Select
                  isMulti
                  name="fantasias_id"
                  options={selectsData.fantasies?.map((x) => ({
                    label: x.name,
                    value: x.id,
                  }))}
                  placeholder={
                    selectsData.fantasies?.length ? "Seleccione" : "Cargando..."
                  }
                  className=" mt-1"
                  classNamePrefix="select"
                  value={selectsData.fantasies
                    ?.filter((it) => filters.fantasias_id.has(it.id.toString()))
                    .map((x) => ({
                      label: x.name,
                      value: x.id,
                    }))}
                  onChange={(value) => {
                    setFilters({
                      ...filters,
                      fantasias_id: new Set([
                        ...value.map((e) => e.value.toString()),
                      ]),
                    });
                  }}
                />
              </div>
              <div className="w-full lg:col-span-2">
                {/* <UiSelect
                  variant="bordered"
                  label="Tipo de Fiesta"
                  selectionMode="multiple"
                  labelPlacement="outside"
                  onChange={handleChangeSelect}
                  color="primary"
                  classNames={{
                    trigger: " rounded-lg border-small mt-0.5",
                    label:
                      "text-[14px] font-medium group-data-[filled-within=true]:text-color2",
                    popoverContent: "rounded-md",
                  }}
                  name="types_id"
                  className="w-full"
                  placeholder={
                    selectsData.partyTypes.length ? "Seleccione" : "Cargando..."
                  }
                >
                  {selectsData.partyTypes.map((x) => (
                    <SelectItem key={x.id} value={x.id}>
                      {x.name}
                    </SelectItem>
                  ))}
                </UiSelect> */}
                <label className="text-[14px] mb-2 font-medium text-primary group-data-[filled-within=true]:text-color2">
                  Tipo de Fiesta
                </label>
                <Select
                  isMulti
                  name="types_id"
                  options={selectsData.partyTypes.map((x) => ({
                    label: x.name,
                    value: x.id,
                  }))}
                  placeholder={
                    selectsData.partyTypes.length ? "Seleccione" : "Cargando..."
                  }
                  value={selectsData.partyTypes
                    .filter((it) => filters.types_id.has(it.id.toString()))
                    .map((x) => ({
                      label: x.name,
                      value: x.id,
                    }))}
                  className=" mt-1"
                  classNamePrefix="select"
                  onChange={(value) => {
                    setFilters({
                      ...filters,
                      types_id: new Set([
                        ...value.map((e) => e.value.toString()),
                      ]),
                    });
                  }}
                />
              </div>
              <div className="w-full lg:col-span-2">
                <label className="text-[14px] mb-2 font-medium text-primary group-data-[filled-within=true]:text-color2">
                  Ciudades
                </label>
                <Select
                  isMulti
                  name="ciudades"
                  options={selectsData.cities.map((x) => ({
                    label: x.name,
                    value: x.id,
                  }))}
                  placeholder={
                    selectsData.partyTypes.length ? "Seleccione" : "Cargando..."
                  }
                  value={selectsData.cities
                    .filter((it) => filters.ciudades.has(it.id.toString()))
                    .map((x) => ({
                      label: x.name,
                      value: x.id,
                    }))}
                  className=" mt-1"
                  classNamePrefix="select"
                  onChange={(value) => {
                    setFilters({
                      ...filters,
                      ciudades: new Set([
                        ...value.map((e) => e.value.toString()),
                      ]),
                    });
                  }}
                />
              </div>
              <div className="w-full lg:col-span-6"></div>
              <div className="w-full z-0 self-end flex items-end justify-center gap-2 md:justify-end lg:col-span-2">
                <SecondaryButton onClick={clearFilters} className="w-full ">
                  Resetear
                </SecondaryButton>

                <PrimaryButton className="w-full">
                  <div className="flex items-center gap-2">
                    <SearchIcon height={20} width={20} stroke={"white"} />
                    <span className="">Buscar</span>
                  </div>
                </PrimaryButton>
              </div>
            </div>

            <div className="w-full flex justify-between flex-wrap md:flex-nowrap items-center z-0">
              <div className="w-full my-3 text-lg order-2 md:order-1">
                <span className="text-primary font-semibold">
                  {isLoading ? "..." : total}
                </span>{" "}
                resultados encontrados
              </div>
              <div className="order-1 md:order-2 flex items-end justify-start flex-wrap md:flex-nowrap gap-2 md:justify-end">
                {showVisibiltySelect && (
                  <ButtonGroup className="">
                    <Button
                      radius="sm"
                      color={filters.estado == 1 ? "primary" : "default"}
                      variant="bordered"
                      className="border-1"
                      onClick={() =>
                        setFilters({
                          ...filters,
                          estado: 1,
                        })
                      }
                    >
                      Visibles
                    </Button>
                    <Button
                      color={filters.estado == 0 ? "primary" : "default"}
                      radius="sm"
                      variant="bordered"
                      className="border-1"
                      onClick={() =>
                        setFilters({
                          ...filters,
                          estado: 0,
                        })
                      }
                    >
                      Ocultas
                    </Button>
                  </ButtonGroup>
                )}
                {showTypeSelect && (
                  <ButtonGroup className="">
                    <Button
                      radius="sm"
                      color={!filters.attended ? "primary" : "default"}
                      variant="bordered"
                      className="border-1"
                      onClick={() =>
                        setFilters({
                          ...filters,
                          attended: false,
                        })
                      }
                    >
                      Creado
                    </Button>
                    <Button
                      color={filters.attended ? "primary" : "default"}
                      radius="sm"
                      variant="bordered"
                      className="border-1"
                      onClick={() =>
                        setFilters({
                          ...filters,
                          attended: true,
                        })
                      }
                    >
                      Asistido
                    </Button>
                  </ButtonGroup>
                )}
                <Dropdown>
                  <DropdownTrigger>
                    <Button
                      radius="sm"
                      variant="bordered"
                      className="max-w-[160px] capitalize border-1 border-color1/30 flex items-center w-full h-[42px]"
                      endContent={
                        <svg
                          className="mt-1"
                          width="12"
                          height="8"
                          viewBox="0 0 12 8"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M1 1.5L6 6.5L11 1.5"
                            stroke="#344054"
                            strokeWidth="1.66667"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      }
                    >
                      Ordenar por
                    </Button>
                  </DropdownTrigger>
                  <DropdownMenu
                    aria-label="Single selection example"
                    variant="flat"
                    disallowEmptySelection
                    selectionMode="single"
                    selectedKeys={selectedKeys}
                    onSelectionChange={onSelectionChange}
                  >
                    <DropdownItem key="fecha_inicio">
                      Fecha de inicio
                    </DropdownItem>
                    <DropdownItem key="nombre">Nombre</DropdownItem>
                    <DropdownItem key="capacidad">Capacidad</DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </div>
            </div>
            <div className="w-full gap-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4  pt-3 z-0">
              {isLoading ? (
                [0, 0, 0, 0].map((_, i) => (
                  <Card
                    className="w-full flex-col space-y-5 p-4  shadow-none border-gray-100 flex py-4 border-1 flex-grow-1"
                    radius="lg"
                    key={i}
                  >
                    <Skeleton className="rounded-lg h-36">
                      <div className="h-24 w-24 rounded-lg bg-default-300"></div>
                    </Skeleton>
                    <div className="space-y-3 ">
                      <Skeleton className="w-3/5 rounded-lg">
                        <div className="h-3 w-3/5 rounded-lg bg-default-200"></div>
                      </Skeleton>
                      <Skeleton className="w-4/5 rounded-lg">
                        <div className="h-3 w-4/5 rounded-lg bg-default-200"></div>
                      </Skeleton>
                      <Skeleton className="w-2/5 rounded-lg">
                        <div className="h-3 w-2/5 rounded-lg bg-default-300"></div>
                      </Skeleton>
                    </div>
                  </Card>
                ))
              ) : parties.length ? (
                parties?.map((party) => (
                  <PartyCard party={party} key={party.id} />
                ))
              ) : (
                <p className="mt-2">No hay fiestas para mostrar 😕</p>
              )}
            </div>
          </div>
        </section>
      </div>
      {pagination && !isLoading && pagination.last_page > 1 && (
        <div className="px-4 md:px-14 mt-16 w-full max-w-8xl">
          <div className="flex  sm:justify-center">
            <Pagination
              showControls
              page={pagination.current_page}
              total={pagination.last_page}
              initialPage={1}
              onChange={setPage}
            />
          </div>
        </div>
      )}
    </>
  );
}
