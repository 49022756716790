import { Button, Card, CardBody, CardFooter, Image, Skeleton, User } from "@nextui-org/react";
import { Link } from "react-router-dom";
// import { PartnerIcon } from "../../assets/icons/UsersIcon";
import { HeartIcon } from "../../assets/icons/HeartIcon";
import { LocationIcon } from "../../assets/icons/LockationIcon";
import { CalendarIcon } from "../../assets/icons/CalendarIcon";
import { Trip } from "../../interfaces/Trip";
import { useDateFormatter } from "@react-aria/i18n";
import Moment from "react-moment";
import { useEffect, useMemo, useState } from "react";
import { useAuthStore } from "../../stores/authStore";
import { likeTrip } from "../../api/trip";
import { getPlaceByLatLng } from "../../utils/googleMaps";
import { SexualOrientation } from "../../interfaces/SexualOrientation";
import { Province } from "../../interfaces/Provice";

export default function Tripcard({
	trip,
	orientations,
	provinces,
}: {
	trip: Trip;
	orientations: SexualOrientation[];
	provinces: Province[];
}) {
	const formatter = useDateFormatter({
		dateStyle: "long",
	});
	const authData = useAuthStore((state) => state.user);
	const [isPastTrip, setIsPastTrip] = useState<boolean | null>(null);
	const [place, setPlace] = useState("Cargando ubicación...");
	const [ckeck, setCheck] = useState(trip?.likes.find((e) => e.user_id == authData?.id) ? true : false);
	const handleLike = async () => {
		if (trip?.id) {
			// console.log(ckeck);
			setCheck(!ckeck);
			await likeTrip(trip?.id);
			// console.log(res);
		}
	};

	// Verificar si el viaje ya pasó
	useEffect(() => {
		if (trip.fecha_fin) {
			// Convertir a UTC (ej: "2025-02-03 05:00:00" → "2025-02-03T05:00:00Z")
			const isoDate = `${trip.fecha_fin.replace(" ", "T")}Z`;
			const tripEndDate = new Date(isoDate);

			// Validar fecha
			if (isNaN(tripEndDate.getTime())) {
				console.error("Fecha inválida:", trip.fecha_fin);
				setIsPastTrip(false);
				return;
			}

			// Comparar en UTC
			const currentUTC = Date.now();
			setIsPastTrip(tripEndDate.getTime() < currentUTC);
		}
	}, [trip.fecha_fin]);

	useEffect(() => {
		const setData = async () => {
			try {
				if (!trip.localizacion) {
					setPlace("Ubicación desconocida");
					return;
				}

				const aux = trip.localizacion.split("|");

				if (aux.length === 2 && !isNaN(parseFloat(aux[0])) && !isNaN(parseFloat(aux[1]))) {
					const lat = parseFloat(aux[0]);
					const lng = parseFloat(aux[1]);

					const auxPlace = await getPlaceFormated(lat, lng);
					if (auxPlace) setPlace(auxPlace);
				} else {
					console.warn("Formato de localización inválido:", trip.localizacion);
					setPlace("Ubicación desconocida");
				}
			} catch (error) {
				console.error("Error al obtener la ubicación:", error);
				setPlace("Ubicación desconocida");
			}
		};

		setData();
	}, [trip.localizacion]);

	const province = useMemo(() => {
		if (provinces.length && trip.user?.info?.provincia_id) {
			const pr = provinces.find((e) => e.id == trip.user?.info?.provincia_id)?.name;
			return pr ?? "";
		}
		return (
			<Skeleton className="ml-2 rounded-lg">
				<p className="w-14 h-3"></p>
			</Skeleton>
		);
	}, [provinces]);

	const getPlaceFormated = async (lat: number, lng: number) => {
		try {
			const place = await getPlaceByLatLng(lat, lng);

			if (!place?.results || place.results.length === 0) {
				console.warn("No se encontraron resultados para las coordenadas:", lat, lng);
				return "Ubicación desconocida";
			}

			// Filtrar para evitar Plus Codes
			const filteredResults = place.results.filter((result) => !result.types.includes("plus_code"));

			if (filteredResults.length === 0) {
				return "Ubicación desconocida";
			}

			// Buscar la mejor dirección (ciudad, provincia, país)
			const preferredTypes = [
				"locality",
				"administrative_area_level_2",
				"administrative_area_level_1",
				"country",
			];

			for (const result of filteredResults) {
				for (const component of result.address_components) {
					if (component.types.some((type) => preferredTypes.includes(type))) {
						const country =
							result.address_components.find((c) => c.types.includes("country"))?.long_name ?? "";
						return `${component.long_name}, ${country}`;
					}
				}
			}

			// Si no se encuentra una opción mejor, devolvemos la dirección formateada
			return filteredResults[0].formatted_address ?? "Ubicación desconocida";
		} catch (error) {
			console.error("Error al obtener el lugar formateado:", error);
			return "Ubicación desconocida";
		}
	};

	const orientation = useMemo(() => {
		const userSO = trip.user?.info?.detalles?.sexualOrentation;
		const userPartnerSO = trip.user?.info?.detalles?.partner_sexualOrentation;
		if (orientations.length) {
			const myO = userSO?.value && orientations.find((e) => e.id.toString() == userSO?.value)?.name;

			if (!trip.user?.info?.is_couple) return myO ?? "?";

			const partnerO =
				userPartnerSO?.value && orientations.find((e) => e.id.toString() == userPartnerSO?.value)?.name;

			if (myO && partnerO) {
				if (myO == partnerO) return myO;
				return myO == "bisexual" ? partnerO : myO;
			}
			return "?";
		} else
			return (
				<Skeleton className="ml-2 rounded-lg">
					<p className="w-14 h-3"></p>
				</Skeleton>
			);
	}, [orientations]);

	const age = useMemo(() => {
		if (!trip.user?.info?.detalles?.birthDate) return "?";
		const birthday = new Date(trip.user?.info?.detalles?.birthDate);
		const ageDifMs = Date.now() - birthday.getTime();
		const ageDate = new Date(ageDifMs); // miliseconds from epoch
		if (trip.user.info.is_couple && trip.user?.info?.detalles?.partner_birthDate) {
			const partnerBirthday = new Date(trip.user?.info?.detalles?.partner_birthDate);
			const partnerAgeDifMs = Date.now() - partnerBirthday.getTime();
			const partnerAgeDate = new Date(partnerAgeDifMs); // miliseconds from epoch
			const uAge = Math.abs(ageDate.getUTCFullYear() - 1970);
			const pAge = Math.abs(partnerAgeDate.getUTCFullYear() - 1970);
			if (uAge == pAge) return `ambos ${pAge}`;
			return `${uAge} y ${pAge}`;
		}
		//partner_birthDate
		return Math.abs(ageDate.getUTCFullYear() - 1970);
	}, [trip.user]);

	if (isPastTrip === null) {
		return null; // ⬅️ No renderizar nada hasta tener el cálculo
	}

	return (
		<>
			<Card className="hidden lg:flex py-4 border-1 w-full flex-grow-1 " shadow="none">
				<CardBody className="overflow-visible py-0 flex flex-row">
					<div className="relative h-full">
						<Link to={`/viajes/${trip.slug}`}>
							<div className="overflow-hidden w-full h-[200px] flex items-center justify-center">
								<Image
									isZoomed
									alt="Card background"
									className="object-cover rounded-xl h-full"
									src={
										trip?.imagen
											? trip?.imagen.startsWith("http")
												? trip?.imagen
												: import.meta.env.VITE_BASE_URL + trip?.imagen
											: undefined
									}
									width={320}
									height={280}
									classNames={{
										wrapper: "h-full",
										zoomedWrapper: "h-full",
										img: "h-full",
									}}
								/>
							</div>
						</Link>

						{isPastTrip && (
							<div className="absolute top-2 left-2 bg-yellow-500 text-white px-3 py-1 rounded-md text-xs font-bold z-10">
								Viaje Pasado
							</div>
						)}
						{/* <Button
              color="primary"
              radius="sm"
              size="sm"
              className=" absolute top-2 left-2 z-10 shadow-none"
            >
              <div className="flex items-center gap-2">
                <PartnerIcon height={20} width={20} />
                <span className="hidden lg:inline">Parejas</span>
              </div>
            </Button> */}
						<div className="absolute top-2 right-2 z-10">
							<Button
								className={` bg-color8/70 like ${ckeck ? "checked" : ""}`}
								variant="flat"
								isIconOnly
								radius="sm"
								aria-label="like"
								onClick={handleLike}
							>
								<div className="checkmark flex items-center justify-center">
									<HeartIcon height={24} width={24} stroke="#A24BFD" />
								</div>
							</Button>
						</div>
					</div>
					<div className="ml-4 flex flex-col w-full justify-between">
						<div>
							<div className="flex gap-3 items-center">
								<span className="text-primary font-medium">
									{trip.tipo_viaje == 1 ? "Viaje Internacional" : "Viaje Nacional"}
								</span>

								{trip.fantasias.slice(0, 4).map((fantasy) => (
									<Link to={`/fantasia/${fantasy.slug}`} key={fantasy.id}>
										<Button
											radius="sm"
											variant="bordered"
											className=" border-1 border-color1 "
											size="sm"
										>
											{fantasy.name}
										</Button>
									</Link>
								))}
								<span className="text-gray-600 flex-1">
									{trip.fantasias.length > 4 ? "+" + (trip.fantasias.length - 4) : ""}
								</span>

								<span className="text-gray-500 mr-5">
									Publicado{" "}
									<Moment fromNow locale="Es">
										{trip.created_at}
									</Moment>
								</span>
							</div>
							<div className="flex flex-col gap-3 justify-center my-2">
								<p className="font-bold text-2xl flex">
									<Link className="hover:text-primary flex" to={`/viajes/${trip.slug}`}>
										{trip.nombre}
									</Link>
								</p>

								<p onClick={() => console.log(trip.user.info)} className="text-gray-500">
									{trip.descripcion}
								</p>
							</div>
						</div>
						<div className="flex gap-3 items-center">
							<span className="text-gray-500 font-medium flex gap-2 itesms-center">
								<LocationIcon width={20} stroke={"#A24BFD"} /> {place}
							</span>
							<span className="text-gray-500 font-medium flex gap-2 items-center flex-1">
								<CalendarIcon width={20} stroke={"#A24BFD"} />{" "}
								{trip.fecha_fin && trip.fecha_inicio
									? formatter.formatRange(new Date(trip.fecha_inicio), new Date(trip.fecha_fin))
									: "--"}
							</span>
							<Link to={`/perfil/${trip?.user.slug}`}>
								<User
									name={
										<span className="text-sm text-gray-600 mr-5">
											Creado por <b>{trip?.user.name}</b>
										</span>
									}
									description={
										<p>
											<span className="text-sm text-gray-500">
												{trip.user.info?.is_couple
													? "Pareja"
													: trip.user.info?.detalles?.sex?.value == "f"
													? "Mujer"
													: "Hombre"}{" "}
												{orientation} -
											</span>
											<span className="text-primary ml-2 font-semibold">{age} años -</span>
											<span className="text-blue-500 ml-2 font-semibold">{province}</span>
										</p>
									}
									avatarProps={{
										src: trip?.user?.profile_path
											? trip?.user?.profile_path.startsWith("http")
												? trip?.user?.profile_path
												: import.meta.env.VITE_BASE_URL + trip?.user?.profile_path
											: undefined,
									}}
								/>
							</Link>
						</div>
					</div>
				</CardBody>
			</Card>

			<Card shadow="none" radius="md" className="lg:hidden border-1  border-gray-200">
				<CardBody className="overflow-visible p-1">
					<div className="relative object-fit">
						<Link to={`/viajes/${trip.slug}`}>
							<Image
								shadow="md"
								radius="md"
								width="100%"
								isZoomed
								alt=""
								className="w-full object-cover h-[200px] md:h-[140px]"
								src={
									trip?.imagen
										? trip?.imagen.startsWith("http")
											? trip?.imagen
											: import.meta.env.VITE_BASE_URL + trip?.imagen
										: undefined
								}
							/>
						</Link>

						<div className="absolute top-2 right-2 z-10">
							<Button
								className={`border-1 bg-color8/70 like ${ckeck ? "checked" : ""}`}
								variant="flat"
								isIconOnly
								aria-label="like"
								onClick={handleLike}
							>
								<div className="checkmark flex items-center justify-center">
									<HeartIcon height={24} width={24} stroke="#A24BFD" />
								</div>
							</Button>
						</div>
					</div>
				</CardBody>
				<CardFooter className="text-small">
					<div className="ml-4 flex flex-col w-full">
						<div className="flex flex-col gap-3 items-start">
							<div className="text-primary font-medium flex gap-4">
								<span>Viaje Nacional</span>
							</div>
							<div className="flex gap-2 w-full flex-wrap">
								{trip.fantasias.map((fantasy) => (
									<div key={fantasy.id}>
										<Link to={`/fantasia/${fantasy.slug}`} key={fantasy.slug}>
											<Button
												radius="sm"
												variant="bordered"
												className="p-1.5  rounded-md border-1 border-color1/60 "
												size="sm"
											>
												{fantasy.name}
											</Button>
										</Link>
									</div>
								))}
							</div>
						</div>
						<div className="flex flex-col gap-3 text-start my-2">
							<p className="font-bold text-2xl flex">
								<Link className="hover:text-primary flex" to={`/viajes/${trip.slug}`}>
									{trip.nombre}
								</Link>
							</p>
							<p className="text-gray-500">{trip.descripcion}</p>
						</div>
						<div className="flex gap-3 flex-col items-start">
							<span className="text-gray-500 font-medium flex gap-2 itesms-center">
								<LocationIcon stroke={"#A24BFD"} />{" "}
								{place.slice(0, 25) + (place.length > 25 ? "..." : "")}
							</span>
							<span className="text-gray-500 font-medium flex gap-2 items-center">
								<CalendarIcon width={20} stroke={"#A24BFD"} />{" "}
								{trip.fecha_fin && trip.fecha_inicio
									? formatter.formatRange(new Date(trip.fecha_inicio), new Date(trip.fecha_fin))
									: "--"}
							</span>
							<span className="text-gray-500 text-sm">
								Publicado{" "}
								<Moment fromNow locale="Es">
									{trip.created_at}
								</Moment>
							</span>
						</div>
					</div>
				</CardFooter>
			</Card>
		</>
	);
}
