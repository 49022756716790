import { FormEvent, useEffect, useRef, useState } from "react";
import {
  // DatePicker,
  DateRangePicker,
  DateValue,
  Input,
  Modal,
  ModalContent,
  RangeValue,
  Select,
  SelectItem,
  Switch,
  Textarea,
  TimeInput,
  TimeInputValue,
  Tooltip,
} from "@nextui-org/react";
import PrimaryButton from "../common/PrimaryButton";
import { CloseIcon } from "../../assets/icons/CloseIcon";
import FileInput from "../form/FileInput";
import { CalendarIcon } from "../../assets/icons/CalendarIcon";
import {
  getLocalTimeZone,
  parseAbsoluteToLocal,
  parseDate,
  today,
} from "@internationalized/date";
import { toast } from "react-toastify";
import { round } from "../../utils/useNumber";
import { uploadFile } from "../../api/upload";
import { Party, PartyType } from "../../interfaces/Party";
import SecondaryButton from "../common/SecondaryButton";
import CustomCheckBox from "../form/CustomCheckBox";
import { Link, useNavigate } from "react-router-dom";
import { Fantasy } from "../../interfaces/Fantasy";
import { getFantasies } from "../../api/formSelectsData";
import { useAuthStore } from "../../stores/authStore";
import { createParty, getPartyTypes } from "../../api/party";
import CustomRadioGroup from "../form/CustomRadioGroup";
import { Map, Marker } from "@vis.gl/react-google-maps";
import MapHandler from "../trip/map-handler";
import { getCity, getPlaceFormated } from "../../utils/googleMaps";
import { InfoCircleIcon } from "../../assets/icons/InfoIcon";
import { usePartyStore } from "../../stores/partyStore";
import CustomSearchableSelect from "./CustomSearchableSelect";
import { AutocompleteCustom } from "../trip/autocomplete-custom";

const INITIAL_CENTER = { lat: 40.416775, lng: -3.70379 };
const emptyState = {
  nombre: "",
  descripcion: "",
  fecha_inicio: "",
  fecha_fin: "",
  localizacion: "",
  direccion: "",
  tipo_id: "",
  imagen: "",
  capacidad: "",
  precio: "",
  publica: "false",
  estado: "true",
  fantasias: "",
  hora_inicio: "",
};
export default function CreatePartyModal({
  isOpen = false,
  closeModal = () => {},
  onSuccessCreateParty,
}: {
  isOpen: boolean;
  closeModal?: () => void;
  onSuccessCreateParty: (trip: Party) => void;
}) {
  const [data, setData] = useState(emptyState);
  const [info, setInfo] = useState<{
    fantasies: Fantasy[];
    partyTypes: PartyType[];
  }>({
    fantasies: [],
    partyTypes: [],
  });
  const [fileUrl, setFileUrl] = useState<string>("");
  const [file, setFile] = useState<File | null>(null);
  const [loading, setLoading] = useState(false);
  const [goInvite, setGoInvite] = useState(false);
  const [customSearch, setCustomSearch] = useState<string>("");
  const [isFree, setIsFree] = useState(false);
  const [isUnlimited, setIsUnlimited] = useState(false);
  const [time, setTime] = useState<TimeInputValue | null>(
    parseAbsoluteToLocal("2024-04-08T18:45:22Z")
  );
  const [date, setDate] = useState<RangeValue<DateValue>>({
    start: parseDate(new Date(new Date()).toISOString().split("T")[0]),
    end: parseDate(
      new Date(new Date().setDate(new Date().getDate() + 3))
        .toISOString()
        .split("T")[0]
    ),
  });
  const authData = useAuthStore((state) => state.user);
  const setInvite = usePartyStore((state) => state.setInvite);
  const navigate = useNavigate();

  //Cargar datos iniciales
  useEffect(() => {
    const getData = async () => {
      const fantasies = await getFantasies();
      const types = await getPartyTypes();
      setInfo({
        ...info,
        fantasies: fantasies?.info?.data[0] ?? [],
        partyTypes: types?.info?.data[0] ?? [],
      });
    };
    if (authData) getData();
  }, [authData]);

  //Manejar cambios en los inputs
  const setFormData = (name: string, value: string) => {
    setData({
      ...data,
      [name]: value,
    });
  };

  const handleChangeInput = (e: FormEvent<HTMLInputElement>) => {
    const data = e.target as HTMLInputElement;
    setFormData(data.name, data.value);
  };
  const handleChangeSelect = (e: FormEvent<HTMLSelectElement>) => {
    const data = e.target as HTMLSelectElement;
    setFormData(data.name, data.value);
  };

  //Manejar envio del formulario

  const [check, setCheck] = useState(false);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [center, setCenter] = useState(INITIAL_CENTER);
  const [selectedPlace, setSelectedPlace] =
    useState<google.maps.places.PlaceResult | null>(null);

  useEffect(() => {
    if (
      selectedPlace?.geometry?.location?.lat() &&
      selectedPlace?.geometry?.location?.lng()
    ) {
      setCenter({
        lat: selectedPlace.geometry.location.lat(),
        lng: selectedPlace.geometry.location.lng(),
      });
    }
    if (selectedPlace?.formatted_address) {
      setCustomSearch(selectedPlace.formatted_address);
    }
  }, [selectedPlace]);

  const onCancel = () => {
    setFile(null);
    setFileUrl("");
    closeModal();
    setData(emptyState);
  };

  const onFileUpload = () => {
    if (inputRef.current) (inputRef.current as HTMLInputElement).click();
  };
  const handleChange = (e: FormEvent<HTMLInputElement>) => {
    if (
      e.nativeEvent.target instanceof HTMLInputElement &&
      e.nativeEvent.target.files
    ) {
      // process selected files
      const selectedFiles = Array.from(e.nativeEvent.target.files);
      if (selectedFiles.length > 0) {
        setFile(selectedFiles[0]);
      }
    }
  };
  useEffect(() => {
    if (file) {
      const fileUrl = URL.createObjectURL(file);
      setFileUrl(fileUrl);

      return () => {
        URL.revokeObjectURL(fileUrl);
      };
    }
  }, [file]);

  async function handleSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();

    // Validación inicial
    if (!time?.hour || !time?.minute) {
      toast.error("Por favor introduce una hora válida.");
      return;
    }

    const maxSize = 7.1; // Tamaño máximo permitido para el archivo (MB)
    if (file && round(file.size / (1024 * 1024)) > maxSize) {
      toast.error(`El archivo no debe superar los ${maxSize} MB`);
      setGoInvite(false);
      return;
    }

    setLoading(true);

    try {
      // Subida de archivos
      const imagen = file ? await handleFileUpload(file) : "";

      // Validar y formatear datos
      const tipoId =
        isNaN(Number(data.tipo_id)) || data.tipo_id === ""
          ? null
          : parseInt(data.tipo_id, 10);
      if (!tipoId) {
        toast.error("Por favor selecciona un Tipo de Fiesta válido.");
        return;
      }

      const fantasias = data.fantasias
        .split(",")
        .map((id) => parseInt(id, 10))
        .filter((id) => !isNaN(id));

      // Obtener dirección y ciudad
      const [address, city] = await Promise.all([
        getPlaceFormated(center.lat, center.lng),
        getCity(center.lat, center.lng),
      ]);

      // Crear el payload
      const payload = {
        ...data,
        tipo_id: tipoId, // Asegurado como número válido
        fantasias: fantasias.join(","), // Validado y formateado correctamente
        imagen,
        nombre: info.partyTypes.find((x) => x.id === tipoId)?.name ?? "",
        precio: isFree ? "0" : data.precio,
        capacidad: isUnlimited ? "0" : data.capacidad,
        fecha_inicio: date.start.toDate(getLocalTimeZone()),
        fecha_fin: date.end.toDate(getLocalTimeZone()),
        localizacion: `${center.lat}|${center.lng}`,
        direccion: address ?? customSearch,
        publica: data.publica === "true",
        estado: data.estado === "true",
        ciudad: city,
      };

      // Crear fiesta
      const responseData = await createParty(payload);

      if (responseData.error === "true") {
        toast.error(responseData.info.message);
      } else {
        toast.success(responseData.info.message);
        onSuccessCreateParty(responseData.info.data[0]);
        setInvite(goInvite);

        if (goInvite) {
          navigate(`/parties/${responseData.info.data[0].id}`);
        }

        resetForm();
      }
    } catch (error) {
      toast.error(
        "Ha ocurrido un error inesperado. Por favor, intenta de nuevo."
      );
      console.error(error);
    } finally {
      setLoading(false);
      setGoInvite(false);
    }
  }
  async function handleFileUpload(file: File): Promise<string> {
    try {
      const formData = new FormData();
      formData.append("files[0]", file, file.name);
      formData.append("type", "image");
      formData.append("folder[0]", "parties");

      const uploadResponse = await uploadFile(formData);
      if (uploadResponse.error === "true") {
        throw new Error(uploadResponse.info.message);
      }

      return uploadResponse.info.data[0].parties.file.content;
    } catch (error) {
      toast.error("Error al subir el archivo. Intenta de nuevo.");
      console.error(error);
      throw error;
    }
  }

  // Función para reiniciar el formulario
  function resetForm() {
    setFile(null);
    setFileUrl("");
    setData(emptyState);
    closeModal();
  }

  const onCheck = (e: FormEvent<HTMLInputElement>) => {
    const input = e.target as HTMLInputElement;
    const value = input.checked;
    setCheck(value);
  };

  useEffect(() => {
    if (
      selectedPlace?.geometry?.location?.lat() &&
      selectedPlace?.geometry?.location?.lng()
    ) {
      setCenter({
        lat: selectedPlace?.geometry?.location?.lat(),
        lng: selectedPlace?.geometry?.location?.lng(),
      });
    }
    if (selectedPlace?.formatted_address)
      setCustomSearch(selectedPlace.formatted_address);
  }, [selectedPlace]);
  const regex = /^(?!0*$)(?!0*\.0*$)\d*\.?\d+$/;

  function validateNumber(n: string) {
    return regex.test(n);
  }
  useEffect(() => {
    if (isFree) {
      setFormData("precio", "");
    }
  }, [isFree]);

  useEffect(() => {
    if (isUnlimited) {
      setFormData("capacidad", "");
    }
  }, [isUnlimited]);

  useEffect(() => {
    if (time?.hour && time.minute)
      setFormData("hora_inicio", time?.hour + ":" + time.minute);
  }, [time]);

  return (
    <Modal
      className="max-w-[820px]"
      isOpen={isOpen}
      placement="center"
      onClose={onCancel}
      classNames={{ closeButton: "mt-4 mr-4", base: "rounded-xl border-none" }}
      closeButton={<CloseIcon />}
    >
      <ModalContent>
        <form onSubmit={handleSubmit} className="py-3">
          <div className="w-full px-6 my-5">
            <h3 className="font-semibold text-color3 text-xl">
              Crear nueva Fiesta
            </h3>
            <p>Rellena los siguientes datos para crear tu Fiesta.</p>
          </div>

          {/* Grid principal: 1 columna en móviles y 2 columnas en lg */}
          <div className="w-full grid grid-cols-1 lg:grid-cols-2 gap-4 max-h-[400px] p-6 overflow-y-auto">
            {/* Dirección (ocupa ancho completo) */}
            <div className="flex items-center gap-4 mb-4 lg:col-span-2">
              <span className="text-color1">Dirección:</span>
              <AutocompleteCustom
                onPlaceSelect={setSelectedPlace}
                customValue={customSearch}
              />
            </div>

            {/* Mapa (ancho completo) */}
            <div className="w-full flex-row gap-4 flex lg:col-span-2">
              <div className="w-full min-h-[230px] bg-zinc-300 flex items-center justify-center rounded-xl border-2 border-color4 text-primary cursor-pointer overflow-hidden">
                <Map
                  defaultCenter={INITIAL_CENTER}
                  defaultZoom={10}
                  scrollwheel={false}
                  zoomControl={true}
                  gestureHandling="greedy"
                  disableDefaultUI={true}
                  onClick={async (e) => {
                    if (e.detail.latLng) {
                      const pos = e.detail.latLng;
                      setCenter(pos);
                      const res = await getPlaceFormated(pos.lat, pos.lng);
                      setCustomSearch(res ?? "");
                    }
                  }}
                >
                  <Marker
                    position={center}
                    draggable
                    onDragEnd={async (e) => {
                      const res = await getPlaceFormated(
                        e.latLng?.lat() ?? 0,
                        e.latLng?.lng() ?? 0
                      );
                      setCustomSearch(res ?? "");
                    }}
                    onDrag={(e) =>
                      setCenter({
                        lat: e.latLng?.lat() ?? 0,
                        lng: e.latLng?.lng() ?? 0,
                      })
                    }
                  />
                  <MapHandler place={selectedPlace} />
                </Map>
              </div>
            </div>

            {/* Imagen de portada (ancho completo) */}
            <div className="w-full my-3 lg:col-span-2">
              <label className="mb-4 text-color1">
                Imagen de portada<span className="text-danger">*</span>
              </label>
              <FileInput
                onClick={onFileUpload}
                file={fileUrl}
                type="image"
                className="mt-3 w-full min-h-[190px] rounded-xl border-1 border-color4 cursor-pointer overflow-hidden"
              />
              <input
                ref={inputRef}
                className="hidden"
                type="file"
                accept="image/*"
                onChange={handleChange}
              />
            </div>

            {/* Título de la fiesta (ancho completo) */}
            <div className="w-full mt-[2px] lg:col-span-2">
              <Input
                placeholder="Introduce un título"
                name="nombre"
                label="Título"
                labelPlacement="outside"
                radius="sm"
                isRequired
                variant="bordered"
                color="primary"
                className="w-full"
                classNames={{
                  inputWrapper: "rounded-lg border-small",
                  label: "text-color2",
                  input: "border-0 focus:ring-1 focus:ring-transparent",
                }}
                onChange={handleChangeInput}
                value={data.nombre}
              />
            </div>

            <div className="w-full mt-[2px] lg:col-span-2">
              <Textarea
                id="description"
                name="descripcion"
                isRequired
                variant="bordered"
                placeholder="Descripción"
                label="Agrega una descripción"
                labelPlacement="outside"
                required
                minRows={4}
                color="primary"
                classNames={{
                  inputWrapper: "rounded-lg border-small",
                  label: "text-color2",
                  input: "border-0 focus:ring-1 focus:ring-transparent",
                }}
                onChange={handleChangeInput}
                value={data.descripcion}
              />
            </div>

            {/* Los campos restantes se ubican en 2 columnas */}
            <div className="w-full mt-1">
              <DateRangePicker
                label="¿Cuando será?"
                labelPlacement="outside"
                radius="sm"
                variant="bordered"
                color="primary"
                isRequired
                minValue={today(getLocalTimeZone())}
                className="w-full"
                classNames={{
                  inputWrapper: "rounded-lg border-small",
                  label: "text-color2",
                  input: "border-0 focus:ring-1 focus:ring-transparent",
                }}
                selectorIcon={<CalendarIcon />}
                value={date}
                onChange={setDate}
              />
            </div>

            <TimeInput
              labelPlacement="outside"
              isRequired
              value={time}
              onChange={setTime}
              className="w-full"
              color="primary"
              name="hora_inicio"
              classNames={{
                inputWrapper: "h-[44px] rounded-lg border-small",
                label: "text-color2",
              }}
              label="Hora de inicio "
              variant="bordered"
              isInvalid={!time?.hour || !time?.minute}
              errorMessage="Por favor introdizca una hora válida"
            />

            <div className="w-full flex flex-col relative">
              <label className="text-sm text-gray-700">Tipo de Fiesta</label>
              <div className="relative">
                <CustomSearchableSelect
                  info={info.partyTypes}
                  handleChangeSelect={(selected: number[]) => {
                    const selectedId = selected[0] ?? null;
                    setFormData(
                      "tipo_id",
                      selectedId ? selectedId.toString() : ""
                    );
                  }}
                  data={{
                    fantasias: data.tipo_id ? [parseInt(data.tipo_id, 10)] : [],
                  }}
                />
              </div>
            </div>

            <div className="flex items-end gap-2">
              <Input
                placeholder="Capacidad máxima"
                label="Capacidad máxima"
                labelPlacement="outside"
                name="capacidad"
                radius="sm"
                variant="bordered"
                disabled={isUnlimited}
                isRequired={!isUnlimited}
                color="primary"
                className="w-full"
                classNames={{
                  inputWrapper: "rounded-lg border-small",
                  label: "text-color2",
                  input:
                    "border-0 focus:ring-1 focus:ring-transparent disabled:opacity-50",
                }}
                type="number"
                onChange={handleChangeInput}
                value={data.capacidad}
                isInvalid={
                  !!data.capacidad &&
                  !isUnlimited &&
                  !validateNumber(data.capacidad)
                }
                errorMessage="La capacidad debe ser válida"
              />
              <Switch
                isSelected={isUnlimited}
                onValueChange={setIsUnlimited}
                aria-label="Ilimitada"
                className="mb-3"
              >
                Ilimitada
              </Switch>
            </div>

            <div className="w-full mt-1">
              <div className="flex items-end gap-2">
                <Input
                  placeholder="Precio por persona"
                  label="Precio"
                  labelPlacement="outside"
                  name="precio"
                  disabled={isFree}
                  radius="sm"
                  variant="bordered"
                  isRequired={!isFree}
                  color="primary"
                  className="w-full"
                  classNames={{
                    inputWrapper: "rounded-lg border-small",
                    label: "text-color2",
                    input:
                      "border-0 focus:ring-1 focus:ring-transparent disabled:opacity-50",
                  }}
                  onChange={handleChangeInput}
                  type="number"
                  value={data.precio}
                  isInvalid={
                    !!data.precio && !isFree && !validateNumber(data.precio)
                  }
                  errorMessage="El precio debe ser válido"
                />
                <Switch
                  isSelected={isFree}
                  onValueChange={setIsFree}
                  aria-label="Gratis"
                  className="mb-3"
                >
                  Gratis
                </Switch>
              </div>
            </div>

            <div className="w-full mt-1 flex flex-col relative">
              <label className="mb-2 text-sm text-gray-700">
                Fantasias destacadas
              </label>
              <div className="relative">
                <CustomSearchableSelect
                  info={info.fantasies}
                  handleChangeSelect={(selected: number[]) => {
                    // Puedes transformar el array de números en cadena, si así lo requiere tu estado
                    const selectedIds = selected.join(",");
                    setFormData("fantasias", selectedIds);
                  }}
                  data={{
                    fantasias: data.fantasias
                      .split(",")
                      .map((id) => parseInt(id, 10))
                      .filter((id) => !isNaN(id)),
                  }}
                />
              </div>
            </div>

            <div className="w-full mt-1 flex justify-center">
              <Select
                variant="bordered"
                placeholder="Seleccionar.."
                label={
                  <div className="inline-flex">
                    <Tooltip
                      content={
                        <div className="max-w-sm text-color2">
                          <p>
                            <b>Oculta:</b> no aparecerá en las búsquedas, un
                            usuario no podrá verla a menos que le envies el link
                          </p>
                          <p>
                            <b>Visible:</b> Aparecerá en las búsquedas y
                            cualquiera podrá verla.
                          </p>
                        </div>
                      }
                      placement="top"
                    >
                      <span className="inline-flex font-normal text-color2 items-center gap-2">
                        Estado <InfoCircleIcon className="text-color1" />
                      </span>
                    </Tooltip>
                  </div>
                }
                labelPlacement="outside"
                onChange={handleChangeSelect}
                value={data.estado}
                isRequired
                color="primary"
                classNames={{
                  trigger: "h-[44px] rounded-lg border-small",
                  label: "text-color2",
                  popoverContent: "rounded-md",
                }}
                name="estado"
                className="w-full"
              >
                <SelectItem key="false" value="false">
                  Oculta
                </SelectItem>
                <SelectItem key="true" value="true">
                  Visible
                </SelectItem>
              </Select>
            </div>

            <div className="w-full">
              <CustomRadioGroup
                title={
                  <div className="inline-flex">
                    <Tooltip
                      content={
                        <div className="max-w-sm text-color2">
                          <p>
                            <b>Privada:</b> Un usuario no podrá unirse a menos
                            que le hayas invitado.
                          </p>
                          <p>
                            <b>Pública:</b> Cualquiera podrá unirse.
                          </p>
                        </div>
                      }
                      placement="top"
                    >
                      <span className="inline-flex font-normal text-color2 items-center gap-2">
                        Tipo de Acceso{" "}
                        <InfoCircleIcon className="text-color1" />
                      </span>
                    </Tooltip>
                  </div>
                }
                required
                name="publica"
                value={data.publica}
                showVisbility={false}
                onChange={handleChangeInput}
                items={[
                  { label: "Privada", value: "false" },
                  { label: "Pública", value: "true" },
                ]}
              />
            </div>

            {/* Términos (ancho completo) */}
            <div className="w-full mt-1 lg:col-span-2">
              <div className="mt-4 w-full">
                <CustomCheckBox isRequired checked={check} onChange={onCheck}>
                  <p>
                    He leído y acepto los{" "}
                    <Link
                      target="_blank"
                      to="https://loouth.com/terminos-y-condiciones"
                      className="underline underline-offset-2 cursor-pointer"
                    >
                      Términos y Condiciones
                    </Link>
                  </p>
                </CustomCheckBox>
              </div>
            </div>
          </div>

          <div className="w-full grid grid-cols-1 lg:grid-cols-3 justify-center mt-3 gap-4 px-6 mb-5">
            <SecondaryButton
              onClick={onCancel}
              disabled={loading}
              className="w-full self-end"
            >
              Cancelar
            </SecondaryButton>
            <PrimaryButton
              loading={loading && !goInvite}
              type="submit"
              disabled={loading}
              className="w-full self-end"
            >
              Aceptar
            </PrimaryButton>
            <PrimaryButton
              loading={loading && goInvite}
              disabled={loading}
              type="submit"
              className="w-full self-end"
              onClick={() => {
                setGoInvite(true);
              }}
            >
              Continuar e invitar
            </PrimaryButton>
          </div>
        </form>
      </ModalContent>
    </Modal>
  );
}
