/* eslint-disable no-mixed-spaces-and-tabs */
import { useEffect, useState } from "react";
import PostImage from "../post/PostImage";
import { Post } from "../../interfaces/Post";
import { User } from "../../interfaces/Login";
import { getPublicationsByUser } from "../../api/publication";
import { toast } from "react-toastify";
import { Card, Chip, Pagination, Skeleton } from "@nextui-org/react";
import { Pagination as PaginationData } from "../../interfaces/pagination";
import { useAuthStore } from "../../stores/authStore";

export default function Galery({ user }: { user: User }) {
  const [posts, setPosts] = useState<Post[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [media, setMedia] = useState<"image" | "video">("image"); // Tipo de media
  const [visibility, setVisibility] = useState<"public" | "private" | "all">(
    "all"
  ); // Visibilidad
  const [pagination, setPagination] = useState<PaginationData>({
    current_page: 0,
    last_page: 0,
    total: 0,
    per_page: 1,
  });
  const [galleryData, setGalleryData] = useState({
    imagenes_publicas: 0,
    imagenes_privadas: 0,
    videos_publicos: 0,
    videos_privados: 0,
    total_galeria: 0,
  });

  const authData = useAuthStore((state) => state.user);
  useEffect(() => {
    getData(1);
  }, [user, media, visibility]);

  const getData = async (page?: number) => {
    setIsLoading(true);

    const filterMedia = media;
    const filterVisibility = visibility === "all" ? undefined : visibility;

    const data = await getPublicationsByUser(
      {
        user_id: user.id,
        media_type: filterMedia,
        ...(filterVisibility && { visibility: filterVisibility }),
        page,
      },
      true
    );

    if (data?.error === "true" || !data) {
      toast.error(data.info.message + " ");
      setIsLoading(false);
      return;
    }

    if (data?.info?.data[0]?.data) {
      setPosts(data.info.data[0].data);
      setPagination({
        current_page: data.info.data[0].current_page,
        last_page: data.info.data[0].last_page,
        total: data.info.data[0].total,
        per_page: data.info.data[0].per_page,
      });
      // ✅ Guardar datos de la galería directamente desde la API
      setGalleryData(data.info.gallery_data);
    }

    setIsLoading(false);
  };

  const onPageChange = async (page: number) => {
    getData(page);
  };

  const handleMediaFilter = (type: "image" | "video") => {
    setMedia(type);
    setVisibility("all");
  };

  const handleVisibilityFilter = (type: "public" | "private") => {
    setVisibility((prev) => (prev === type ? "all" : type));
  };

  return (
    <>
      {/* 📌 Encabezado de la galería */}
      <div className="mt-10 flex justify-between px-4 md:px-6 mb-2">
        <h3 className="font-bold text-[18px] text-color3  mr-2">Galería</h3>
        <div className="flex flex-wrap gap-2 md:gap-3">
          {/* ✅ Filtros por tipo de media */}
          <Chip
            onClick={() => handleMediaFilter("image")}
            color={media === "image" ? "primary" : "default"}
            variant="bordered"
            className="cursor-pointer"
          >
            Fotos (
            {galleryData.imagenes_publicas + galleryData.imagenes_privadas})
          </Chip>
          <Chip
            onClick={() => handleMediaFilter("video")}
            color={media === "video" ? "primary" : "default"}
            variant="bordered"
            className="cursor-pointer"
          >
            Videos ({galleryData.videos_publicos + galleryData.videos_privados})
          </Chip>

          {/* ✅ Contador total */}
          <Chip color="primary" variant="solid" className="font-medium">
            Total:{" "}
            <span className="font-medium">{galleryData.total_galeria}</span>
          </Chip>
        </div>
      </div>
      <div className="flex justify-end gap-3 px-4 mb-4 md:px-6">
        {/* ✅ Filtros por visibilidad (Público / Privado) */}
        <Chip
          onClick={() => handleVisibilityFilter("public")}
          color={visibility === "public" ? "primary" : "default"}
          variant="dot"
          className="cursor-pointer"
        >
          Público (
          {media === "image"
            ? galleryData.imagenes_publicas
            : galleryData.videos_publicos}
          )
        </Chip>
        <Chip
          onClick={() => handleVisibilityFilter("private")}
          color={visibility === "private" ? "primary" : "default"}
          variant="dot"
          className="cursor-pointer"
        >
          Privado (
          {media === "image"
            ? galleryData.imagenes_privadas
            : galleryData.videos_privados}
          )
        </Chip>
        <div className="w-6"></div>
      </div>

      {/* 📌 Mensaje si no hay contenido */}
      <div className="flex justify-start px-6 mb-10 ">
        {!isLoading && posts.length === 0 && (
          <p>
            No hay {media === "video" ? "videos" : "imágenes"}{" "}
            {visibility === "private" ? "privadas " : ""}
            para mostrar 😕
          </p>
        )}
      </div>

      {/* 📌 Galería de imágenes/videos */}
      <div className="w-full gap-4 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 px-8">
        {isLoading
          ? [1, 2, 3, 4, 5, 6].map((post) => (
              <Card
                key={post}
                className="h-[300px] p-2 rounded-xl shadow-none border border-gray-200"
              >
                <Skeleton className="rounded-lg">
                  <div className="h-56 rounded-lg bg-default-300"></div>
                </Skeleton>
              </Card>
            ))
          : posts.map((post) => (
              <div
                key={post.id}
                className="relative rounded-2xl overflow-hidden"
              >
                <PostImage post={post} />
                {/* ✅ Mostrar el candado si es privado */}
                {post.blur && authData?.id != post.user_id && (
                  <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex items-center justify-center gap-2 p-2 rounded">
                    <span className="text-sm text-white text-center">
                      Debes ser amigo de {post.user.name} para ver el contenido
                      privado
                    </span>
                  </div>
                )}
              </div>
            ))}
      </div>

      {/* 📌 Paginación */}
      {pagination &&
        pagination.current_page > 0 &&
        pagination.last_page > 1 && (
          <div className="px-4 mt-3 mb-2 w-full">
            <div className="flex justify-center">
              <Pagination
                showControls
                total={pagination.last_page}
                initialPage={1}
                page={pagination.current_page}
                onChange={onPageChange}
              />
            </div>
          </div>
        )}
    </>
  );
}
