import { SVGProps } from "react";

export function AddIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill={props.stroke ?? "#888888"}
        d="M11 13H5v-2h6V5h2v6h6v2h-6v6h-2z"
      ></path>
    </svg>
  );
}
