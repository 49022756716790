//

import NavBar from "../components/common/Navbar";
import Footer from "../components/common/Footer";
import { useEffect, useState } from "react";
import { useAuthStore } from "../stores/authStore";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Divider,
  Link,
  Button,
  Spinner,
  Chip,
} from "@nextui-org/react";
import PremiumIcon from "../assets/icons/PremiumIcon";
import PremiumModal from "../components/common/PremiumModal";
import PlanModal from "../components/common/PlanModal";
import PaymentInformationModal from "../components/common/PaymentInformationModal";
import { SubscriptionRequest } from "../interfaces/Login";
import { getUser } from "../api/user";
import { getCustomerPortalLink } from "../api/mine";
import { withErrorHandler } from "../utils/withErrorFallback";

export default function Settings() {
  const authData = useAuthStore((state) => state.user);
  const setUserData = useAuthStore((state) => state.saveUser);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [openPlanModal, setIsOpenPlanModal] = useState(false);
  const [openPurchaseModal, setIsOpenPurchaseModal] = useState(false);
  const [planSelected, setPlanSelected] = useState({
    monto: 0,
    anual: false,
  });
  const [portalUrl, setPortalUrl] = useState("");
  const onPrmiumClick = () => {
    setIsModalOpen(!isModalOpen);
  };
  const onViewPlans = () => {
    setIsModalOpen(false);
    setIsOpenPlanModal(true);
  };
  const onCompletePurchase = (plan: SubscriptionRequest) => {
    setPlanSelected(plan);
    setIsOpenPurchaseModal(true);
    setIsOpenPlanModal(false);
  };

  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    if (authData) {
      const getData = withErrorHandler(async () => {
        setIsLoading(true);
        const userRes = await getUser(authData!.id);
        const userData = userRes.info.data[0];
        setUserData({
          ...authData,
          ...userData,
        });
        const portalSession = await getCustomerPortalLink();
        // console.log("portalSession", portalSession);
        if (portalSession?.info?.data?.url) {
          setPortalUrl(portalSession?.info?.data?.url);
        }
        setIsLoading(false);
      });
      getData();
    }
  }, []);

  const properties = [
    "Chat Ilimitado",
    "Acceso al buscador",
    "Acceso a fiestas",
    "Acceso a viajes",
  ];

  return (
    <div className=" h-screen ">
      <NavBar />
      <main className="flex justify-center">
        <div className="flex max-w-8xl w-full overflow-hidden pt-6 md:pt-18 min-h-[100vh] flex-col">
          <div className="px-4 md:px-8 w-full max-w-8xl mb-4">
            <section className="w-full mb-4">
              <div className="mt-2 flex flex-col items-center">
                <h1 className="text-3xl font-semibold text-black">
                  Gestiona tu membresía
                </h1>
                {isLoading ? (
                  <div className="w-full flex justify-center pt-20 h-[50vh]">
                    <Spinner color="primary" />
                  </div>
                ) : authData?.is_payed ? (
                  <Card className="max-w-screen-md w-full mt-10">
                    <CardHeader className="flex gap-3">
                      <div className="border border-primary/60 bg-primary/10 p-2.5 rounded-lg">
                        <PremiumIcon stroke="#A24BFD" />
                      </div>
                      <div className="flex flex-col w-full">
                        <p className="text-md">
                          Suscripción
                          {authData?.suscription?.anual
                            ? " anual"
                            : " mensual"}{" "}
                          a loouth
                        </p>
                        <div className="w-full flex flex-row justify-between">
                          <p className="text-small text-default-500">
                            Desde{" "}
                            {authData?.suscription?.created_at
                              ? new Date(
                                  authData?.suscription?.created_at
                                ).toLocaleDateString("es")
                              : ""}
                          </p>
                          <p className="text-small text-default-500">
                            Vence:{" "}
                            <span className="font-semibold text-primary">
                              {" "}
                              {authData?.suscription?.vence
                                ? new Date(
                                    authData?.suscription?.vence
                                  ).toLocaleDateString("es")
                                : ""}
                            </span>
                          </p>
                        </div>
                      </div>
                    </CardHeader>
                    <Divider />
                    <CardBody className="flex flex-row justify-between">
                      <div>
                        {properties.map((item) => (
                          <li className="flex gap-3 py-1.5" key={item}>
                            <svg
                              width="24"
                              height="25"
                              viewBox="0 0 24 25"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g clipPath="url(#clip0_367_4890)">
                                <path
                                  d="M7.5 12.5L10.5 15.5L16.5 9.5M22 12.5C22 18.0228 17.5228 22.5 12 22.5C6.47715 22.5 2 18.0228 2 12.5C2 6.97715 6.47715 2.5 12 2.5C17.5228 2.5 22 6.97715 22 12.5Z"
                                  stroke="#A24BFD"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_367_4890">
                                  <rect
                                    y="0.5"
                                    width="24"
                                    height="24"
                                    rx="12"
                                    fill="white"
                                  />
                                </clipPath>
                              </defs>
                            </svg>
                            {item}
                          </li>
                        ))}
                      </div>
                      <div className="">
                        <Chip
                          size="lg"
                          variant="flat"
                          color="primary"
                          className="mt-2 font-bold"
                        >
                          <p className="font-semibold text-lg">
                            {authData?.suscription?.monto
                              ? parseInt(authData.suscription.monto) / 100
                              : ""}{" "}€ 
                            <span>
                              {authData?.suscription?.anual ? " /año" : " /mes"}
                            </span>
                            <span className="">💰</span>
                          </p>
                        </Chip>
                      </div>
                    </CardBody>
                    <Divider />
                    <CardFooter>
                      {portalUrl ? (
                        <Link showAnchorIcon href={portalUrl}>
                          Visitar mi portal de cliente
                        </Link>
                      ) : (
                        <span>Error al traer el link para los usuarios que tienen la primera suscripción</span>
                      )}
                    </CardFooter>
                  </Card>
                ) : (
                  <div className="mt-10 flex flex-col justify-center items-center">
                    <p className="text-lg">
                      Hazte premium para disfrutar de todas las ventajas de
                      Loouth
                    </p>
                    <Button
                      onClick={onPrmiumClick}
                      variant="bordered"
                      className="mt-6 border-1 rounded-lg border-color4 border-color4/30 hidden xl:flex bg-color4/5"
                    >
                      <PremiumIcon stroke={"#475467"} />
                      <label className="text-color4 font-semibold">
                        Hazte Premium
                      </label>
                    </Button>
                  </div>
                )}
              </div>
            </section>
          </div>
        </div>
      </main>
      <PremiumModal
        isOpen={isModalOpen}
        closeModal={onPrmiumClick}
        onViewPlans={onViewPlans}
      />
      <PlanModal
        isOpen={openPlanModal}
        closeModal={() => setIsOpenPlanModal(false)}
        finalizePurchase={onCompletePurchase}
      />
      <PaymentInformationModal
        plan={planSelected}
        isOpen={openPurchaseModal}
        closeModal={() => setIsOpenPurchaseModal(false)}
      />
      <Footer />
    </div>
  );
}
