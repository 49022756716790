import { useState } from "react";
import axios from "axios";
import { FireIcon } from "../../assets/icons/FireIcon";
import { User } from "../../interfaces/Login";
import { useAuthStore } from "../../stores/authStore";
import PrimaryButton from "../common/PrimaryButton";

export default function Affinity({ userData }: { userData: User }) {
  const affinity = userData?.afinidad ?? 0;
  const [liked, setLiked] = useState(!!userData?.like); // Estado inicial para "me gusta"
  const [totalLikes, setTotalLikes] = useState(userData?.likesCount ?? 0); // Estado inicial para total de "me gusta"
  const [loading, setLoading] = useState(false); // Estado para mostrar el indicador de carga

  // Obtener el token desde el store
  const token = useAuthStore.getState().token;

  // Función para manejar el "me gusta"
  const handleLike = async () => {
    if (loading) return; // Evitar solicitudes duplicadas
    setLoading(true); // Iniciar carga

    try {
      // Enviar solicitud al backend con Axios
      await axios.post(
        `https://api.loouth.com/api/user/${userData.id}/like`,
        { like: !liked }, // Datos enviados al backend
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`, // Enviar el token en los headers
          },
        }
      );

      // Cambiar el estado local basado en la respuesta
      setLiked((prevLiked) => !prevLiked);
      setTotalLikes((prevLikes) => (liked ? prevLikes - 1 : prevLikes + 1));
    } catch (error) {
      console.error("Error al dar me gusta:", error);
    } finally {
      setLoading(false); // Finalizar carga
    }
  };

  return (
    <div
      className={`min-h-44 mb-4 flex flex-col gap-4 p-6 justify-center border-1 rounded-2xl ${
        affinity < 50 ? "bg-gray-400" : "bg-primary"
      }`}
    >
      <div className="flex justify-center gap-3">
        <div
          className={`flex justify-center items-center size-[74px] rounded-full bg-white ${
            affinity < 50 ? "text-gray-400" : "text-primary"
          }`}
        >
          <span className="font-medium text-xl">
            {userData?.afinidad ? Math.round(userData?.afinidad) : "?"}%
          </span>
        </div>
        <div className="flex flex-col gap-1 pt-4">
          <span className="font-medium text-[14px] text-white/60">
            Afinidad
          </span>
          <span className="font-medium text-xl text-white">
            {affinity > 70 ? "Alta" : affinity >= 50 ? "Media" : "Baja"}
          </span>
        </div>
      </div>
      <div className="w-full text-white">
        {/* Mostrar afinidad */}
        {affinity > 70 ? (
          <div>
            <p className="font-medium leading-5 text-[17px] text-center">
              ¡Ey! Tienes muy buena afinidad con {userData?.name}.
            </p>
            <p className="text-[16px] text-center">
              Anímate y envíale un mensaje.
            </p>
          </div>
        ) : affinity >= 50 ? (
          <div>
            <p className="font-medium leading-5 text-center text-[17px] mb-2">
              Tu afinidad con {userData?.name} es normal, tal vez tengas algo en
              común.
            </p>
            <p className="text-small text-center">
              Anímate y envíale un mensaje.
            </p>
          </div>
        ) : (
          <div>
            <p className="font-medium leading-5 text-center text-[17px] mb-2">
              Parece que no tienes muchas coincidencias con {userData?.name}.
            </p>
            <p className="text-small text-center">
              ¡A lo mejor podéis tener algo en común!
            </p>
          </div>
        )}

        {/* Botón de "me gusta" con indicador de carga */}
        <PrimaryButton
          onClick={handleLike}
          className="bg-white text-primary font-semibold w-full mt-2 flex items-center justify-center"
          disabled={loading} // Deshabilitar botón mientras carga
        >
          {loading ? (
            <span className="flex items-center">
              <span className="spinner-border animate-spin inline-block w-4 h-4 border-2 rounded-full mr-2"></span>
              Cargando...
            </span>
          ) : (
            <>
              <FireIcon /> {liked ? "¡Le has dado me gusta!" : "¡Me gusta!"}
            </>
          )}
        </PrimaryButton>

        {/* Mostrar total de "me gusta" */}
        <p className="text-center text-sm mt-2">
          A {totalLikes}{" "}
          {totalLikes === 1 ? "persona le gusta" : "personas les gusta"} este
          perfil.
        </p>
      </div>
    </div>
  );
}
