import { create } from "zustand";
import { User } from "../interfaces/Login";

interface UserState {
  user: User | null;
  saveUser: (data: User | null) => void;
}

export const useUserStore = create<UserState>()((set) => ({
  user: null,
  saveUser: (data) => set(() => ({ user: data })),
}));

export const infoDetals = () =>
  useUserStore.getState().user?.info?.detalles ?? null;
export const infoOthers = () =>
  useUserStore.getState().user?.info?.otros ?? null;
export const infoHowAmI = () =>
  useUserStore.getState().user?.info?.como_soy ?? null;
