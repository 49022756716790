/* eslint-disable no-mixed-spaces-and-tabs */
import {
	Modal,
	ModalContent,
	Skeleton,
	Input,
	User as UserItem,
	Chip,
	Spinner,
	Badge,
	Avatar,
} from "@nextui-org/react";
import SecondaryButton from "../common/SecondaryButton";
import { CloseIcon } from "../../assets/icons/CloseIcon";
// import { Link } from "react-router-dom";
import { FormEvent, Fragment, useEffect, useMemo, useState } from "react";
import { User } from "../../interfaces/Login";
import { SearchIcon } from "../../assets/icons/SearchIcon";
import { Party } from "../../interfaces/Party";
import { Trip } from "../../interfaces/Trip";
import { searchAll } from "../../api/mine";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import BuildingIcon from "../../assets/icons/BuildingIcon";
import { useHomeStore } from "../../stores/homeData";

export default function SearchModal({
	isOpen = false,
	closeModal = () => {},
}: {
	isOpen: boolean;
	closeModal?: () => void;
}) {
	const [isLoading, setIsLoading] = useState(false);

	const [users, setUsers] = useState<User[]>([]);
	const [parties, setParties] = useState<Party[]>([]);
	const [trips, setTrips] = useState<Trip[]>([]);

	const [search, setSearch] = useState("");
	const [debouncedSearch, setDebouncedSearch] = useState("");
	const handleSubmit = (e: FormEvent) => {
		e.preventDefault();
	};
	const handleChange = (e: FormEvent<HTMLInputElement>) => {
		const data = e.target as HTMLInputElement;
		setSearch(data.value);
	};
	const homeData = useHomeStore((state) => state.home);
	const friends = useMemo(() => {
		const res = homeData.amigos.map((e) => {
			const user = e.to == "me" ? e.from : e.to;
			return {
				...(user as User),
				// id: e.id,
			};
		});
		return res ?? [];
	}, [homeData.amigos]);

	const getData = async () => {
		if (search.length < 1) {
			setUsers([]);
			setParties([]);
			setTrips([]);
			return;
		}
		setIsLoading(true);

		const res = await searchAll(search);
		if (res.error == "false" && res?.info?.data) {
			const sortedUsers = res.info.data.usuarios;
			console.log(sortedUsers);
			console.log(friends);
			sortedUsers.sort((a: User, b: User) => {
				if (friends.find((it) => it.id == a.id)) return -1;
				if (friends.find((it) => it.id == b.id)) return 1;
				return 0;
			});
			setUsers(sortedUsers);
			setParties(res.info.data.fiestas);
			setTrips(res.info.data.viajes);
		} else toast.error("Ha ocurrido un error cargando los datos.");

		setIsLoading(false);
	};

	useEffect(() => {
		const timeout = setTimeout(() => {
			setDebouncedSearch(search);
		}, 500);
		return () => clearTimeout(timeout);
	}, [search]);

	useEffect(() => {
		getData();
	}, [debouncedSearch]);

	const onclose = () => {
		closeModal();
		setSearch("");
	};

	return (
		<Modal
			className=""
			isOpen={isOpen}
			placement="center"
			onClose={onclose}
			classNames={{
				closeButton: "mt-4 mr-4",
				base: "rounded-xl border-none",
			}}
			closeButton={<CloseIcon />}
			scrollBehavior="inside"
			size="xl"
		>
			<ModalContent>
				<div className="">
					<div className="flex ">
						<div className="text-start w-full">
							<h3 className="mt-6 font-semibold text-color3 text-xl  px-6">
								Buscar perfiles, viajes y fiestas
							</h3>
							<p className="mt-2 font-normal text-[14px] text-color5"></p>
							<form className="px-4" onSubmit={handleSubmit}>
								<Input
									className="min-w-sm text-input w-full "
									id="search"
									type="text"
									variant="bordered"
									label=""
									startContent={<SearchIcon />}
									placeholder="Buscar..."
									required
									value={search}
									onChange={handleChange}
									endContent={
										search.length >= 1 && isLoading && <Spinner size="sm" color="primary" />
									}
									classNames={{ inputWrapper: "border-1 rounded-lg" }}
								/>
							</form>
							<div className="h-[330px] mt-4 py-2 gap-1 flex flex-col    overflow-auto w-full  px-6">
								{search.length == 0 && "Escribe algo para comenzar a buscar"}
								{isLoading ? (
									[0, 0].map((_, i) => (
										<Fragment key={"k" + i}>
											<div className="max-w-[300px] w-full flex items-center gap-3 p-2">
												<div>
													<Skeleton className="flex rounded-full w-12 h-12" />
												</div>
												<div className="w-full flex flex-col gap-2">
													<Skeleton className="h-3 w-3/5 rounded-lg" />
													<Skeleton className="h-3 w-4/5 rounded-lg" />
												</div>
											</div>
											<div className="max-w-[300px] w-full flex items-center gap-3 p-2">
												<div>
													<Skeleton className="flex rounded-md w-12 h-12" />
												</div>
												<div className="w-full flex flex-col gap-2">
													<Skeleton className="h-3 w-3/5 rounded-lg" />
													<Skeleton className="h-3 w-4/5 rounded-lg" />
												</div>
											</div>
										</Fragment>
									))
								) : !users.length && !parties.length && !trips.length && search.length > 0 ? (
									// Si la búsqueda no está vacía y no hay usuarios, fiestas o viajes
									<div>No hay ningún resultado</div>
								) : (
									<div className="flex flex-col">
										{/* USERS */}
										<div hidden={!users.length} className="mb-2">
											<Chip variant="flat" color="primary" className="mt-2">
												Usuarios{" "}
											</Chip>
										</div>
										{users.map((e) => (
											<Link
												to={`/perfil/${e.slug}`}
												key={"u-" + e.id}
												className="cursor-pointer hover:bg-primary/10 rounded-md transition-colors ease-in-out p-2"
											>
												<div className="flex gap-2">
													<Badge
														color="primary"
														isOneChar
														placement="bottom-right"
														className="bg-green-500"
														isInvisible={e?.role_id != "3"}
														content={
															e?.role_id == "3" && (
																<BuildingIcon height={12} width={12}></BuildingIcon>
															)
														}
													>
														<Avatar
															showFallback
															classNames={{
																base: "bg-zinc-100",
																icon: "text-black/50",
															}}
															className="cursor-pointer flex-shrink-0 min-w-10 h-10"
															name={e.name}
															src={
																e?.profile_path
																	? e?.profile_path?.startsWith("http")
																		? e?.profile_path
																		: import.meta.env.VITE_BASE_URL +
																		  e?.profile_path
																	: undefined
															}
														></Avatar>
													</Badge>

													<div>
														<p className="text-sm">{e.name}</p>
														{/* <p className="text-[12px] text-gray-400">{e.email}</p> */}
													</div>
												</div>
											</Link>
										))}
										{/* PARTIES */}
										<div hidden={!parties.length} className="mb-2">
											<Chip variant="flat" color="primary" className="mt-2">
												Fiestas{" "}
											</Chip>
										</div>
										{parties.map((e) => (
											<Link
												to={`/parties/${e.id}`}
												key={"p-" + e.id}
												className="cursor-pointer hover:bg-primary/10 rounded-md transition-colors ease-in-out p-2"
											>
												<UserItem
													name={e.nombre}
													description={e.descripcion.slice(0, 80) + "..."}
													avatarProps={{
														radius: "sm",
														src: e?.imagen
															? e?.imagen?.startsWith("http")
																? e?.imagen
																: import.meta.env.VITE_BASE_URL + e?.imagen
															: undefined,
													}}
												/>
											</Link>
										))}

										{/* TRIPS */}
										<div hidden={!trips.length} className="mb-2">
											<Chip variant="flat" color="primary" className="mt-2">
												Viajes{" "}
											</Chip>
										</div>
										{trips.map((e) => (
											<Link
												to={`/trips/${e.id}`}
												key={"t-" + e.id}
												className="cursor-pointer hover:bg-primary/10 rounded-md transition-colors ease-in-out p-2"
											>
												<UserItem
													name={e.nombre}
													description={e.descripcion.slice(0, 80) + "..."}
													avatarProps={{
														radius: "sm",
														src: e?.imagen
															? e?.imagen?.startsWith("http")
																? e?.imagen
																: import.meta.env.VITE_BASE_URL + e?.imagen
															: undefined,
													}}
												/>
											</Link>
										))}
									</div>
								)}
							</div>
						</div>
					</div>
					<div className="p-6 pt-0 md:flex block justify-end ">
						<div className="md:mt-0 mt-4 flex gap-[12px]">
							<SecondaryButton className="md:w-auto w-full" onClick={onclose}>
								Cancelar
							</SecondaryButton>
						</div>
					</div>
				</div>
			</ModalContent>
		</Modal>
	);
}
