import { useEffect, useRef } from "react";
import videojs from "video.js";
import Player from "video.js/dist/types/player";
import "video.js/dist/video-js.css";
import "../../assets/video-styles.css";

interface videoJSProps {
	options: {
		autoplay: boolean;
		sources: any[];
		controls: boolean;
		fluid: boolean;
		responsive: boolean;
		preload: string;
		experimentalSvgIcons: boolean;
		poster: string;
		userActions?: {
			click: boolean;
		};
		heigh?: number | string;
	};
	onReady: (player: Player) => void;
}

export default function VideoPlayer(props: videoJSProps) {
	const videoRef = useRef<any | null>(null);
	const playerRef = useRef<any | null>(null);
	const { options, onReady } = props;

	useEffect(() => {
		if (!playerRef.current) {
			const videoElement = document.createElement("video-js");

			videoElement.classList.add("vjs-big-play-centered");
			videoRef.current?.appendChild(videoElement);

			const player = (playerRef.current = videojs(videoElement, options, () => {
				videojs.log("player is ready");
				onReady && onReady(player);
			}));
			// player.crossOrigin('anonymous')
		} else {
			const player = playerRef.current;
			// player.crossOrigin('anonymous')
			player.autoplay(options.autoplay);
			player.src(options.sources);
		}
	}, [options, videoRef]);

	// Dispose the Video.js player when the functional component unmounts
	useEffect(() => {
		const player = playerRef.current;

		return () => {
			if (player && !player.isDisposed()) {
				player.dispose();
				playerRef.current = null;
			}
		};
	}, [playerRef]);

	return (
		<div data-vjs-player>
			<div ref={videoRef} />
		</div>
	);
}
