/* eslint-disable no-mixed-spaces-and-tabs */
import {
  Avatar,
  AvatarGroup,
  Button,
  CircularProgress,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
  Modal,
  ModalContent,
  Skeleton,
  Spinner,
  Textarea,
} from "@nextui-org/react";
import { Post } from "../../interfaces/Post";
import { FormEvent, useEffect, useMemo, useRef, useState } from "react";
import {
  addPostComment,
  getPostComments,
  getPublication,
} from "../../api/publication";
import { toast } from "react-toastify";
import { useAuthStore } from "../../stores/authStore";
import { CloseIcon } from "../../assets/icons/CloseIcon";
import { EmojiStyle } from "emoji-picker-react";
import EmojiPicker from "emoji-picker-react";
import { EmojiIcon } from "../../assets/icons/EmojiIcon";
import ChatBubble from "../chat/ChatBubble";
import { Comment } from "../../interfaces/Comment";
import { useHomeStore } from "../../stores/homeData";
import Moment from "react-moment";
import { Link } from "react-router-dom";
import { IconamoonSendLight } from "../../assets/icons/SendIcon";
import LightBoxImage from "../common/LightBoxImage";
// import VideoPlayer from "../common/VideoPlayer";
import videojs from "video.js";
import "video.js/dist/video-js.css";

export default function CommentPostModal({
  isOpen = false,
  closeModal = () => {},
  post,
  showComments = true,
}: {
  isOpen: boolean;
  closeModal?: () => void;
  post: Post;
  showComments?: boolean;
}) {
  const [loading, setLoading] = useState(false);
  const [isLoadingCmts, setIsLoadingCmts] = useState(false);
  const [comment, setComent] = useState("");
  const [comments, setComents] = useState<Comment[]>([]);
  const container = useRef(null);
  const [hasNewComment, setHasNewComment] = useState(false);
  const [fullPostData, setFullPostData] = useState(post);

  const firstCommentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isOpen && showComments && firstCommentRef.current) {
      setTimeout(() => {
        if (firstCommentRef.current) {
          firstCommentRef.current.scrollIntoView({
            behavior: "smooth",
            block: "start",
          });
        }
      }, 100);
    }
  }, [isOpen, showComments, comments]);

  const [isOpenLightbox, setIsOpenLightbox] = useState({
    open: false,
    type: "image" as "image" | "video",
    poster: "",
    src: "",
  });
  const setNewComment = useHomeStore((state) => state.setNewComment);
  // const newComment = useHomeStore((state) => state.newComment);

  const authData = useAuthStore((state) => state.user);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onEmojiClick = (event: any, _: unknown) => {
    // console.log(event);
    setComent(comment + event.emoji);
  };

  const onCancel = () => {
    closeModal();
  };
  const handleChange = (e: FormEvent<HTMLInputElement>) => {
    const data = e.target as HTMLInputElement;
    setComent(data.value);
  };
  async function handleSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();

    setLoading(true);

    //Data for request
    const data = {
      publicacion_id: post.id,
      type: "text",
      content: comment,
    };

    const responseData = await addPostComment(data);
    // console.log(responseData);

    if (responseData.error == "true") {
      toast.error(responseData.info.message + " ");
    } else {
      toast.success(responseData.info.message);
      // console.log(responseData.info);

      setNewComment({ post: post.id, count: 1 });
      setHasNewComment(true);
      setComents([...comments, responseData.info.data[0]]);
      // onCancel();
      setComent("");
    }

    setLoading(false);
  }

  const fetchPostData = async () => {
    if (!post.info) {
      const data = await getPublication(post.id);
      if (data.info?.data) {
        setFullPostData(data.info.data[0]);
      }
    }
  };

  const fetchComments = async () => {
    console.log("post", post);
    setIsLoadingCmts(true);
    try {
      const response = await getPostComments(post.id);
      const data = response.info.data[0].data;
      setComents([...data]);
      //console.log(response);
    } catch (error) {
      console.error(error);
    }
    setIsLoadingCmts(false);
  };

  useEffect(() => {
    fetchPostData();
    if (showComments) fetchComments();
  }, [showComments]);

  useEffect(() => {
    if (container.current && hasNewComment) {
      (container.current as HTMLDivElement).scrollTo({
        behavior: "smooth",
        top: (container.current as HTMLDivElement).scrollHeight,
      });
      setHasNewComment(false);
    }
  }, [comments.length]);

  const url = useMemo(() => {
    if (!post.hasMedia) return "";
    let res = "";
    if (!post.content.startsWith("http")) res += import.meta.env.VITE_BASE_URL;

    res += post.content;
    if (post.media == "video") {
      res = res.replace("content", "frame.jpg");
      // console.log(res);
    }

    return res;
  }, [post.media]);

  const endComponent = useMemo(() => {
    if (comment.length > 0 && !loading)
      return (
        <>
          <Dropdown className="top-0">
            <DropdownTrigger>
              <button>
                <EmojiIcon
                  stroke={"#667085"}
                  className="cursor-pointer"
                  width={16}
                  height={16}
                />
              </button>
            </DropdownTrigger>
            <DropdownMenu
              aria-label="Static Actions"
              emptyContent={
                <EmojiPicker
                  onEmojiClick={onEmojiClick}
                  emojiStyle={"native" as EmojiStyle}
                  open={true}
                />
              }
            >
              {[].map(() => (
                <DropdownItem></DropdownItem>
              ))}
            </DropdownMenu>
          </Dropdown>
          <Button
            isIconOnly
            aria-label="publish"
            size="sm"
            className="focus:outline-none absolute top-10 right-1 bg-zinc-300/10 mt"
            type="submit"
          >
            <IconamoonSendLight height={24} width={24} stroke={"#667085"} />
          </Button>
        </>
      );
    if (loading)
      return (
        <CircularProgress
          className="absolute top-2 right-2"
          size="sm"
          aria-label="Loading.."
        />
      );
    return (
      <Dropdown>
        <DropdownTrigger>
          <button>
            <EmojiIcon
              stroke={"#667085"}
              className="cursor-pointer"
              width={16}
              height={16}
            />
          </button>
        </DropdownTrigger>
        <DropdownMenu
          aria-label="Static Actions"
          emptyContent={
            <EmojiPicker
              onEmojiClick={onEmojiClick}
              emojiStyle={"native" as EmojiStyle}
              open={true}
            />
          }
        >
          {[].map(() => (
            <DropdownItem></DropdownItem>
          ))}
        </DropdownMenu>
      </Dropdown>
    );
  }, [comment, loading]);
  const urlVideo = useMemo(() => {
    if (!post.hasMedia || post.media != "video") return "";
    let res = "";
    if (!post.content.startsWith("http")) res += import.meta.env.VITE_BASE_URL;

    res += post.content;

    return res;
  }, [post.media]);

  return (
    <>
      <Modal
        className="max-w-[700px] overflow-visible"
        isOpen={isOpen}
        placement="top"
        onClose={onCancel}
        classNames={{
          closeButton: "mt-4 mr-4",
          base: "rounded-xl border-none overflow-visible",
        }}
        closeButton={<CloseIcon />}
      >
        <ModalContent className="overflow-visible">
          <div className=" pb-7 overflow-visible">
            <div className=" px-6 pt-6 w-full flex justify-center text-lg font-bold">
              <h3>
                Publicación de{" "}
                <Link
                  className="hover:text-primary"
                  to={`/perfil/${post.user?.slug}`}
                >
                  {post.user?.name}
                </Link>
              </h3>
            </div>
            <form className="w-full " onSubmit={handleSubmit}>
              <div className="flex justify-center">
                <div className="text-center w-full relative">
                  <div className="mt-6 w-full flex flex-col">
                    {showComments && (
                      <div
                        ref={container}
                        className="w-full px-6 mb-1 flex flex-col items-start h-[calc(100vh-300px)] overflow-y-auto"
                      >
                        <div className="w-full pb-4 mb-6 border-b-1">
                          <div className="flex items-center gap-2 mb-3">
                            <Link to={`/perfil/${post.user?.slug}`}>
                              <Avatar
                                showFallback
                                classNames={{
                                  base: "bg-zinc-100",
                                  icon: "text-black/50",
                                }}
                                className="w-12 h-12 "
                                src={
                                  post.user?.profile_path
                                    ? post.user?.profile_path.startsWith("http")
                                      ? post.user?.profile_path
                                      : import.meta.env.VITE_BASE_URL +
                                        post.user?.profile_path
                                    : undefined
                                }
                              />
                            </Link>
                            <div className="flex flex-col">
                              <label className="font-bold text-[16px] text-back">
                                <Link
                                  className="hover:text-primary"
                                  to={`/perfil/${post.user?.slug}`}
                                >
                                  {post.user?.name}
                                </Link>
                              </label>
                              <label className="font-bold text-[12px] text-color7">
                                {post.created_at && (
                                  <Moment fromNow locale="Es">
                                    {new Date(post.created_at) ??
                                      "1976-04-19T12:59-0500"}
                                  </Moment>
                                )}
                              </label>
                            </div>
                          </div>
                          <p
                            hidden={post.media == null ? false : true}
                            className="mt-8 font-medium text-[16px] text-black text-start"
                          >
                            {post.content}
                          </p>
                          {post.media && (
                            <div className="flex items-center justify-center overflow-hidden w-full h-[300px] rounded-xl bg-black relative">
                              {post.media === "video" ? (
                                <video
                                  id="my-video"
                                  className="video-js h-full rounded-xl w-full vjs-default-skin"
                                  controls
                                  preload="metadata"
                                  poster={url}
                                  ref={(node) => {
                                    if (node) {
                                      videojs(node, {
                                        autoplay: false,
                                        controls: true,
                                        responsive: true,
                                        fluid: true,
                                      });
                                    }
                                  }}
                                >
                                  <source
                                    src={urlVideo}
                                    type="application/x-mpegURL"
                                  />
                                  <p className="vjs-no-js">...</p>
                                </video>
                              ) : (
                                <img
                                  src={url}
                                  alt="Publicación"
                                  className="max-w-full z-0 max-h-full object-contain aspect-[16/9] cursor-pointer"
                                  onClick={() => {
                                    if (
                                      !post.blur ||
                                      (post.blur &&
                                        authData?.id == post.user.id)
                                    )
                                      setIsOpenLightbox({
                                        open: true,
                                        type: "image",
                                        poster: "",
                                        src: url,
                                      });
                                  }}
                                />
                              )}
                            </div>
                          )}
                          <div className="mt-4 flex justify-between items-end px-3">
                            {!fullPostData.info && (
                              <div className="flex">
                                <Skeleton className="flex rounded-full w-10 h-10 border-2 border-white" />
                                <Skeleton className=" -ml-3 flex rounded-full w-10 h-10 border-2 border-white" />
                              </div>
                            )}
                            {fullPostData.info?.lastReacciones.length ? (
                              <AvatarGroup max={3}>
                                {fullPostData.info?.lastReacciones?.map(
                                  (it) => (
                                    <Link
                                      key={it.id}
                                      to={`/perfil/${it.user?.slug}`}
                                    >
                                      <Avatar
                                        showFallback
                                        classNames={{
                                          base: "w-10 h-10 bg-zinc-100 border-2 border-white",
                                        }}
                                        name={it.user?.name}
                                        src={
                                          it.user?.profile_path
                                            ? it.user?.profile_path.startsWith(
                                                "http"
                                              )
                                              ? it.user?.profile_path
                                              : import.meta.env.VITE_BASE_URL +
                                                it.user?.profile_path
                                            : undefined
                                        }
                                      />
                                    </Link>
                                  )
                                )}
                              </AvatarGroup>
                            ) : (
                              <></>
                            )}
                            <span className="font-bold text-[16px] text-color7">
                              {!fullPostData.info ? (
                                <Skeleton className="h-6 w-28 rounded-xl" />
                              ) : (
                                <>
                                  {" "}
                                  Comentarios {fullPostData.info?.comentarios}
                                </>
                              )}
                            </span>
                          </div>
                        </div>

                        {comments.map((e, index) => (
                          <div
                            ref={index === 0 ? firstCommentRef : null}
                            key={e.id}
                            className="mb-4 flex w-full"
                          >
                            <ChatBubble
                              isMine={e.user_id == authData?.id}
                              content={e.content}
                              user={e.user}
                              dateString={e.created_at}
                            />
                          </div>
                        ))}
                        {isLoadingCmts && (
                          <div className="w-full flex justify-center py-5">
                            <Spinner color="primary" />
                          </div>
                        )}
                      </div>
                    )}
                    <div className="flex gap-2 border-t-1 border-gray-200 pt-4 px-6">
                      <Avatar
                        showFallback
                        classNames={{
                          base: "min-w-12 min-h-12 bg-zinc-100 border-2 border-white",
                        }}
                        name={authData?.name}
                        src={
                          authData?.profile_path
                            ? authData?.profile_path.startsWith("http")
                              ? authData?.profile_path
                              : import.meta.env.VITE_BASE_URL +
                                authData?.profile_path
                            : undefined
                        }
                      />
                      <div className="relative w-full">
                        <Textarea
                          placeholder="Escribe un comentario..."
                          value={comment}
                          onChange={handleChange}
                          required
                          endContent={endComponent}
                          className="w-full"
                          maxRows={3}
                          classNames={{
                            inputWrapper:
                              "borer-1 border-0 focus:ring-1 focus:ring-transparent ",
                            base: "w-full",
                            innerWrapper: "",
                            input:
                              "border-0 focus:ring-1 focus:ring-transparent",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </ModalContent>
      </Modal>
      <LightBoxImage
        src={isOpenLightbox.src}
        isOpen={isOpenLightbox.open}
        type={isOpenLightbox.type}
        poster={isOpenLightbox.poster}
        closeModal={() =>
          setIsOpenLightbox({
            open: false,
            type: "image",
            poster: "",
            src: "",
          })
        }
      />
    </>
  );
}
