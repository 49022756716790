/* eslint-disable no-mixed-spaces-and-tabs */
import { Card, Modal, ModalContent, Pagination, Skeleton, Input, Button } from "@nextui-org/react";
import SecondaryButton from "../common/SecondaryButton";
import { CloseIcon } from "../../assets/icons/CloseIcon";
import { Party } from "../../interfaces/Party";
import { FormEvent, useEffect, useState } from "react";
import { Pagination as PaginationData } from "../../interfaces/pagination";
import { getUsers } from "../../api/user";
import { User } from "../../interfaces/Login";
import { useAuthStore } from "../../stores/authStore";
import { SearchIcon } from "../../assets/icons/SearchIcon";
import PartyUserCard from "./PartyUserCard";
import { SexualOrientation } from "../../interfaces/SexualOrientation";
import { Province } from "../../interfaces/Provice";
import { masiveInviteParty } from "../../api/party";
import { toast } from "react-toastify";

export default function PartyUsersModal({
	isOpen = false,
	closeModal = () => {},
	party,
	setPartyData,
	orientations,
	provinces,
}: {
	isOpen: boolean;
	closeModal?: () => void;
	party: Party;
	setPartyData: (party: Party) => void;
	orientations: SexualOrientation[];
	provinces: Province[];
}) {
	const [isLoading, setIsLoading] = useState(false);
	const [users, setUsers] = useState<User[]>([]);
	const authData = useAuthStore((state) => state.user);
	const [search, setSearch] = useState("");
	const [pagination, setPagination] = useState<PaginationData>({
		current_page: 0,
		last_page: 0,
		total: 0,
		per_page: 1,
	});

	const handleChange = (e: FormEvent<HTMLInputElement>) => {
		const data = e.target as HTMLInputElement;
		setSearch(data.value);
	};

	const getData = async (page: number) => {
		setIsLoading(true);

		const filters = {
			name: search ?? "",
			limit: 30,
		};
		const res = await getUsers(page, filters);
		setUsers(res.info.data[0].data);
		setPagination({
			current_page: res?.info?.data[0].current_page,
			last_page: res?.info?.data[0].last_page,
			total: res?.info?.data[0].total,
			per_page: res?.info?.data[0].per_page,
		});
		setIsLoading(false);
	};

	useEffect(() => {
		if (authData) getData(1);
	}, []);

	const onPageChange = async (page: number) => {
		getData(page);
	};

	const onSendRequest = (id: number, user: User) => {
		setPartyData({
			...party,
			solicitudes: [
				...party.solicitudes,
				{
					id: id,
					user: user,
					estado: { name: "pendiente" },
					invitacion: 1,
				},
			],
		});
	};
	const [isLoadingInvAll, setIsLoadingInvAll] = useState(false);
	const inviteAll = async () => {
		setIsLoadingInvAll(true);
		try {
			const us: User[] = [];
			users.forEach((user) => {
				const exist = party.integrantes.find((u) => u.id == user.id);
				const request = party.solicitudes.find((req) => req.user.id == user.id);
				const maybe = party.talvez.find((req) => req.user.id == user.id);
				const declined = party.denegado.find((req) => req.id == user.id);

				if (!exist && !request && authData?.id != user.id && !maybe && !declined) {
					us.push(user);
				}
			});
			const ids = us.map((u) => u.id);
			if (ids.length == 0) {
				toast.error("No hay usuarios por invitar en esta lista");
				setIsLoadingInvAll(false);
				return;
			}
			const res = await masiveInviteParty(ids, party.id);
			console.log(res.error);
			if (res.errors || res.error) toast.error(res.message || res.info.message);
			else {
				toast.success(res.info.message);
				setPartyData({
					...party,
					solicitudes: [
						...party.solicitudes,
						...us.map((u) => ({
							id: u.id,
							user: u,
							estado: { name: "pendiente" },
							invitacion: 1,
						})),
					],
				});
			}
		} catch (error) {
			toast.error("Ha ocurrido un error");
		}
		setIsLoadingInvAll(false);
	};
	const [isLoadingInvCouples, setIsLoadingInvCouples] = useState(false);
	const inviteCouples = async () => {
		setIsLoadingInvCouples(true);
		try {
			const us: User[] = [];
			users.forEach((user) => {
				const exist = party.integrantes.find((u) => u.id == user.id);
				const request = party.solicitudes.find((req) => req.user.id == user.id);
				const isCouple = user.info?.is_couple;
				if (!exist && !request && authData?.id != user.id && isCouple) {
					us.push(user);
				}
			});
			const ids = us.map((u) => u.id);
			if (ids.length == 0) {
				toast.error("No hay parejas por invitar en esta lista");
				setIsLoadingInvCouples(false);
				return;
			}
			const res = await masiveInviteParty(ids, party.id);
			console.log(res.error);
			if (res.errors || res.error) toast.error(res.message || res.info.message);
			else {
				toast.success(res.info.message);
				setPartyData({
					...party,
					solicitudes: [
						...party.solicitudes,
						...us.map((u) => ({
							id: u.id,
							user: u,
							estado: { name: "pendiente" },
							invitacion: 1,
						})),
					],
				});
			}
		} catch (error) {
			toast.error("Ha ocurrido un error");
		}
		setIsLoadingInvCouples(false);
	};
	return (
		<Modal
			className=""
			isOpen={isOpen}
			placement="center"
			onClose={closeModal}
			classNames={{
				closeButton: "mt-4 mr-4",
				base: "rounded-xl border-none max-h-[90vh] overflow-auto",
			}}
			closeButton={<CloseIcon />}
			scrollBehavior="inside"
			size="5xl"
		>
			<ModalContent>
				<div className="">
					<div className="flex ">
						<div className="text-start w-full">
							<h3 className="mt-6 font-semibold text-color3 text-xl  px-6">
								Invita usuarios a unirse a {party.nombre}
							</h3>
							<p className="mt-2 font-normal text-[14px] text-color5"></p>
							<form
								onSubmit={(e: FormEvent<HTMLFormElement>) => {
									e.preventDefault();
									getData(1);
								}}
								className="px-4"
							>
								<Input
									className="min-w-sm text-input w-full "
									id="search"
									type="text"
									variant="bordered"
									label=""
									startContent={<SearchIcon />}
									placeholder="Buscar..."
									value={search}
									onChange={handleChange}
									endContent={
										<Button
											color="primary"
											variant="solid"
											className="rounded-lg "
											type="submit"
											size="sm"
										>
											Buscar
										</Button>
									}
									classNames={{ inputWrapper: " border-1 rounded-lg" }}
								/>
								<div className="w-full py-3 px-4 flex gap-5 text-gray-500">
									<button
										type="button"
										onClick={inviteAll}
										disabled={isLoadingInvAll || isLoadingInvCouples}
										className=" text-primary hover:text-color6"
									>
										{isLoadingInvAll ? "Invitando..." : "Invitar a todos"}
									</button>
									|
									<button
										type="button"
										onClick={inviteCouples}
										disabled={isLoadingInvCouples || isLoadingInvAll}
										className=" text-primary  hover:text-color6"
									>
										{isLoadingInvCouples ? "Invitando..." : " Invitar a todas las parejas"}
									</button>
								</div>
							</form>
							<div className="h-[330px] mt-4 py-2 gap-4 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 overflow-auto w-full  px-6">
								{isLoading
									? [0, 0, 0, 0].map((_, i) => (
											<Card
												className="w-full h-[200px] space-y-5 p-4 shadow-none border-1 border-gray-100"
												radius="lg"
												key={i}
											>
												<Skeleton className="rounded-lg">
													<div className="h-24 rounded-lg bg-default-300"></div>
												</Skeleton>
												<div className="space-y-3">
													<Skeleton className="w-3/5 rounded-lg">
														<div className="h-3 w-3/5 rounded-lg bg-default-200"></div>
													</Skeleton>
													<Skeleton className="w-4/5 rounded-lg">
														<div className="h-3 w-4/5 rounded-lg bg-default-200"></div>
													</Skeleton>
													<Skeleton className="w-2/5 rounded-lg">
														<div className="h-3 w-2/5 rounded-lg bg-default-300"></div>
													</Skeleton>
												</div>
											</Card>
									  ))
									: users.map((e) => (
											<PartyUserCard
												key={e.id}
												user={e}
												party={party}
												onSendRequest={(id) => onSendRequest(id, e)}
												orientations={orientations}
												provinces={provinces}
											/>
									  ))}
							</div>
							{pagination && pagination?.current_page > 0 && (
								<div className="px-4 mt-3 mb-2 w-full">
									<div className="flex justify-center">
										<Pagination
											showControls
											total={pagination.last_page}
											initialPage={1}
											onChange={onPageChange}
										/>
									</div>
								</div>
							)}
						</div>
					</div>
					<div className="p-6 pt-0 md:flex block justify-end">
						<div className="md:mt-0 mt-4 flex gap-3 justify-end w-full">
							<SecondaryButton
								className="md:w-auto w-full md:px-6 px-4 py-2 rounded-lg shadow-sm text-center bg-gray-100 hover:bg-gray-200 transition-all duration-150"
								onClick={closeModal}
							>
								Terminar
							</SecondaryButton>
						</div>
					</div>
				</div>
			</ModalContent>
		</Modal>
	);
}
