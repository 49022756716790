import { ControlPosition, MapControl } from "@vis.gl/react-google-maps";

// import { PlaceAutocompleteClassic } from "./autocomplete-classic";
// import { AutocompleteCustomHybrid } from "./autocomplete-hybrid";
import { AutocompleteCustom } from "./autocomplete-custom";

type CustomAutocompleteControlProps = {
  controlPosition: ControlPosition;
  onPlaceSelect: (place: google.maps.places.PlaceResult | null) => void;
  customValue: string;
};

export const CustomMapControl = ({
  controlPosition,
  onPlaceSelect,
  customValue,
}: CustomAutocompleteControlProps) => {
  return (
    <MapControl position={controlPosition}>
      <div className="autocomplete-control">
        {/* <PlaceAutocompleteClassic onPlaceSelect={onPlaceSelect} /> */}
        {/* <AutocompleteCustomHybrid onPlaceSelect={onPlaceSelect} /> */}
        <AutocompleteCustom
          onPlaceSelect={onPlaceSelect}
          customValue={customValue}
        />
      </div>
    </MapControl>
  );
};
