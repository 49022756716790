/* eslint-disable no-mixed-spaces-and-tabs */
import {
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Button,
  DropdownTrigger,
  // DropdownSection,
  Spinner,
  User,
  DropdownSection,
} from "@nextui-org/react";
import NavBar from "../components/common/Navbar";
import Footer from "../components/common/Footer";
import PrimaryButton from "../components/common/PrimaryButton";

import { MenuIcon } from "../assets/icons/MenuIcon";
// import { BlockIcon } from "../assets/icons/LockIcon";
import { CopyIcon } from "../assets/icons/CopyIcon";
// import { FlagIcon } from "../assets/icons/FlagIcon";
import { LocationIcon } from "../assets/icons/LockationIcon";
import { HeartIcon } from "../assets/icons/HeartIcon";
import { CalendarIcon } from "../assets/icons/CalendarIcon";
import { Link, useParams } from "react-router-dom";
import { Key, useEffect, useMemo, useState } from "react";
import { useAuthStore } from "../stores/authStore";
import { Trip as TripData } from "../interfaces/Trip";
import { getTrip, likeTrip } from "../api/trip";
import { useDateFormatter } from "@react-aria/i18n";
import Moment from "react-moment";
import { Marker, Map } from "@vis.gl/react-google-maps";
import CreateTripModal from "../components/trip/CreateTripModal";
import { withErrorHandler } from "../utils/withErrorFallback";
import { TrashCanIcon } from "../assets/icons/TrashCanIcon";
import DeleteTripModal from "../components/trip/DeleteTripModal";

const menuItems = [
  {
    key: "copy",
    icon: <CopyIcon />,
    label: "Copiar enlace del viaje",
  },
];

const INITIAL_CENTER = { lat: 40.416775, lng: -3.70379 };
export default function Trip() {
  const { tripId } = useParams<{ tripId: string }>();
  if (!tripId) throw new Error("Slug is missing");

  const [tripData, setTripData] = useState<TripData | undefined>();
  const authData = useAuthStore((state) => state.user);

  const [isLoading, setIsLoading] = useState(false);
  const [isOpenEditModal, setIsOpenEditModal] = useState(false);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const [place, setPlace] = useState("");
  const [center, setCenter] = useState(INITIAL_CENTER);
  const [ckeck, setCheck] = useState(
    tripData?.likes.find((e) => e.user_id == authData?.id) ? true : false
  );

  const getData = withErrorHandler(async () => {
    setIsLoading(true);
    if (tripId) {
      const res = await getTrip(parseInt(tripId));
      if (res) {
        setTripData(res.info.data[0]);
        const data = res.info.data[0] as TripData;
        if (data)
          setCheck(
            data.likes.find((e) => e.user_id == authData?.id) ? true : false
          );
        try {
          const aux = res.info.data[0]?.localizacion.split("|");
          // console.log(aux.length == 2);
          if (aux.length == 2) {
            const lat = parseFloat(aux[0]?.toString());
            const lng = parseFloat(aux[1]?.toString());
            // console.log(lat, lng);
            setCenter({ lat: lat, lng: lng });
            const auxPlace = await getPlaceFormated(lat, lng);
            if (auxPlace) setPlace(auxPlace);
          }
        } catch (error) {
          console.error(error);
        }
      }
    }

    setIsLoading(false);
  });

  useEffect(() => {
    // console.log(authData?.info?.fantasias_id);

    if (authData) getData();
  }, [authData]);

  const formatter = useDateFormatter({
    dateStyle: "long",
  });

  const getPlaceByLatLng = async (lat: number, lng: number) => {
    const geocoder = new google.maps.Geocoder();
    const results = await geocoder.geocode({ location: { lat, lng } });
    if (results) return results;
  };

  const getPlaceFormated = async (lat: number, lng: number) => {
    const place = await getPlaceByLatLng(lat, lng);

    // console.log(place);
    return place?.results[0].formatted_address;
  };

  const handleLike = async () => {
    if (tripData?.id) {
      // console.log(ckeck);
      setCheck(!ckeck);
      await likeTrip(tripData?.id);
      // console.log(res);
    }
  };
  const dangerMenuitems = useMemo(() => {
    if (authData?.id == tripData?.user_id)
      return [
        {
          key: "delete",
          icon: <TrashCanIcon />,
          label: "Eliminar",
        },
      ];

    return [];
  }, [tripData]);
  return (
    <div className=" h-screen">
      <NavBar />
      <main className="p-4 lg:px-10 flex justify-center w-full overflow-hidden">
        {isLoading && (
          <div className="w-full flex justify-center pt-20 h-[50vh]">
            <Spinner color="primary" />
          </div>
        )}
        {!isLoading && (
          <div className="w-full max-w-8xl">
            <section className="w-full pb-10">
              <div
                className={`h-96 rounded-md w-full bg-center bg-cover bg-[url('/assets/fantasyImage.jpeg')] `}
                style={{
                  backgroundImage: ` url(${
                    tripData?.imagen
                      ? tripData?.imagen.startsWith("http")
                        ? tripData?.imagen
                        : import.meta.env.VITE_BASE_URL + tripData?.imagen
                      : undefined
                  })`,
                }}
              ></div>
              <div className="flex flex-col md:flex-row flex-wrap justify-between lg:px-2 w-full mt-10">
                <div className="flex gap-3 flex-col  w-full lg:w-8/12 lg:pr-8">
                  <div className="w-full flex items-center justify-between ">
                    <div className="w-full flex items-center gap-4">
                      <Link to={`/profile/${tripData?.user_id}`}>
                        <User
                          name={tripData?.user.name}
                          description={
                            <span className="text-sm text-gray-500">
                              <Moment fromNow locale="Es">
                                {tripData?.created_at}
                              </Moment>
                            </span>
                          }
                          avatarProps={{
                            src: tripData?.user?.profile_path
                              ? tripData?.user?.profile_path.startsWith("http")
                                ? tripData?.user?.profile_path
                                : import.meta.env.VITE_BASE_URL +
                                  tripData?.user?.profile_path
                              : undefined,
                          }}
                        />
                      </Link>
                    </div>
                    <div className="flex  mt-5 gap-2 lg:hidden">
                      <Button
                        className={`border-1 bg-color8 like ${
                          ckeck ? "checked" : ""
                        }`}
                        variant="bordered"
                        isIconOnly
                        aria-label="like"
                        onClick={handleLike}
                      >
                        <div className="checkmark flex items-center justify-center">
                          <HeartIcon height={24} width={24} />
                        </div>
                      </Button>

                      <Dropdown>
                        <DropdownTrigger>
                          <Button
                            className="border-1"
                            variant="bordered"
                            isIconOnly
                            aria-label="menu"
                          >
                            <MenuIcon />
                          </Button>
                        </DropdownTrigger>
                        <DropdownMenu
                          variant="flat"
                          aria-label="Dropdown menu with icons"
                          onAction={async (key: Key) => {
                            if (key == "copy")
                              await navigator.clipboard.writeText(
                                location.href
                              );
                            else setIsOpenDeleteModal(true);
                          }}
                        >
                          <DropdownSection>
                            {menuItems.map((e) => (
                              <DropdownItem
                                key={e.key}
                                startContent={e.icon}
                                color="primary"
                              >
                                {e.label}
                              </DropdownItem>
                            ))}
                          </DropdownSection>
                          <DropdownSection>
                            {dangerMenuitems.map((e) => (
                              <DropdownItem
                                key={e.key}
                                className="text-danger"
                                color="danger"
                                startContent={e.icon}
                              >
                                {e.label}
                              </DropdownItem>
                            ))}
                          </DropdownSection>
                        </DropdownMenu>
                      </Dropdown>
                      {tripData?.user_id == authData?.id ? (
                        <PrimaryButton
                          onClick={() => setIsOpenEditModal(true)}
                          className=" w-full max-w-sm"
                        >
                          <div className="flex items-center gap-2">
                            <span className="">Editar </span>
                          </div>
                        </PrimaryButton>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                  <div className="w-full flex flex-col mt-4">
                    <h1 className="text-4xl text-color3 font-bold">
                      {tripData?.nombre}
                    </h1>

                    <div className=" flex flex-wrap gap-2 mt-5">
                      {tripData?.fantasias?.map((fantasy) => (
                        <Link to={`/fantasia/${fantasy.slug}`} key={fantasy.id}>
                          <Button
                            radius="sm"
                            variant="bordered"
                            className=" border-1 border-color1/20"
                            size="sm"
                          >
                            {fantasy.name}
                          </Button>
                        </Link>
                      ))}
                    </div>
                    <div className="flex flex-col md:flex-row gap-6 mt-5">
                      {/* <span className="text-gray-500 flex gap-2 itesms-center">
                        <b>Origen</b>{" "}
                        <LocationIcon width={20} stroke={"#A24BFD"} /> Alicante
                      </span> */}
                      <span className="text-gray-500 flex gap-2 itesms-center">
                        <b>Destino</b>{" "}
                        <LocationIcon width={20} stroke={"#A24BFD"} />{" "}
                        {place ?? tripData?.place}
                      </span>
                      <span className="text-gray-500  flex gap-2 items-center">
                        {tripData && (
                          <span className="text-gray-500  flex gap-2 items-center">
                            <CalendarIcon width={20} stroke={"#A24BFD"} />{" "}
                            {tripData.fecha_fin && tripData.fecha_inicio
                              ? formatter.formatRange(
                                  new Date(tripData.fecha_inicio),
                                  new Date(tripData.fecha_fin)
                                )
                              : "--"}
                          </span>
                        )}
                      </span>
                    </div>
                    <div className="flex flex-col mt-6">
                      <h3 className="text-2xl text-color3 font-semibold">
                        Descripción
                      </h3>
                      <p className="mt-6 text-color1">
                        {tripData?.descripcion}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="flex lg:flex-col flex-wrap w-full lg:w-4/12 mt-6 lg:mt-0">
                  <div className="w-full  sm:pr-5 lg:pr-0">
                    <div className="hidden lg:flex gap-2 ">
                      <div className="hidden lg:flex w-full items-center justify-end gap-3">
                        <Button
                          className={`border-1 bg-color8 like ${
                            ckeck ? "checked" : ""
                          }`}
                          variant="bordered"
                          isIconOnly
                          aria-label="like"
                          onClick={handleLike}
                        >
                          <div className="checkmark flex items-center justify-center">
                            <HeartIcon height={24} width={24} />
                          </div>
                        </Button>

                        <Dropdown>
                          <DropdownTrigger>
                            <Button
                              className="border-1"
                              variant="bordered"
                              isIconOnly
                              aria-label="menu"
                            >
                              <MenuIcon />
                            </Button>
                          </DropdownTrigger>
                          <DropdownMenu
                            variant="flat"
                            aria-label="Dropdown menu with icons"
                            onAction={async (key: Key) => {
                              if (key == "copy")
                                await navigator.clipboard.writeText(
                                  location.href
                                );
                              else setIsOpenDeleteModal(true);
                            }}
                          >
                            <DropdownSection>
                              {menuItems.map((e) => (
                                <DropdownItem
                                  key={e.key}
                                  startContent={e.icon}
                                  color="primary"
                                >
                                  {e.label}
                                </DropdownItem>
                              ))}
                            </DropdownSection>
                            <DropdownSection>
                              {dangerMenuitems.map((e) => (
                                <DropdownItem
                                  key={e.key}
                                  className="text-danger"
                                  color="danger"
                                  startContent={e.icon}
                                >
                                  {e.label}
                                </DropdownItem>
                              ))}
                            </DropdownSection>
                          </DropdownMenu>
                        </Dropdown>
                        {tripData?.user_id == authData?.id ? (
                          <PrimaryButton
                            className=" max-w-xs"
                            onClick={() => setIsOpenEditModal(true)}
                          >
                            <div className="flex items-center gap-2">
                              <span className="">Editar </span>
                            </div>
                          </PrimaryButton>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                    <div className="border-1 object-contain mt-5 border-gray-300 rounded-2xl  min-h-[300px]">
                      <div className="w-full flex-row gap-4 flex ">
                        <div className="w-full  min-h-[300px] bg-zinc-300 border-gray-300  flex items-center justify-center rounded-xl border-2 cursor-pointer overflow-hidden">
                          <Map
                            defaultCenter={center}
                            defaultZoom={5}
                            gestureHandling={"greedy"}
                            disableDefaultUI={true}
                            scrollwheel={false}
                            zoomControl={true}
                          >
                            <Marker position={center} />
                          </Map>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        )}
      </main>
      <CreateTripModal
        isOpen={isOpenEditModal}
        closeModal={() => setIsOpenEditModal(false)}
        onSuccessCreateTrip={() => {}}
        tripToEdid={tripData}
        onSuccessEdit={() => {
          getData();
        }}
      />
      {tripData && (
        <DeleteTripModal
          id={tripData.id}
          isOpen={isOpenDeleteModal}
          name={tripData.nombre}
          closeModal={() => setIsOpenDeleteModal(false)}
        />
      )}
      <Footer />
    </div>
  );
}
