/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button } from "@nextui-org/react";

export default function PrimaryButton({
  type,
  as,
  onClick = () => {},
  className,
  href,
  children,
  target,
  loading = false,
  disabled = false,
}: {
  type?: "button" | "submit" | "reset" | undefined;
  as?: any;
  onClick?: Function;
  className?: string;
  href?: string;
  children?: any;
  target?: string;
  loading?: boolean;
  disabled?: boolean;
}) {
  return (
    <Button
      type={type}
      href={href}
      as={as}
      variant="solid"
      className={
        "h-[44px] border-small text-[16px] text-white rounded-small font-semibold bg-color4 " +
        className
      }
      onClick={() => onClick()}
      target={target}
      isLoading={loading}
      isDisabled={disabled || loading}
    >
      {children}
    </Button>
  );
}
