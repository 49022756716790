import { Bounce, ToastContainer } from "react-toastify";

export default function ToastConfig() {
  const contextClass = {
    success: "bg-primary text-white",
    error: "bg-red-500 text-white",
    info: "bg-gray-600",
    warning: "bg-orange-400",
    default: "bg-white font-gray-700",
    dark: "bg-white font-gray-300",
  };

  return (
    <ToastContainer
      position="bottom-right"
      autoClose={3000}
      hideProgressBar={true}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      icon={false}
      pauseOnHover={false}
      theme="light"
      transition={Bounce}
      toastClassName={(context) =>
        contextClass[context?.type || "default"] +
        " relative flex p-1 py-2 pl-2 min-h-10 rounded-md"
      }
    />
  );
}
